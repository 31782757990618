import {
    PaginationResponse,
    RawHydraResponse,
    RawHydraResponseIntoPaginationResponse,
} from "@/types/paginationResponse";
import { amenities, jsonApiCall } from "@/api/apiCall";
import { Amenity } from "@/types/amenity";

export async function fetchAmenitiesPage(page: number, perPage: number): Promise<PaginationResponse<Amenity> | null> {
    const r = await jsonApiCall<RawHydraResponse<Amenity>>({
        url: `${amenities}?page=${page}&per_page=${perPage}`,
        method: "GET",
        headers: {
            "accept": "application/ld+json",
        },
    });

    if (r !== null) {
        return RawHydraResponseIntoPaginationResponse<Amenity>(r);
    } else {
        return null;
    }
}

export async function fetchAllAmenities(): Promise<Array<Amenity> | null> {
    const r = await jsonApiCall<Array<Amenity>>({
        url: amenities,
        method: "GET",
    });

    return r;
}

export async function apiAddNewAmenity(name: string): Promise<Amenity | null> {
    const r = await jsonApiCall<Amenity>({
        url: amenities,
        method: "POST",
        jsonData: {
            name,
        }
    });

    return r;
}
