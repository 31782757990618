
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Notification as BeysNotification } from "@/types/notification";
import Button, { ButtonType } from "@/components/Ui/Button/Button.vue";
import { formatDistance } from "date-fns";
import { bs } from "date-fns/locale";
import DropdownMenu from "@/components/Ui/DropdownMenu/DropdownMenu.vue";
import DropdownMenuItem from "@/components/Ui/DropdownMenu/DropdownMenuItem.vue";
import { notificationsModule } from "@/store/notifications/notifications";
import Spinner from "@/components/Ui/Spinner/Spinner.vue";

@Component({
    components: { Spinner, DropdownMenuItem, DropdownMenu, Button },
})
export default class SingleNotification extends Vue {
    @Prop() readonly notification!: BeysNotification;
    ButtonType = ButtonType;

    markAsReadAsync = false;
    deleteAsync = false;

    get async(): boolean {
        return this.markAsReadAsync || this.deleteAsync;
    }

    async markNotificationRead(): Promise<void> {
        this.markAsReadAsync = true;
        await notificationsModule.markNotificationAsReadAction(this.notification.id);
        this.markAsReadAsync = false;
    }

    async deleteNotification(): Promise<void> {
        this.deleteAsync = true;
        await notificationsModule.deleteNotificationAction(this.notification.id);
        this.deleteAsync = false;
    }

    get formattedCreatedAt(): string {
        return formatDistance(new Date(this.notification.createdAt), new Date(), { addSuffix: true, locale: bs });
    }

    onMouseOut(): void {
        (this.$refs.dropdownRef as any).hide();
    }

    mounted(): void {
        this.$el.addEventListener("mouseleave", this.onMouseOut);
    }
}
