
import Vue from "vue";
import Component from "vue-class-component";
import ExpandableContainer from "@/components/ExpandableContainer/ExpandableContainer.vue";
import InputLabel from "@/components/Ui/InputLabel/InputLabel.vue";
import SelectField from "@/components/Ui/SelectField/SelectField.vue";
import InputField from "@/components/Ui/InputField/InputField.vue";
import Checkbox, { CheckboxType } from "@/components/Ui/Checkbox/Checkbox.vue";
import PlaceholderableImage from "@/components/Ui/PlaceholderableImage/PlaceholderableImage.vue";
import { UploadedFile } from "@/types/mediaObject";
import { Passenger, PassengerAge, PassengerFormErrors } from "@/types/Passenger";
import { Prop } from "vue-property-decorator";
import { settingsModule } from "@/store/settings/settings";
import UploaderModal from "@/components/UploaderModal/UploaderModal.vue";
import GIcon from "@/components/Ui/GIcon/GIcon.vue";
import Button, { ButtonType } from "@/components/Ui/Button/Button.vue";
import { isEmpty } from "lodash";

enum ImagePickerMode {
    Passenger = 'Passenger',
    Passport = 'Passport'
}

@Component({
    components: {
        Button,
        GIcon,
        UploaderModal,
        PlaceholderableImage,
        Checkbox,
        InputField,
        SelectField,
        InputLabel,
        ExpandableContainer
    }
})
export default class PassengerForm extends Vue {
    @Prop({}) passenger!: Passenger;
    @Prop({ default: "Putnik" }) label!: string;
    @Prop({ default: false }) showActions!: boolean;
    @Prop({
        default: () => {
            return {}
        }
    }) errors!: PassengerFormErrors;


    CheckboxType = CheckboxType
    ButtonType = ButtonType

    currentPassengerIndex: number | null = null;
    imagePickerMode: ImagePickerMode | null = null;
    formExpanded = false;

    images: Array<UploadedFile> = [];

    ageOptions = [
        {
            label: this.$tc('adults'),
            value: PassengerAge.ADULT,
        },
        {
            label: this.$tc('children'),
            value: PassengerAge.CHILD_UNDER_12,
        },
        {
            label: `${this.$tc('children')} (${this.$tc('up_to_6_years')})`,
            value: PassengerAge.CHILD_UNDER_6,
        },
    ];

    get imageModalOpened(): boolean {
        return this.imagePickerMode !== null;
    }

    get visaPrice(): number {
        return settingsModule.visaPrice;
    }

    get hasError(): boolean {
        return !isEmpty(this.errors);
    }

    handleEditPassengerImage(): void {
        this.imagePickerMode = ImagePickerMode.Passenger;
    }

    handleEditPassportImage(): void {
        this.imagePickerMode = ImagePickerMode.Passport;
    }

    addImage(images: Array<UploadedFile>): void {
        if (this.imagePickerMode === ImagePickerMode.Passport) {
            this.passenger.passportImage = images[0];
        } else {
            this.passenger.passengerImage = images[0];
        }
    }

    onClickSave(): void {
        this.formExpanded = false;
    }
}
