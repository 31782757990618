// import router from '../router';

import { cloneDeep, merge } from 'lodash';
import { showSwalToast } from '@/utils/utils';
import { StorageKeys } from '@/utils/consts';
import * as Sentry from '@sentry/vue';
import { errors } from '@/utils/errors';

// ENDPOINTS

export const endpointNoApi = process.env.VUE_APP_ENDPOINT || 'http://localhost:8000';
export const endpoint = endpointNoApi + '/api';
export const storage_url = process.env.VUE_APP_STORAGE_URL || 'http://localhost:8001';
export const socket_url = process.env.VUE_APP_WS_ENDPOINT || 'http://localhost:8070';

export const reservations = `${endpoint}/reservations`;
export const manualReservations = `${endpoint}/manual_reservations`;
export const amenities = `${endpoint}/amenities`;
export const additionalContentCategories = `${endpoint}/additional_content_categories`;
export const login = `${endpoint}/login`;
export const me = `${endpoint}/me`;
export const users = `${endpoint}/users`;
export const statistics = `${endpoint}/statistics`;
export const rooms = `${endpoint}/rooms`;
export const availabilities = `${endpoint}/availabilities`;
export const beds = `${endpoint}/beds`;
export const notifications = `${endpoint}/notifications`;
export const agencies = `${endpoint}/agencies`;
export const media_objects = `${endpoint}/media_objects`;
export const additionalContent = `${endpoint}/additional_contents`;
export const hotels = `${endpoint}/hotels`;
export const roomBeds = `${endpoint}/room_beds`;
export const roomVariants = `${endpoint}/room_variants`;
export const settings = `${endpoint}/settings_keys`;
export const offers = `${endpoint}/offers`;
export const hotelSpecialOffers = `${endpoint}/hotel_special_offers`;
export const cities = `${endpoint}/cities`;
export const available_cities = `${endpoint}/available_cities`;
export const paymentHistory = `${endpoint}/payment_history`;

export interface ApiCallParams {
    url: string,
    noAuth?: boolean,
    headers?: Record<string, string>,
    body?: ReadableStream<Uint8Array> | string | null | FormData,
    jsonData?: Record<string, unknown>,
    method?: 'GET' | 'POST' | 'PUT' | 'DELETE',
}

export interface GenericErrorResponse {
    detail: string,
}

export interface AuthErrorResponse {
    message: string,
}

export interface DefaultApiCallParams {
    headers: Record<string, string>,
    method: 'GET' | 'POST' | 'PUT' | 'DELETE',
    body?: ReadableStream<Uint8Array> | string | null,
}

export function handleErrors(response: Response): Response | undefined {
    if (response.ok || response.status === 204) {
        return response;
    } else {
        throw response;
    }
}

export async function jsonApiCall<T>(params: ApiCallParams): Promise<T | null> {
    const r = await apiCall(params);

    if (r && r.status !== 204) {
        return await r.json();
    } else {
        return null;
    }
}

export function apiCall(params: ApiCallParams = {
    url: '',
    body: undefined,
    jsonData: undefined,
    method: 'GET',
    noAuth: false,
}): Promise<Response | undefined> {

    const originalParams = cloneDeep(params);

    const token = localStorage.getItem(StorageKeys.TOKEN);

    const defaultParams: DefaultApiCallParams = {
        headers: {
            'content-type': 'application/json',
        },
        method: 'GET',
        body: null,
    };

    if (params.body) {
        delete defaultParams.headers['content-type'];
    }

    if (params.jsonData) {
        params.body = JSON.stringify(params.jsonData);
        delete params.jsonData;
    }

    if (token && !params.noAuth) {
        defaultParams.headers['Authorization'] = `Bearer ${token}`;
    }

    const actualParams = merge(defaultParams, params);

    const p = new Promise<Response | undefined>((resolve, reject) => {
        return fetch(params.url, actualParams)
            .then(handleErrors)
            .then(r => resolve(r))
            .catch(async ex => {
                if (ex.status === 401) {
                    if (ex.json) {
                        const message = await ex.json()
                            .then((data: AuthErrorResponse) => {
                                return data.message;
                            });
                        if (message === 'Invalid credentials.') {
                            // this is login, propagate this error up
                            // showSwalToast(message);
                            reject(message);
                        } else if (message === 'Expired JWT Token') {
                            // we should probs refresh token here
                            const refreshToken = localStorage.getItem(StorageKeys.REFRESH_TOKEN);

                            if (refreshToken) {
                                // proceed
                                const data = new FormData();
                                data.append('refresh_token', refreshToken);
                                return fetch(endpoint + '/token/refresh', {
                                    method: 'post',
                                    body: data,
                                })
                                    .then(handleErrors)
                                    .then(r => r?.json())
                                    .then(r => {
                                        localStorage.setItem(StorageKeys.TOKEN, r.token);
                                        localStorage.setItem(StorageKeys.REFRESH_TOKEN, r.refresh_token);
                                        //redo api call
                                        apiCall(originalParams)
                                            .then(resolve);
                                    })
                                    .catch(() => {
                                        logOut();
                                    });
                            } else {
                                logOut();
                            }
                        } else if (message === 'An authentication exception occurred.') {
                            // USER PROBABLY GOT DISABLED
                            showSwalToast(message);
                            logOut();
                        }
                    }
                } else if (ex.status === 404) {
                    // showSwalToast(ex.url + '\n' + ex.statusText);
                } else {
                    if (ex.json) {
                        try {
                            await ex.json()
                                .then((data: GenericErrorResponse) => {
                                    if (data.detail) {
                                        Sentry.captureMessage(data.detail);
                                        showSwalToast(errors[data.detail] ?? data.detail);
                                    }
                                });
                        } catch (ex) {
                            // this is most likely an  internal server that returned html body.
                            // rip
                            Sentry.captureException(ex);
                            showSwalToast('Desila se nepoznata greška');
                            reject('Desila se nepoznata greška');
                        }
                    }
                }

                reject(ex);
            });
    });

    return p;
}

export function logOut(): void {
    localStorage.removeItem(StorageKeys.TOKEN);
    localStorage.removeItem(StorageKeys.REFRESH_TOKEN);
    window.location.reload();
}
