
import Component from "vue-class-component";
import Vue from "vue";
import Checkbox from "@/components/Ui/Checkbox/Checkbox.vue";
import Button, { ButtonType } from "@/components/Ui/Button/Button.vue";
import { reservationsModule } from "@/store/reservations/reservations";

@Component({
    components: { Button, Checkbox },
})
export default class DeleteReservation extends Vue {
    ButtonType = ButtonType;
    deleteConfirmed = false;

    get async(): boolean {
        return reservationsModule.deleteAsync;
    }

    onDeleteReservation(): void {
        if (!this.deleteConfirmed) {
            return;
        }
        this.$emit("onDelete");
    }

    cancel(): void {
        this.$emit("cancel");
    }
}
