
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

@Component
export default class PlaceholderableImage extends Vue {
    @Prop() readonly src!: string;
    @Prop() readonly clazz!: string;
    isError = false;

    get isPdf(): boolean {
        return Boolean(this.src) && (this.src.endsWith("pdf") || this.src.includes("application/pdf"));
    }

    onClickPdf() {
        if (this.src.startsWith("http")) {
            window.open(this.src, "_blank");
        }
    }

    @Watch("src")
    onChangeSrc(): void {
        this.isError = false;
        this.$forceUpdate();
    }

}
