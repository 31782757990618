
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import { AddFormItem_Input } from "@/types/addForm/addFormItem";
import Component from "vue-class-component";
import InputLabel from "@/components/Ui/InputLabel/InputLabel.vue";
import InputField from "@/components/Ui/InputField/InputField.vue";
import { snakeCase } from "snake-case";

@Component({
    components: {InputField, InputLabel},
})
export default class AddContentInputNumberPlusMinus extends Vue {
    @Prop() readonly item!: AddFormItem_Input;
    @Prop() readonly entity!: Record<string, unknown>;

    get accessor(): string {
        return snakeCase(this.item.name);
    }

    mounted(): void {
        if (!this.entity[this.accessor]) {
            Vue.set(this.entity, this.accessor, 0);
        }
    }

    decrement(): void {
        if (this.entity[this.accessor] as number > 0) {
            (this.entity[this.accessor] as number) -= 1;
        }
    }

    increment(): void {
        (this.entity[this.accessor] as number) += 1;
    }
}
