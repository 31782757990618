
import Vue from "vue";
import Component from "vue-class-component";
import { agenciesModule } from "@/store/agencies/agencies";
import Spinner from "@/components/Ui/Spinner/Spinner.vue";
import CustomTable, { TableField } from "@/components/Ui/CustomTable/CustomTable.vue";
import { ListAgency } from "@/types/agency";
import Button from "@/components/Ui/Button/Button.vue";
import ListPagination from "@/components/ListPagination/ListPagination.vue";
import { RouteLink, RouteName } from "@/router";
import AdminPageHeader from "@/components/AdminPageHeader/AdminPageHeader.vue";
import InputLabel from "@/components/Ui/InputLabel/InputLabel.vue";
import InputField from "@/components/Ui/InputField/InputField.vue";

@Component({
    components: { AdminPageHeader, ListPagination, Button, CustomTable, Spinner, InputLabel, InputField },
    mounted() {
        agenciesModule.fetchAgenciesFirstPage();
    },
})
export default class Agencies extends Vue {
    tableFields: Array<TableField<ListAgency>> = [{
        name: "",
        title: "Sortiraj",
        slot: "info",
    }, {
        name: "createdAt",
        title: "Početak suradnje",
        slot: "createdAt",
    }, {
        name: "actions",
        title: "Akcije",
        width: "15%",
        slot: "actions",
    }];

    get currentPage(): number {
        return agenciesModule.currentPage;
    }

    get totalPages(): number {
        return agenciesModule.totalPages;
    }

    get agencies(): Array<ListAgency> {
        return agenciesModule.agencies;
    }

    get async(): boolean {
        return agenciesModule.fetchAsync;
    }

    get agenciesSearch(): string {
        return agenciesModule.search;
    }

    onPageChange(page: number): void {
        agenciesModule.fetchAgenciesPageAction(page);
    }

    addNewAgency(): void {
        this.$router.push(RouteLink.ManageAgency);
    }

    editAgency(agency: ListAgency): void {
        this.$router.push({
            name: RouteName.ManageAgency,
            params: {
                agencyId: agency.id,
            },
        });
    }

    updateAgenciesSearchText(text: string): void {
        agenciesModule.setSearchText(text);
    }

    onSearch(): void {
        agenciesModule.fetchAgenciesFirstPage();
    }
}
