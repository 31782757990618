import { UserRoles } from "@/utils/consts";
import { Agency } from "@/types/agency";
import { MediaObject } from "@/types/mediaObject";
import { isAgent, isSuperAdmin } from "@/utils/filters";

export interface User {
    agency: Agency | null,
    createdAt: string,
    email: string,
    enabled: boolean,
    firstName: string,
    lastName: string | null,
    isActive: boolean,
    id: string,
    middleName: string | null,
    phone: string | null,
    roles: Array<UserRoles>,
    profilePicture: MediaObject | null
}

export interface ListUser {
    agency: Agency | null,
    createdAt: string,
    email: string,
    enabled: boolean,
    firstName: string,
    lastName: string | null,
    isActive: boolean,
    id: string,
    middleName: string | null,
    phone: string | null,
    roles: Array<UserRoles>,
    profilePicture: MediaObject | null
}

export class UserForm {
    email?: string;
    enabled?: boolean;
    firstName?: string;
    lastName?: string | null;
    isActive?: boolean;
    middleName?: string | null;
    phone?: string | null;
    user_role?: UserRoles;
    password?: string;
    confirmPassword?: string;
    profilePicture?: string | null;
    agency?: string;

    static createFormWithUser(user: User): UserForm {

        const form = { ...user, agency: undefined, profilePicture: user?.profilePicture?.id } as UserForm;

        form.user_role = UserRoles.ROLE_USER;

        if (isSuperAdmin(user)) {
            form.user_role = UserRoles.ROLE_SUPER_ADMIN
        } else if (isAgent(user)) {
            form.user_role = UserRoles.ROLE_AGENCY_ADMIN
        }

        delete form.agency;

        return form;
    }

}
