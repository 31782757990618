import { Amenity } from "@/types/amenity";
import { RoomAvailability } from "@/types/availability";
import { MediaObject } from "@/types/mediaObject";
import { cloneDeep, merge } from "lodash";
import { difference } from "@/utils/utils";
import { Bed, RoomBedForm } from "@/types/beds";
import { Hotel } from "@/types/hotel";
import { RoomVariant } from "@/types/roomVariant";

export interface ListRoom {
    id: string,
    createdAt: string,
    name: string,
    descriptionBs: string | null,
    descriptionEn: string | null,
    capacity: number,
    beds: number,
    availabilities: Array<RoomAvailability>,
    image: string,
    price: number;
    amenities: Array<Amenity>,
    mediaObjects: Array<MediaObject>,
    roomBeds: Array<RoomBed>,
    roomVariants: Array<RoomVariant>
}

export interface RoomBed {
    id: string,
    bed: Bed,
    quantity: number,
}

export interface PostRoomBed {
    bed: string,
    quantity: number,
}

export interface Room {
    id: string,
    createdAt: string,
    name: string,
    descriptionBs: string | null,
    descriptionEn: string | null,
    capacity: number,
    roomBeds: Array<RoomBed>
    availabilities: Array<RoomAvailability>,
    image: string,
    price: number;
    amenities: Array<Amenity>,
    mediaObjects: Array<MediaObject>,
    hotel?: Hotel,
    q?: number,
    roomVariants: Array<RoomVariant>
}

export interface IRoomForm {
    id?: string,
    createdAt?: string,
    name?: string,
    descriptionBs?: string | null,
    descriptionEn?: string | null,
    q?: number,
    roomBeds?: Array<RoomBedForm>
    availabilities?: Array<RoomAvailability>,
    image?: string,
    price?: number;
    hotel?: string,
    amenities?: Array<string>,
    mediaObjects?: Array<string | MediaObject>,
    roomVariants?: Array<RoomVariant>
}

export class RoomForm implements IRoomForm {
    name?: string;
    descriptionBs?: string | null;
    descriptionEn?: string | null;
    q?: number;
    roomBeds?: Array<RoomBedForm>;
    availabilities?: Array<RoomAvailability>;
    image?: string;
    price?: number;
    hotel?: string;
    amenities: Array<string> = [];
    mediaObjects: Array<string | MediaObject> = [];
    roomVariants: Array<RoomVariant> = [];

    constructor(props: IRoomForm = {}) {
        merge(this, props);
    }

    static createFormWithRoom(room: Room): RoomForm {

        const { hotel, amenities, mediaObjects, ...needed } = room;

        return new RoomForm({
            ...needed,
            hotel: hotel?.id || "",                 // TODO, only cause mock_data (later remove hotel being optional)
            amenities: amenities.map(a => a.id),
            mediaObjects: cloneDeep(mediaObjects),
        });
    }

    public validate(): boolean {
        return this.name !== "";            // TODO
    }

    public prepareFormForSending(): void {
        this.price = this.price && +this.price;
        this.q = this.q && +this.q;
    }

    public difference(anotherForm: IRoomForm): IRoomForm {
        const diff = difference(this as IRoomForm, anotherForm) as IRoomForm;

        // Override diff
        delete diff.roomBeds;
        delete diff.roomVariants;

        return diff;
    }
}

export interface AvailableRoomsForDateRangeResponse {
    max_q: number,
}

export enum RoomServiceType {
    BED_AND_BREAKFAST = "BED_AND_BREAKFAST",
    HALF_BOARD = "HALF_BOARD",
    FULL_BOARD = "FULL_BOARD",
    ALL_INCLUSIVE = "ALL_INCLUSIVE",
    ULTRA_ALL_INCLUSIVE = "ULTRA_ALL_INCLUSIVE",
}

export const RoomServiceTypeToPricingMap = {
    [RoomServiceType.ALL_INCLUSIVE]: "allInclusiveSupplement",
    [RoomServiceType.BED_AND_BREAKFAST]: "adults",
    [RoomServiceType.HALF_BOARD]: "halfBoardSupplement",
    [RoomServiceType.FULL_BOARD]: "fullBoardSupplement",
    [RoomServiceType.ULTRA_ALL_INCLUSIVE]: "ultraAllInclusive",
};
