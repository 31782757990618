
import Vue from "vue";
import Component from "vue-class-component";
import Button, { ButtonType } from "@/components/Ui/Button/Button.vue";
import { RouteLink } from "@/router";
import { endpointNoApi } from "@/api/apiCall";

@Component({
    components: { Button },
})
export default class ReservationComplete extends Vue {
    ButtonType = ButtonType;


    goToReservations(): void {
        this.$router.push(RouteLink.Reservations);
    }

    onPrintButton(): void {
        const { params: { reservationId } } = this.$route;

        if (reservationId) {
            window.open(`${endpointNoApi}/public/reservations/${reservationId}/generate_confirmation_pdf`, "_blank");
        }
    }
}
