
import Component from "vue-class-component";
import Vue from "vue";
import Button, { ButtonType } from "@/components/Ui/Button/Button.vue";
import InputLabel from "@/components/Ui/InputLabel/InputLabel.vue";
import InputField, { InputFieldDisplayType } from "@/components/Ui/InputField/InputField.vue";
import Uploader from "@/components/Ui/Uploader/Uploader.vue";
import { loginModule } from "@/store/login/login";
import { cloneDeep, isEmpty } from "lodash";
import { difference, showSwalToast } from "@/utils/utils";
import { User, UserForm } from "@/types/user";
import Spinner from "@/components/Ui/Spinner/Spinner.vue";
import AdminPageHeader from "@/components/AdminPageHeader/AdminPageHeader.vue";
import CustomUploader from "@/components/Ui/CustomUploader/CustomUploader.vue";
import { Prop, Watch } from "vue-property-decorator";
import { mediaModule } from "@/store/media_objects/media_objects";
import { MediaObject, UploadedFile } from "@/types/mediaObject";
import { usersModule } from "@/store/users/users";
import { BUILD_VERSION, RELEASE_TIMESTAMP, UserRoles } from "@/utils/consts";
import UploaderModal from "@/components/UploaderModal/UploaderModal.vue";
import PlaceholderableImage from "@/components/Ui/PlaceholderableImage/PlaceholderableImage.vue";
import SelectField from "@/components/Ui/SelectField/SelectField.vue";
import moment from "moment";
import ChangePasswordModal from "@/views/ManageUser/components/ChangePasswordModal.vue";

interface FormErrors {
    firstName?: string,
    lastName?: string,
    phone?: string,
    password?: string,
    confirmPassword?: string
}

@Component({
    components: {
        ChangePasswordModal,
        SelectField,
        PlaceholderableImage,
        UploaderModal,
        CustomUploader,
        AdminPageHeader,
        Spinner,
        Uploader,
        InputField,
        InputLabel,
        Button,
    },
})
export default class ManageUser extends Vue {
    @Prop({ default: true }) readonly showHeader!: boolean;

    errors: FormErrors = {};
    images: Array<UploadedFile> = [];
    form: UserForm = {};
    uploadAsync = false;

    changePasswordModalOpen = false;
    InputFieldDisplayType = InputFieldDisplayType;
    ButtonType = ButtonType;
    roleOptions = usersModule.roleOptions;

    uploaderOpened = false;

    get async(): boolean {
        return loginModule.userAsync || loginModule.loginAsync || usersModule.addEditAsync;
    }

    get isSuperAdmin(): boolean {
        return loginModule.isSuperAdmin;
    }

    get user(): User | null {
        return usersModule.selectedUser;
    }

    get userImage(): UploadedFile | MediaObject {
        return this.images[0] ?? this.user?.profilePicture;
    }

    get versionText(): string {
        return `${this.$tc("version")} ${BUILD_VERSION}@${moment(RELEASE_TIMESTAMP).format("DD/MM/YY")}`;
    }

    goBack(): void {
        this.$router.back();
    }

    onRemoveImage(file: UploadedFile): void {
        this.images = this.images.filter(i => i.url !== file.url);
    }

    openChangePasswordModal(): void {
        this.changePasswordModalOpen = true;
    }

    validateFields(): boolean {
        const errors: FormErrors = {};

        const { password, confirmPassword } = this.form;

        if (!this.form.firstName) {
            errors.firstName = "Ime je obavezno";
        }

        if (!this.form.lastName) {
            errors.lastName = "Prezime je obavezno";
        }

        if (password) {
            if (password.length > 0 && password.length < 8) {
                errors.password = "Dužina šifre mora biti veća od 8.";
            } else if (password !== confirmPassword) {
                errors.confirmPassword = "Šifre se ne slažu.";
            }
        }

        this.errors = errors;

        return Object.keys(errors).length === 0;
    }

    async onSubmit(): Promise<void> {
        if (!this.validateFields()) return;

        if (this.user) {
            const diff = difference(this.form, UserForm.createFormWithUser(this.user)) as UserForm;

            try {
                let mediaObj = null;

                if (this.images.length) {
                    this.uploadAsync = true;
                    mediaObj = await mediaModule.uploadMediaObject({ file: this.images[0] }).finally(() => this.uploadAsync = false);
                }

                if (mediaObj) {
                    diff.profilePicture = `/api/media_objects/${mediaObj.id}`;
                }

                if (!isEmpty(diff)) {
                    await usersModule.editUserByIdAction({ userId: this.user.id, data: diff });
                }

                if (diff.password) {
                    await usersModule.changeUserPasswordByIdAction({
                        userId: this.user.id,
                        password: diff.password,
                    }).then(() => {
                        delete this.form.password;
                        delete this.form.confirmPassword;
                    });
                }

                // this.goToSettings();
            } catch (ex) {
                //
            }
        } else {
            // we adding
            const form = cloneDeep(this.form);
            let mediaObj = null;

            if (this.images.length) {
                this.uploadAsync = true;
                mediaObj = await mediaModule.uploadMediaObject({ file: this.images[0] }).finally(() => this.uploadAsync = false);
            }

            if (mediaObj) {
                form.profilePicture = mediaObj.id;
            }

            if (this.agencyId !== null) {
                form.agency = `/api/agencies/${this.agencyId}`;
                form.user_role = UserRoles.ROLE_AGENCY_ADMIN;
            }

            await usersModule.addUserAction(form).then(success => {
                if (success) {
                    showSwalToast("Korisnik uspješno dodan.", "success");
                    this.form.password = "";
                    this.form.confirmPassword = "";
                    this.$router.back();
                }
            });
        }
    }

    async beforeMount(): Promise<void> {
        const { params: { userId, agencyId } } = this.$route;

        if (userId) {
            await usersModule.fetchUserByIdAction(userId);
        }

        if (usersModule.selectedUser) {
            this.setData(usersModule.selectedUser);
        }
    }

    beforeDestroy() {
        usersModule.selectUser(null);
        this.form = {};
    }

    get agencyId(): string | null {
        const { params: { agencyId } } = this.$route;

        return agencyId ?? null;
    }

    @Watch("user")
    onUserUpdated(val: User): void {
        this.setData(val);
    }

    transformFormFields(form: UserForm): UserForm {
        return form;
    }

    setData(user: User): void {
        this.form = this.transformFormFields(cloneDeep(UserForm.createFormWithUser(user)));
    }
}
