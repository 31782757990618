import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { store } from "@/store";
import { SettingsKey } from "@/types/settings";
import { apiDeleteSettingsKey, apiFetchSettings } from "@/api/settings/settings";

@Module({ name: "SettingsModule", store: store, dynamic: true })
export default class SettingsModule extends VuexModule {
    // settings
    settings: Array<SettingsKey> = []
    settingsAsync = false;

    @Mutation
    setSettings(settings: Array<SettingsKey>): void {
        this.settings = settings;
    }

    @Mutation
    setSettingsAsync(async: boolean): void {
        this.settingsAsync = async;
    }

    @Action
    async fetchSettings(): Promise<Array<SettingsKey>> {
        this.setSettingsAsync(true);

        const res = await apiFetchSettings().finally(() => this.setSettingsAsync(false))

        this.setSettings(res ?? [])

        return res ?? [];
    }

    @Action
    async deleteSettingsKey(keyId: string): Promise<void> {
        this.setSettingsAsync(true);
        apiDeleteSettingsKey(keyId).then(() => this.fetchSettings()).then(() => this.setSettingsAsync(false))
    }

    get visaPrice(): number {
        return Number(this.settings.find(s => s.key.toUpperCase() === "VISA_PRICE_IN_FILS")?.value ?? 0) ?? 8000;
    }

    get adminMarkup(): number {
        return Number(this.settings.find(s => s.key.toUpperCase() === "ADMIN_MARKUP")?.value ?? 0) ?? 0;
    }
}

export const settingsModule = getModule(SettingsModule);
