import { AddFormPage } from "@/types/addForm/addFormPage";
import { merge } from "lodash";
import { difference } from "@/utils/utils";
import { AddForm } from "@/types/addForm/addForm";
import { AddFormItem } from "@/types/addForm/addFormItem";

export interface ListAdditionalContentCategory {
    id: string,
    createdAt: string,
    nameBs: string,
    nameEn: string | null,
    description: string | null,
    isMultiSelect: boolean,
    isPerPerson: boolean,
    fields: Array<AddFormPage>,
    canPriceBeDoubled: boolean,
    doublePriceLabel?: string,
    agentFields: Array<AddFormItem>
}

export interface AdditionalContentCategory {
    id: string,
    createdAt: string,
    nameBs: string,
    nameEn: string | null,
    description: string | null,
    isMultiSelect: boolean,
    isPerPerson: boolean,
    canPriceBeDoubled: boolean,
    doublePriceLabel?: string
    fields: Array<AddFormPage>,
    agentFields: Array<AddFormItem>
}

export interface IContentCategoryForm {
    nameBs?: string,
    nameEn?: string | null,
    description?: string,
    config?: AddForm,
    isMultiSelect?: boolean,
    isPerPerson?: boolean,
    fields?: Array<AddFormPage>,
    canPriceBeDoubled?: boolean,
    doublePriceLabel?: string
}

export class ContentCategoryForm implements IContentCategoryForm {
    nameBs = '';
    nameEn = '';
    description = '';
    isMultiSelect = false;
    isPerPerson = false;
    canPriceBeDoubled = false;
    doublePriceLabel = '';
    config = new AddForm([]);
    fields: Array<AddFormPage> = [];            // Only for sending (TODO, rework)

    constructor(props: IContentCategoryForm = {}) {
        merge(this, props);
    }

    static createFormWithCategory(category: AdditionalContentCategory): ContentCategoryForm {

        return new ContentCategoryForm({
            ...category,
            description: category.description || '',
            config: AddForm.parse(JSON.stringify(category.fields))
        });
    }

    public validate(): boolean {
        return this.nameBs !== '';            // TODO
    }

    public prepareFormForSending(): void {
        this.fields = this.config.pages;
    }

    public difference(anotherForm: IContentCategoryForm): IContentCategoryForm {

        const diff = difference(this as IContentCategoryForm, anotherForm) as IContentCategoryForm

        // override
        // diff.fields = this.config.pages;
        delete diff.fields;     // TODO
        delete diff.config;

        return diff;
    }
}
