
import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class Bugling extends Vue {
    beforeMount(): void {
        throw new Error("broken");
    }
}
