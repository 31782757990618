import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { store } from "@/store";
import {
    AdditionalContentCategory,
    IContentCategoryForm,
    ListAdditionalContentCategory,
} from "@/types/additionalContentCategory";
import {
    deleteAdditionalContentCategory,
    editAdditionalContentCategory,
    fetchAdditionalContentCategoriesPage, fetchAdditionalContentCategoryById,
    fetchAllAdditionalContentCategories, postAddAdditionalContentCategory,
} from "@/api/additional_content_categories/additional_content_categories";
import { AdditionalContentCategoryForm } from "@/types/AdditionalContentCategoryForm";
import { ListHotel } from "@/types/hotel";

@Module({ name: "AdditionalContentCategoriesModule", store: store, dynamic: true })
export default class AdditionalContentCategoriesModule extends VuexModule {
    fetchAsync = false;
    additionalContentCategories: Array<AdditionalContentCategory> = [];
    hasMore = true;
    currentPage = 0;
    perPage = 10;
    totalPages = 1;
    addEditAsync = false;
    deleteAsync = false;
    selectedAdditionalContentCategory: AdditionalContentCategory | null = null;

    @Mutation
    setCategoriesFetchAsync(async: boolean): void {
        this.fetchAsync = async;
    }

    @Mutation
    setCategoriesAddEditAsync(async: boolean): void {
        this.addEditAsync = async;
    }

    @Mutation
    setCategoriesDeleteAsync(async: boolean): void {
        this.deleteAsync = async;
    }

    @Mutation
    setCurrentPage(page: number): void {
        this.currentPage = page;
    }

    @Mutation
    setHasMore(hasMore: boolean): void {
        this.hasMore = hasMore;
    }

    @Mutation
    setPerPage(perPage: number): void {
        this.perPage = perPage;
    }

    @Mutation
    setTotalPages(totalPages: number): void {
        this.totalPages = totalPages;
    }

    @Mutation
    appendAdditionalContentCategories(additionalContentCategories: Array<AdditionalContentCategory>): void {
        this.additionalContentCategories.concat(additionalContentCategories);
    }

    @Mutation
    selectAdditionalContentCategory(additionalContentCategory: AdditionalContentCategory | null): void {
        this.selectedAdditionalContentCategory = additionalContentCategory;
    }

    @Mutation
    setAdditionalContentCategories(additionalContentCategories: Array<AdditionalContentCategory>): void {
        this.additionalContentCategories = additionalContentCategories;
    }

    @Action
    async fetchAllAdditionalContentCategories(): Promise<void> {
        this.setCategoriesFetchAsync(true);

        try {
            const r = await fetchAllAdditionalContentCategories();

            if (r !== null) {
                this.setAdditionalContentCategories(r);
                this.setCurrentPage(1);
                this.setHasMore(false);
            }
        } catch (e) {
            console.error(e);
        } finally {
            this.setCategoriesFetchAsync(false);
        }
    }

    @Action
    async fetchAdditionalContentCategoryFirstPage(): Promise<void> {
        return this.fetchAdditionalContentCategoryPageAction(1);
    }

    @Action
    async fetchAdditionalContentCategoryPageAction(page: number): Promise<void> {
        this.setCategoriesFetchAsync(true);

        try {
            const r = await fetchAdditionalContentCategoriesPage(page, this.perPage);

            if (r !== null) {
                this.setAdditionalContentCategories(r.data);
                this.setTotalPages(Math.ceil(r.totalItems / this.perPage));
                this.setCurrentPage(page);
                this.setHasMore(r.hasMore);
            }
        } catch (e) {
            console.error(e);
        } finally {
            this.setCategoriesFetchAsync(false);
        }
    }

    @Action
    async selectAdditionalContentCategoryById(categoryId: string | null): Promise<AdditionalContentCategory | null> {
        if (categoryId !== null) {
            let category = this.additionalContentCategories.find((cat) => cat.id === categoryId) || null;
            if (category) {
                this.selectAdditionalContentCategory(category);
            } else {
                this.setCategoriesFetchAsync(true);

                category = await fetchAdditionalContentCategoryById(categoryId);
                this.selectAdditionalContentCategory(category);

                this.setCategoriesFetchAsync(false);
            }

            return category;
        } else {
            this.selectAdditionalContentCategory(null);

            return null;
        }
    }

    @Action
    async addNewAdditionalContentCategory({ data }: { data: AdditionalContentCategoryForm }): Promise<void> {
        this.setCategoriesAddEditAsync(true);

        try {
            const category = await postAddAdditionalContentCategory(data);

            if (category !== null) {
                this.appendAdditionalContentCategories([category]);
            }
        } catch (ex) {
            console.error(ex);
        } finally {
            this.setCategoriesAddEditAsync(false);
        }
    }

    @Action
    async editAdditionalContentCategory({
                                            categoryId,
                                            data,
                                        }: { categoryId: string, data: IContentCategoryForm }): Promise<void> {

        this.setCategoriesAddEditAsync(true);

        try {
            const updatedCategory = await editAdditionalContentCategory(categoryId, data);

            if (updatedCategory !== null) {
                this.selectAdditionalContentCategory(updatedCategory);
            }
        } catch (e) {
            console.error(e);
        } finally {
            this.setCategoriesAddEditAsync(false);
        }
    }

    @Action({ rawError: true })
    async deleteAdditionalContentCategoryAction(categoryId: string): Promise<null | void> {

        if (this.deleteAsync) return;

        this.setCategoriesDeleteAsync(true);

        return deleteAdditionalContentCategory(categoryId).finally(() => this.setCategoriesDeleteAsync(false));
    }

    get getAdditionalContentCategoryById(): (id: string) => ListAdditionalContentCategory | undefined {
        return (id: string) => {
            return this.additionalContentCategories.find((c) => c.id === id);
        };
    }

}

export const additionalContentCategoriesModule = getModule(AdditionalContentCategoriesModule);
