import {
    PaginationResponse,
    RawHydraResponse,
    RawHydraResponseIntoPaginationResponse,
} from "@/types/paginationResponse";
import { jsonApiCall, additionalContent } from "@/api/apiCall";
import {
    AdditionalContent,
    AdditionalContentForm, AdditionalContentQuery,
    IAdditionalContentForm,
    ListAdditionalContent,
} from "@/types/additionalContent";

export async function fetchAdditionalContentPage(page: number, perPage: number, additionalContentCategoryId: string | null = null,): Promise<PaginationResponse<ListAdditionalContent> | null> {
    const url = new URL(`${additionalContent}?page=${page}&per_page=${perPage}`);

    url.searchParams.append("page", page.toString());
    url.searchParams.append("per_page", perPage.toString());

    if (additionalContentCategoryId !== null) {
        url.searchParams.append("category", additionalContentCategoryId);
    }

    const r = await jsonApiCall<RawHydraResponse<ListAdditionalContent>>({
        url: url.toString(),
        method: "GET",
        headers: {
            "accept": "application/ld+json",
        },
    });

    if (r !== null) {
        return RawHydraResponseIntoPaginationResponse<ListAdditionalContent>(r);
    } else {
        return null;
    }
}

export async function addAdditionalContent(data: AdditionalContentForm): Promise<AdditionalContent | null> {
    const r = await jsonApiCall<AdditionalContent>({
        url: additionalContent,
        method: "POST",
        jsonData: data as unknown as Record<string, unknown>
    });

    if (r !== null) {
        return r;
    }

    return null;
}

export async function editAdditionalContent(additional_contentId: string, data: IAdditionalContentForm): Promise<AdditionalContent | null> {
    const r = await jsonApiCall<AdditionalContent>({
        url: `${additionalContent}/${additional_contentId}`,
        method: "PUT",
        jsonData: data as Record<string, unknown>
    });

    if (r !== null) {
        return r;
    }

    return null;
}

export async function fetchAdditionalContentById(additional_contentId: string): Promise<AdditionalContent | null> {
    const r = await jsonApiCall<AdditionalContent>({
        url: `${additionalContent}/${additional_contentId}`,
    });

    if (r !== null) {
        return r;
    }

    return null;
}

export async function deleteAdditionalContent(contentId: string): Promise<null> {

    return await jsonApiCall<null>({
        url: `${additionalContent}/${contentId}`,
        method: "DELETE"
    });
}


export async function apiSearchAdditionalContent(queryData: AdditionalContentQuery): Promise<Array<ListAdditionalContent> | null> {
    const url = new URL(`${additionalContent}/search`);

    if (queryData.name) {
        url.searchParams.append('name', queryData.name);
    }

    return await jsonApiCall<Array<ListAdditionalContent>>({
        url: url.toString(),
        method: "GET",
    });
}
