
import Vue from "vue";
import Component from "vue-class-component";
import InputLabel from "../../../components/Ui/InputLabel/InputLabel.vue";
import InputField from "../../../components/Ui/InputField/InputField.vue";
import { Prop } from "vue-property-decorator";
import { RoomForm } from "@/types/room";
import TextareaField from "@/components/Ui/TextareaField/TextareaField.vue";
import { InputFieldType } from "@/components/Ui/InputField/InputField.vue";
import { Language } from "@/types/language";

@Component({
    components: { TextareaField, InputField, InputLabel }
})
export default class AboutRoomForm extends Vue {
    @Prop() roomForm!: RoomForm;
    @Prop() readonly roomQuantity!: number

    InputFieldType = InputFieldType

    descLanguages: Language[] = [Language.bs, Language.en];
    activeLang: Language = Language.bs;

    get description(): string {
        return (this.activeLang === Language.bs ? this.roomForm.descriptionBs : this.roomForm.descriptionEn) ?? "";
    }

    set description(val: string) {
        if (this.activeLang === Language.bs) {
            this.roomForm.descriptionBs = val;
        } else {
            this.roomForm.descriptionEn = val;
        }
    }
}
