
import Vue from "vue";
import Component from "vue-class-component";
import Button, { ButtonType } from "@/components/Ui/Button/Button.vue";
import { goBackOrPush, showSwalToast } from "@/utils/utils";
import { RouteLink } from "@/router";
import { Bed, BedForm, IBedForm } from "@/types/beds";
import { bedsModule } from "@/store/beds/beds";
import Spinner from "@/components/Ui/Spinner/Spinner.vue";
import DeleteBed from "@/views/EditBed/components/DeleteBed.vue";
import InputLabel from "@/components/Ui/InputLabel/InputLabel.vue";
import InputField from "@/components/Ui/InputField/InputField.vue";
import TextareaField from "@/components/Ui/TextareaField/TextareaField.vue";
import AdminPageHeader from "@/components/AdminPageHeader/AdminPageHeader.vue";

enum EditBedSectionType {
    ABOUT = "ABOUT",
    DELETE = "DELETE"
}

interface EditBedSection {
    name: string,
    type: EditBedSectionType
}

@Component({
    components: {
        AdminPageHeader,
        TextareaField,
        InputField,
        InputLabel,
        DeleteBed,
        Spinner,
        Button
    },
})
export default class EditBed extends Vue {
    EditBedSectionType = EditBedSectionType;
    ButtonType = ButtonType
    goBackOrPush = goBackOrPush;
    RouteLink = RouteLink;

    sections: Array<EditBedSection> = [
        {
            name: 'Osnovne informacije',
            type: EditBedSectionType.ABOUT
        },
    ];

    selectedSection = EditBedSectionType.ABOUT;

    bedForm = new BedForm();

    get fetchAsync(): boolean {
        return bedsModule.fetchAsync;
    }

    get editAsync(): boolean {
        return bedsModule.addEditAsync;
    }

    selectSection(section: EditBedSectionType): void {
        this.selectedSection = section;
    }

    backToBeds(): void {
        goBackOrPush(RouteLink.BedContent);
    }

    async onSubmit(): Promise<void> {
        if (!this.bedForm.validate()) return;

        if (bedsModule.selectedBed) {

            this.bedForm.prepareFormForSending();

            const diff = this.bedForm.difference(BedForm.createFormWithBed(bedsModule.selectedBed)) as IBedForm

            try {
                await bedsModule.editBedAction({ bedId: bedsModule.selectedBed.id, data: diff });
            } catch (ex) {
                showSwalToast("Error saving bed.");
            }
        }
    }

    async beforeMount() {

        const { params: { bedId } } = this.$route;


        if (bedId) {
            await bedsModule.fetchBedByIdAction(bedId);
        }


        if (bedsModule.selectedBed) {
            this.setData(bedsModule.selectedBed);
        }
    }

    setData(bed: Bed): void {
        this.bedForm = BedForm.createFormWithBed(bed);
    }

}
