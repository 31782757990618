
import Vue from "vue";
import Component from "vue-class-component";
import Container from "@/components/Ui/Container/Container.vue";
import {Prop} from "vue-property-decorator";
import {ReservationClass} from "@/types/reservation";
import {AdditionalContent, AdditionalContentUtils, ReservationAdditionalContent} from "@/types/additionalContent";
import {reservationFormModule, ReservationFormSelectedRoomVariant} from "@/store/reservation_form/reservation_form";
import ReceiptRoom from "@/views/SingleReservation/components/ReceiptRoom.vue";
import {RoomAvailability} from "@/types/availability";
import Decimal from "decimal.js";
import {settingsModule} from "@/store/settings/settings";
import {Language} from "@/types/language";

@Component({
    components: { ReceiptRoom, Container },
})
export default class Receipt extends Vue {
    @Prop() readonly reservation?: ReservationClass;
    @Prop({ default: false }) readonly isNewReservation!: boolean;

    get additionalContent(): Array<ReservationAdditionalContent> {
        return this.reservation?.reservationAdditionalContents ?? [];
    }

    get passengersWithoutVisa(): number {
        return [...this.reservation?.passengers ?? []].reduce<number>((acc, passenger) => {
            if (!passenger.hasVisa) {
                acc += 1;
            }

            return acc;
        }, 0);
    }

    get singleVisaPrice(): number {
        return settingsModule.visaPrice;
    }

    get totalVisaPrice(): number {
        return this.passengersWithoutVisa * this.singleVisaPrice;
    }

    getAdditionalContentItemPrice(item: ReservationAdditionalContent): number {
        return new Decimal(item.additionalContent.priceInCents).mul(item.passengerCount).mul(item.isPriceDoubled ? 2 : 1).toNumber();
    }

    getItemDoublePriceLabel(item: ReservationAdditionalContent): string {
        return `${item.additionalContent.category.doublePriceLabel ?? ""} (2x)`;
    }

    get rooms(): Array<{ room: { availabilities: RoomAvailability[], name: string, id: string, }, variants: Record<string, ReservationFormSelectedRoomVariant> }> {
        if (this.isNewReservation) {
            return reservationFormModule.rooms;
        } else {
            if (this.reservation) {
                return new ReservationClass(this.reservation).rooms;
            }
        }

        return [];
    }

    get totalAccomodationPrice(): number {
        if (this.isNewReservation) {
            return reservationFormModule.totalAccommodationPrice;
        } else {
            return this.reservation?.totalAccommodationPrice ?? 0;
        }
    }

    get allAdditionalContentItemsPrice(): number {
        let totalPrice = 0;

        this.reservation?.reservationAdditionalContents?.forEach(v => {
            totalPrice += this.getAdditionalContentItemPrice(v);
        });

        return totalPrice;
    }

    get earlyBookingDiscount(): number | null {
        if (this.isNewReservation) {
            return reservationFormModule.earlyBookingDiscount;
        } else {
            return this.reservation?.earlyBookingDiscount ?? null;
        }
    }

    get totalPrice(): number {
        return this.allAdditionalContentItemsPrice + this.totalAccomodationPrice + this.totalVisaPrice;
    }

    getAdditionalContentName(ac: AdditionalContent): string {
        return AdditionalContentUtils.resolveName(ac, this.$i18n.locale as Language) ?? "";
    }
}
