
import Vue from "vue";
import Component from "vue-class-component";
import Spinner from "@/components/Ui/Spinner/Spinner.vue";
import CustomTable from "@/components/Ui/CustomTable/CustomTable.vue";
import InputLabel from "@/components/Ui/InputLabel/InputLabel.vue";
import InputField, { InputFieldDisplayType, InputFieldType } from "@/components/Ui/InputField/InputField.vue";
import Button, { ButtonType } from "@/components/Ui/Button/Button.vue";
import SelectField from "@/components/Ui/SelectField/SelectField.vue";
import { Agency, AgencyForm } from "@/types/agency";
import { loginModule } from "@/store/login/login";
import { agenciesModule } from "@/store/agencies/agencies";
import { difference } from "@/utils/utils";

@Component({
    components: { SelectField, Button, InputField, InputLabel, CustomTable, Spinner },
})
export default class GeneralSettingsAgent extends Vue {
    InputType = InputFieldType;
    ButtonType = ButtonType;
    InputFieldDisplayType = InputFieldDisplayType;

    form: AgencyForm = {};

    get async(): boolean {
        return this.agency === null;
    }

    get editAsync(): boolean {
        return agenciesModule.addEditAsync;
    }

    get agency(): Agency | null {
        return loginModule.user?.agency ?? null;
    }

    async onSubmit(): Promise<void> {
        if (this.agency === null) return;

        const diff = difference(this.form, AgencyForm.createFormWithAgency(this.agency))

        try {
            await agenciesModule.editAgencyAction({ agencyId: this.agency.id, data: diff });
        } catch (ex) {
            //
        }
    }

    beforeMount(): void {
        if (this.agency !== null) {
            this.form = AgencyForm.createFormWithAgency(this.agency);
        } else {
            this.form = new AgencyForm();
        }
    }
}
