import { render, staticRenderFns } from "./AboutCategoryForm.vue?vue&type=template&id=9357e344&scoped=true&"
import script from "./AboutCategoryForm.vue?vue&type=script&lang=ts&"
export * from "./AboutCategoryForm.vue?vue&type=script&lang=ts&"
import style0 from "./AboutCategoryForm.vue?vue&type=style&index=0&id=9357e344&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9357e344",
  null
  
)

export default component.exports