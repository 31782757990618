
import Vue from "vue";
import Component from "vue-class-component";
import Spinner from "@/components/Ui/Spinner/Spinner.vue";
import CustomTable from "@/components/Ui/CustomTable/CustomTable.vue";
import Button, { ButtonType } from "@/components/Ui/Button/Button.vue";
import ListPagination from "@/components/ListPagination/ListPagination.vue";
import { agenciesModule } from "@/store/agencies/agencies";
import SelectField from "@/components/Ui/SelectField/SelectField.vue";
import InputLabel from "@/components/Ui/InputLabel/InputLabel.vue";
import InputField, {
    InputFieldType,
} from "@/components/Ui/InputField/InputField.vue";
import { Agency, AgencyForm, IAgencyForm } from "@/types/agency";
import { AdditionalContentCategory } from "@/types/additionalContentCategory";
import { additionalContentCategoriesModule } from "@/store/additional_content_categories/additional_content_categories";
import { difference } from "@/utils/utils";
import { Watch } from "vue-property-decorator";

@Component({
    components: {
        ListPagination,
        Button,
        InputLabel,
        InputField,
        SelectField,
        CustomTable,
        Spinner,
    },
})
export default class AgencyDiscounts extends Vue {
    ButtonType = ButtonType;
    InputFieldType = InputFieldType;

    agencyForm: AgencyForm = new AgencyForm();

    async onSubmit(): Promise<void> {

        if (agenciesModule.selectedAgency) {

            const diff: IAgencyForm = difference(this.agencyForm, AgencyForm.createFormWithAgency(agenciesModule.selectedAgency))


            try {

                // override
                diff.discount = this.agencyForm.discount;
                delete diff.email;

                await agenciesModule.editAgencyAction({ agencyId: agenciesModule.selectedAgency.id, data: diff });
            } catch (ex) {
                //
            }
        }
    }

    @Watch("agency")
    onAgencyChanged(agency: Agency | null): void {
        if (agency) {
            this.agencyForm = AgencyForm.createFormWithAgency(agency);
        }
    }

    beforeMount() {
        if (this.agency !== null) {
            this.agencyForm = AgencyForm.createFormWithAgency(this.agency);
        }

        additionalContentCategoriesModule.fetchAllAdditionalContentCategories();
    }

    get additionalContentCategories(): AdditionalContentCategory[] {
        return additionalContentCategoriesModule.additionalContentCategories;
    }

    get async(): boolean {
        return (
            agenciesModule.fetchAsync ||
            agenciesModule.addEditAsync ||
            additionalContentCategoriesModule.fetchAsync
        );
    }

    get agency(): Agency | null {
        return agenciesModule.selectedAgency;
    }
}
