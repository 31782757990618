
import SingleHotelRoomHeader from "@/views/SingleHotelRoom/components/SingleHotelRoomHeader.vue";
import { Room } from "@/types/room";
import Component from "vue-class-component";
import Vue from "vue";
import Spinner from "@/components/Ui/Spinner/Spinner.vue";
import ImageSlider from "@/components/Ui/ImageSlider/ImageSlider.vue";
import SingleHotelRoomBeds from "@/views/SingleHotelRoom/components/SingleHotelRoomBeds.vue";
import SingleHotelRoomAmenities from "@/views/SingleHotelRoom/components/SingleHotelRoomAmenities.vue";
import Button from "@/components/Ui/Button/Button.vue";
import { getRoomTotalPrice } from "@/utils/utils";
import { RoomVariantClassification } from "@/types/roomVariant";
import { Prop } from "vue-property-decorator";
import { fetchRoomById } from "@/api/rooms/rooms";
import ContentGallery from "@/views/SingleHotelRoom/components/ContentGallery.vue";
import { Language } from "@/types/language";

@Component({
    components: {
        ContentGallery,
        Button,
        SingleHotelRoomAmenities,
        SingleHotelRoomBeds,
        ImageSlider,
        SingleHotelRoomHeader,
        Spinner
    },
})
export default class SingleHotelRoom extends Vue {
    @Prop({ default: null }) readonly roomId!: string

    room: Room | null = null;

    get totalPrice(): number {
        return this.room ? getRoomTotalPrice(this.room, RoomVariantClassification.SINGLE) : 0;
    }

    get roomDescription(): string {
        return (this.$i18n.locale === Language.bs ? this.room?.descriptionBs : this.room?.descriptionEn) ?? ""
    }

    mounted(): void {
        if (this.roomId) {
            fetchRoomById(this.roomId).then(r => this.room = r);
        }
    }
}
