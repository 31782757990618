import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { store } from "@/store";
import { ListOffer, Offer, OfferClientData } from "@/types/offer";
import {
    addOffer,
    apiFetchAllOffers,
    deleteOffer,
    fetchOfferById,
    fetchOffersPage, OfferPostData, printOffer,
} from "@/api/offers/offers";
import { merge } from "lodash";
import { ReservationPostData } from "@/api/reservations/reservations";
import { apiCall, offers } from "@/api/apiCall";
import { reservationFormModule } from "@/store/reservation_form/reservation_form";

@Module({ name: "OffersModule", store: store, dynamic: true })
export default class OffersModule extends VuexModule {
    fetchAsync = false;
    singleFetchAsync = false;
    offers: Array<ListOffer> = [];
    hasMore = true;
    currentPage = 0;
    totalPages = 1;
    perPage = 10;
    confirmPaidAsync = false;
    selectedOffer: Offer | null = null;
    addEditAsync = false;
    deleteAsync = false;
    printAsync = false;

    @Mutation
    setFetchAsync(async: boolean): void {
        this.fetchAsync = async;
    }

    @Mutation
    setOfferConfirmPaidAsync(async: boolean): void {
        this.confirmPaidAsync = async;
    }

    @Mutation
    setSingleFetchAsync(async: boolean): void {
        this.singleFetchAsync = async;
    }

    @Mutation
    setOfferDeleteAsync(async: boolean): void {
        this.deleteAsync = async;
    }

    @Mutation
    setCurrentPage(page: number): void {
        this.currentPage = page;
    }

    @Mutation
    setHasMore(hasMore: boolean): void {
        this.hasMore = hasMore;
    }

    @Mutation
    setPerPage(perPage: number): void {
        this.perPage = perPage;
    }

    @Mutation
    setTotalPages(totalPages: number): void {
        this.totalPages = totalPages;
    }

    @Mutation
    setOffers(offers: Array<ListOffer>): void {
        this.offers = offers.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
    }

    @Mutation
    removeOffer(id: string): void {
        this.offers = this.offers.filter(r => r.id !== id);
    }

    @Mutation
    selectOffer(offer: Offer | null): void {
        this.selectedOffer = offer;
    }

    @Mutation
    setAddEditAsync(async: boolean): void {
        this.addEditAsync = async;
    }

    @Mutation
    setPrintAsync(async: boolean): void {
        this.printAsync = async;
    }

    @Action
    async fetchAllOffers(): Promise<Array<ListOffer> | null> {
        this.setFetchAsync(true);

        try {
            const r = await apiFetchAllOffers();

            if (r !== null) {
                this.setOffers(r);
            }

            return r;
        } catch (e) {
            console.error(e);
        } finally {
            this.setFetchAsync(false);
        }

        return null;
    }

    @Action
    async fetchOffersFirstPage(): Promise<void> {
        return this.fetchOffersPageAction(1);
    }

    @Action
    async createOffer(clientData: OfferClientData): Promise<Offer | null> {

        const { reservation, selectedRooms, reservationPostData } = reservationFormModule;

        if (reservation !== null && selectedRooms !== null) {

            this.setAddEditAsync(true);

            const data: (OfferPostData & OfferClientData) = {
                ...clientData,
                ...reservationPostData,
                offerAdditionalContents: reservationPostData.reservationAdditionalContents,
            };

            const offer = await addOffer(data).finally(() => this.setAddEditAsync(false));

            if (offer !== null) {            // Offer created, disable offer creation
                reservationFormModule.setOfferCreatedForCurrentData(true);
            }

            return offer;
        }

        return null;
    }

    @Mutation
    updateListOfferById({
                            offerId,
                            data,
                        }: { offerId: string, data: Partial<ListOffer> }): void {
        const index = this.offers.findIndex(offer => offer.id === offerId);
        if (index !== -1) {
            this.offers[index] = merge(this.offers[index], data);
            this.offers = [...this.offers];
        }

        if (offerId === this.selectedOffer?.id) {
            this.selectedOffer = merge(this.selectedOffer, data);
        }
    }

    @Action
    async fetchOffersPageAction(page: number): Promise<void> {
        this.setFetchAsync(true);

        try {
            const r = await fetchOffersPage(page, this.perPage);

            if (r !== null) {
                this.setOffers(r.data);
                this.setTotalPages(Math.ceil(r.totalItems / this.perPage));
                this.setCurrentPage(page);
                this.setHasMore(r.hasMore);
            }
        } catch (e) {
            console.error(e);
        } finally {
            this.setFetchAsync(false);
        }
    }

    @Action({ rawError: true })
    async deleteOfferAction(offerId: string): Promise<null | void> {
        if (this.deleteAsync) return;

        this.setOfferDeleteAsync(true);

        return deleteOffer(offerId).finally(() => {
            this.removeOffer(offerId);
            this.setOfferDeleteAsync(false);
        });
    }

    @Action({ rawError: true })
    async printOfferAction(offerId: string): Promise<string | null> {
        this.setPrintAsync(true);
        return printOffer(offerId).finally(() => this.setPrintAsync(false));
    }


    @Action
    async fetchOfferByIdAction(offerId: string): Promise<void> {
        this.setSingleFetchAsync(true);

        try {
            const offer = await fetchOfferById(offerId);

            if (offer !== null) {
                this.selectOffer(offer);
            }
        } catch (e) {
            console.error(e);
        } finally {
            this.setSingleFetchAsync(false);
        }
    }
}

export const offersModule = getModule(OffersModule);
