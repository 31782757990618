

import Vue from "vue";
import Component from "vue-class-component";
import Checkbox from "@/components/Ui/Checkbox/Checkbox.vue";
import Button, { ButtonType } from "@/components/Ui/Button/Button.vue";
import { goBackOrPush } from "@/utils/utils";
import { RouteLink } from "@/router";
import { hotelsModule } from "@/store/hotels/hotels";

@Component({
    components: { Button, Checkbox }
})
export default class DeleteHotel extends Vue {
    ButtonType = ButtonType

    deleteConfirmed = false;

    get async(): boolean {
        return hotelsModule.deleteAsync;
    }

    backToHotels(): void {
        goBackOrPush(RouteLink.HotelContent);
    }

    deleteHotel(): void {
        if (!this.deleteConfirmed) return;

        if (hotelsModule.selectedHotel) {
            hotelsModule.deleteHotelAction(hotelsModule.selectedHotel.id).then(this.backToHotels);
        }
    }
}
