
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import { AddFormItem_Select } from "@/types/addForm/addFormItem";
import Component from "vue-class-component";
import InputLabel from "@/components/Ui/InputLabel/InputLabel.vue";
import { snakeCase } from "snake-case";
import SelectField from "@/components/Ui/SelectField/SelectField.vue";

@Component({
    components: {SelectField, InputLabel},
})
export default class AddContentSelect extends Vue {
    @Prop() readonly item!: AddFormItem_Select;
    @Prop() readonly entity!: Record<string, unknown>;

    get accessor(): string {
        return snakeCase(this.item.name);
    }
}
