

import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

@Component({})
export default class CustomModal extends Vue {
    @Prop({ default: false }) readonly opened!: boolean;
    @Prop({ default: true }) readonly dismissible!: boolean;

    close(): void {
        this.$emit("close");
    }

    onBackdropClick(): void {
        if (this.dismissible) {
            this.$emit("close");
        }
    }

    @Watch("opened")
    onChange(isOpened: boolean): void {
        if (isOpened) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }

    $destroy(): void {
        document.body.style.overflow = "auto";
    }

    onModalClick(e: Event): void {
        // e.preventDefault();
        e.stopPropagation();
    }

    get hasBodySlot(): boolean {
        return !!this.$slots.body;
    }

    get hasFooterSlot(): boolean {
        return !!this.$slots.footer;
    }

    get hasHeaderSlot(): boolean {
        return !!this.$slots.header;
    }
}
