import { Passenger, PassengerFormErrors } from "@/types/Passenger";
import { ReservationType } from "@/types/reservation";
import { RoomVariantClassification } from "@/types/roomVariant";
import { RoomServiceType } from "@/types/room";
import moment from "moment";
import { cloneDeep } from "lodash";
import { ReservationStatus } from "@/types/reservationStatus";

export interface ManualReservationRoom {
    name: string,
    variant: RoomVariantClassification,
    service: RoomServiceType,
    quantity: number,
    priceInCents: number
}

export interface RoomFormErrors {
    name?: string,
    priceInCents?: string
}

export interface ManualReservationAdditionalContent {
    name: string,
    priceInCents: number,
    passengerCount: number,
    type?: string,
}

export interface ReservationAdditionalContentErrors {
    name?: string,
    priceInCents?: string,
    passengerCount?: string
}

export interface ManualReservation {
    id: string,
    createdAt: string,
    fromDate: string | null,
    toDate: string | null,
    type: ReservationType,
    passengers: Array<Passenger>,
    validThrough: string,
    numberOfPassengersInGroup: number,
    isPaid: boolean,
    status: Array<ReservationStatus>,
    confirmationNumber: string,
    invoiceGeneratedAt: string | null,
    invoice: string | null,
    isEmailToHotelSent: boolean | null,
    specialRequest: string | null,
    hotel: string,
    agency: string | null,
    agencyIdNumber: string | null,
    agencyAttn: string | null,
    agencyPhone: string | null,
    agencyEmail: string | null,
    rooms: ManualReservationRoom[]
    additionalContents: ManualReservationAdditionalContent[],
}

export interface ManualReservationForm {
    fromDate?: string,
    toDate?: string,
    passengers: Passenger[],
    isPaid?: boolean,
    hotel?: string,
    agency?: string,
    rooms: ManualReservationRoom[],
    additionalContents: ManualReservationAdditionalContent[]
    visaPaidPrice?: number,
    hotelPaidPrice?: number,
    transferPaidPrice?: number,
}

export interface ManualReservationFormErrors {
    fromDate?: string,
    toDate?: string,
    hotel?: string,
    passengers: Record<number, PassengerFormErrors>     // index in array -> passenger form errors
    rooms: Record<number, RoomFormErrors>     // index in array -> room form errors
    additionalContent: Record<number, ReservationAdditionalContentErrors>     // index in array -> room form errors
}

export class ManualReservationUtils {

    static getTotalAccommodationPrice(reservation: ManualReservation): number {
        let price = 0;

        reservation.rooms.forEach(room => {
            price += (room.priceInCents * room.quantity);
        });

        return price;
    }

    static getTotalAdditionalContentsPrice(reservation: ManualReservation): number {
        let price = 0;

        reservation.additionalContents.forEach(additionalContent => {
            price += (additionalContent.priceInCents * additionalContent.passengerCount);
        });

        return price;
    }

    static getNightsCount(reservation: ManualReservation): number {
        return moment(reservation.toDate).diff(reservation.fromDate, "days");
    }

    static createForm(reservation?: ManualReservation | null): ManualReservationForm {
        if (!reservation) {
            return {
                passengers: [],
                additionalContents: [],
                rooms: [],
            };
        }

        return {
            hotel: reservation.hotel,
            rooms: cloneDeep(reservation.rooms),
            additionalContents: cloneDeep(reservation.additionalContents),
            isPaid: reservation.isPaid,
            fromDate: reservation.fromDate ?? undefined,
            toDate: reservation.toDate ?? undefined,
            passengers: cloneDeep(reservation.passengers),
        };
    }
}
