import "./class-component-hooks";

import Vue from "vue";
import App from "./App.vue";
import VTooltip from "v-tooltip";
import router from "./router";
import { store } from "./store";
import "./utils/filters";
import VueSweetalert2 from "vue-sweetalert2";
import moment from "moment";

moment.locale("bs");
moment.updateLocale("bs", {
    months: "Januar_Februar_Mart_April_Maj_Juni_Juli_August_Septembar_Oktobar_Novembar_Decembar".split(
        "_",
    ),
});

import VueMoment from "vue-moment";

import VCalendar from "v-calendar";
import CoolLightBox from "vue-cool-lightbox";
import VueEllipseProgress from "vue-ellipse-progress";
import { CalendarView, CalendarViewHeader } from "vue-simple-calendar";

import "vue-simple-calendar/static/css/default.css";
import "vue-simple-calendar/static/css/gcal.css";

import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import "vue-select/dist/vue-select.css";
import "sweetalert2/src/sweetalert2.scss";
import "dragula/dist/dragula.min.css";

import "@/assets/scss/main.scss";
import "@/assets/scss/calendar.scss";
import "@/assets/scss/overrides.scss";
import "@/assets/scss/misc.scss";
import { IS_DEV } from "@/utils/consts";
import { Integrations } from "@sentry/tracing";
import * as Sentry from "@sentry/vue";
import VueCurrencyInput from "vue-currency-input";
import { socket_url } from "@/api/apiCall";
import VueSocketIOExt from "vue-socket.io-extended";
import { io } from "socket.io-client";
import i18n from "@/i18n/i18n";


window.debugExplainPricing = false;

if (!IS_DEV) {
    Sentry.init({
        Vue,
        dsn: "https://376baad84f694619b01c3af5b595a0ce@o229960.ingest.sentry.io/5741046",
        integrations: [new Integrations.BrowserTracing()],
        release: process.env.VUE_APP_BUILD_VERSION,

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}

// if you want to see events
// localStorage.debug = '*'

export const socket = io(socket_url, {
    reconnection: true,
    reconnectionDelay: 500,
    reconnectionAttempts: Infinity,
    autoConnect: false,
    path: "/ws"
});

Vue.use(VueSocketIOExt, socket, {
    store,
    eventToActionTransformer: (name: string) => {
        const [eventName, entityId, eventAction] = name.split(":");
        // this should be updated for future stuff
        // but we need the notification event that looks like
        // notification:$userid to be
        // SOCKET_notification
        return eventName;
    },
    eventToMutationTransformer: (name: string) => {
        const [eventName, entityId, eventAction] = name.split(":");
        // this should be updated for future stuff
        // but we need the notification event that looks like
        // notification:$userid to be
        // SOCKET_notification
        return eventName
    },
});

Vue.use(VueCurrencyInput);
Vue.use(CalendarView);
Vue.use(CalendarViewHeader);
Vue.use(VueEllipseProgress);
Vue.use(CoolLightBox);
Vue.use(VCalendar);
Vue.use(VueSweetalert2);
Vue.use(VTooltip);
Vue.use(VueMoment, {
    moment,
})
Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: h => h(App),
    i18n
}).$mount("#app");
