
import Component from "vue-class-component";
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import { AdditionalContent, AdditionalContentUtils, ReservationAdditionalContent } from "@/types/additionalContent";
import ReservationAdditionalContentCard
    from "@/views/SingleReservation/components/ReservationAdditionalContentCard.vue";
import { Language } from "@/types/language";

interface AdditionalContentGroups {
    [index: string]: Array<ReservationAdditionalContent> | undefined
}

@Component({
    components: { ReservationAdditionalContentCard },
})
export default class ReservationAdditionalContentCards extends Vue {
    @Prop({ default: [] }) additionalContent!: Array<ReservationAdditionalContent>;

    get groupedAdditionalContent(): AdditionalContentGroups {
        let groups: AdditionalContentGroups = {};

        for (let content of this.additionalContent) {

            const categoryName = AdditionalContentUtils.resolveName(content.additionalContent.category, this.$i18n.locale as Language) ?? "";

            let g = groups[categoryName];
            if (g === undefined) {
                groups[categoryName] = [content];
            } else {
                g.push(content);
            }
        }

        return groups;
    }
}
