

import Button from "@/components/Ui/Button/Button.vue";
import { readFileToDataUrl } from "@/utils/utils";
import Component from "vue-class-component";
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import Dropzone, { DropzoneFile } from "dropzone";
import { MediaObject, UploadedFile } from "@/types/mediaObject";
import CustomDnD from "@/components/Ui/CustomDnD/CustomDnD.vue";
import dragula, { Drake } from "dragula";

@Component({
    components: { CustomDnD, Button },
})
export default class CustomUploader extends Vue {
    @Prop({ default: false }) readonly async!: boolean;
    @Prop({ default: false }) readonly multiple!: boolean;
    @Prop() readonly value?: Array<UploadedFile>;
    @Prop({ default: () => [] }) mediaObjects!: Array<MediaObject>;
    @Prop({ default: [] }) readonly additionalTypes!: Array<string>;

    // DnD
    drake?: Drake;

    $refs!: {
        fileInput: HTMLFormElement
    };

    get allowedFiles(): string {
        const allowed = [".jpg", ".jpeg", ".png", ...this.additionalTypes];

        return allowed.join(",");
    }

    removeMediaObject(media: MediaObject): void {
        this.$emit("removeMediaObject", media);
    }

    get hasImages(): boolean {
        return Boolean(this.value?.length) || Boolean(this.mediaObjects?.length);
    }

    onAddImageClicked(): void {
        this.$refs.fileInput.click();
    }

    cancelOrRemoveImage(file: UploadedFile): void {

        if (this.isCancellable(file)) {
            file.abortToken.abort();
            file.abortToken = new AbortController();
            file.progress = null;
        } else {
            this.$emit("removeImage", file);
        }
    }

    isCancellable(file: UploadedFile): boolean {
        return file.progress !== null && file.progress < 97;
    }

    isBeingUploaded(file: UploadedFile): boolean {
        return file.progress !== null && file.progress < 100;
    }

    async readFiles(files: FileList | DropzoneFile[]): Promise<Array<UploadedFile>> {

        const retFiles: Array<UploadedFile> = [];

        if (files.length > 0) {
            for (let file of files) {
                const url = await readFileToDataUrl(file) as string;
                retFiles.push({
                    url,
                    file,
                    progress: null,
                    abortToken: new AbortController(),
                });
            }
        }

        return retFiles;
    }

    async onImagePicked(e: Event): Promise<void> {
        const files = (e.target as HTMLInputElement).files;

        if (files !== null) {
            this.$emit("input", await this.readFiles(files));
        }
    }

    async mounted(): Promise<void> {
        // if (!document.getElementById("custom-uploader")) {
        //     return;
        // }

        // Set dropzone
        Dropzone.autoDiscover = false;

        let dropzone = new Dropzone("#custom-uploader", {
            url: "/",
            acceptedFiles: this.allowedFiles,
            previewsContainer: false,
            autoProcessQueue: false,
        });

        dropzone.on("addedfiles", async files => {
            this.$emit("input", await this.readFiles(files));
        });

        // Set DnD (dragula)
        this.drake = dragula();

        const container = document.querySelector("#drake-container");

        if (container) {
            this.drake.containers.push(container);
        }

        this.drake.on("drop", (sourceEl, targetCont, sourceCont, targetEl) => {
            const sourceMediaObjIndex = this.mediaObjects.findIndex(m => m.id === sourceEl.id);
            const targetMediaObjIndex = this.mediaObjects.findIndex(m => m.id === targetEl.id);

            if (sourceMediaObjIndex !== -1 && targetMediaObjIndex !== -1) {
                // Rearrange elements
                this.mediaObjects.splice(targetMediaObjIndex, 0, this.mediaObjects.splice(sourceMediaObjIndex, 1)[0]);
            }
        });
    }
}
