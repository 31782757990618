import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { store } from "@/store";
import { Notification, INotificationForm } from "@/types/notification";
import {
    addNotification,
    editNotification,
    fetchNotificationsPage,
    fetchNotificationById,
    fetchAllNotifications,
    deleteNotification,
    markNotificationAsRead,
    createNotificationGlobal,
    createNotificationForAgencies,
    createNotificationForUsers,
} from "@/api/notifications/notifications";
import { merge } from "lodash";
import { showSwalToast } from "@/utils/utils";

@Module({ name: "NotificationsModule", store: store, dynamic: true })
export default class NotificationsModule extends VuexModule {
    fetchAsync = false;
    notifications: Array<Notification> = [];
    hasMore = true;
    currentPage = 0;
    totalPages = 1;
    perPage = 10;
    selectedNotification: Notification | null = null;
    addEditAsync = false;
    deleteAsync = false;
    markReadAsync = false;

    @Mutation
    setNotificationsFetchAsync(async: boolean): void {
        this.fetchAsync = async;
    }

    @Mutation
    setNotificationDeleteAsync(async: boolean): void {
        this.deleteAsync = async;
    }

    @Mutation
    setNotificationsCurrentPage(page: number): void {
        this.currentPage = page;
    }

    @Mutation
    setNotificationsHasMore(hasMore: boolean): void {
        this.hasMore = hasMore;
    }

    @Mutation
    setNotificationsPerPage(perPage: number): void {
        this.perPage = perPage;
    }

    @Mutation
    setNotificationsTotalPages(totalPages: number): void {
        this.totalPages = totalPages;
    }

    @Mutation
    setNotifications(notifications: Array<Notification>): void {
        this.notifications = notifications;
    }

    @Mutation
    selectNotification(notification: Notification | null): void {
        this.selectedNotification = notification;
    }

    @Mutation
    setNotificationsAddEditAsync(async: boolean): void {
        this.addEditAsync = async;
    }

    @Mutation
    setNotificationMarkAsync(async: boolean): void {
        this.markReadAsync = async;
    }

    @Mutation
    SOCKET_notification(rawNotification: string): void {
        const notification: Notification = JSON.parse(rawNotification);
        this.notifications.unshift(notification);

        showSwalToast("Nova obavijest", "info");
    }

    @Mutation
    updateNotification({
                           notificationId,
                           data,
                       }: { notificationId: string, data: Partial<Notification> }): void {
        const index = this.notifications.findIndex(notification => notification.id === notificationId);
        if (index !== -1) {
            this.notifications[index] = merge(this.notifications[index], data);
            this.notifications = [...this.notifications];
        }
    }

    @Mutation
    removeNotification(notificationId: string): void {
        const index = this.notifications.findIndex(notification => notification.id === notificationId);
        if (index !== -1) {
            this.notifications.splice(index, 1);
            this.notifications = [...this.notifications];
        }
    }

    @Action
    async fetchNotificationsFirstPage(): Promise<void> {
        this.setNotifications([]);
        return this.fetchNotificationsPageAction(1);
    }

    @Action
    async fetchAllNotifications(): Promise<Array<Notification> | null> {
        this.setNotificationsFetchAsync(true);

        try {
            const r = await fetchAllNotifications();

            if (r !== null) {
                this.setNotifications(r);
            }

            return r;
        } catch (e) {
            console.error(e);
        } finally {
            this.setNotificationsFetchAsync(false);
        }

        return null;
    }

    @Action
    async fetchNotificationsPageAction(page: number): Promise<void> {
        if (this.fetchAsync || this.currentPage === page) {
            return;
        }

        this.setNotificationsFetchAsync(true);

        try {
            const r = await fetchNotificationsPage(page, this.perPage);

            if (r !== null) {
                this.setNotifications([...this.notifications, ...r.data]);
                this.setNotificationsTotalPages(Math.ceil(r.totalItems / this.perPage));
                this.setNotificationsCurrentPage(page);
                this.setNotificationsHasMore(r.hasMore);
            }
        } catch (e) {
            console.error(e);
        } finally {
            this.setNotificationsFetchAsync(false);
        }
    }

    @Action
    async addNotificationAction(data: INotificationForm): Promise<void> {
        this.setNotificationsAddEditAsync(true);

        try {
            const newNotification = await addNotification(data);

            if (newNotification !== null) {
                // this.appendNotifications([newNotification])
            }
        } catch (e) {
            console.error(e);
        } finally {
            this.setNotificationsAddEditAsync(false);
        }
    }

    @Action
    async editNotificationAction({
                                     notificationId,
                                     data,
                                 }: { notificationId: string, data: INotificationForm }): Promise<void> {

        this.setNotificationsAddEditAsync(true);

        try {
            const updatedNotification = await editNotification(notificationId, data as INotificationForm);

            if (updatedNotification !== null) {
                // this.updateNotification(updatedNotification);
                this.selectNotification(updatedNotification);
            }
        } catch (e) {
            console.error(e);
        } finally {
            this.setNotificationsAddEditAsync(false);
        }
    }

    @Action
    async fetchNotificationByIdAction(notificationId: string): Promise<void> {
        this.setNotificationsFetchAsync(true);

        try {
            const notification = await fetchNotificationById(notificationId);

            if (notification !== null) {
                this.selectNotification(notification);
            }
        } catch (e) {
            console.error(e);
        } finally {
            this.setNotificationsFetchAsync(false);
        }
    }

    @Action({ rawError: true })
    async deleteNotificationAction(notificationId: string): Promise<null | void> {

        if (this.deleteAsync) return;

        this.setNotificationDeleteAsync(true);

        return deleteNotification(notificationId)
            .then(() => this.removeNotification(notificationId))
            .finally(() => this.setNotificationDeleteAsync(false));
    }

    @Action({ rawError: true })
    async markNotificationAsReadAction(notificationId: string): Promise<null | void> {

        if (this.markReadAsync) return;

        this.setNotificationMarkAsync(true);

        const r = await markNotificationAsRead(notificationId)
            .finally(() => this.setNotificationMarkAsync(false));

        if (r !== null) {
            this.updateNotification({ notificationId, data: r });
        }
    }

    @Action
    async createNotificationGlobal(data: INotificationForm): Promise<boolean> {
        this.setNotificationsAddEditAsync(true);

        let ret = true;

        try {
            const newNotification = await createNotificationGlobal(data);

            if (newNotification !== null) {
                // this.appendNotifications([newNotification])
            }
        } catch (e) {
            console.error(e);
            ret = false;
        } finally {
            this.setNotificationsAddEditAsync(false);
        }

        return ret;
    }

    @Action
    async createNotificationForAgencies({
                                            data,
                                            agenciesIds,
                                        }: { data: INotificationForm, agenciesIds: string[] }): Promise<boolean> {
        this.setNotificationsAddEditAsync(true);

        let ret = true;

        try {
            const notifications = await createNotificationForAgencies(data, agenciesIds);

            if (notifications) {
                // this.appendNotifications([newNotification])
            }
        } catch (e) {
            console.error(e);
            ret = false;
        } finally {
            this.setNotificationsAddEditAsync(false);
        }

        return ret;
    }

    @Action
    async createNotificationForUsers({
                                         data,
                                         usersIds,
                                     }: { data: INotificationForm, usersIds: string[] }): Promise<boolean> {
        this.setNotificationsAddEditAsync(true);

        let ret = true;

        try {
            const notifications = await createNotificationForUsers(data, usersIds);

            if (notifications) {
                // this.appendNotifications([newNotification])
            }
        } catch (e) {
            console.error(e);
            ret = false;
        } finally {
            this.setNotificationsAddEditAsync(false);
        }

        return ret;
    }
}

export const notificationsModule = getModule(NotificationsModule);
