import { SelectFieldOption } from "@/components/Ui/SelectField/SelectField.vue";

export type AddFormItem = AddFormItem_Input
    | AddFormItem_Title
    | AddFormItem_TextArea
    | AddFormItem_Select
    | AddFormItem_Amenities
    | AddFormItem_Gallery
    | AddFormItem_GoogleMap
    | AddFormItem_Hours
    | AddFormItem_RoomBeds
    | AddFormItem_HotelNameAutocompleteInput
    | AddFormItem_Address
    | AddFormItem_Datetime;

export enum AddFormItemTypes {
    Title = "TITLE",
    Input = "INPUT",
    HotelNameAutocompleteInput = "HOTEL_NAME_AUTOCOMPLETE_INPUT",
    TextArea = "TEXT_AREA",
    Select = "SELECT",
    HotelSelect = "HOTEL_SELECT",
    GoogleMap = "GOOGLE_MAP",
    Amenities = "AMENITIES",
    Gallery = "GALLERY",
    Hours = "HOURS",
    RoomBeds = "ROOM_BEDS",
    Address = "ADDRESS",
    Datetime = "DATETIME"
}

export class AddFormItem_Title {
    type = AddFormItemTypes.Title;
    text: string;

    public constructor(text: string) {
        this.text = text;
    }

    static parse(data: Record<string, unknown>): AddFormItem_Title {
        const text = data["text"] as string;

        return new AddFormItem_Title(text);
    }
}

export enum AddFormItem_InputType {
    Text = "TEXT",
    NumberPlusMinus = "NUMBER_PLUS_MINUS",
    Number = "NUMBER",
}

export interface AddFormItemInputOpts {
    min: number | undefined,
    nullable?: boolean
}

export class AddFormItem_Input {
    type = AddFormItemTypes.Input;
    name: string;
    inputType = AddFormItem_InputType.Text;
    inputOptions = {};

    public constructor(name: string, inputType?: AddFormItem_InputType, inputOptions?: AddFormItemInputOpts) {
        this.name = name;

        if (inputType !== undefined) {
            this.inputType = inputType;
        }

        if (inputOptions !== undefined) {
            this.inputOptions = inputOptions;
        }
    }

    static parse(data: Record<string, unknown>): AddFormItem_Input {
        const name = data["name"] as string;
        const inputType = data["inputType"] as AddFormItem_InputType;
        const inputOptions = data["inputOptions"] as AddFormItemInputOpts;

        return new AddFormItem_Input(name, inputType, inputOptions);
    }
}

export class AddFormItem_HotelNameAutocompleteInput {
    type = AddFormItemTypes.HotelNameAutocompleteInput;
    name: string;

    public constructor(name: string) {
        this.name = name;
    }

    static parse(data: Record<string, unknown>): AddFormItem_HotelNameAutocompleteInput {
        const name = data["name"] as string;

        return new AddFormItem_HotelNameAutocompleteInput(name);
    }
}

export class AddFormItem_TextArea {
    type = AddFormItemTypes.TextArea;
    name: string;

    public constructor(name: string) {
        this.name = name;
    }

    static parse(data: Record<string, unknown>): AddFormItem_TextArea {
        const name = data["name"] as string;

        return new AddFormItem_TextArea(name);
    }
}

export class AddFormItem_Select {
    type = AddFormItemTypes.Select;
    name: string;
    options: SelectFieldOption[];

    public constructor(name: string, options: SelectFieldOption[]) {
        this.name = name;
        this.options = options;
    }

    static parse(data: Record<string, unknown>): AddFormItem_Select {
        const name = data["name"] as string;
        const options = data["options"] as SelectFieldOption[];

        return new AddFormItem_Select(name, options);
    }
}

export class AddFormItem_Amenities {
    type = AddFormItemTypes.Amenities;

    public constructor() {
        /**/
    }

    static parse(): AddFormItem_Amenities {
        return new AddFormItem_Amenities();
    }
}

export class AddFormItem_GoogleMap {
    type = AddFormItemTypes.GoogleMap;

    public constructor() {
        /**/
    }

    static parse(): AddFormItem_GoogleMap {
        return new AddFormItem_GoogleMap();
    }
}

export class AddFormItem_Gallery {
    type = AddFormItemTypes.Gallery;

    public constructor() {
        /**/
    }

    static parse(): AddFormItem_Gallery {
        return new AddFormItem_Gallery();
    }
}

export class AddFormItem_Datetime {
    type = AddFormItemTypes.Datetime;
    name: string;

    public constructor(name: string) {
        this.name = name;
    }

    static parse(data: Record<string, unknown>): AddFormItem_Datetime {
        const name = data["name"] as string;

        return new AddFormItem_Datetime(name);
    }
}

export class AddFormItem_Hours {
    type = AddFormItemTypes.Hours;
    name: string;

    public constructor(name: string) {
        this.name = name;
    }

    static parse(data: Record<string, unknown>): AddFormItem_Hours {
        const name = data["name"] as string;

        return new AddFormItem_Hours(name);
    }
}

export class AddFormItem_Address {
    type = AddFormItemTypes.Address;

    public constructor() {
        /**/
    }
}

export class AddFormItem_HotelSelect {
    type = AddFormItemTypes.HotelSelect;
    name = "Hotel";
    options: Array<SelectFieldOption>;

    public constructor() {
        this.options = [];
    }

    static parse(_data: Record<string, unknown>): AddFormItem_HotelSelect {
        return new AddFormItem_HotelSelect();
    }
}

export class AddFormItem_RoomBeds {
    type = AddFormItemTypes.RoomBeds;

    public constructor() {
        /**/
    }

    static parse(): AddFormItem_RoomBeds {
        return new AddFormItem_RoomBeds();
    }
}
