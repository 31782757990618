import {RoomAvailability} from "@/types/availability";
import {RoomPricing} from "@/types/pricing";
import {RoomVariant} from "@/types/roomVariant";
import {RoomServiceType} from "@/types/room";
import Decimal from "decimal.js";
import {ReservationFormSelectedRoomVariant} from "@/store/reservation_form/reservation_form";
import {Hotel} from "@/types/hotel";
import moment, {Moment} from "moment";
import {loginModule} from "@/store/login/login";

export interface AvailabilityGroupForRoomAvailability {
    id: string,
    q: number,
    pricing: RoomPricing
}

export interface CalculatedRoomVariantPrice {
    q: number,
    priceWithQ: number,
}

export class AvailabilityGroups {
    groups: IAvailabilityGroups;

    constructor() {
        this.groups = {};
    }

    isOverlapingAvailabilityRange(): boolean {
        let firstPricing = null;

        for (const group of Object.values(this.groups)) {
            if (firstPricing === null) {
                firstPricing = JSON.stringify(group.pricing);
            }

            if (firstPricing !== JSON.stringify(group.pricing)) {
                return true;
            }
        }

        return false;
    }

    calculatePriceForVariantsArray(variants: ReservationFormSelectedRoomVariant[], agencyMarkup: number = loginModule.agencyMarkup): number {
        return variants.reduce<number>((previousValue, variant) => {
            if (variant.rawVariant && variant.serviceType && variant.passengers && variant.passengers > 0) {
                const price = this.calculatePriceForRoomVariant(variant.passengers, variant.q ?? 1, variant.rawVariant, variant.serviceType, agencyMarkup);
                price.forEach(value => {
                    previousValue += value.priceWithQ;
                });
            }

            return previousValue;
        }, 0);
    }

    calculatePriceForRoomVariant(numPassengersForThisVariant: number, qOfThisVariant: number, variant: RoomVariant, serviceType: RoomServiceType, agencyMarkup: number): CalculatedRoomVariantPrice[] {
        const prices = [];

        for (const group of Object.values(this.groups)) {
            const singlePrice = group.pricing.getPriceForClassificationAndServiceType(numPassengersForThisVariant, variant, serviceType, agencyMarkup);
            prices.push({
                q: group.q,
                price: new Decimal(singlePrice).toNumber(),
                priceWithQ: new Decimal(singlePrice).mul(group.q).mul(qOfThisVariant).toNumber(),
            });
        }

        return prices;
    }

    calculateVariantNightAveragePrice(roomVariant: RoomVariant, availabilities: Array<RoomAvailability>, roomServiceType: RoomServiceType = RoomServiceType.BED_AND_BREAKFAST, agencyMarkup: number = loginModule.agencyMarkup): number {
        return this.calculatePriceForRoomVariant(1, 1, roomVariant, roomServiceType, agencyMarkup).reduce<number>((previousValue, currentValue) => {
            previousValue += currentValue.priceWithQ;
            return previousValue;
        }, 0) / availabilities.length;
    }
}

export interface IAvailabilityGroups {
    [key: string]: AvailabilityGroupForRoomAvailability,
}

export function getAvailabilityGroupsForRoomAvailabilities(availabilities: RoomAvailability[]): AvailabilityGroups {
    const availabilityGroups = new AvailabilityGroups();

    availabilities.forEach(availability => {
        if (!availabilityGroups.groups[availability.groupId]) {
            availabilityGroups.groups[availability.groupId] = {
                id: availability.groupId,
                q: 1,
                pricing: new RoomPricing(availability.pricing),
            };

            return;
        }

        availabilityGroups.groups[availability.groupId].q++;
    });

    return availabilityGroups;
}

export function getEarlyBookingDiscountForHotel(hotel: Hotel, reservationStartDate: Moment): number | null {

    const daysEarly = reservationStartDate.diff(moment(), "days");

    let possibleDiscount = null;

    // hotel early bookings are already sorted
    [...hotel.earlyBookings].forEach(eb => {
        if (daysEarly >= eb.daysBefore) {
            possibleDiscount = eb.discount;
        }
    });

    return possibleDiscount;
}

export function pricingDebugLog(callback: () => void): void {
    if (window.debugExplainPricing) {
        callback();
    }
}
