

import VueSelect from "vue-select";
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import Component from "vue-class-component";

export interface SelectFieldOption {
    label: string,
    value: string,
    topDash: boolean,
}

@Component({
    components: { VueSelect },
})
export default class SelectField extends Vue {
    @Prop() readonly name!: string;
    @Prop() readonly placeholder?: string;
    @Prop() readonly label?: string;
    @Prop({ default: false }) readonly required!: boolean;
    @Prop({ default: false }) readonly multiple!: boolean;
    @Prop({ default: false }) readonly disabled!: boolean;
    @Prop({ default: false }) readonly taggable!: boolean;
    @Prop({ default: true }) readonly filterable!: boolean;
    @Prop({ default: true }) readonly clearable!: boolean;
    @Prop({ default: () => ([]) }) readonly options!: Array<SelectFieldOption>;
    @Prop() readonly value!: string;
    @Prop() readonly accessor?: string;
    @Prop() readonly createOption?: (newOption: string) => SelectFieldOption | string;

    onScroll(): void {
        const select = this.$refs.selectRef as any;
        if (select) {
            const selectRefRect = select.$el.getBoundingClientRect();
            const dropdownRef = select.$refs.dropdownMenu;

            if (dropdownRef) {
                dropdownRef.style.top = selectRefRect.top + selectRefRect.height + "px";
            }
        }
    }

    mounted(): void {
        window.addEventListener("scroll", this.onScroll);
    }

    beforeUnmount(): void {
        window.removeEventListener("scroll", this.onScroll);
    }

    getDropdownPos(dropdownList: HTMLUListElement, component: Vue, {
        width,
        top, left,
    }: { width: string, top: string, left: string }): void {

        const selectRect = component.$el.getBoundingClientRect();

        let topN = +top.replace("px", "");
        let widthN = +width.replace("px", "");

        dropdownList.style.top = (topN - window.scrollY) + "px";
        dropdownList.style.left = (selectRect.left + (widthN - selectRect.width))  + "px";
        dropdownList.style.width = width;
    }

    valueAccessor(option: SelectFieldOption | string): string {
        if (typeof option === "object") {
            return option.value;
        }

        return option;
    }
}
