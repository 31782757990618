
import { RoomCapacity } from "@/types/roomVariant";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import PassengerNumberCategory from "@/views/Home/PassengerNumberWrapper/PassengerNumberCategory.vue";

@Component({
    components: {
        PassengerNumberCategory,
    },
})
export default class RoomSingleAlternativeCapacity extends Vue {
    @Prop() alternativeCapacity!: RoomCapacity;
}
