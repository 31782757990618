

import { getSponsoredLevelForHotel, Hotel } from "@/types/hotel";
import { Prop } from "vue-property-decorator";
import Vue from "vue";
import Component from "vue-class-component";
import { storage_url } from "@/api/apiCall";
import { Amenity } from "@/types/amenity";
import PlaceholderableImage from "@/components/Ui/PlaceholderableImage/PlaceholderableImage.vue";
import { getEarlyBookingDiscountForHotel, pricingDebugLog } from "@/utils/price_utils";
import { homeFiltersModule } from "@/store/home_filters/home_filters";
import { HotelSponsoredLevel } from "@/types/hotelSpecialOffer";
import GIcon from "@/components/Ui/GIcon/GIcon.vue";
import { loginModule } from "@/store/login/login";
import { settingsModule } from "@/store/settings/settings";
import Decimal from "decimal.js";
import { getHotelLowestPrice } from "@/utils/utils";

@Component({
    components: { GIcon, PlaceholderableImage },
})
export default class HotelListCard extends Vue {
    @Prop() readonly hotel!: Hotel;
    @Prop() readonly hotelImageOverride?: string;
    @Prop() readonly hotelSponsoredLevelOverride?: HotelSponsoredLevel;

    HotelSponsoredLevel = HotelSponsoredLevel;

    get hasDiscount(): boolean {
        return getEarlyBookingDiscountForHotel(this.hotel, homeFiltersModule.startDate) !== null;
    }

    get sponsoredLevel(): HotelSponsoredLevel | null {
        return this.hotelSponsoredLevelOverride ?? getSponsoredLevelForHotel(this.hotel, homeFiltersModule.startDate, homeFiltersModule.endDate);
    }

    get price(): number {
        return getHotelLowestPrice(this.hotel, this.adminMarkup, this.agencyMarkup);
    }

    get random3Amenities(): Array<Amenity> {
        let r = [];
        let until = this.hotel.amenities.length >= 3 ? 3 : this.hotel.amenities.length;

        for (let i = 0; i < until; i++) {
            r.push(this.hotel.amenities[i]);
        }

        return r;
    }

    get hotelImage(): string | null {
        const thumbnail = this.hotel.featuredImage?.thumbnail;

        return this.hotelImageOverride ?? (thumbnail ? storage_url + thumbnail.thumbnailUrl : null);
    }

    get adminMarkup() {
        return settingsModule.adminMarkup;
    }

    get agencyMarkup() {
        return loginModule.agencyMarkup;
    }
}
