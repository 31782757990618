import { merge } from "lodash";
import { difference } from "@/utils/utils";

export interface Bed {
    id: string,
    name: string,
    description: string | null,
    capacity: number,
}

export interface RoomBedForm {
    bed: Bed | string,              // Full Bed or bedId
    quantity: number,
    roomVariant?: string
}

export interface IBedForm {
    name?: string,
    description?: string,
    capacity?: number
}

export class BedForm implements IBedForm {
    name?: string = '';
    description?: string = '';
    capacity?: number = 1;

    constructor(props: IBedForm = {}) {
        merge(this, props);
    }

    static createFormWithBed(bed: Bed): BedForm {
        return new BedForm({
            ...bed,
            description: bed.description || ''
        });
    }

    public validate(): boolean {
        return this.name !== '';            // TODO
    }

    public prepareFormForSending(): void {
        this.capacity = this.capacity && +this.capacity;
    }

    public difference(anotherForm: IBedForm): IBedForm {

        return difference(this as IBedForm, anotherForm) as IBedForm
    }
}
