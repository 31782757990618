import Vue from "vue";
import { storage_url } from "@/api/apiCall";
import { MediaObject, UploadedFile } from "@/types/mediaObject";
import { Address } from "@/types/address";
import { Hotel, HotelFilterTerms, HotelRatingFilterType } from "@/types/hotel";
import { RoomVariantClassification } from "@/types/roomVariant";
import { RoomServiceType } from "@/types/room";
import { uiModule } from "@/store/ui";
import { settingsModule } from "@/store/settings/settings";
import { getRoomServiceTypeText, roundDecimals } from "@/utils/utils";
import { Currency } from "@/types/pricing";
import { User } from "@/types/user";
import { UserRoles, UserRoleText } from "@/utils/consts";
import VueI18n from "vue-i18n/types/index";

Vue.filter("mergeName", function (user: { firstName?: string, middleName?: string, lastName?: string }) {
    if (!user) return "";

    if (!user.firstName && !user.lastName) {
        return "N.N.";
    }

    return user.firstName + (user.middleName ? `${user.middleName} ` : " ") + user.lastName;
});

Vue.filter("mediaUrl", function (mediaObject: MediaObject) {
    if (!mediaObject) return "";

    return `${storage_url}/${mediaObject.contentUrl}`;
});

Vue.filter("mediaThumbnailUrl", function (mediaObject: MediaObject) {
    if (!mediaObject?.thumbnail) return "";

    return `${storage_url}/${mediaObject.thumbnail.thumbnailUrl}`;
});

Vue.filter("mediaThumbnailOrPreview", function (media: MediaObject | UploadedFile) {
    if (!media) return "";

    const thumb = (media as MediaObject)?.thumbnail;

    return thumb ? storage_url + thumb.thumbnailUrl : (media as UploadedFile)?.url;
});

Vue.filter("mergeAddress", function (address: Address) {
    if (!address) return "";

    const addr = [address.street, address.city, address.country].filter(b => b);

    return addr.join(" - ");
});

Vue.filter("roomVariantClassification", function (roomVariantClassification: RoomVariantClassification, i18n: VueI18n) {
    if (!roomVariantClassification) return "";

    switch (roomVariantClassification) {
        case RoomVariantClassification.SINGLE:
            return i18n.tc('single_bed');
        case RoomVariantClassification.DOUBLE:
            return i18n.tc('double_bed');
        case RoomVariantClassification.TRIPLE:
            return i18n.tc('triple_bed');
        case RoomVariantClassification.QUADRUPLE:
            return i18n.tc('quadruple_bed');
        case RoomVariantClassification.QUINTUPLE:
            return i18n.tc('quintuple_bed');
    }
});


Vue.filter("serviceType", function (serviceType: RoomServiceType, i18n: VueI18n) {
    if (!serviceType) return "";

    return getRoomServiceTypeText(serviceType, i18n);
});

export function priceFormatFilter(basePriceInCents: number): string {
    if (!basePriceInCents) return "";

    const currency = uiModule.selectedCurrency;
    const currencyValue = Number(settingsModule.settings.find(s => s.key.toUpperCase() === currency)?.value ?? 0);

    const convertedCurrency = roundDecimals((basePriceInCents / 100) * currencyValue, 2);

    switch (currency) {
        case Currency.USD:
            return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(convertedCurrency);
        case Currency.BAM:
            return new Intl.NumberFormat("bs-BA", { style: "currency", currency: "BAM" }).format(convertedCurrency);
        case Currency.EUR:
            return new Intl.NumberFormat("de-DE", { style: "currency", currency: "EUR" }).format(convertedCurrency);
    }
}

Vue.filter("priceFormat", function (basePriceInCents: number) {
    return priceFormatFilter(basePriceInCents);
});


// return true if you want this hotel to be included
export function filterHotels(hotel: Hotel, filterTerms: HotelFilterTerms): boolean {
    let amenitiesPass = filterTerms.amenities.length === 0;
    for (const amenity of filterTerms.amenities) {
        if (hotel.amenities.findIndex((i) => i.id === amenity) !== -1) {
            amenitiesPass = true;
            break;
        }
    }

    let ratingPass = filterTerms.rating === null;

    if (filterTerms.rating !== null) {
        switch (filterTerms.rating) {
            case HotelRatingFilterType.OVER4:
                ratingPass = hotel.stars !== undefined && hotel.stars > 4;
                break;
            case HotelRatingFilterType.MIDDLE3TO4:
                ratingPass = hotel.stars !== undefined && (hotel.stars > 3 && hotel.stars < 4);
                break;
            case HotelRatingFilterType.LESS3:
                ratingPass = hotel.stars !== undefined && hotel.stars < 3;
                break;
        }
    }

    return amenitiesPass && ratingPass;
}

export function isSuperAdmin(user: User): boolean {
    return user?.roles.includes(UserRoles.ROLE_SUPER_ADMIN) || false;
}

export function isAgent(user: User): boolean {
    return user?.roles.includes(UserRoles.ROLE_AGENCY_ADMIN) || false;
}

Vue.filter('displayUserRole', function (user: User) {
    if (isSuperAdmin(user)) {
        return UserRoleText[UserRoles.ROLE_SUPER_ADMIN];
    } else if (isAgent(user)) {
        return UserRoleText[UserRoles.ROLE_AGENCY_ADMIN];
    }
    return UserRoleText[UserRoles.ROLE_USER];
});


Vue.filter('capitalize', function (text: string) {
    return text.charAt(0).toUpperCase() + text.slice(1);
})

Vue.filter('uppercase', function (text: string) {
    return text.toUpperCase();
})

Vue.filter('lowercase', function (text: string) {
    return text.toLowerCase()
})