
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import Component from "vue-class-component";
import AddContentInput from "@/components/AddContentRenderer/AddContentItemRenderers/AddContentInput.vue";
import InputLabel from "@/components/Ui/InputLabel/InputLabel.vue";
import InputField, { InputFieldType } from "@/components/Ui/InputField/InputField.vue";
import { IEarlyBookingForm } from "@/types/earlyBooking";
import Button, { ButtonType } from "@/components/Ui/Button/Button.vue";

@Component({
    components: { Button, InputField, InputLabel, AddContentInput },
})
export default class SingleEarlyBooking extends Vue {
    @Prop() earlyBooking!: IEarlyBookingForm;
    @Prop() btnText!: string;
    @Prop() btnType!: ButtonType;
    @Prop({ default: false }) btnDisabled!: boolean;

    InputFieldType = InputFieldType;
    ButtonType = ButtonType;
}
