
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Room, RoomBed } from "@/types/room";
import { Bed } from "@/types/beds";

@Component({})
export default class SingleHotelRoomBeds extends Vue {
    @Prop() readonly room?: Room;

    get roomBeds(): Array<RoomBed> {
        return this.room?.roomBeds ?? [];
    }

    bedCapacityText(bed: Bed | string): string {
        if (typeof bed === "string") return '';

        return bed.capacity + (bed.capacity === 1 ? ' osoba' : ' osobe');
    }
}
