import queryString from "query-string";

export interface RawHydraView {
    "hydra:first": string,
    "hydra:last": string,
    "hydra:next"?: string,
    "hydra:previous"?: string,
}

export interface RawHydraResponse<T> {
    "hydra:member": Array<T>,
    "hydra:view": RawHydraView,
    "hydra:totalItems": number
}

export function RawHydraResponseIntoPaginationResponse<T>(r: RawHydraResponse<T>): PaginationResponse<T> {
    const firstPage = queryString.parse(r["hydra:view"]["hydra:first"])["page"];
    const lastPage = queryString.parse(r["hydra:view"]["hydra:last"])["page"];
    let fp = 0;
    let lp = 99999;

    if (firstPage instanceof String) {
        fp = +(firstPage as string);
    }

    if (lastPage instanceof String) {
        lp = +(lastPage as string);
    }

    return {
        data: r["hydra:member"],
        hasMore: r["hydra:view"]["hydra:next"] !== undefined,
        firstPage: fp,
        lastPage: lp,
        totalItems: r['hydra:totalItems']
    };
}

export interface PaginationResponse<T> {
    "data": Array<T>,
    hasMore: boolean,
    firstPage: number,
    lastPage: number,
    totalItems: number
}
