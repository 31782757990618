import { merge } from "lodash";
import { MediaObject, UploadedFile } from "@/types/mediaObject";

export interface IPassenger {
    firstName?: string;
    lastName?: string;
    phone?: string;
    email?: string | null;
    hasVisa?: boolean | null;
    passportNumber?: string;
    age?: PassengerAge | null;
    isMainPassenger?: boolean,
    notices?: string
    passportImage?: MediaObject | UploadedFile | null,
    passengerImage?: MediaObject | UploadedFile | null,
}

export class Passenger {
    firstName = '';
    lastName = '';
    phone = '';
    email?: string | null = '';
    hasVisa?: boolean | null = true;
    passportNumber = '';
    age?: PassengerAge | null = null;
    isMainPassenger = false;
    notices = '';
    passportImage: MediaObject | UploadedFile | null = null;
    passengerImage: MediaObject | UploadedFile | null = null;

    constructor(props: IPassenger = {}) {
        merge(this, props);
    }
}

export enum PassengerAge {
    ADULT = "ADULT",
    CHILD_UNDER_12 = "CHILD_UNDER_12",
    CHILD_UNDER_6 = "CHILD_UNDER_6",
}

export interface PassengerFormErrors {
    firstName?: string,
    lastName?: string
    age?: string
}