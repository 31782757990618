import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { store } from "@/store";
import {
    AdditionalContent,
    ListAdditionalContent,
    AdditionalContentForm,
    IAdditionalContentForm, AdditionalContentQuery,
} from "@/types/additionalContent";
import {
    addAdditionalContent,
    editAdditionalContent,
    fetchAdditionalContentPage,
    fetchAdditionalContentById, deleteAdditionalContent, apiSearchAdditionalContent,
} from "@/api/additional_content/additional_content";

@Module({ name: "AdditionalContentModule", store: store, dynamic: true })
export default class AdditionalContentModule extends VuexModule {
    fetchAsync = false;
    additionalContent: Array<ListAdditionalContent> = [];
    hasMore = true;
    currentPage = 0;
    totalPages = 1;
    perPage = 10;
    selectedAdditionalContent: AdditionalContent | null = null;
    addEditAsync = false;
    deleteAsync = false;

    @Mutation
    resetState(): void {
        this.fetchAsync = false;
        this.additionalContent = [];
        this.hasMore = true;
        this.currentPage = 0;
        this.totalPages = 1;
        this.perPage = 10;
        this.selectedAdditionalContent = null;
        this.addEditAsync = false;
        this.deleteAsync = false;
    }

    queryData: AdditionalContentQuery = {
        name: null,
    };

    @Mutation
    setFetchAsync(async: boolean): void {
        this.fetchAsync = async;
    }

    @Mutation
    setCurrentPage(page: number): void {
        this.currentPage = page;
    }

    @Mutation
    setHasMore(hasMore: boolean): void {
        this.hasMore = hasMore;
    }

    @Mutation
    setPerPage(perPage: number): void {
        this.perPage = perPage;
    }

    @Mutation
    setTotalPages(totalPages: number): void {
        this.totalPages = totalPages;
    }

    @Mutation
    setAdditionalContent(additionalContent: Array<ListAdditionalContent>): void {
        this.additionalContent = additionalContent;
    }

    @Mutation
    selectAdditionalContent(additionalContent: AdditionalContent | null): void {
        this.selectedAdditionalContent = additionalContent;
    }

    @Mutation
    setAddEditAsync(async: boolean): void {
        this.addEditAsync = async;
    }

    @Mutation
    setContentDeleteAsync(async: boolean): void {
        this.deleteAsync = async;
    }


    @Mutation
    setAdditionalContentQuery(queryData: AdditionalContentQuery): void {
        this.queryData = queryData;
    }

    @Action
    async fetchAdditionalContentFirstPage(): Promise<void> {
        return this.fetchAdditionalContentPageAction(1);
    }

    @Action
    async fetchAdditionalContentPageAction(page: number): Promise<void> {
        this.setFetchAsync(true);

        try {
            const r = await fetchAdditionalContentPage(page, this.perPage);

            if (r !== null) {
                this.setAdditionalContent(r.data);
                this.setTotalPages(Math.ceil(r.totalItems / this.perPage));
                this.setCurrentPage(page);
                this.setHasMore(r.hasMore);
            }
        } catch (e) {
            console.error(e);
        } finally {
            this.setFetchAsync(false);
        }
    }

    @Action
    async addAdditionalContentAction(data: AdditionalContentForm): Promise<void> {
        this.setAddEditAsync(true);

        try {
            const newAdditionalContent = await addAdditionalContent(data);

            if (newAdditionalContent !== null) {
                // this.appendAdditionalContent([newAdditionalContent])
            }
        } catch (e) {
            console.error(e);
        } finally {
            this.setAddEditAsync(false);
        }
    }

    @Action
    async editAdditionalContentAction({
                                          additionalContentId,
                                          data,
                                      }: { additionalContentId: string, data: IAdditionalContentForm }): Promise<void> {

        this.setAddEditAsync(true);

        try {
            const updatedAdditionalContent = await editAdditionalContent(additionalContentId, data);

            if (updatedAdditionalContent !== null) {
                // this.updateAdditionalContent(updatedAdditionalContent);
                this.selectAdditionalContent(updatedAdditionalContent);
            }
        } catch (e) {
            console.error(e);
        } finally {
            this.setAddEditAsync(false);
        }
    }

    @Action
    async fetchAdditionalContentFirstPageByCategoryIdAction(additionalContentCategoryId: string): Promise<void> {
        return this.fetchAdditionalContentPageByCategoryIdAction({ page: 1, categoryId: additionalContentCategoryId });
    }

    @Action
    async fetchAdditionalContentPageByCategoryIdAction({
                                                           page,
                                                           categoryId,
                                                       }: { page: number, categoryId: string }): Promise<void> {
        this.setFetchAsync(true);

        try {
            const r = await fetchAdditionalContentPage(page, this.perPage, categoryId);

            if (r !== null) {
                this.setAdditionalContent(r.data);
                this.setTotalPages(Math.ceil(r.totalItems / this.perPage));
                this.setCurrentPage(page);
                this.setHasMore(r.hasMore);
            }
        } catch (e) {
            console.error(e);
        } finally {
            this.setFetchAsync(false);
        }
    }

    @Action
    async fetchAdditionalContentByIdAction(additionalContentId: string): Promise<void> {
        this.setFetchAsync(true);

        try {
            const additionalContent = await fetchAdditionalContentById(additionalContentId);

            if (additionalContent !== null) {
                this.selectAdditionalContent(additionalContent);
            }
        } catch (e) {
            console.error(e);
        } finally {
            this.setFetchAsync(false);
        }
    }

    @Action({ rawError: true })
    async deleteAdditionalContentAction(contentId: string): Promise<null | void> {

        if (this.deleteAsync) return;

        this.setContentDeleteAsync(true);

        return deleteAdditionalContent(contentId).finally(() => this.setContentDeleteAsync(false));
    }


    @Action
    async searchAdditionalContentAction(queryData: AdditionalContentQuery): Promise<Array<ListAdditionalContent> | null> {
        this.setFetchAsync(true);

        try {
            const r = await apiSearchAdditionalContent(queryData);

            if (r !== null) {
                this.setAdditionalContent(r);
                this.setTotalPages(1);
                this.setCurrentPage(1);
                this.setAdditionalContentQuery(queryData);
            }

            return r;
        } catch (e) {
            console.error(e);
        } finally {
            this.setFetchAsync(false);
        }

        return null;
    }

}

export const additionalContentModule = getModule(AdditionalContentModule);
