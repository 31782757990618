

import Vue from "vue";
import Spinner from "@/components/Ui/Spinner/Spinner.vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

export enum ButtonType {
    LIGHTGREEN = "lightgreen",
    TRANSPARENT = "transparent",
    PLAINWHITE = "plainwhite",
    GREEN = "green",
    RED = "red",
}

@Component({
    components: {
        Spinner,
    },
})
export default class Button extends Vue {
    @Prop({ default: ButtonType.RED }) readonly type!: ButtonType;
    @Prop({ default: false }) readonly loading!: boolean;
    @Prop({ default: false }) readonly disabled!: boolean;
    @Prop({ default: false }) readonly inversed!: boolean;
    @Prop() readonly spinnerColor?: string;
}

