import AutocompletionRequest = google.maps.places.AutocompletionRequest;
import AutocompletePrediction = google.maps.places.AutocompletePrediction;
import GeocoderResult = google.maps.GeocoderResult;
import PlacesServiceStatus = google.maps.places.PlacesServiceStatus;
import GeocoderStatus = google.maps.GeocoderStatus;
import { Address } from "@/types/address";

export interface PlacesPredictionsResult {
    predictions: AutocompletePrediction[],
    status: PlacesServiceStatus,
    isOk: boolean,
}

export interface PlacesGeocoderResult {
    results: GeocoderResult[],
    status: GeocoderStatus,
    isOk: boolean,
}

export class PlacesWrapper {
    static parseGeocoderToAddress(geocoderResult: GeocoderResult): Address {
        const ShouldBeComponent: Record<string, Array<string>> = {
            postalCode: ["postal_code"],
            street: ["street_address", "route"],
            city: [
                "locality",
                "sublocality",
                "sublocality_level_1",
                "sublocality_level_2",
                "sublocality_level_3",
                "sublocality_level_4",
            ],
            country: ["country"],
        };

        const address: Address = {
            postalCode: "",
            street: "",
            city: "",
            country: "",
        };

        geocoderResult.address_components.forEach(component => {
            for (const shouldBe in ShouldBeComponent) {
                if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
                    (address as Record<string, string>)[shouldBe] = component.long_name;
                }
            }
        });

        address.lat = `${geocoderResult.geometry.location.lat()}`;
        address.lng = `${geocoderResult.geometry.location.lng()}`;

        return address;
    }

    static async getPlacePredictions(request: AutocompletionRequest): Promise<PlacesPredictionsResult> {
        const maps = window.google?.maps;

        if (maps) {
            const autocompleteService = new maps.places.AutocompleteService();

            return new Promise<PlacesPredictionsResult>(resolve => {
                autocompleteService.getPlacePredictions(request, (result, status) => {
                    resolve({
                        isOk: status === PlacesServiceStatus.OK,
                        status,
                        predictions: result,
                    });
                });
            });
        } else {
            return {
                isOk: false,
                status: PlacesServiceStatus.UNKNOWN_ERROR,
                predictions: [],
            };
        }
    }

    static async placeIdToAddress(placeId: string): Promise<PlacesGeocoderResult> {
        const maps = window.google?.maps;
        if (maps) {
            const geocoder = new google.maps.Geocoder();

            return new Promise<PlacesGeocoderResult>(resolve => {

                geocoder.geocode({ placeId }, (results, status) => {
                    resolve({
                        isOk: status === GeocoderStatus.OK,
                        status,
                        results,
                    });
                });
            });
        } else {
            return {
                isOk: false,
                status: GeocoderStatus.UNKNOWN_ERROR,
                results: [],
            };
        }
    }
}
