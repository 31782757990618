
import Button from "@/components/Ui/Button/Button.vue";
import Component from "vue-class-component";
import { Hotel } from "@/types/hotel";
import { Prop } from "vue-property-decorator";
import { Room } from "@/types/room";
import Vue from "vue";
import { reservationFormModule } from "@/store/reservation_form/reservation_form";
import Breadcrumbs, { Breadcrumb } from "@/components/Ui/Breadcrumbs/Breadcrumbs.vue";
import { homeFiltersModule } from "@/store/home_filters/home_filters";

@Component({
    components: { Breadcrumbs, Button },
})
export default class SingleHotelRoomHeader extends Vue {
    @Prop() readonly hotel!: Hotel;
    @Prop() readonly room!: Room;

    dateRangeText = homeFiltersModule.dateRangeText;
    numOfPassengers = homeFiltersModule.numOfPassengers

    get numOfPassengersText(): string {
        return `${this.numOfPassengers + (this.numOfPassengers === 1 ? ` ${this.$tc('passenger')}` : ` ${this.$tc('passengers', 2)}`)}`;
    }

    get breadcrumbs(): Array<Breadcrumb> {
        return reservationFormModule.breadcrumbs;
    }

    get displayCrumbs(): boolean {
        return reservationFormModule.reservation !== null
    }
}
