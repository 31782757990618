
import Vue from "vue";
import InputLabel from "@/components/Ui/InputLabel/InputLabel.vue";
import InputField from "@/components/Ui/InputField/InputField.vue";
import Button from "@/components/Ui/Button/Button.vue";
import Component from "vue-class-component";
import { LoginErrors } from "@/types/loginErrors";
import { loginModule } from "@/store/login/login";

@Component({
    components: { Button, InputField, InputLabel },
})
export default class Login extends Vue {
    email = "";
    password = "";
    errors: LoginErrors = {};

    get currentYear(): number {
        return new Date().getFullYear();
    }

    get async(): boolean {
        return loginModule.loginAsync;
    }

    validateForm(): boolean {
        const retErrors: LoginErrors = {};

        if (!this.email || !this.email.includes("@")) {
            retErrors.email = "Molimo unesite validan E-Mail";
        }

        if (!this.password || this.password.length < 8) {
            retErrors.password = "Molimo unesite validan password";
        }

        this.errors = retErrors;

        return Object.keys(retErrors).length === 0;
    }

    onLogin(): void {
        if (!this.validateForm()) return;

        loginModule.performLoginAction({
            email: this.email,
            password: this.password,
        });
    }
}
