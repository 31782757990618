

import Vue from "vue";
import Component from "vue-class-component";
import Container from "@/components/Ui/Container/Container.vue";
import { Prop } from "vue-property-decorator";
import { reservationFormModule, ReservationFormSelectedRoomVariant } from "@/store/reservation_form/reservation_form";
import { Room } from "@/types/room";
import { getAvailabilityGroupsForRoomAvailabilities } from "@/utils/price_utils";

@Component({
    components: { Container },
})
export default class ReservationRoomCard extends Vue {
    @Prop() readonly room!: Room;
    @Prop({}) readonly variants!: Record<string, ReservationFormSelectedRoomVariant>;

    get variantObjs(): ReservationFormSelectedRoomVariant[] {
        const variants = Object.values(this.variants);

        return variants;
    }

    get getPrice(): number {
        const groups = getAvailabilityGroupsForRoomAvailabilities(this.room.availabilities);

        const totalForObjs = groups.calculatePriceForVariantsArray(this.variantObjs);

        return totalForObjs;
    }

    get hotelName(): string {
        return reservationFormModule.hotel?.name ?? "";
    }
}
