import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { store } from "@/store";
import {
    HotelsQuery,
    IHotelForm,
    Hotel,
    HotelFilterTerms,
    HotelWithStatistics,
    HotelWithSpecialOffers,
} from "@/types/hotel";
import {
    addHotel,
    editHotel,
    fetchHotelsPage,
    fetchHotelById,
    apiFetchAllHotels,
    deleteHotel,
    apiSearchHotels,
    apiGetTopReservedHotels,
    fetchHotelsPageOnlyWithOffers,
    deleteHotelSpecialOffer,
    apiFetchAllHotelsWithOffers, addHotelSpecialOffer, editHotelSpecialOffer,
} from "@/api/hotels/hotels";
import { filterHotels } from "@/utils/filters";
import { HotelSpecialOffer, IHotelSpecialOfferForm } from "@/types/hotelSpecialOffer";
import { cloneDeep } from "lodash";
import { sortHotelsByPrice } from "@/utils/utils";

@Module({ name: "HotelsModule", store: store, dynamic: true })
export default class HotelsModule extends VuexModule {
    fetchAsync = false;
    hotels: Array<Hotel> = [];
    filteredHotels: Array<Hotel> | null = null;
    filterTerms: HotelFilterTerms | null = null;
    hasMore = true;

    currentPage = 1;
    totalPages = 1;
    perPage = 10;

    selectedHotel: Hotel | null = null;
    addEditAsync = false;
    deleteAsync = false;
    deleteSpecialOfferAsync = false;
    addEditSpecialOfferAsync = false;

    topReservedHotels: Array<HotelWithStatistics> = [];

    queryData: HotelsQuery | null = null;

    hotelsWithOffers: HotelWithSpecialOffers[] = [];

    @Mutation
    setHotelsFetchAsync(async: boolean): void {
        this.fetchAsync = async;
    }

    @Mutation
    setHotelDeleteAsync(async: boolean): void {
        this.deleteAsync = async;
    }

    @Mutation
    setSpecialOfferDeleteAsync(async: boolean): void {
        this.deleteSpecialOfferAsync = async;
    }

    @Mutation
    setSpecialOfferAddEditAsync(async: boolean): void {
        this.addEditSpecialOfferAsync = async;
    }

    @Mutation
    setCurrentPage(page: number): void {
        this.currentPage = page;
    }

    @Mutation
    setHasMore(hasMore: boolean): void {
        this.hasMore = hasMore;
    }

    @Mutation
    setPerPage(perPage: number): void {
        this.perPage = perPage;
    }

    @Mutation
    setTotalPages(totalPages: number): void {
        this.totalPages = totalPages;
    }

    @Mutation
    setHotels(hotels: Array<Hotel>): void {
        this.hotels = hotels;
    }

    @Mutation
    setHotelsWithOffers(hotels: Array<HotelWithSpecialOffers>): void {
        this.hotelsWithOffers = hotels;
    }

    @Mutation
    selectHotel(hotel: Hotel | null): void {
        this.selectedHotel = hotel;
    }

    @Mutation
    setAddEditAsync(async: boolean): void {
        this.addEditAsync = async;
    }


    @Mutation
    setHotelsQuery(queryData: HotelsQuery): void {
        this.queryData = queryData;
    }

    @Mutation
    setFilteredHotels(hotels: Array<Hotel> | null): void {
        this.filteredHotels = hotels;
    }

    @Mutation
    setHotelFilterTerms(hotelsFilter: HotelFilterTerms | null): void {
        this.filterTerms = hotelsFilter;

        if (hotelsFilter === null) {
            this.filteredHotels = null;
        }
    }

    @Mutation
    removeHotelWithOffers(hotelId: string): void {
        this.hotelsWithOffers = this.hotelsWithOffers.filter(h => h.id !== hotelId);
    }

    @Mutation
    removeHotelSpecialOffer({ hotelId, offerId }: { hotelId: string, offerId: string }): void {
        const updatedHotel = this.hotelsWithOffers.find(h => h.id === hotelId);

        if (updatedHotel) {
            updatedHotel.specialOffers = updatedHotel.specialOffers.filter(offer => offer.id !== offerId);

            if (!updatedHotel.specialOffers?.length) {
                this.hotelsWithOffers = this.hotelsWithOffers.filter(h => h.id !== hotelId);
            }
        }
    }

    @Mutation
    setTopReservedHotels(hotels: Array<HotelWithStatistics>): void {
        this.topReservedHotels = hotels;
    }

    //
    // @Action
    // async fetchHotelsFirstPage(onlyWithOffers = false): Promise<void> {
    //     return this.fetchHotelsPageAction({ page: 1, onlyWithOffers });
    // }

    @Action
    async fetchAllHotels(): Promise<Array<Hotel> | null> {
        this.setHotelsFetchAsync(true);

        try {
            const r = await apiFetchAllHotels();

            if (r !== null) {
                this.setHotels(r);
            }

            return r;
        } catch (e) {
            console.error(e);
        } finally {
            this.setHotelsFetchAsync(false);
        }

        return null;
    }

    @Action
    async fetchAllHotelsWithOffers(): Promise<Array<HotelWithSpecialOffers> | null> {
        this.setHotelsFetchAsync(true);

        try {
            const r = await apiFetchAllHotelsWithOffers();

            if (r !== null) {
                this.setHotelsWithOffers(r);
            }

            return r;
        } catch (e) {
            console.error(e);
        } finally {
            this.setHotelsFetchAsync(false);
        }

        return null;
    }

    //
    // @Action
    // async fetchHotelsPageAction({ page, onlyWithOffers }: { page: number, onlyWithOffers?: boolean }): Promise<void> {
    //     this.setHotelsFetchAsync(true);
    //
    //     try {
    //
    //         let r;
    //
    //         if (onlyWithOffers) {
    //             r = await fetchHotelsPageOnlyWithOffers(page, this.perPage);
    //         } else {
    //             r = await fetchHotelsPage(page, this.perPage);
    //         }
    //
    //         if (r !== null) {
    //             this.setHotels(r.data);
    //             this.setTotalPages(Math.ceil(r.totalItems / this.perPage));
    //             this.setCurrentPage(page);
    //             this.setHasMore(r.hasMore);
    //         }
    //     } catch (e) {
    //         console.error(e);
    //     } finally {
    //         this.setHotelsFetchAsync(false);
    //     }
    // }
    //
    // @Action
    // async fetchSearchHotelPage(page: number): Promise<Array<Hotel> | null> {
    //     this.setHotelsFetchAsync(true);
    //
    //     if (this.queryData) {
    //         try {
    //             const r = await apiSearchHotels(this.queryData);
    //
    //             const totalPages = Math.ceil((r?.totalItems ?? 0) / this.perPage);
    //
    //             if (r) {
    //                 this.setHotels(r.data);
    //                 this.setTotalPages(totalPages);
    //                 this.setCurrentPage(page);
    //                 this.setHasMore(r.hasMore);
    //             }
    //
    //             return r?.data ?? null;
    //         } catch (e) {
    //             console.error(e);
    //         } finally {
    //             this.setHotelsFetchAsync(false);
    //         }
    //     }
    //
    //     return null;
    // }

    @Action
    async searchHotelsAction({
                                 queryData,
                                 adminMarkup,
                                 agencyMarkup,
                             }: { queryData: HotelsQuery, adminMarkup: number, agencyMarkup: number }): Promise<Array<Hotel> | null> {
        this.setCurrentPage(1);
        this.setPerPage(10);
        this.setHasMore(true);
        this.setHotelsFetchAsync(true);

        try {
            const r = await apiSearchHotels(queryData);

            const totalPages = Math.ceil((r?.totalItems ?? 0) / this.perPage);

            if (r) {
                const hotels = r.data;

                hotels.sort((a, b) => sortHotelsByPrice(a, b, adminMarkup, agencyMarkup));

                this.setHotels(hotels);
                this.setTotalPages(totalPages);
                this.setCurrentPage(this.currentPage);
                this.setHasMore(r.hasMore);
                this.setHotelsQuery(queryData);
            }

            return r?.data ?? null;
        } catch (e) {
            console.error(e);
        } finally {
            this.setHotelsFetchAsync(false);
        }

        return null;
    }

    @Action
    async addHotelAction(data: IHotelForm): Promise<boolean> {
        this.setAddEditAsync(true);

        try {
            const newHotel = await addHotel(data);

            if (newHotel !== null) {
                // this.appendHotels([newHotel])
            }

            return newHotel !== null;
        } catch (e) {
            console.error(e);
            return false;
        } finally {
            this.setAddEditAsync(false);
        }
    }

    @Action
    async editHotelAction({ hotelId, data }: { hotelId: string, data: IHotelForm }): Promise<void> {

        this.setAddEditAsync(true);

        try {
            const updatedHotel = await editHotel(hotelId, data as IHotelForm);

            if (updatedHotel !== null) {
                // this.updateHotel(updatedHotel);
                this.selectHotel(updatedHotel);
            }
        } catch (e) {
            console.error(e);
        } finally {
            this.setAddEditAsync(false);
        }
    }

    @Action
    async fetchHotelByIdActionPure(hotelId: string): Promise<Hotel | null> {
        this.setHotelsFetchAsync(true);

        try {
            const hotel = await fetchHotelById(hotelId);

            return hotel;
        } catch (e) {
            console.error(e);
        } finally {
            this.setHotelsFetchAsync(false);
        }

        return null;
    }

    @Action
    async fetchHotelByIdAction({
                                   hotelId,
                                   oldHotel = null,
                               }: { hotelId: string, oldHotel?: Hotel | null }): Promise<Hotel | null> {
        this.setHotelsFetchAsync(true);

        try {
            const hotel = await fetchHotelById(hotelId);

            if (hotel !== null) {
                let newRooms = cloneDeep(oldHotel?.rooms ?? []);

                newRooms = newRooms.map(room => {
                    const newHotelSameRoom = hotel.rooms.find(rm => rm.id === room.id);

                    if (newHotelSameRoom) {
                        room.roomVariants = newHotelSameRoom.roomVariants;
                        room.mediaObjects = newHotelSameRoom.mediaObjects;
                    }

                    return room;
                });

                hotel.rooms = newRooms;
                this.selectHotel(hotel);
            }

            return hotel;
        } catch (e) {
            console.error(e);
        } finally {
            this.setHotelsFetchAsync(false);
        }

        return null;
    }

    @Action
    filterHotelsAction(filterTerms: HotelFilterTerms | null): void {
        this.setHotelFilterTerms(filterTerms);
        if (filterTerms === null) return;

        const filtered = this.hotels.filter((h) => filterHotels(h, filterTerms));
        this.setFilteredHotels(filtered);
    }

    @Action({ rawError: true })
    async deleteHotelAction(hotelId: string): Promise<null | void> {

        if (this.deleteAsync) return;

        this.setHotelDeleteAsync(true);

        return deleteHotel(hotelId).finally(() => this.setHotelDeleteAsync(false));
    }

    @Action
    async fetchTopReservedHotels(): Promise<Array<HotelWithStatistics> | null> {
        this.setHotelsFetchAsync(true);

        try {
            const r = await apiGetTopReservedHotels();

            if (r !== null) {
                this.setTopReservedHotels(r);
            }

            return r;
        } catch (e) {
            console.error(e);
        } finally {
            this.setHotelsFetchAsync(false);
        }

        return null;
    }

    @Action({ rawError: true })
    async addSpecialOfferAction(data: IHotelSpecialOfferForm): Promise<boolean> {
        this.setSpecialOfferAddEditAsync(true);

        try {
            const newOffer = await addHotelSpecialOffer(data);

            if (newOffer !== null) {
                //
            }

            return newOffer !== null;
        } catch (e) {
            console.error(e);
            return false;
        } finally {
            this.setSpecialOfferAddEditAsync(false);
        }
    }

    @Action({ rawError: true })
    async editSpecialOfferAction({
                                     offerId,
                                     data,
                                 }: { offerId: string, data: IHotelSpecialOfferForm }): Promise<boolean> {
        this.setSpecialOfferAddEditAsync(true);

        try {
            const updatedOffer = await editHotelSpecialOffer(offerId, data);

            if (updatedOffer !== null) {
                //
            }

            return updatedOffer !== null;
        } catch (e) {
            console.error(e);
            return false;
        } finally {
            this.setSpecialOfferAddEditAsync(false);
        }
    }

    @Action({ rawError: true })
    async deleteSpecialOffer({ hotelId, offerId }: { hotelId: string, offerId: string }): Promise<void | null> {
        if (this.deleteSpecialOfferAsync) return;

        this.setSpecialOfferDeleteAsync(true);

        return deleteHotelSpecialOffer(offerId)
            .then(() => this.removeHotelSpecialOffer({ hotelId, offerId }))
            .finally(() => this.setSpecialOfferDeleteAsync(false));
    }
}

export const hotelsModule = getModule(HotelsModule);
