
import Component from "vue-class-component";
import Vue from "vue";
import { additionalContentCategoriesModule } from "@/store/additional_content_categories/additional_content_categories";
import { AdditionalContentCategory } from "@/types/additionalContentCategory";
import Spinner from "@/components/Ui/Spinner/Spinner.vue";
import InputLabel from "@/components/Ui/InputLabel/InputLabel.vue";
import SelectField, { SelectFieldOption } from "@/components/Ui/SelectField/SelectField.vue";
import Button from "@/components/Ui/Button/Button.vue";
import { RouteLink, RouteName } from "@/router";

export enum AddNewFixedType {
    HOTEL = "hotel",
    ROOM = "room",
    ADDITIONAL_CONTENT_CATEGORY = "additional_content_category",
}

@Component({
    components: { Button, SelectField, InputLabel, Spinner },
})
export default class AddNew extends Vue {
    selectedContent: string | null = null;

    beforeMount(): void {
        additionalContentCategoriesModule.fetchAllAdditionalContentCategories();
    }

    onContinue(): void {
        if (this.selectedContent === AddNewFixedType.HOTEL) {
            this.$router.push(RouteLink.AddHotel);
        } else if (this.selectedContent === AddNewFixedType.ROOM) {
            this.$router.push(RouteLink.AddRoom);
        } else if (this.selectedContent === AddNewFixedType.ADDITIONAL_CONTENT_CATEGORY) {
            this.$router.push(RouteLink.AddAdditionalContentCategory);
        } else {
            if (this.selectedContent !== null) {
                this.$router.replace({
                    name: RouteName.AddAdditionalContent,
                    params: {
                        categoryId: this.selectedContent,
                    },
                });
            }
        }
    }

    get contentTypeOptions(): Array<SelectFieldOption> {
        return [{
            value: AddNewFixedType.HOTEL,
            label: "Hotel",
            topDash: false,
        }, {
            value: AddNewFixedType.ROOM,
            label: "Room",
            topDash: false,
        }, ...this.additionalContentCategories.map((category) => ({
            value: category.id,
            label: category.nameBs,
            topDash: false,
        })), {
            value: AddNewFixedType.ADDITIONAL_CONTENT_CATEGORY,
            label: "Kategorija",
            topDash: true,
        }];
    }

    get async(): boolean {
        return additionalContentCategoriesModule.fetchAsync;
    }

    get additionalContentCategories(): Array<AdditionalContentCategory> {
        return additionalContentCategoriesModule.additionalContentCategories;
    }
}
