
import Vue from 'vue';
import Component from "vue-class-component";
import { Amenity } from "@/types/amenity";
import { amenitiesModule } from "@/store/amenities/amenities";
import Checkbox, { CheckboxType } from "@/components/Ui/Checkbox/Checkbox.vue";
import Button, { ButtonType } from "@/components/Ui/Button/Button.vue";
@Component({
    components: { Button, Checkbox },
})
export default class AdditionalContentListFilters extends Vue {
    CheckboxType = CheckboxType;
    ButtonType = ButtonType;

    rating: Record<string, boolean> = {};
    amenityFilters: Record<string, boolean> = {};

    get amenities(): Array<Amenity> {
        return amenitiesModule.amenities;
    }

    applyFilters(): void {
        /**/
    }

    mounted(): void {
        amenitiesModule.fetchAllAmenities();
    }

}
