
import Vue from "vue";
import Component from "vue-class-component";
import { hotelsModule } from "@/store/hotels/hotels";
import Spinner from "@/components/Ui/Spinner/Spinner.vue";
import CustomTable, { TableField } from "@/components/Ui/CustomTable/CustomTable.vue";
import { Hotel, ListHotel } from "@/types/hotel";
import Button from "@/components/Ui/Button/Button.vue";
import ListPagination from "@/components/ListPagination/ListPagination.vue";
import { RouteName } from "@/router";
import AdminPageHeader from "@/components/AdminPageHeader/AdminPageHeader.vue";
import RoomsTable from "@/views/RoomsTable/RoomsTable.vue";

@Component({
    components: { RoomsTable, AdminPageHeader, ListPagination, Button, CustomTable, Spinner },
    mounted() {
        hotelsModule.fetchAllHotels();
    }
})
export default class HotelsTable extends Vue {
    tableFields: Array<TableField<ListHotel>> = [{
        name: "",
        title: "Sortiraj",
        slot: "name"
    }, {
        name: "city",
        title: "Grad",
        slot: "city",
    }, {
        name: "price",
        title: "Cijena",
        slot: "price",
    }, {
        name: "address",
        title: "Adresa",
        slot: "address",
    }, {
        name: "actions",
        title: "Pretraga",
        width: "14%",
        slot: "actions",
    }];

    get currentPage(): number {
        return hotelsModule.currentPage;
    }

    get totalPages(): number {
        return hotelsModule.totalPages;
    }

    get hotels(): Array<Hotel> {
        return hotelsModule.hotels;
    }

    get async(): boolean {
        return hotelsModule.fetchAsync;
    }
    //
    // onPageChange(page: number): void {
    //     hotelsModule.fetchHotelsPageAction({ page });
    // }

    editHotel(hotel: ListHotel): void {
        this.$router.push({
            name: RouteName.EditHotel,
            params: {
                hotelId: hotel.id,
            },
        });
    }

    previewHotel(hotel: ListHotel): void {
        this.$router.push({
            name: RouteName.SingleHotelAdmin,
            params: {
                hotelId: hotel.id,
            },
        });
    }
}
