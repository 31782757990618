
import Button, { ButtonType } from "@/components/Ui/Button/Button.vue";
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import Component from "vue-class-component";

@Component({
    components: { Button },
})
export default class ListPagination extends Vue {
    @Prop() readonly numPages!: number;
    @Prop() readonly currentPage!: number;
    @Prop() readonly numberButtonType?: ButtonType;
    @Prop({ default: 3 }) readonly displayPages!: number;

    onPrevious(): void {
        let desiredPage = this.currentPage - 1;

        if (desiredPage > 0) {
            this.$emit("onNavigate", desiredPage);
        }
    }

    onNext(): void {
        let desiredPage = this.currentPage + 1;

        if (desiredPage <= this.numPages) {
            this.$emit("onNavigate", desiredPage);
        }
    }

    get next5Pages(): Array<number> {
        let next5Max = this.currentPage + 5;
        let clamped = false;

        if (this.numPages < next5Max) {
            next5Max = this.numPages;
            clamped = true;
        }

        let retPages = [];

        if (clamped) {
            for (let i = this.currentPage; i <= next5Max; i++) {
                retPages.push(i);
            }
        } else {
            for (let i = next5Max - 5; i <= next5Max; i++) {
                retPages.push(i);
            }
        }

        return retPages;
    }

    get nextNPages(): Array<number> {
        let nextNMax = this.currentPage + this.displayPages;
        let clamped = false;

        if (nextNMax > this.numPages) {
            nextNMax = this.numPages;
            clamped = true;
        }

        const retPages = [];

        if (clamped) {

            const first = this.numPages - this.displayPages + 1;

            for (let i = first <= 0 ? 1 : first; i <= nextNMax; i++) {
                retPages.push(i);
            }
        } else {
            for (let i = this.currentPage; i < nextNMax; i++) {
                retPages.push(i);
            }
        }

        return retPages;
    }
}
