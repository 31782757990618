
import Vue from "vue";
import Component from "vue-class-component";
import Spinner from "@/components/Ui/Spinner/Spinner.vue";
import CustomTable, { TableField } from "@/components/Ui/CustomTable/CustomTable.vue";
import { User } from "@/types/user";
import Button, { ButtonType } from "@/components/Ui/Button/Button.vue";
import ListPagination from "@/components/ListPagination/ListPagination.vue";
import AdminPageHeader from "@/components/AdminPageHeader/AdminPageHeader.vue";
import { usersModule } from "@/store/users/users";
import { RouteLink, RouteName } from "@/router";
import InputLabel from "@/components/Ui/InputLabel/InputLabel.vue";
import InputField from "@/components/Ui/InputField/InputField.vue";

@Component({
    components: { AdminPageHeader, ListPagination, Button, CustomTable, Spinner, InputLabel, InputField },
})
export default class Users extends Vue {
    ButtonType = ButtonType;
    RouteLink = RouteLink;

    tableFields: Array<TableField<User>> = [{
        name: "",
        title: "Korisnik",
        slot: "info",
    }, {
        name: "email",
        title: "Email",
    }, {
        name: "phone",
        title: "Telefon",
    }, {
        name: "role",
        title: "Uloga",
        slot: "role",
    }, {
        name: "actions",
        title: "Akcije",
        width: "13%",
        slot: "actions",
    }];

    get currentPage(): number {
        return usersModule.currentPage;
    }

    get totalPages(): number {
        return usersModule.totalPages;
    }

    get users(): Array<User> {
        return usersModule.users;
    }

    get async(): boolean {
        return usersModule.fetchAsync || usersModule.addEditAsync;
    }

    get usersSearch(): string {
        return usersModule.search;
    }

    onPageChange(page: number): void {
        usersModule.fetchUsersPageAction(page);
    }

    editUser(user: User): void {
        this.$router.push({
            name: RouteName.ManageUser,
            params: {
                userId: user.id,
            },
        });
    }

    async deleteUser(user: User): Promise<void> {
        const r = await this.$swal.fire<boolean>({
            icon: "error",
            title: "Da li ste sigurni?",
            text: "Izbriši korisnika!",
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: "Obriši",
            cancelButtonText: "Odustani",
            confirmButtonColor: "#EB5E55",
            reverseButtons: true,
        });

        if (r.value) {
            await usersModule.deleteUser(user.id).then(() => usersModule.fetchAllUsers());
        }
    }

    updateUsersSearchText(text: string): void {
        usersModule.setSearchText(text);
    }

    onSearch(): void {
        usersModule.fetchUsersFirstPage();
    }

    mounted(): void {
        usersModule.fetchUsersFirstPage();
    }
}
