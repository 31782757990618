
import Vue from "vue";
import Component from "vue-class-component";
import { CalendarView, CalendarViewHeader } from "vue-simple-calendar";
import Button, { ButtonType } from "@/components/Ui/Button/Button.vue";
import CalendarHeader from "@/views/Calendar/CalendarHeader.vue";
import CalendarDay, { DaySelectionType } from "@/views/Calendar/CalendarDay.vue";
import moment, { Moment } from "moment";
import {
    apiAddAvailabilitiesBulk,
    apiDeleteAvailabilitiesBulk,
    apiEditAvailabilitiesBulk,
    apiEditAvailabilitiesGroupEnabled,
    apiEditHotelGlobalDiscount,
    apiEditPromoCodeBulk,
    apiFetchAllRoomAvailabilities,
    apiFetchAvailableRoomsForDateRange,
    apiMergeAvailabilities,
    apiRecalculateGroupRoomsQuantity,
    apiSplitAvailabilities,
} from "@/api/rooms/rooms";
import { AvailabilityItem } from "@/types/availability";
import { ListRoom, Room } from "@/types/room";
import { roomsModule } from "@/store/rooms/rooms";
import InputLabel from "@/components/Ui/InputLabel/InputLabel.vue";
import InputField, { InputFieldType } from "@/components/Ui/InputField/InputField.vue";
import SelectField, { SelectFieldOption } from "@/components/Ui/SelectField/SelectField.vue";
import Checkbox, { CheckboxType } from "@/components/Ui/Checkbox/Checkbox.vue";
import { CalendarRibbon, TmpCalendarRibbon } from "@/types/availabilityRibbon";
import { Watch } from "vue-property-decorator";
import Spinner from "@/components/Ui/Spinner/Spinner.vue";
import { Pricing, RoomPricing } from "@/types/pricing";
import { Hotel } from "@/types/hotel";
import {
    areDateRangesOverlapping,
    getRoomVariantPricingObject,
    getRoomVariantText, showAreYouSureSwal,
    showSwalToast,
} from "@/utils/utils";
import ClickOutside from "vue-click-outside";
import { RoomVariantClassification } from "@/types/roomVariant";
import { hotelsModule } from "@/store/hotels/hotels";

@Component({
    components: {
        Spinner,
        Checkbox,
        SelectField,
        InputField,
        InputLabel,
        CalendarHeader,
        CalendarDay,
        Button,
        CalendarView,
        CalendarViewHeader,
    },
    directives: {
        ClickOutside,
    },
})
export default class Calendar extends Vue {
    selections: Array<CalendarRibbon> = [];

    tmpSelection: TmpCalendarRibbon | null = null;

    showDate = new Date();

    selectionStart: Date | null = null;
    selectionEnd: Date | null = null;

    selectedSelectionsIds: Array<string> = [];

    selectedRoomAvailabilities: Array<AvailabilityItem> = [];

    theSelectedRoom: string | null = null;

    hotelRoomTypes: Array<ListRoom> = [];

    selectedHotel: string | null = null;
    hotel: Hotel | null = null;

    roomAvailabilityAsync = false;
    roomNumberOptions: Array<SelectFieldOption> = [];
    roomQuantity: number | null = null;

    promoCode: string | null = null;

    // ROOM PRICING
    roomPricing: RoomPricing = new RoomPricing({});
    selectedRoomVariant: RoomVariantClassification | null = null;
    activeVariantPricingForm: Pricing | null = null;        // Reference to a pricing model, not a deep copy

    addEditAsync = false;
    deleteAsync = false;
    loadingAsync = false;
    splitMergeAsync = false;
    savePromoCodeAsync = false;

    closeFormOnOutsideClick = true;

    // Room wide discount
    roomDiscountActive = false;
    roomDiscount: number | null = null;

    roomDiscountToAllRooms = false;

    CheckboxType = CheckboxType;
    ButtonType = ButtonType;
    InputType = InputFieldType;

    updateActivePricingForm(field: string, val: unknown): void {
        if (this.activeVariantPricingForm) {
            Vue.set(this.activeVariantPricingForm, field, val);
            this.$forceUpdate();
        }
    }

    updateServiceField(serviceField: string, val: boolean): void {
        if (this.activeVariantPricingForm?.servicesPricings) {
            Vue.set(this.activeVariantPricingForm.servicesPricings[serviceField], "isActive", val);
            this.$forceUpdate();
        }
    }

    get showRoomDiscountForm(): boolean {
        return this.pricingFormDisabled && this.editMode;
    }

    get showMergeView(): boolean {
        return this.selectedSelectionsIds.length > 1;
    }

    get selectionsHaveDifferentPromoCodes(): boolean {
        let haveDifferentCodes = false;

        const selections = this.selections.filter(s => this.selectedSelectionsIds.includes(s.id));

        let previousCode = selections[0].availabilities[0].promoCode;

        for (let selection of selections) {
            const thisCode = selection.availabilities[0].promoCode;
            if (previousCode !== thisCode) {
                haveDifferentCodes = true;
                break;
            }

            previousCode = thisCode;
        }

        return haveDifferentCodes;
    }

    get showSplitView(): boolean {
        return this.tmpSelectionSplitRibbon !== null;
    }

    get selectedSelectionId(): string | null {
        return this.selectedSelectionsIds.length === 1 ? this.selectedSelectionsIds[0] : null;
    }

    get pricingFormDisabled(): boolean {
        return this.selectedRoomVariant === null || this.activeVariantPricingForm === null;
    }

    get selectedRoomType(): ListRoom | null {
        return this.hotelRoomTypes.find((r: Room) => r.id === this.theSelectedRoom) ?? null;
    }

    get roomVariantOptions(): Array<SelectFieldOption> {
        const options: Array<SelectFieldOption> = [];

        if (this.selectedRoomType) {
            this.selectedRoomType.roomVariants.forEach(rv => options.push({
                value: rv.classification,
                label: getRoomVariantText(rv.classification),
                topDash: false,
            }));
        }

        return options;
    }

    onAnyRoomFormInput(): void {
        this.closeFormOnOutsideClick = false;
    }

    onOutsideCalendarClick(e: Event): void {

        const isOutside = !e.composedPath().some((value) => {
            return (value as HTMLElement)?.className?.includes?.("calendar-details");
        });

        if (isOutside && this.closeFormOnOutsideClick) {
            this.resetSelection();
        }
    }

    resetSelection(): void {
        this.selectionStart = null;
        this.selectionEnd = null;
        this.tmpSelection = null;
        this.selectedSelectionsIds = [];
    }

    addNewGroupPricing(): void {
        if (this.activeVariantPricingForm) {
            this.activeVariantPricingForm.groups.push({
                from: 0,
                to: 0,
                priceInCents: 0,
            });
        }
    }

    recalculateRoomNumber(): void {
        const room = this.hotelRoomTypes.find((r: Room) => r.id === this.theSelectedRoom);

        if (this.selectedSelection && room) {
            apiRecalculateGroupRoomsQuantity(this.selectedSelection?.id).then(() => {
                this.fetchRoomAvailabilities(this.selectedHotel || "", room?.name);
                showSwalToast("Uspješno", "success");
                this.closeFormOnOutsideClick = true;
            }).finally(() => this.addEditAsync = false);
        }
    }

    toggleSale(): void {
        const room = this.hotelRoomTypes.find((r: Room) => r.id === this.theSelectedRoom);

        if (this.selectedSelection && room) {
            apiEditAvailabilitiesGroupEnabled(this.selectedSelection?.id, !this.isSelectedSelectionEnabled).then(() => {
                this.fetchRoomAvailabilities(this.selectedHotel || "", room?.name);
                showSwalToast("Uspješno", "success");
                this.closeFormOnOutsideClick = true;
            }).finally(() => this.addEditAsync = false);
        }
    }

    @Watch("selectedRoomVariant")
    onChangeRoomVariant(): void {
        this.activeVariantPricingForm = getRoomVariantPricingObject(this.roomPricing, this.selectedRoomVariant ?? RoomVariantClassification.SINGLE);
    }

    @Watch("activeRibbon")
    async onChangeActiveRibbon(_newRibbon: TmpCalendarRibbon | CalendarRibbon | undefined, oldRibbon: TmpCalendarRibbon | CalendarRibbon | undefined): Promise<void> {
        if (oldRibbon?.id !== this.activeRibbon?.id) {
            this.selectedRoomVariant = null;
        }

        if (this.selectedHotel !== null && this.theSelectedRoom !== null) {
            let room = roomsModule.rooms.find(r => r.id === this.theSelectedRoom);
            let ribbon = this.activeRibbon;

            if (room && ribbon) {
                this.roomAvailabilityAsync = true;

                const availRooms = await apiFetchAvailableRoomsForDateRange(this.selectedHotel, room.id, {
                    startDate: ribbon.startDate,
                    endDate: ribbon.endDate,
                });

                this.roomAvailabilityAsync = false;

                const ablty = (ribbon as CalendarRibbon).availabilities?.[0];

                this.promoCode = ablty?.promoCode;

                // Set pricing
                if (ablty?.pricing) {
                    this.setRoomPricing(ablty.pricing);
                }

                this.roomNumberOptions = this.generateRoomNumberOptions(availRooms || 0);
            }
        }
    }

    async onSavePromoCode(): Promise<void> {
        const promoCode = (this.promoCode?.length ?? 0) > 0 ? this.promoCode : null;

        const room = this.hotelRoomTypes.find((r: Room) => r.id === this.theSelectedRoom);

        const availability = (this.selectedSelection as CalendarRibbon)?.availabilities?.[0];

        if (availability && room) {
            this.savePromoCodeAsync = true;

            const r = await apiEditPromoCodeBulk(availability.groupId, promoCode);

            if (r) {
                this.fetchRoomAvailabilities(this.selectedHotel || "", room.name);
                showSwalToast("Uspješno", "success");
            }

            this.savePromoCodeAsync = false;
        }
    }

    async mergeAvailabilities(): Promise<void> {
        if (this.showMergeView) {

            const room = this.hotelRoomTypes.find((r: Room) => r.id === this.theSelectedRoom);

            const groupIds: Array<string> = [];

            this.selectedSelectionsIds.forEach(ribbonId => {
                const groupId = this.selections.find(r => r.id === ribbonId)?.availabilities[0]?.groupId;

                if (groupId) {
                    groupIds.push(groupId);
                }
            });

            this.splitMergeAsync = true;

            apiMergeAvailabilities(groupIds)
                .then(() => this.fetchRoomAvailabilities(this.selectedHotel ?? "", room?.name ?? ""))
                .then(() => {
                    this.resetPricings();
                    this.resetSelection();
                    showSwalToast("Uspješno", "success");
                })
                .finally(() => this.splitMergeAsync = false);
        }
    }

    async splitAvailabilities(): Promise<void> {
        if (this.tmpSelectionSplitRibbon && this.tmpSelection) {

            const room = this.hotelRoomTypes.find((r: Room) => r.id === this.theSelectedRoom);

            const { startDate, endDate } = this.tmpSelection;

            this.splitMergeAsync = true;

            apiSplitAvailabilities(this.tmpSelectionSplitRibbon.availabilities[0].groupId, startDate, endDate)
                .then(() => this.fetchRoomAvailabilities(this.selectedHotel ?? "", room?.name ?? ""))
                .then(() => {
                    this.resetPricings();
                    this.resetSelection();
                    showSwalToast("Uspješno", "success");
                })
                .finally(() => this.splitMergeAsync = false);
        }
    }

    async onSubmit(): Promise<void> {

        const room = this.hotelRoomTypes.find((r: Room) => r.id === this.theSelectedRoom);

        const roomPricing = this.roomPricing.getPricingForSubmit();

        // add
        if (this.selectedHotel && room?.id) {

            this.addEditAsync = true;


            if (!this.editMode && this.roomQuantity) {
                await apiAddAvailabilitiesBulk(this.selectedHotel, room.id, +this.roomQuantity, roomPricing, {
                    startDate: this.activeRibbon?.startDate || new Date(),
                    endDate: this.activeRibbon?.endDate || new Date(),
                }).then(async newAws => {
                    if (newAws) {
                        this.selectedRoomAvailabilities.push(...newAws);
                        await this.fetchRoomAvailabilities(this.selectedHotel ?? "", room.name);
                        this.resetPricings();

                        this.selectedSelectionsIds = [newAws[0].groupId];
                        this.tmpSelection = null;

                        this.closeFormOnOutsideClick = true;
                        showSwalToast("Uspješno", "success");
                    }
                }).finally(() => {
                    this.addEditAsync = false;
                });
            } else {
                const availability = (this.selectedSelection as CalendarRibbon)?.availabilities?.[0];

                if (availability) {
                    await this.editAvailabilitiesBulk(availability, roomPricing, room.name, null);
                }
            }

            this.addEditAsync = false;
        }
    }

    async editAvailabilitiesBulk(availability: AvailabilityItem, roomPricing: RoomPricing, roomName: string, rawDiscount: number | null = null): Promise<void> {
        if (availability && roomName) {
            this.addEditAsync = true;

            let promise;

            if (this.roomDiscountToAllRooms && this.selectedHotel && rawDiscount !== null) {
                promise = apiEditHotelGlobalDiscount(availability.groupId, rawDiscount);
            } else {
                promise = apiEditAvailabilitiesBulk(availability.groupId, roomPricing);
            }

            return promise.then(() => {
                this.fetchRoomAvailabilities(this.selectedHotel || "", roomName);
                showSwalToast("Uspješno", "success");
                this.closeFormOnOutsideClick = true;
            }).finally(() => this.addEditAsync = false);
        }
    }

    async applyRoomDiscount(): Promise<void> {

        const roomPricing = this.roomPricing.getPricingForSubmit();

        Object.values(roomPricing).forEach((variantPricing: Pricing) => {
            variantPricing.standard.discount = this.roomDiscount ?? 0;
        });

        const room = this.hotelRoomTypes.find((r: Room) => r.id === this.theSelectedRoom);
        const availability = (this.selectedSelection as CalendarRibbon)?.availabilities?.[0];

        await this.editAvailabilitiesBulk(availability, roomPricing, room?.name ?? "", this.roomDiscount ?? 0).then(() => {
            this.roomDiscount = null;
            this.roomDiscountActive = false;
        });
    }

    async deleteAvsGroup(): Promise<void> {

        const room = this.hotelRoomTypes.find((r: Room) => r.id === this.theSelectedRoom);
        const availability = (this.selectedSelection as CalendarRibbon)?.availabilities?.[0];

        if (availability) {
            showAreYouSureSwal({
                text: "Ovo će izbrisati odabrane dostupnosti!",
            }).then(r => {
                if (r.isConfirmed) {
                    this.deleteAsync = true;

                    apiDeleteAvailabilitiesBulk(availability.groupId).then(() => {

                        if (this.selectedHotel && room) {
                            this.fetchRoomAvailabilities(this.selectedHotel, room.name);
                        }

                        this.resetPricings();
                        this.deselectCalendarAll();
                        showSwalToast("Uspješno", "success");
                    }).finally(() => this.deleteAsync = false);
                }
            });
        }
    }

    setRoomPricing(roomPricing: RoomPricing): void {

        this.roomPricing = new RoomPricing(roomPricing);

        this.roomPricing.prepareForForm();
    }

    resetPricings(): void {
        this.roomPricing = new RoomPricing({});
        this.roomQuantity = null;
    }

    selectRibbon(ribbonId: string, event: MouseEvent): void {

        if (event.ctrlKey || event.metaKey) {        // multiple
            const idx = this.selectedSelectionsIds.findIndex(s => s === ribbonId);

            if (idx !== -1) {
                this.selectedSelectionsIds.splice(idx, 1);
            } else {
                this.selectedSelectionsIds.push(ribbonId);
            }
        } else {
            this.selectedSelectionsIds = [ribbonId];
        }

        this.selectionStart = null;
        this.selectionEnd = null;
        this.tmpSelection = null;
        this.selectedRoomVariant = null;
        this.resetPricings();
        this.closeFormOnOutsideClick = true;
    }

    get getNumAvailableRooms(): number | undefined {
        if (!this.selectedSelection) {
            return this.roomNumberOptions.length;
        }

        const counted = this.selectedSelection.availabilities.reduce((res, currentAv) => {      // TODO, refactor myb
            res[currentAv.date] = (res[currentAv.date] || 0) + 1;
            return res;
        }, {} as Record<string, number>);

        return Math.max(...Object.values(counted));
    }

    get editMode(): boolean {
        return this.selectedSelection !== undefined;
    }

    get addMode(): boolean {
        return this.tmpSelection !== null;
    }

    get isMergeValid(): boolean {
        const { startDate, endDate, avbCount } = this.getMergeInfo;

        return Math.abs(moment(startDate).diff(moment(endDate), "days")) === avbCount - 1;
    }

    get getMergeInfo(): { startDate: Date | null, endDate: Date | null, avbCount: number } {
        const avbs: Array<AvailabilityItem> = [];

        this.selectedSelectionsIds.forEach(ribbonId => {
            avbs.push(...this.selections.find(s => s.id === ribbonId)?.availabilities ?? []);
        });

        avbs.sort((a, b) => a.date > b.date ? 1 : -1);

        const startDate = avbs[0]?.date;
        const endDate = avbs[avbs.length - 1]?.date;

        return { startDate: new Date(startDate), endDate: new Date(endDate), avbCount: avbs.length };
    }

    get calendarRangeText(): string {
        let ribbon = this.activeRibbon;

        if (ribbon !== undefined) {
            if (ribbon.startDate.getTime() === ribbon.endDate.getTime()) {
                return moment(ribbon.startDate).format("D. MMMM");
            } else {
                return `${moment(ribbon.startDate).format("D. MMMM")} - ${moment(ribbon.endDate).format("D. MMMM")}`;
            }
        } else if (this.showMergeView) {

            const { startDate, endDate } = this.getMergeInfo;

            if (startDate && endDate) {
                return `${moment(startDate).format("D. MMMM")} - ${moment(endDate).format("D. MMMM")}`;
            }

            return "";
        } else {
            return "";
        }
    }

    get isSelectedSelectionEnabled(): boolean {
        const enabled = this.selectedSelection?.availabilities?.[0]?.enabled;

        return enabled !== undefined ? enabled : true;
    }

    get selectedSelection(): CalendarRibbon | undefined {
        if (this.selectedSelectionId === null) return undefined;

        const selection = this.selections.find(s => s.id === this.selectedSelectionId);

        return selection;
    }

    get activeRibbon(): TmpCalendarRibbon | CalendarRibbon | undefined {
        if (this.tmpSelection !== null) {
            return this.tmpSelection;
        } else {
            return this.selectedSelection;
        }
    }

    get detailsViewOpen(): boolean {
        return this.tmpSelection !== null || this.selectedSelection !== undefined || this.showMergeView;
    }

    generateRoomNumberOptions(maxRooms: number): Array<SelectFieldOption> {
        let rOptions: Array<SelectFieldOption> = [];

        for (let i = 1; i <= maxRooms; i++) {
            rOptions.push({
                label: `${i}`,
                value: `${i}`,
                topDash: false,
            });
        }

        return rOptions;
    }

    groupRoomAvailabilitiesToSelections(avail: Array<AvailabilityItem>): Array<CalendarRibbon> {
        let retArr: Array<CalendarRibbon> = [];
        let availabilitiesRibbons: Record<string, Array<AvailabilityItem>> = {};

        for (let roomAvailability of avail) {
            let obj = availabilitiesRibbons[roomAvailability.groupId];

            if (obj !== undefined) {
                availabilitiesRibbons[roomAvailability.groupId].push(roomAvailability);
            } else {
                availabilitiesRibbons[roomAvailability.groupId] = [roomAvailability];
            }
        }

        for (let [id, availabilities] of Object.entries(availabilitiesRibbons)) {
            availabilities.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

            const startDate = new Date(availabilities[0].date);
            const endDate = new Date(availabilities[availabilities.length - 1].date);

            let r: CalendarRibbon = {
                availabilities: availabilities,
                id: id,
                startDate,
                endDate,
                ribbonRow: this.calculateRibbonRow(startDate, endDate, retArr),
            };

            retArr.push(r);
        }

        return retArr;
    }

    calculateRibbonRow(startDate: Date, endDate: Date, existingRibbons: Array<CalendarRibbon>): number {
        let ribbonRow = 0;

        existingRibbons.forEach(ribbon => {
            if ((startDate <= ribbon.endDate) && (endDate >= ribbon.startDate)) {
                ribbonRow = ribbon.ribbonRow + 1;       // Add up ribbon rows
            }
        });

        return ribbonRow;
    }

    async fetchRoomAvailabilities(hotelId: string, roomName: string): Promise<void> {
        this.loadingAsync = true;
        const availabilities = await apiFetchAllRoomAvailabilities(hotelId, roomName);

        if (availabilities !== null) {
            this.selectedRoomAvailabilities = availabilities;

            this.selections = this.groupRoomAvailabilitiesToSelections(availabilities);
        }

        this.loadingAsync = false;
    }

    deselectRoom(): void {
        this.theSelectedRoom = null;
        this.selectedRoomAvailabilities = [];
        this.selections = [];
        this.roomNumberOptions = [];
    }

    async fetchHotelRoomTypes(hotelId: string): Promise<void> {
        const r = await roomsModule.fetchAllHotelRooms(hotelId);

        if (r !== null) {
            this.hotelRoomTypes = r;
        }
    }


    async onSelectedHotelChanged(hotelId: string | null): Promise<void> {
        this.selectedHotel = hotelId;
        this.deselectCalendarAll();
        this.deselectRoom();

        if (hotelId === null) {
            this.hotelRoomTypes = [];
            // this.hotel = null;
        } else {
            // this.hotel = await hotelsModule.fetchHotelByIdActionPure(hotelId);
            this.fetchHotelRoomTypes(hotelId);
        }
    }

    deselectCalendarAll(): void {
        this.tmpSelection = null;
        this.selectedSelectionsIds = [];
        this.selectedRoomAvailabilities = [];
    }

    async onSelectedRoomChanged(roomId: string | null): Promise<void> {
        this.deselectCalendarAll();

        if (roomId === null) {
            this.deselectRoom();
        } else {
            this.theSelectedRoom = roomId;
        }

        if (this.selectedHotel !== null) {
            // if (this.hotel === null) {
            //     this.hotel = await hotelsModule.fetchHotelByIdActionPure(this.selectedHotel);
            // }

            // console.log(this.hotel);

            //if (this.hotel !== null) {
            const room = this.hotelRoomTypes.find((r: Room) => r.id === roomId);

            if (room !== undefined) {
                this.fetchRoomAvailabilities(this.selectedHotel, room.name);
            }
            //}
        }
    }

    clickDate(date: Date): void {
        if (!this.calendarEditable) return;

        this.selectedSelectionsIds = [];

        if (this.selectionStart === null) {
            this.selectionStart = date;
            this.resetPricings();
            this.tmpSelection = null;
        } else {
            if (this.selectionEnd === null) {
                if (moment(date).isAfter(moment(this.selectionStart)) || moment(date).isSame(moment(this.selectionStart))) {
                    this.tmpSelection = {
                        startDate: this.selectionStart,
                        endDate: new Date(date),
                    };

                    // Handle possible partial overlap ( not full overlap )
                    if (this.tmpSelectionPartiallyOverlaps && this.tmpSelectionSplitRibbon === null) {
                        this.handleTmpSelectionPartialOverlap();
                    }

                    this.resetPricings();
                    this.selectionStart = null;
                    this.selectionEnd = null;
                } else {
                    this.selectionStart = date;
                }
            }
        }
    }

    setMyShowDate(d: Date): void {
        this.showDate = d;
    }

    // use vue's getter caching for performance stuff
    get mSelectionStart(): Moment {
        return moment(this.selectionStart);
    }

    // use vue's getter caching for performance stuff
    get mSelectionEnd(): Moment {
        return moment(this.selectionEnd);
    }

    get calendarEditable(): boolean {
        return this.selectedHotel !== null && this.theSelectedRoom !== null;
    }

    getDaySelectionType(day: Date): DaySelectionType | null {
        if (this.selectionStart === null && this.selectionEnd === null) {
            return null;
        }

        let mDay = moment(day);

        if (this.mSelectionStart.isSame(mDay)) {
            return DaySelectionType.START;
        } else if (this.mSelectionEnd.isSame(mDay)) {
            return DaySelectionType.END;
        } else if (mDay.isBetween(this.mSelectionStart, this.mSelectionEnd)) {
            return DaySelectionType.MIDDLE;
        }

        return null;
    }

    setSelection(dateRange: Array<Date>): void {
        if (!this.calendarEditable) return;

        this.selectedSelectionsIds = [];
        this.tmpSelection = null;
        this.resetPricings();

        this.selectionStart = dateRange[0];
        this.selectionEnd = dateRange[1];
    }

    finishSelection(dateRange: Array<Date>): void {
        if (!this.calendarEditable) return;

        this.tmpSelection = {
            startDate: dateRange[0],
            endDate: dateRange[1],
        };

        // Handle possible partial overlap ( not full overlap )
        if (this.tmpSelectionPartiallyOverlaps && this.tmpSelectionSplitRibbon === null) {
            this.handleTmpSelectionPartialOverlap();
        }

        this.selectedSelectionsIds = [];
        this.resetPricings();

        this.selectionStart = null;
        this.selectionEnd = null;
    }

    handleTmpSelectionPartialOverlap(): void {
        showSwalToast("Datumi se preklapaju za odabranu selekciju.");
        this.tmpSelection = null;
    }

    get tmpSelectionPartiallyOverlaps(): boolean {

        if (!this.tmpSelection) return false;

        const { startDate, endDate } = this.tmpSelection;

        return this.selections.some(ribbon => {
            return areDateRangesOverlapping(startDate, endDate, ribbon.startDate, ribbon.endDate);
        });
    }

    get tmpSelectionSplitRibbon(): CalendarRibbon | null {
        if (!this.tmpSelection) return null;

        const { startDate, endDate } = this.tmpSelection;

        const momentTmpStart = moment(startDate);
        const momentTmpEnd = moment(endDate);

        return this.selections.find(ribbon => {

            const momentRibbonStart = moment(ribbon.startDate);
            const momentRibbonEnd = moment(ribbon.endDate);

            return (momentTmpStart.isSameOrAfter(momentRibbonStart, "days") && momentTmpEnd.isBefore(momentRibbonEnd, "days"))
                || (momentTmpStart.isAfter(momentRibbonStart, "days") && momentTmpEnd.isSameOrBefore(momentRibbonEnd, "days"));
        }) ?? null;
    }

    get currentPeriodLabel(): string {
        return moment(this.showDate).locale("bs").format("MMMM YYYY");
    }

    get mergeMainRibbonDateRangeText(): string {

        if (this.showMergeView) {
            const avbs: AvailabilityItem[] = this.selections.find(ribbon => ribbon.id === this.selectedSelectionsIds[0])?.availabilities ?? [];

            const startDate = avbs[0]?.date;
            const endDate = avbs[avbs.length - 1]?.date;

            if (startDate && endDate) {
                return `${moment(startDate).format("D. MMMM")} - ${moment(endDate).format("D. MMMM")}`;
            }
        }

        return "";
    }

    beforeDestroy(): void {
        hotelsModule.setHotels([]);
    }

}
