
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import { AddFormItem_Address } from "@/types/addForm/addFormItem";
import Component from "vue-class-component";
import InputLabel from "@/components/Ui/InputLabel/InputLabel.vue";
import InputField from "@/components/Ui/InputField/InputField.vue";

@Component({
    components: {InputField, InputLabel},
})
export default class AddContentAddress extends Vue {
    @Prop() readonly item!: AddFormItem_Address;
    @Prop() readonly entity!: Record<string, unknown>;

    beforeMount(): void {
        if (!this.entity.address) {
            Vue.set(this.entity, 'address', {});
        }
    }
}
