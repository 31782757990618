
import Vue from "vue";
import Component from "vue-class-component";
import InputLabel from "../../../components/Ui/InputLabel/InputLabel.vue";
import InputField from "../../../components/Ui/InputField/InputField.vue";
import { Prop, Watch } from "vue-property-decorator";
import { RoomBed, RoomForm } from "@/types/room";
import TextareaField from "../../../components/Ui/TextareaField/TextareaField.vue";
import NumberPlusMinus from "@/components/NumberPlusMinus/NumberPlusMinus.vue";
import { Bed, RoomBedForm } from "@/types/beds";
import {
    addRoomBed,
    addRoomVariant,
    deleteRoomBed,
    deleteRoomVariant,
    editRoomBed,
    editRoomVariant,
} from "@/api/rooms/rooms";
import { roomsModule } from "@/store/rooms/rooms";
import SelectField, { SelectFieldOption } from "@/components/Ui/SelectField/SelectField.vue";
import cloneDeep from "lodash/cloneDeep";
import Button, { ButtonType } from "@/components/Ui/Button/Button.vue";
import { bedsModule } from "@/store/beds/beds";
import { getRoomVariantText, goBackOrPush, showAreYouSureSwal } from "@/utils/utils";
import { RoomVariant, RoomVariantClassification } from "@/types/roomVariant";
import { RouteLink } from "@/router";
import PassengerNumberCategory from "@/views/Home/PassengerNumberWrapper/PassengerNumberCategory.vue";
import Checkbox, { CheckboxType } from "@/components/Ui/Checkbox/Checkbox.vue";
import RoomSingleAlternativeCapacity from "@/views/EditRoom/components/RoomSingleAlternativeCapacity.vue";

@Component({
    components: {
        Checkbox,
        PassengerNumberCategory,
        RoomSingleAlternativeCapacity,
        Button,
        SelectField,
        NumberPlusMinus,
        TextareaField,
        InputField,
        InputLabel,
    },
})
export default class RoomVariants extends Vue {
    @Prop() roomForm!: RoomForm;

    selectedRoomVariant: RoomVariantClassification | null = null;
    activeVariantForm: RoomVariant | null = null;

    addMode = false;
    selectedBed: string | null = null;
    isAddingExtraBed = false;

    copyFromActive = false;

    nBedsInRoom = 0;
    bedCapacity = 0;
    bedDescription = "";

    addAsync = false;

    ButtonType = ButtonType;
    CheckboxType = CheckboxType;
    activeButtonType = ButtonType.PLAINWHITE;
    inactiveButtonType = ButtonType.TRANSPARENT;

    get isExistingVariant(): boolean {
        return Boolean(this.activeVariantForm?.id);
    }

    get roomVariantOptions(): Array<SelectFieldOption> {
        const options: Array<SelectFieldOption> = [];

        for (const variant in RoomVariantClassification) {
            options.push({
                value: variant,
                label: getRoomVariantText(variant),
                topDash: false,
            });
        }

        return options;
    }

    get fetchAsync(): boolean {
        return roomsModule.fetchAsync;
    }

    get editAsync(): boolean {
        return roomsModule.addEditAsync;
    }

    get bedsAsync(): boolean {
        return bedsModule.fetchAsync;
    }

    get bedOptions(): Array<SelectFieldOption> {
        if (this.bedsAsync) {
            return [];
        } else {
            return cloneDeep(bedsModule.beds).map((bed: Bed): SelectFieldOption => {
                return {
                    label: bed.name,
                    value: bed.id,
                    topDash: false,
                };
            });
        }
    }

    @Watch("roomForm")
    onRoomUpdate(): void {
        if (this.selectedRoomVariant) {
            this.switchVariant(this.selectedRoomVariant);            // Refresh active form
        }
    }

    async handleCopyFrom(variant: RoomVariantClassification): Promise<void> {
        this.copyFromActive = false;

        const roomVariant = this.roomForm.roomVariants.find(rv => rv.classification === variant);

        if (this.selectedRoomVariant !== null && roomVariant) {
            await addRoomVariant({
                classification: this.selectedRoomVariant,
                roomName: roomsModule.selectedRoom?.name ?? "",
                hotelId: this.roomForm.hotel ?? "",

                alternativeCapacity: roomVariant.alternativeCapacity,
                roomBeds: roomVariant.roomBeds.map(bed => {
                    return {
                        bed: bed.bed.id,
                        quantity: bed.quantity,
                    };
                }),
                extraRoomBeds: roomVariant.extraRoomBeds.map(bed => {
                    return {
                        bed: bed.bed.id,
                        quantity: bed.quantity,
                    };
                }),
                capacity: roomVariant.capacity,
            });

            this.$emit("refreshRoomData");
            this.switchVariant(this.selectedRoomVariant);       // Refresh active form
        }
    }

    switchVariant(variant: RoomVariantClassification): void {
        if (this.copyFromActive) {
            this.handleCopyFrom(variant);
            return;
        }

        this.selectedRoomVariant = variant;

        const roomVariant = this.roomForm.roomVariants.find(rv => rv.classification === variant);

        this.activeVariantForm = roomVariant ? roomVariant : {
            classification: variant,
            capacity: {},
            alternativeCapacity: {
                capacity1: {},
                capacity2: {},
                capacity3: {},
            },
            roomBeds: [],
            extraRoomBeds: [],
        };
    }

    deleteVariant(): void {
        if (this.activeVariantForm?.id) {
            showAreYouSureSwal({
                text: "Ovo će izbrisati odabranu varijantu!",
            }).then(r => {
                if (r.isConfirmed) {
                    deleteRoomVariant(this.activeVariantForm?.id ?? "").then(() => {
                        this.resetState();
                        this.$emit("refreshRoomData");
                    });
                }
            });
        }
    }

    bedCapacityText(bed: Bed | string): string {
        if (typeof bed === "string") return "";

        return bed.capacity + (bed.capacity === 1 ? " gost" : " gostiju");
    }

    newBedOption(bedName: string): SelectFieldOption {
        return {
            label: bedName,
            value: bedName,
            topDash: false,
        };
    }

    selectBed(value: string | null): void {
        this.addMode = value !== null && this.bedOptions.findIndex(b => b.value === value) === -1;      // Set or reset add mode
        this.selectedBed = value;
    }

    async createVariant(): Promise<RoomVariant | null> {
        if (this.selectedRoomVariant) {
            return addRoomVariant({
                classification: this.selectedRoomVariant,
                roomName: roomsModule.selectedRoom?.name ?? "",
                hotelId: this.roomForm.hotel ?? "",
            });
        }

        return null;
    }

    async onAddRoomBed(): Promise<void> {

        if (!this.selectedBed) {
            return;
        }

        const bed = this.addMode ? {
            name: this.selectedBed,
            capacity: this.bedCapacity,
            description: this.bedDescription,
        } as Bed : `/api/beds/${this.selectedBed as string}`;

        const newRoomBed: RoomBedForm = {
            bed,
            quantity: this.nBedsInRoom,
        };

        this.addAsync = true;

        const variantExists = Boolean(this.activeVariantForm?.id);

        if (!variantExists) {
            await this.createVariant().then(newVariant => {
                this.activeVariantForm = newVariant;
            });
        }

        addRoomBed(newRoomBed).then(createdRoomBed => {

            const roomBedsIds: Array<string> = ((this.isAddingExtraBed ? this.activeVariantForm?.extraRoomBeds : this.activeVariantForm?.roomBeds) ?? []).map((rb: RoomBed) => `/api/room_beds/${rb.id}`);

            if (this.activeVariantForm?.id && createdRoomBed?.id) {
                return editRoomVariant(this.activeVariantForm.id, {
                    [this.isAddingExtraBed ? "extraRoomBeds" : "roomBeds"]: [...roomBedsIds, `/api/room_beds/${createdRoomBed.id}`],
                });
            }
        }).then(() => {
            this.$emit("refreshRoomData");
            this.resetState();
            if (this.selectedRoomVariant) {
                this.switchVariant(this.selectedRoomVariant);       // Refresh active form
            }
        }).finally(() => this.addAsync = false);
    }

    onRemoveRoomBed(roomBed: RoomBed & RoomBedForm): void {

        // Atomic (neki confirm dialog)
        if (roomBed.id) {
            deleteRoomBed(roomBed.id).then(() => {
                this.$emit("refreshRoomData");
                if (this.selectedRoomVariant) {
                    this.switchVariant(this.selectedRoomVariant);       // Refresh active form
                }
            });
        }
    }

    backToRooms(): void {
        goBackOrPush(RouteLink.HotelContent);
    }

    async onSubmit(): Promise<void> {

        const roomVariantsForms = cloneDeep(this.roomForm.roomVariants);

        const promises: Array<Promise<RoomVariant | RoomBed | null>> = [];

        roomsModule.setAddEditAsync(true);

        // Edit beds for each room variant
        if (roomVariantsForms) {
            (roomVariantsForms as RoomVariant[]).forEach(variant => {

                // Update capacities
                if (variant.id) {
                    promises.push(editRoomVariant(variant.id, {
                        capacity: variant.capacity,
                        alternativeCapacity: variant.alternativeCapacity,
                    }));
                }

                // Update beds
                ([...variant.roomBeds, ...variant.extraRoomBeds] as RoomBed[]).forEach(rb => {
                    const roomBedId = rb.id;
                    promises.push(editRoomBed(roomBedId, { quantity: rb.quantity }));
                });
            });
        }

        await Promise.allSettled(promises).finally(() => roomsModule.setAddEditAsync(false));
    }

    resetState(): void {
        this.addMode = false;
        this.nBedsInRoom = 0;
        this.bedCapacity = 0;
        this.bedDescription = "";
        this.selectedBed = null;
        this.isAddingExtraBed = false;
    }

    beforeMount(): void {
        bedsModule.fetchAllBeds();
    }
}
