
import CustomGoogleMap from '@/components/CustomGoogleMap/CustomGoogleMap.vue';
import Component from "vue-class-component";
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import { Hotel } from "@/types/hotel";

@Component({
    components: { CustomGoogleMap },
})
export default class SingleHotelLocation extends Vue {
    @Prop() readonly hotel!: Hotel;
}
