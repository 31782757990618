
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import { AddFormItem_Input, AddFormItem_InputType } from "@/types/addForm/addFormItem";
import Component from "vue-class-component";
import InputLabel from "@/components/Ui/InputLabel/InputLabel.vue";
import InputField from "@/components/Ui/InputField/InputField.vue";
import { snakeCase } from "snake-case";
import latinize from "latinize";

@Component({
    components: { InputField, InputLabel },
})
export default class AddContentInput extends Vue {
    @Prop() readonly item!: AddFormItem_Input;
    @Prop() readonly entity!: Record<string, unknown>;

    get accessor(): string {
        return snakeCase(latinize(this.item.name));
    }

    get inputType(): string {
        if (this.item.inputType === AddFormItem_InputType.Number) {
            return "number";
        }

        return "text"
    }
}
