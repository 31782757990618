import {
    PaginationResponse,
    RawHydraResponse,
    RawHydraResponseIntoPaginationResponse,
} from "@/types/paginationResponse";
import { jsonApiCall, users, apiCall } from "@/api/apiCall";
import { User, UserForm, ListUser } from "@/types/user";

export async function fetchAllUsers(): Promise<Array<User> | null> {
    const r = await jsonApiCall<Array<User>>({
        url: users,
        method: "GET",
    });

    return r;
}

export async function fetchUsersPage(page: number, perPage: number, search: string): Promise<PaginationResponse<ListUser> | null> {
    const r = await jsonApiCall<RawHydraResponse<ListUser>>({
        url: `${users}?page=${page}&per_page=${perPage}&search=${search}`,
        method: "GET",
        headers: {
            "accept": "application/ld+json",
        },
    });

    if (r !== null) {
        return RawHydraResponseIntoPaginationResponse<ListUser>(r);
    } else {
        return null;
    }
}

export async function addUser(data: UserForm): Promise<User | null> {
    const r = await jsonApiCall<User>({
        url: users,
        method: "POST",
        jsonData: data as Record<string, unknown>,
    });

    if (r !== null) {
        return r;
    }

    return null;
}

export async function editUser(userId: string, data: UserForm): Promise<User | null> {
    const r = await jsonApiCall<User>({
        url: `${users}/${userId}`,
        method: "PUT",
        jsonData: data as Record<string, unknown>,
    });

    if (r !== null) {
        return r;
    }

    return null;
}

export async function deleteUser(userId: string): Promise<boolean | null> {
    const r = await apiCall({
        url: `${users}/${userId}`,
        method: "DELETE",
        jsonData: {},
    });

    if (r !== undefined) {
        return true;
    }

    return null;
}

export async function fetchUserById(userId: string): Promise<User | null> {
    const r = await jsonApiCall<User>({
        url: `${users}/${userId}`,
    });

    if (r !== null) {
        return r;
    }

    return null;
}
