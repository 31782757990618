import { render, staticRenderFns } from "./BedsTable.vue?vue&type=template&id=2dc6d70f&scoped=true&"
import script from "./BedsTable.vue?vue&type=script&lang=ts&"
export * from "./BedsTable.vue?vue&type=script&lang=ts&"
import style0 from "./BedsTable.vue?vue&type=style&index=0&id=2dc6d70f&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2dc6d70f",
  null
  
)

export default component.exports