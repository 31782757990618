
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import TextareaField from "../../../components/Ui/TextareaField/TextareaField.vue";
import CustomUploader from "@/components/Ui/CustomUploader/CustomUploader.vue";
import { MediaObject, UploadedFile } from "@/types/mediaObject";

@Component({
    components: { CustomUploader, TextareaField }
})
export default class HotelGalleryForm extends Vue {
    @Prop() newUploads!: Array<UploadedFile>;
    @Prop() currentMedia!: Array<MediaObject>;
}
