
import Vue from "vue";
import Component from "vue-class-component";
import { ListReservation } from "@/types/reservation";
import { getThumbnailStorageUrl, mergeName } from "@/utils/utils";
import moment from "moment";
import PlaceholderableImage from "@/components/Ui/PlaceholderableImage/PlaceholderableImage.vue";
import { Prop } from "vue-property-decorator";

@Component({
    components: { PlaceholderableImage },
})
export default class ReservationInfo extends Vue {
    @Prop({ default: () => null }) readonly reservation!: ListReservation;

    get hotelName() {
        return this.reservation?.hotelName;
    }

    get invoiceNumber() {
        return this.reservation?.invoiceNumber;
    }

    get passangerNames() {
        return (this.reservation?.passengers ?? []).map(pass => mergeName(pass)).filter(o => o !== "").join(", ");
    }

    getAgencyName(reservation: ListReservation): string {
        return reservation.agent.agency?.name || mergeName(reservation.agent);
    }

    getFromToText(reservation: ListReservation): string {
        if (reservation.fromDate !== null && reservation.toDate !== null) {
            return `${moment(reservation.fromDate).format("DD MMMM YYYY")} - ${moment(reservation.toDate).format("DD MMMM YYYY")}`;
        }

        return "";
    }
}
