
import Vue from 'vue';
import Component from "vue-class-component";
import CustomModal from "@/components/Ui/CustomModal/CustomModal.vue";
import { Prop } from "vue-property-decorator";
import InputLabel from "@/components/Ui/InputLabel/InputLabel.vue";
import InputField from "@/components/Ui/InputField/InputField.vue";
import Button, { ButtonType } from "@/components/Ui/Button/Button.vue";
import { offersModule } from "@/store/offers/offers";
import { Offer } from "@/types/offer";
import { RouteLink } from "@/router";

@Component({
    components: { Button, InputField, InputLabel, CustomModal }
})
export default class OfferCreationModal extends Vue {
    @Prop({ default: false }) readonly opened!: boolean;

    customerName = '';
    customerPhone = '';
    customerEmail = '';

    ButtonType = ButtonType;

    successCreated = false;

    get addOfferAsync(): boolean {
        return offersModule.addEditAsync;
    }

    goToOffers(): void {
        this.closeModal();
        this.$router.push(RouteLink.Offers);
    }

    resetForm(): void {
        this.customerName = '';
        this.customerPhone = '';
        this.customerEmail = '';
    }

    async createOffer(): Promise<Offer | null | void> {
        await offersModule.createOffer({
            customerName: this.customerName,
            customerEmail: this.customerEmail,
            customerPhone: this.customerPhone
        });

        this.resetForm();
        this.successCreated = true;
    }

    closeModal(): void {
        this.successCreated = false;
        this.resetForm();
        this.$emit('close');
    }
}
