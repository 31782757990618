import { render, staticRenderFns } from "./AddContentAmenities.vue?vue&type=template&id=05d2d1b0&scoped=true&"
import script from "./AddContentAmenities.vue?vue&type=script&lang=ts&"
export * from "./AddContentAmenities.vue?vue&type=script&lang=ts&"
import style0 from "./AddContentAmenities.vue?vue&type=style&index=0&id=05d2d1b0&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05d2d1b0",
  null
  
)

export default component.exports