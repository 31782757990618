
import Vue from "vue";
import { RouteName } from "@/router";
import Component from "vue-class-component";
import { homeFiltersModule } from "@/store/home_filters/home_filters";
import Spinner from "@/components/Ui/Spinner/Spinner.vue";
import CustomTable, { TableField } from "@/components/Ui/CustomTable/CustomTable.vue";
import { PaymentHistoryItem, PaymentType } from "@/types/PaymentHistoryItem";
import { endOfMonth, getMonth, getYear } from "date-fns";
import PaymentHistoryApi from "@/api/payment_history/payment_history";
import moment from "moment/moment";
import { capitalize } from "lodash";
import SelectField, { SelectFieldOption } from "@/components/Ui/SelectField/SelectField.vue";
import InputLabel from "@/components/Ui/InputLabel/InputLabel.vue";
import Button, { ButtonType } from "@/components/Ui/Button/Button.vue";

@Component({
    components: {
        Button,
        Spinner,
        CustomTable,
        InputLabel,
        SelectField,
    },
})
export default class Payments extends Vue {
    async = false;
    initialAsync = false;

    data: Array<PaymentHistoryItem> = [];

    selectedYear: string | null = getYear(new Date()).toString();
    selectedMonth: string | null = getMonth(new Date()).toString();

    PaymentType = PaymentType;
    ButtonType = ButtonType;

    tableFields: Array<TableField<PaymentHistoryItem>> = [{
        name: "id",
        title: "ID",
    }, {
        name: "from",
        title: "Od",
        slot: "from",
    }, {
        name: "to",
        title: "Za",
        slot: "to",
    }, {
        name: "when",
        title: "Kada",
        slot: "createdAt",
    }, {
        name: "type",
        title: "Tip Uplate",
        slot: "type",
    }, {
        name: "entry",
        title: "Ulaz",
        slot: "entry",
    }, {
        name: "exit",
        title: "Izlaz",
        slot: "exit",
    }];

    get yearOptions(): SelectFieldOption[] {
        const thisYear = this.$moment().year();

        const options: SelectFieldOption[] = [];

        for (let i = thisYear; i >= 2021; i--) {
            options.push({
                label: i.toString(),
                value: i.toString(),
                topDash: false,
            });
        }

        return options;
    }

    get saldo(): number {
        return this.data.reduce<number>((previous, current) => {
            if (current.outgoing) {
                previous -= current.amount;
            } else {
                previous += current.amount;
            }

            return previous;
        }, 0);
    }

    get monthOptions(): SelectFieldOption[] {
        return moment.months().map(longname => {

            const date = moment(longname, "MMMM").locale("bs");

            return {
                label: capitalize(date.format("MMMM")),
                value: date.month().toString(),
                topDash: false,
            };
        });
    }

    async fetchHistoryData(controlAsync = true): Promise<void> {
        if (controlAsync) {
            this.async = true;
        }

        this.data = [];

        const from = new Date(Number(this.selectedYear), Number(this.selectedMonth), 1);
        const to = endOfMonth(from);

        const r = await PaymentHistoryApi.fetchPaymentHistory(from.toISOString(), to.toISOString());

        if (controlAsync) {
            this.async = false;
        }

        this.data = (r ?? []).sort((a, b) => b.id.localeCompare(a.id));
    }

    beforeMount(): void {
        if (homeFiltersModule.invalid) {
            this.$router.replace({
                name: RouteName.Home,
            });

            return;
        }
    }

    mounted(): void {
        this.initialAsync = true;
        this.fetchHistoryData(false);
        this.initialAsync = false;
    }

}
