
import Vue from "vue";
import Component from "vue-class-component";
import { hotelsModule } from "@/store/hotels/hotels";
import Spinner from "@/components/Ui/Spinner/Spinner.vue";
import CustomTable, { TableField } from "@/components/Ui/CustomTable/CustomTable.vue";
import { Hotel, HotelWithSpecialOffers } from "@/types/hotel";
import Button from "@/components/Ui/Button/Button.vue";
import ListPagination from "@/components/ListPagination/ListPagination.vue";
import AdminPageHeader from "@/components/AdminPageHeader/AdminPageHeader.vue";
import RoomsTable from "@/views/RoomsTable/RoomsTable.vue";
import SingleHotelOfferList from "@/views/HotelOffers/SingleHotelOfferList.vue";
import SingleHotelOfferListItem from "@/views/HotelOffers/SingleHotelOfferListItem.vue";
import { HotelSpecialOffer, HotelSponsoredLevel } from "@/types/hotelSpecialOffer";
import { cloneDeep } from "lodash";
import { apiDeleteAllHotelSpecialOffers } from "@/api/hotels/hotels";
import { showAreYouSureSwal } from "@/utils/utils";
import GIcon from "@/components/Ui/GIcon/GIcon.vue";
import HotelSpecialOfferPlaceholderCard
    from "@/views/ManageHotelSpecialOffer/HotelSpecialOfferPlaceholderCard/HotelSpecialOfferPlaceholderCard.vue";

interface CategorisedOffers {
    specialOffersLevel1: Array<HotelSpecialOffer>;
    specialOffersLevel2: Array<HotelSpecialOffer>;
    specialOffersLevel3: Array<HotelSpecialOffer>;
}

@Component({
    components: {
        HotelSpecialOfferPlaceholderCard,
        GIcon,
        SingleHotelOfferListItem,
        SingleHotelOfferList,
        RoomsTable,
        AdminPageHeader,
        ListPagination,
        Button,
        CustomTable,
        Spinner
    },
})
export default class HotelOffers extends Vue {

    HotelSponsoredLevel = HotelSponsoredLevel;

    expandedHotelId: string | null = null;
    deleteAllAsyncHotelId: string | null = null;

    tableFields: Array<TableField<HotelWithSpecialOffers>> = [{
        name: "hotel",
        title: "Hotel",
        slot: 'name',
        width: '20%',
    }, {
        name: "offers_1",
        title: "Izdvojenost 1",
        slot: "offers_1",
        headerSlot: 'offers_1_header',
        rowClass: 'offers-list'
    }, {
        name: "offers_2",
        title: "Izdvojenost 2",
        slot: "offers_2",
        headerSlot: 'offers_2_header',
        rowClass: 'offers-list'
    }, {
        name: "offers_3",
        title: "Izdvojenost 3",
        slot: "offers_3",
        headerSlot: 'offers_3_header',
        rowClass: 'offers-list'
    }, {
        name: "actions",
        title: "",
        width: '8%',
        slot: "actions",
    }];

    get currentPage(): number {
        return hotelsModule.currentPage;
    }

    get totalPages(): number {
        return hotelsModule.totalPages;
    }

    get hotels(): Array<HotelWithSpecialOffers> {
        return hotelsModule.hotelsWithOffers;
    }

    get async(): boolean {
        return hotelsModule.fetchAsync;
    }

    toggleRowExpanded(hotelId: string | null): void {
        this.expandedHotelId = hotelId;
    }

    getOffersLevel1(hotel: HotelWithSpecialOffers): HotelSpecialOffer[] {
        return hotel.specialOffers.filter(offer => offer.sponsoredLevel === HotelSponsoredLevel.Level1);
    }

    getOffersLevel2(hotel: HotelWithSpecialOffers): HotelSpecialOffer[] {
        return hotel.specialOffers.filter(offer => offer.sponsoredLevel === HotelSponsoredLevel.Level2);
    }

    getOffersLevel3(hotel: HotelWithSpecialOffers): HotelSpecialOffer[] {
        return hotel.specialOffers.filter(offer => offer.sponsoredLevel === HotelSponsoredLevel.Level3);
    }

    // onPageChange(page: number): void {
    //     hotelsModule.fetchHotelsPageAction({ page, onlyWithOffers: true });
    // }

    async deleteAllOffers(hotel: HotelWithSpecialOffers): Promise<void> {

        const prompt = await showAreYouSureSwal({ text: 'Da, izbriši sve izdvojenosti za ovaj hotel.' });

        if (prompt.value) {
            this.deleteAllAsyncHotelId = hotel.id;

            await apiDeleteAllHotelSpecialOffers(hotel.id)
                .then(() => hotelsModule.removeHotelWithOffers(hotel.id))
                .finally(() => this.deleteAllAsyncHotelId = null);
        }
    }

    mounted(): void {
        hotelsModule.fetchAllHotelsWithOffers();
    }
}
