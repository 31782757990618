

import Component from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";
import Vue from "vue";
import { AddForm } from "@/types/addForm/addForm";
import AddContentPage from "@/components/AddContentRenderer/AddContentPage.vue";
import { AddFormPage } from "@/types/addForm/addFormPage";
import Button, { ButtonType } from "@/components/Ui/Button/Button.vue";
import { AddFormItem_Gallery } from "@/types/addForm/addFormItem";
import { MediaObject, UploadedFile } from "@/types/mediaObject";
import { uploadMediaObject } from "@/api/media_objects/media_objects";

@Component({
    components: { Button, AddContentPage },
})
export default class AddContentRenderer extends Vue {
    currentPage = 0;
    continueAsync = false;

    get activePage(): AddFormPage | undefined {
        return this.form.pages[this.editMode ? this.activePageOverride : this.currentPage];
    }

    get lastPageIndex(): number {
        return this.form.pages.length - 1;
    }

    ButtonType = ButtonType;

    async uploadGalleryImages(): Promise<void> {

        const images = this.entity["images"] as Array<UploadedFile>;

        if (images) {

            this.continueAsync = true;

            const promises: Array<Promise<MediaObject | null>> = [];

            images.forEach((image) => {
                promises.push(uploadMediaObject(image, (p => image.progress = p)).catch(() => null));
            });

            const p = await Promise.all(promises).finally(() => this.continueAsync = false);

            this.entity["images"] = p.filter((x): x is MediaObject => x !== null);
        }
    }

    async onNextPage(): Promise<void> {
        let currentContainsGallery = this.activePage?.items.find((item) => item instanceof AddFormItem_Gallery);

        if (currentContainsGallery) {
            await this.uploadGalleryImages().then(this.onNext)      // TODO, catch abort or upload fail to try again myb
        } else {
            this.onNext();
        }
    }

    @Emit("changePage")
    onNext(): number {
        this.currentPage += 1;

        return this.currentPage;
    }

    @Emit("submit")
    onSubmit(): void {/**/
    }

    @Emit("changePage")
    onPrevious(): number {
        this.currentPage -= 1;

        return this.currentPage;
    }

    @Prop({ default: 0 }) readonly activePageOverride!: number;
    @Prop({ default: false }) readonly editMode!: boolean;
    @Prop({ default: false }) readonly submitAsync!: boolean;
    @Prop({ default: false }) readonly disablePageSwitching!: boolean;
    @Prop() readonly form!: AddForm;
    @Prop() readonly entity!: Record<string, unknown>;

    mounted() {
        // console.log(this.form);
    }
}

