
import Vue from "vue";
import Component from "vue-class-component";
import InputField, { InputFieldType } from "@/components/Ui/InputField/InputField.vue";
import { ButtonType } from "@/components/Ui/Button/Button.vue";
import InputLabel from "@/components/Ui/InputLabel/InputLabel.vue";
import ListPagination from "@/components/ListPagination/ListPagination.vue";
import CustomGoogleMap from "@/components/CustomGoogleMap/CustomGoogleMap.vue";
import AdditionalContentListFilters
    from "@/views/SingleAdditionalContentList/AdditionalContentListFilters/AdditionalContentListFilters.vue";
import { additionalContentModule } from "@/store/additional_content/additional_content";
import { ListAdditionalContent } from "@/types/additionalContent";
import { AdditionalContentCategory } from "@/types/additionalContentCategory";
import { additionalContentCategoriesModule } from "@/store/additional_content_categories/additional_content_categories";
import AdditionalContentItemListCard
    from "@/views/SingleAdditionalContentList/AdditionalContentItemListCard/AdditionalContentItemListCard.vue";
import { NavigationGuardNext, Route } from "vue-router/types/router";
import Spinner from "@/components/Ui/Spinner/Spinner.vue";

@Component({
    components: {
        Spinner,
        AdditionalContentItemListCard,
        AdditionalContentListFilters,
        CustomGoogleMap,
        InputField,
        InputLabel,
        ListPagination,
    },
})
export default class SingleAdditionalContentList extends Vue {
    contentId: string | null = null;
    additionalContentCategory: AdditionalContentCategory | null = null;

    ButtonType = ButtonType;
    InputType = InputFieldType;

    priceRangeOpened = false;
    filtersOpened = false;

    // openSingleAdditionalContent(additionalContentId: string): void {
    //     this.$router.push({
    //         name: RouteName.SingleAdditionalContentItem,
    //         params: {
    //             additionalContentId,
    //         },
    //     });
    // }

    onChangePage(nextPage: number): void {
        if (this.contentId !== null) {
            additionalContentModule.fetchAdditionalContentPageByCategoryIdAction({
                page: nextPage,
                categoryId: this.contentId,
            });
        }
    }

    get additionalContent(): Array<ListAdditionalContent> {
        return additionalContentModule.additionalContent ?? [];
    }

    get currentPage(): number {
        return additionalContentModule.currentPage;
    }

    get numPages(): number {
        return additionalContentModule.totalPages;
    }

    mounted(): void {
        if (this.contentId !== null) {
            additionalContentModule.fetchAdditionalContentFirstPageByCategoryIdAction(this.contentId);
        }
    }


    beforeRouteUpdate(to: Route,
                      from: Route,
                      next: NavigationGuardNext,
    ): void {
        const { params: { contentId: toContentId } } = to;
        const { params: { contentId: fromContentId } } = from;

        if (toContentId !== fromContentId) {
            additionalContentModule.resetState();

            this.contentId = toContentId;
            this.additionalContentCategory = additionalContentCategoriesModule.getAdditionalContentCategoryById(this.contentId) ?? null;

            additionalContentModule.fetchAdditionalContentFirstPageByCategoryIdAction(this.contentId);
        }

        next();
    }

    get async(): boolean {
        return additionalContentModule.fetchAsync;
    }

    beforeUnmount(): void {
        additionalContentModule.resetState();
    }

    beforeMount(): void {
        const { params: { contentId } } = this.$route;

        this.contentId = contentId;
        this.additionalContentCategory = additionalContentCategoriesModule.getAdditionalContentCategoryById(this.contentId) ?? null;
    }
}

