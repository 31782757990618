
import Component from "vue-class-component";
import Vue from "vue";
import { AddForm } from "@/types/addForm/addForm";
import { AddFormPage } from "@/types/addForm/addFormPage";
import InputLabel from "@/components/Ui/InputLabel/InputLabel.vue";
import SelectField, { SelectFieldOption } from "@/components/Ui/SelectField/SelectField.vue";
import {
    AddFormItem,
    AddFormItem_Address,
    AddFormItem_Amenities, AddFormItem_Gallery,
    AddFormItem_Input,
    AddFormItem_InputType,
    AddFormItem_Select,
    AddFormItem_TextArea,
    AddFormItem_Title,
    AddFormItemTypes,
} from "@/types/addForm/addFormItem";
import InputField from "@/components/Ui/InputField/InputField.vue";
import Button, { ButtonType } from "@/components/Ui/Button/Button.vue";
import AddNewAdditionalContentPageItem
    from "@/views/AddNew/AddNewAdditionalContentCategory/AddNewAdditionalContentPageItem.vue";
import cloneDeep from "lodash/cloneDeep";

import { snakeCase } from "snake-case";
import TextareaField from "@/components/Ui/TextareaField/TextareaField.vue";
import { RouteLink } from "@/router";
import { AdditionalContentCategoryForm } from "@/types/AdditionalContentCategoryForm";
import { additionalContentCategoriesModule } from "@/store/additional_content_categories/additional_content_categories";
import Checkbox, { CheckboxType } from "@/components/Ui/Checkbox/Checkbox.vue";

@Component({
    components: {
        Checkbox,
        TextareaField,
        AddNewAdditionalContentPageItem,
        Button,
        InputField,
        SelectField,
        InputLabel,
    },
})

export default class AddNewAdditionalContentCategory extends Vue {
    additionalContentCategory: AdditionalContentCategoryForm = {
        isMultiSelect: false,
        isPerPerson: false,
    };

    form: AddForm = new AddForm([
        new AddFormPage(0, [
            new AddFormItem_Title("Postavite fotografije"),
            new AddFormItem_Gallery(),
        ]),
    ]);

    availableFields: SelectFieldOption[] = [{
        value: AddFormItemTypes.Title,
        label: "Naslov",
        topDash: false,
    }, {
        value: AddFormItemTypes.Input,
        label: "Input",
        topDash: false,
    }, {
        value: AddFormItemTypes.TextArea,
        label: "TextArea",
        topDash: false,
    }, {
        value: AddFormItemTypes.Select,
        label: "Selection",
        topDash: false,
    }, {
        value: AddFormItemTypes.Amenities,
        label: "Amenities",
        topDash: false,
    }, {
        value: AddFormItemTypes.Address,
        label: "Adresa",
        topDash: false,
    }, {
        value: AddFormItemTypes.Gallery,
        label: "Galerija",
        topDash: false,
    }];

    AddFormItemTypes = AddFormItemTypes;
    ButtonType = ButtonType;
    CheckboxType = CheckboxType;

    aboutCategorySelected = true;

    selectedPage: AddFormPage | null = null;
    selectedItem: AddFormItem | null = null;
    selectedItemIndex: number | null = null;

    selectedFieldOption: AddFormItemTypes | null = null;

    validateForm(): boolean {
        if (!this.additionalContentCategory.nameBs) {
            return false;
        }

        return true;
    }

    async onSubmitForm(): Promise<void> {
        if (!this.validateForm()) {
            return;
        }

        this.additionalContentCategory.fields = this.form.pages;

        await additionalContentCategoriesModule.addNewAdditionalContentCategory({ data: this.additionalContentCategory });

        this.$router.back();
    }

    onCancelEverything(): void {
        this.$router.replace(RouteLink.Dashboard);
    }

    onCancelButton(): void {
        this.deselectItem();
    }

    removeCategoryPage(index: number): void {
        this.form.pages.splice(index, 1);

        let n = 0;

        this.form.pages.forEach(page => {
            page.pageNumber = n;
            n++;
        });
    }

    onAddField(): void {
        if (this.selectedItem instanceof AddFormItem_Select) {
            // add values
            this.selectedItem.options.forEach((option) => {
                option.value = snakeCase(option.label);
            });
        }

        if (this.selectedItem !== null) {
            this.selectedPage?.items.push(this.selectedItem);
        }

        this.deselectItem();
    }

    onSelectedFieldOptionChange(fieldOption: AddFormItemTypes): void {
        this.selectedFieldOption = fieldOption;

        if (this.selectedFieldOption === AddFormItemTypes.Input) {
            this.selectedItem = new AddFormItem_Input("");
        } else if (this.selectedFieldOption === AddFormItemTypes.Select) {
            this.selectedItem = new AddFormItem_Select("", []);
        } else if (this.selectedFieldOption === AddFormItemTypes.Title) {
            this.selectedItem = new AddFormItem_Title("");
        } else if (this.selectedFieldOption === AddFormItemTypes.TextArea) {
            this.selectedItem = new AddFormItem_TextArea("");
        } else if (this.selectedFieldOption === AddFormItemTypes.Amenities) {
            this.selectedItem = new AddFormItem_Amenities();
        } else if (this.selectedFieldOption === AddFormItemTypes.Address) {
            this.selectedItem = new AddFormItem_Address();
        }
    }

    removePageItem(index: number): void {
        if (this.selectedPage !== null) {
            this.selectedPage.items.splice(index, 1);
        }
    }

    onSaveField(): void {
        if (this.selectedPage !== null) {
            if (this.selectedItem !== null && this.selectedItemIndex !== null) {
                this.selectedPage.items[this.selectedItemIndex] = cloneDeep(this.selectedItem);

                this.deselectItem();
            }
        }
    }

    selectPageItem(index: number): void {
        if (this.selectedPage !== null) {
            let item = this.selectedPage.items[index];

            if (item) {
                this.selectedItem = cloneDeep(item);
                this.selectedFieldOption = item.type;
                this.selectedItemIndex = index;
            }
        }
    }

    addNewSelectOption(): void {
        if (this.selectedItem instanceof AddFormItem_Select) {
            this.selectedItem.options.push({ label: "", value: "", topDash: false });
        }
    }

    removeSelectOption(index: number): void {
        if (this.selectedItem instanceof AddFormItem_Select) {
            this.selectedItem.options.splice(index, 1);
        }
    }

    mounted(): void {
        let newPage = new AddFormPage(this.form.pages.length, []);
        this.form.pages.push(newPage);
    }

    selectPage(page: AddFormPage): void {
        this.deselectItem();

        this.aboutCategorySelected = false;
        this.selectedPage = page;
    }

    selectAboutCategory(): void {
        this.aboutCategorySelected = true;
        this.selectedPage = null;
    }

    deselectItem(): void {
        this.selectedFieldOption = null;
        this.selectedItem = null;
        this.selectedItemIndex = null;
    }

    addFormPage(): void {
        this.deselectItem();

        let newPage = new AddFormPage(this.form.pages.length, []);
        this.form.pages.push(newPage);

        this.aboutCategorySelected = false;
        this.selectedPage = newPage;
    }

    get selectedPageItems(): Array<AddFormItem> {
        return this.selectedPage?.items || [];
    }

    get isInputText(): boolean {
        return this.selectedItem instanceof AddFormItem_Input && this.selectedItem.inputType === AddFormItem_InputType.Text;
    }

    get isInputNumberPlusMinus(): boolean {
        return this.selectedItem instanceof AddFormItem_Input && this.selectedItem.inputType === AddFormItem_InputType.NumberPlusMinus;
    }

    get isTitle(): boolean {
        return this.selectedItem instanceof AddFormItem_Title;
    }

    get isTextArea(): boolean {
        return this.selectedItem instanceof AddFormItem_TextArea;
    }

    get isAddress(): boolean {
        return this.selectedItem instanceof AddFormItem_Address;
    }

    get isSelect(): boolean {
        return this.selectedItem instanceof AddFormItem_Select;
    }

    get isGallery(): boolean {
        return this.selectedItem instanceof AddFormItem_Gallery;
    }

    get isAmenities(): boolean {
        return this.selectedItem instanceof AddFormItem_Amenities;
    }

    get addAsync(): boolean {
        return additionalContentCategoriesModule.addEditAsync;
    }
}
