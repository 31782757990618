
import Vue from "vue";
import Component from "vue-class-component";
import InputLabel from "@/components/Ui/InputLabel/InputLabel.vue";
import SelectField, { SelectFieldOption } from "@/components/Ui/SelectField/SelectField.vue";
import InputField from "@/components/Ui/InputField/InputField.vue";
import { AddForm } from "@/types/addForm/addForm";
import { AddFormPage } from "@/types/addForm/addFormPage";
import {
    AddFormItem, AddFormItem_Address, AddFormItem_Amenities, AddFormItem_Gallery,
    AddFormItem_Input, AddFormItem_InputType,
    AddFormItem_Select,
    AddFormItem_TextArea,
    AddFormItem_Title, AddFormItemTypes,
} from "@/types/addForm/addFormItem";
import { snakeCase } from "snake-case";
import cloneDeep from "lodash/cloneDeep";
import Button, { ButtonType } from "@/components/Ui/Button/Button.vue";
import FormEditorPageItem from "@/views/AddNew/AddNewAdditionalContentCategory/components/FormEditorPageItem.vue";
import { Prop, Watch } from "vue-property-decorator";
import { User } from "@/types/user";

@Component({
    components: { Button, FormEditorPageItem, InputField, SelectField, InputLabel },
})
export default class FormEditor extends Vue {
    @Prop() readonly form!: AddForm;
    @Prop({ default: 0 }) readonly selectedPageNumber!: number;

    availableFields: SelectFieldOption[] = [{
        value: AddFormItemTypes.Title,
        label: "Naslov",
        topDash: false,
    }, {
        value: AddFormItemTypes.Input,
        label: "Input",
        topDash: false,
    }, {
        value: AddFormItemTypes.TextArea,
        label: "TextArea",
        topDash: false,
    }, {
        value: AddFormItemTypes.Select,
        label: "Selection",
        topDash: false,
    }, {
        value: AddFormItemTypes.Amenities,
        label: "Amenities",
        topDash: false,
    }, {
        value: AddFormItemTypes.Address,
        label: "Adresa",
        topDash: false,
    }, {
        value: AddFormItemTypes.Gallery,
        label: "Galerija",
        topDash: false,
    }];

    AddFormItemTypes = AddFormItemTypes;
    ButtonType = ButtonType;

    selectedItem: AddFormItem | null = null;
    selectedItemIndex: number | null = null;

    selectedFieldOption: AddFormItemTypes | null = null;

    get selectedPage(): AddFormPage | null {
        return this.form.pages[this.selectedPageNumber] ?? null;
    }

    @Watch("selectedPageNumber")
    onChangePage(): void {
        this.deselectItem();
    }

    mounted(): void {
        console.log(this.form);
        console.log(this.selectedPage);
    }

    onCancelButton(): void {
        this.deselectItem();
    }

    onAddField(): void {
        if (this.selectedItem instanceof AddFormItem_Select) {
            // add values
            this.selectedItem.options.forEach((option) => {
                option.value = snakeCase(option.label);
            });
        }

        if (this.selectedItem !== null) {
            this.selectedPage?.items.push(this.selectedItem);
        }

        this.deselectItem();
    }

    onSelectedFieldOptionChange(fieldOption: AddFormItemTypes): void {
        this.selectedFieldOption = fieldOption;

        if (this.selectedFieldOption === AddFormItemTypes.Input) {
            this.selectedItem = new AddFormItem_Input("");
        } else if (this.selectedFieldOption === AddFormItemTypes.Select) {
            this.selectedItem = new AddFormItem_Select("", []);
        } else if (this.selectedFieldOption === AddFormItemTypes.Title) {
            this.selectedItem = new AddFormItem_Title("");
        } else if (this.selectedFieldOption === AddFormItemTypes.TextArea) {
            this.selectedItem = new AddFormItem_TextArea("");
        } else if (this.selectedFieldOption === AddFormItemTypes.Amenities) {
            this.selectedItem = new AddFormItem_Amenities();
        } else if (this.selectedFieldOption === AddFormItemTypes.Address) {
            this.selectedItem = new AddFormItem_Address();
        } else if (this.selectedFieldOption === AddFormItemTypes.Gallery) {
            this.selectedItem = new AddFormItem_Gallery();
        }
    }

    removePageItem(index: number): void {
        if (this.selectedPage !== null) {
            this.selectedPage.items.splice(index, 1);
        }
    }

    onSaveField(): void {
        if (this.selectedPage !== null) {
            if (this.selectedItem !== null && this.selectedItemIndex !== null) {
                this.selectedPage.items[this.selectedItemIndex] = cloneDeep(this.selectedItem);

                this.deselectItem();
            }
        }
    }

    selectPageItem(index: number): void {
        if (this.selectedPage !== null) {
            let item = this.selectedPage.items[index];

            if (item) {
                this.selectedItem = cloneDeep(item);
                this.selectedFieldOption = item.type;
                this.selectedItemIndex = index;
            }
        }
    }

    addNewSelectOption(): void {
        if (this.selectedItem instanceof AddFormItem_Select) {
            this.selectedItem.options.push({ label: "", value: "", topDash: false });
        }
    }

    removeSelectOption(index: number): void {
        if (this.selectedItem instanceof AddFormItem_Select) {
            this.selectedItem.options.splice(index, 1);
        }
    }

    deselectItem(): void {
        this.selectedFieldOption = null;
        this.selectedItem = null;
        this.selectedItemIndex = null;
    }

    get selectedPageItems(): Array<AddFormItem> {
        return this.selectedPage?.items || [];
    }

    get isInputText(): boolean {
        return this.selectedItem instanceof AddFormItem_Input && this.selectedItem.inputType === AddFormItem_InputType.Text;
    }

    get isInputNumberPlusMinus(): boolean {
        return this.selectedItem instanceof AddFormItem_Input && this.selectedItem.inputType === AddFormItem_InputType.NumberPlusMinus;
    }

    get isTitle(): boolean {
        return this.selectedItem instanceof AddFormItem_Title;
    }

    get isTextArea(): boolean {
        return this.selectedItem instanceof AddFormItem_TextArea;
    }

    get isAddress(): boolean {
        return this.selectedItem instanceof AddFormItem_Address;
    }

    get isSelect(): boolean {
        return this.selectedItem instanceof AddFormItem_Select;
    }

    get isAmenities(): boolean {
        return this.selectedItem instanceof AddFormItem_Amenities;
    }
}
