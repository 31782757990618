
import Component from "vue-class-component";
import CustomModal from "@/components/Ui/CustomModal/CustomModal.vue";
import {Prop} from "vue-property-decorator";
import SingleReservation from "@/views/SingleReservation/SingleReservation.vue";
import Vue from "vue";
import {reservationsModule} from "@/store/reservations/reservations";

@Component({
    components: { SingleReservation, CustomModal },
})
export default class SingleReservationModal extends Vue {
    @Prop() readonly open!: boolean;

    closeModal(): void {
        reservationsModule.selectReservation(null);
        this.$emit("close");
    }
}
