<template>
    <div class="passenger-category">
        <div class="left">
            <span class="title">{{ title }}</span> <span class="age-detail">{{ ageDetail }}</span>
        </div>
        <div class="increment-decrement-buttons">
            <div class="button" @click="decrement">
                <span>-</span>
            </div>
            <div class="category-number">
                {{ value }}
            </div>
            <div class="button" @click="$emit('input', value + 1)">
                <span>+</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PassengerNumberCategory',
    props: {
        title: String,
        ageDetail: String,
        value: Number,
    },
    methods: {
        decrement() {
            if (this.value === 0) {
                return;
            }

            this.$emit('input', this.value - 1);
        },
    },
};
</script>

<style scoped lang="scss">
.passenger-category {
    background: #FFFFFF;
    display: flex;
    padding: 1.625rem 1.25rem;
    border-radius: 8px;
    border: 1px solid $lighterGray;
    margin-bottom: 0.5rem;
    min-width: 25rem;
    justify-content: space-between;

    .title {
        font-size: 0.875rem;
        color: $textBlack;
        font-weight: bold;
    }

    .age-detail {
        margin-left: 1rem;
        font-size: 0.875rem;
        color: $lightGrey;
        font-weight: bold;
    }

    .increment-decrement-buttons {
        display: flex;
        align-items: center;
        justify-content: center;

        .category-number {
            margin-left: 0.5rem;
            margin-right: 0.5rem;
            font-weight: bold;
            color: $textBlack;
        }

        .button {
            user-select: none;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;

            color: $textBlack;
            border-radius: 64px;
            border: 2px solid $textBlack;
            width: 1.125rem;
            height: 1.125rem;
            font-weight: bold;
        }
    }
}
</style>
