
import Vue from "vue";
import { MapCenterPosition } from "@/types/mapCenterPosition";
import { Prop, Watch } from "vue-property-decorator";
import Component from "vue-class-component";
import { MapsWrapper, Maps } from "@/api/google/MapsWrapper";
import { Address } from "@/types/address";
import { PlacesWrapper } from "@/api/google/PlacesWrapper";

export interface CustomMapMarkerData {
    address: Address,
    label?: string,
}

@Component({})
export default class CustomGoogleMap extends Vue {
    @Prop({
        default: () => ({
            lat: 25.076532,
            lng: 55.239487,
        }),
    }) readonly centerPosition!: MapCenterPosition;

    @Prop({ default: false }) readonly full!: boolean;

    @Prop({ default: () => [] }) readonly markerData!: Array<CustomMapMarkerData>;

    map?: Maps.Map;
    markers: Array<Maps.Marker> = [];

    mounted(): void {
        this.map = MapsWrapper.createMap(document.getElementById("map") as HTMLElement, {
            center: this.centerPosition,
            zoom: 10,
        });

        this.clearMarkers();
        MapsWrapper.setMarkers(this.map, this.markerData, this.markers);

        this.map.addListener("click", (e: Maps.IconMouseEvent) => {
            if (e.placeId) {
                PlacesWrapper.placeIdToAddress(e.placeId).then(r => {
                    if (r.results[0]) {
                        console.log(r.results[0]);
                        const address = PlacesWrapper.parseGeocoderToAddress(r.results[0]);
                        this.$emit("onClickLocation", address);
                    }
                });
            }
        });
    }

    clearMarkers(): void {
        this.markers.forEach(m => m.setMap(null));
        this.markers = [];
    }

    @Watch("markerData")
    onAddressesUpdate(): void {
        if (this.map) {
            this.clearMarkers();
            MapsWrapper.setMarkers(this.map, this.markerData, this.markers);
        }
    }
}
