import {
    PaginationResponse,
    RawHydraResponse,
    RawHydraResponseIntoPaginationResponse,
} from "@/types/paginationResponse";
import { jsonApiCall, manualReservations } from "@/api/apiCall";
import {
    Reservation,
    ReservationType,
} from "@/types/reservation";
import { Passenger } from "@/types/Passenger";
import { RoomServiceType } from "@/types/room";
import { ReservationFormAdditionalContentItem } from "@/types/additionalContent";
import { SortOrder } from "@/utils/utils";
import { ManualReservation, ManualReservationForm } from "@/types/manualReservation";

export interface ReservationPostVariantData {
    id: string,
    q: number,
    serviceType: RoomServiceType,
    passengers: number
}

export interface ManualReservationPostData {
    type?: ReservationType;
    from: string;
    to: string;
    variantsData: Array<ReservationPostVariantData>;
    passengers: Array<Passenger>;
    reservationAdditionalContents: Array<ReservationFormAdditionalContentItem>;
    specialRequest?: string;
}

export class ManualReservationsApi {
    static async apiFetchAllReservations(): Promise<Array<ManualReservation> | null> {
        return await jsonApiCall<Array<ManualReservation>>({
            url: manualReservations,
            method: "GET",
        });
    }

    static async fetchReservationsPage(page: number, perPage: number, sortField: string | null, sortOrder: SortOrder): Promise<PaginationResponse<ManualReservation> | null> {

        const url = new URL(manualReservations);

        url.searchParams.append("page", page.toString());
        url.searchParams.append("per_page", perPage.toString());

        if (sortField) {
            url.searchParams.append(`order[${sortField}]`, "");
        }

        const r = await jsonApiCall<RawHydraResponse<ManualReservation>>({
            url: url.toString(),
            method: "GET",
            headers: {
                "accept": "application/ld+json",
            },
        });

        if (r !== null) {
            return RawHydraResponseIntoPaginationResponse<ManualReservation>(r);
        } else {
            return null;
        }
    }

    static async fetchReservationById(reservationId: string): Promise<ManualReservation | null> {
        const r = await jsonApiCall<ManualReservation>({
            url: `${manualReservations}/${reservationId}`,
        });

        if (r !== null) {
            return r;
        }

        return null;
    }


    static async editListReservation(reservationId: string, data: Partial<ManualReservation>): Promise<ManualReservation | null> {
        const r = await jsonApiCall<ManualReservation>({
            url: `${manualReservations}/${reservationId}`,
            method: "PUT",
            jsonData: data as Record<string, any>,
        });

        if (r !== null) {
            return r;
        }

        return null;

    }

    static async apiValidateReservation(reservationId: string): Promise<ManualReservation | null> {
        const r = await jsonApiCall<ManualReservation>({
            url: `${manualReservations}/${reservationId}/validate`,
            method: "PUT",
            jsonData: {},
        });

        if (r !== null) {
            return r;
        }

        return null;
    }

    static async apiConfirmReservation(reservationId: string, data: { confirmationNumber?: string }): Promise<ManualReservation | null> {
        const r = await jsonApiCall<ManualReservation>({
            url: `${manualReservations}/${reservationId}/confirm`,
            method: "PUT",
            jsonData: data as Record<string, any>,
        });

        if (r !== null) {
            return r;
        }

        return null;
    }

    static async addReservation(data: ManualReservationForm): Promise<ManualReservation | null> {
        const r = await jsonApiCall<ManualReservation>({
            url: manualReservations,
            method: "POST",
            jsonData: data as Record<string, any>,
        });

        if (r !== null) {
            return r;
        }

        return null;
    }

    static async deleteReservation(reservationId: string): Promise<null> {
        const r = await jsonApiCall<null>({
            url: `${manualReservations}/${reservationId}`,
            method: "DELETE",
        });

        console.log(r);

        return r;
    }

    // static async apiGenerateReservationInvoice(reservationId: string): Promise<null> {
    //     return await jsonApiCall<null>({
    //         url: `${manualReservations}/${reservationId}/generate_confirmation_pdf`,
    //         method: "PUT",
    //         jsonData: {},
    //     });
    // }

    static async apiReadReservationInvoice(reservationId: string): Promise<string | null> {
        return await jsonApiCall<string>({
            url: `${manualReservations}/${reservationId}/pdf`,
        });
    }

    static async apiGenerateManualReservationInvoice(reservationId: string): Promise<ManualReservation | null> {
        return await jsonApiCall<null>({
            url: `${manualReservations}/${reservationId}/generate_invoice`,
        });
    }
}
