
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import { AddFormItem_TextArea } from "@/types/addForm/addFormItem";
import Component from "vue-class-component";
import TextareaField from "@/components/Ui/TextareaField/TextareaField.vue";
import { snakeCase } from "snake-case";
import InputLabel from "@/components/Ui/InputLabel/InputLabel.vue";

@Component({
    components: {InputLabel, TextareaField},
})
export default class AddContentTextArea extends Vue {
    @Prop() readonly item!: AddFormItem_TextArea;
    @Prop() readonly entity!: Record<string, unknown>;

    get accessor(): string {
        return snakeCase(this.item.name);
    }
}
