import { Language } from '@/types/language';

export const GOOGLE_PLACES_API_KEY = 'AIzaSyDMFu6yoy6ojKEHC1MjNNHXAQmKeGUXlc8';
export const GOOGLE_ANALYTICS_TAG = process.env.VUE_APP_GTAG;

export enum StorageKeys {
    TOKEN = 'beystravel_frontend_token',
    REFRESH_TOKEN = 'beystravel_frontend_refresh_token',
    LANGUAGE = 'bs'
}

export const IS_DEV: boolean = process.env.NODE_ENV === 'development';

export const BUILD_VERSION = process.env.VUE_APP_BUILD_VERSION;

export const RELEASE_TIMESTAMP = process.env.VUE_APP_RELEASE_TIMESTAMP;

export const DISABLE_SOCKET_CONNECTION = Boolean(process.env.VUE_APP_DISABLE_SOCKET_CONNECTION);

export enum UserRoles {
    ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN',
    ROLE_AGENCY_ADMIN = 'ROLE_AGENCY_ADMIN',
    ROLE_USER = 'ROLE_USER',
}

export enum UserRolesAccessLevels {
    ROLE_UNKNOWN = -1,
    ROLE_USER = 0,
    ROLE_AGENCY_ADMIN = 1,
    ROLE_SUPER_ADMIN = 2,
}

export const UserRoleText: Record<keyof typeof UserRoles, string> = {
    [UserRoles.ROLE_SUPER_ADMIN]: 'Super Admin',
    [UserRoles.ROLE_AGENCY_ADMIN]: 'Agent',
    [UserRoles.ROLE_USER]: 'Korisnik',
};

export const VISA_PRICE_KEY = 'VISA_KEY';

export const TRANSFER_CATEGORY_NAME = 'Transferi';

export const LANGUAGES = [Language.bs, Language.en];
