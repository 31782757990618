
import Vue from "vue";
import Component from "vue-class-component";
import { User } from "@/types/user";
import { loginModule } from "@/store/login/login";
import {
    getThumbnailStorageUrl,
    showSwalToast,
} from "@/utils/utils";
import { Hotel } from "@/types/hotel";
import { Passenger } from "@/types/Passenger";
import Button from "@/components/Ui/Button/Button.vue";
import { reservationFormModule, ReservationFormSelectedRoomVariant } from "@/store/reservation_form/reservation_form";
import Breadcrumbs, { Breadcrumb } from "@/components/Ui/Breadcrumbs/Breadcrumbs.vue";
import { Agency } from "@/types/agency";
import { ReservationForm } from "@/types/reservation";
import { homeFiltersModule } from "@/store/home_filters/home_filters";
import { Room } from "@/types/room";
import { RouteName } from "@/router";
import { AdditionalContent, AdditionalContentUtils, ReservationAdditionalContent } from "@/types/additionalContent";
import { settingsModule } from "@/store/settings/settings";
import ReceiptRoom from "@/views/SingleReservation/components/ReceiptRoom.vue";
import PlaceholderableImage from "@/components/Ui/PlaceholderableImage/PlaceholderableImage.vue";
import Decimal from "decimal.js";
import Spinner from "@/components/Ui/Spinner/Spinner.vue";
import { Language } from "@/types/language";
import { AdditionalContentCategory } from "@/types/additionalContentCategory";

@Component({
    components: { Spinner, PlaceholderableImage, ReceiptRoom, Breadcrumbs, Button },
})
export default class ConfirmReservation extends Vue {

    getItemDoublePriceLabel(item: ReservationAdditionalContent): string {
        return `${item.additionalContent.category.doublePriceLabel ?? ""} (2x)`;
    }

    get async(): boolean {
        return reservationFormModule.addEditAsync;
    }

    get visaPrice(): number {
        return settingsModule.visaPrice;
    }

    get reservation(): ReservationForm | null {
        return reservationFormModule.reservation;
    }

    get additionalContents(): Array<ReservationAdditionalContent> {
        return this.reservation?.reservationAdditionalContents || [];
    }

    get accomodationPrice(): number {
        return reservationFormModule.totalAccommodationPrice;
    }

    get rooms(): Array<{ room: Room, variants: Record<string, ReservationFormSelectedRoomVariant> }> {
        return reservationFormModule.rooms;
    }

    get allAdditionalContentItemsPrice(): number {
        let totalPrice = 0;

        this.reservation?.reservationAdditionalContents?.forEach(v => {
            totalPrice += new Decimal(v.additionalContent.priceInCents).mul(v.passengerCount).mul(v.isPriceDoubled ? 2 : 1).toNumber();
        });

        return totalPrice;
    }

    get passengersWithoutVisa(): number {
        return [...this.reservation?.passengers ?? []].reduce<number>((acc, passenger) => {
            if (!passenger.hasVisa) {
                acc += 1;
            }

            return acc;
        }, 0);
    }

    get totalVisaPrice(): number {
        return this.passengersWithoutVisa * this.visaPrice;
    }

    get totalPrice(): number {
        return this.allAdditionalContentItemsPrice + this.accomodationPrice + this.totalVisaPrice;
    }

    get hotel(): Hotel | null {
        return reservationFormModule.selectedHotel;
    }

    get hotelImage(): string {
        return getThumbnailStorageUrl(this.hotel?.mediaObjects?.[0]);
    }

    get passengers(): Array<Passenger> {
        return reservationFormModule.reservation?.passengers ?? [];
    }

    get agency(): Agency | null {
        return this.user?.agency ?? null;
    }

    get user(): User | null {
        return loginModule.user;
    }

    get profilePicture(): string {
        return getThumbnailStorageUrl(this.user?.profilePicture);
    }

    get breadcrumbs(): Array<Breadcrumb> {
        return reservationFormModule.breadcrumbs;
    }

    get agencyPhone(): string {
        return this.agency?.phone ?? "";
    }

    get agencyPostal(): string {
        return (this.agency?.postalCode ? `${this.agency.postalCode} ` : "") + (this.agency?.city ?? "");
    }

    get agencyEmail(): string {
        return this.agency?.email ?? "";
    }

    get checkInDate(): string {
        return reservationFormModule.reservationCheckInDate;
    }

    get checkOutDate(): string {
        return reservationFormModule.reservationCheckOutDate;
    }

    get nAdults(): number {
        return homeFiltersModule.passengersData.adults;
    }

    get nChildren(): number {
        return homeFiltersModule.passengersData.children;
    }

    get nChildrenUnder6(): number {
        return homeFiltersModule.passengersData.childrenUnder6;
    }

    getAdditionalContentName(ac: AdditionalContent): string {
        return AdditionalContentUtils.resolveName(ac, this.$i18n.locale as Language) ?? "";
    }

    getAdditionalContentCategoryName(cat: AdditionalContentCategory): string {
        return AdditionalContentUtils.resolveName(cat, this.$i18n.locale as Language) ?? "";
    }

    get currentYear() {
        return new Date().getFullYear();
    }

    async submitReservation(): Promise<void> {
        reservationFormModule.addReservationAction().then(r => {
            if (r !== null) {
                showSwalToast(this.$tc("success"), "success");
                reservationFormModule.cancelReservation();
                this.$router.push({
                    name: RouteName.ReservationComplete,
                    params: {
                        reservationId: r.id,
                    },
                });
            }
        });
    }
}
