import {cloneDeep} from "lodash";
import {RoomVariant, RoomVariantClassification} from "@/types/roomVariant";
import {RoomServiceType} from "@/types/room";
import {settingsModule} from "@/store/settings/settings";
import Decimal from "decimal.js";
import {pricingDebugLog} from "@/utils/price_utils";


export interface ServicesPricingField {
    price: number | null,
    isActive: boolean,
    label: string,
}

export enum ServicePricingType {
    thirdPersonSupplement = "thirdPersonSupplement",
    thirdPersonBreakfastSupplement = "thirdPersonBreakfastSupplement",
    halfBoardSupplement = "halfBoardSupplement",
    fullBoardSupplement = "fullBoardSupplement",
    allInclusiveSupplement = "allInclusiveSupplement"
}

export interface PriceSectionGroups {
    from: number,
    to: number;
    priceInCents: number,
}

export interface PriceSectionStandard {
    adults: number | null,
    children6: number | null,
    children: number | null,
    thirdPersonSupplement: number | null,
    thirdPersonBreakfastSupplement: number | null,
    halfBoardSupplement: number | null,
    fullBoardSupplement: number | null,
    allInclusiveSupplement: number | null,
    ultraAllInclusive: number | null,
    discount: number | null,
}

export interface Pricing {
    standard: PriceSectionStandard
    groups: Array<PriceSectionGroups>,
    servicesPricings: Record<string, ServicesPricingField>
    ultraAllInclusiveActive?: boolean,
    childrenPricingActive?: boolean,
    children6PricingActive?: boolean,
    groupPricingActive?: boolean,
    servicesPricingActive?: boolean,
    discountsActive?: boolean,
    baseDiscountActive?: boolean,
}

export interface IRoomPricing {
    single: Pricing;
    double: Pricing;
    triple: Pricing;
    quadruple: Pricing;
    quintuple: Pricing;
}

export class RoomPricing implements IRoomPricing {
    single: Pricing;
    double: Pricing;
    triple: Pricing;
    quadruple: Pricing;
    quintuple: Pricing;

    static defaultServicesPricings: Record<string, ServicesPricingField> = {
        // [ServicePricingType.thirdPersonSupplement]: {
        //     isActive: false,
        //     label: "Treća osoba",
        //     price: null,
        // },
        [ServicePricingType.thirdPersonBreakfastSupplement]: {
            isActive: false,
            label: "Doručak za treću osobu",
            price: null,
        },
        [ServicePricingType.halfBoardSupplement]: {
            isActive: false,
            label: "Polupansion",
            price: null,
        },
        [ServicePricingType.fullBoardSupplement]: {
            isActive: false,
            label: "Puni pansion",
            price: null,
        },
        [ServicePricingType.allInclusiveSupplement]: {
            isActive: false,
            label: "All Inclusive",
            price: null,
        },
    };

    constructor(props: Partial<IRoomPricing>) {
        const emptyPricing: Pricing = {
            standard: {
                adults: 0,
                thirdPersonSupplement: 0,
                thirdPersonBreakfastSupplement: 0,
                allInclusiveSupplement: 0,
                fullBoardSupplement: 0,
                halfBoardSupplement: 0,
                children: 0,
                children6: 0,
                ultraAllInclusive: 0,
                discount: 0,
            },
            groups: [],
            servicesPricings: cloneDeep(RoomPricing.defaultServicesPricings),
            childrenPricingActive: false,
            children6PricingActive: false,
            groupPricingActive: false,
            servicesPricingActive: false,
            discountsActive: false,
            ultraAllInclusiveActive: false,
            baseDiscountActive: false,
        };

        this.single = props.single ?? cloneDeep(emptyPricing);
        this.double = props.double ?? cloneDeep(emptyPricing);
        this.triple = props.triple ?? cloneDeep(emptyPricing);
        this.quadruple = props.quadruple ?? cloneDeep(emptyPricing);
        this.quintuple = props.quintuple ?? cloneDeep(emptyPricing);
    }

    public prepareForForm(): void {

        Object.values(this).forEach((pricing: Pricing) => {
            const { standard, groups } = pricing;

            const {
                adults,
                children,
                children6,
                thirdPersonSupplement,
                thirdPersonBreakfastSupplement,
                halfBoardSupplement,
                fullBoardSupplement,
                allInclusiveSupplement,
                ultraAllInclusive,
                discount,
            } = standard || {};

            pricing.standard.adults = adults || null;
            pricing.standard.children = children || null;
            pricing.standard.children6 = children6 || null;
            pricing.standard.ultraAllInclusive = ultraAllInclusive || null;
            pricing.standard.discount = discount || null;
            pricing.childrenPricingActive = Boolean(children);
            pricing.children6PricingActive = Boolean(children6);
            pricing.ultraAllInclusiveActive = Boolean(ultraAllInclusive);

            pricing.groupPricingActive = groups.length > 0;

            // discounts
            pricing.discountsActive = Boolean(discount);
            pricing.baseDiscountActive = Boolean(discount);

            // services
            pricing.servicesPricingActive = [thirdPersonBreakfastSupplement, halfBoardSupplement, fullBoardSupplement, allInclusiveSupplement].some((p) => Boolean(p));
            console.log(pricing.servicesPricingActive);
            pricing.servicesPricings = {
                // [ServicePricingType.thirdPersonSupplement]: {
                //     price: thirdPersonSupplement,
                //     isActive: Boolean(thirdPersonSupplement),
                //     label: RoomPricing.defaultServicesPricings[ServicePricingType.thirdPersonSupplement].label,
                // },
                [ServicePricingType.thirdPersonBreakfastSupplement]: {
                    price: thirdPersonBreakfastSupplement,
                    isActive: Boolean(thirdPersonBreakfastSupplement),
                    label: RoomPricing.defaultServicesPricings[ServicePricingType.thirdPersonBreakfastSupplement].label,
                },
                [ServicePricingType.halfBoardSupplement]: {
                    price: halfBoardSupplement,
                    isActive: Boolean(halfBoardSupplement),
                    label: RoomPricing.defaultServicesPricings[ServicePricingType.halfBoardSupplement].label,
                },
                [ServicePricingType.fullBoardSupplement]: {
                    price: fullBoardSupplement,
                    isActive: Boolean(fullBoardSupplement),
                    label: RoomPricing.defaultServicesPricings[ServicePricingType.fullBoardSupplement].label,
                },
                [ServicePricingType.allInclusiveSupplement]: {
                    price: allInclusiveSupplement,
                    isActive: Boolean(allInclusiveSupplement),
                    label: RoomPricing.defaultServicesPricings[ServicePricingType.allInclusiveSupplement].label,
                },
            };
        });
    }

    public getPricingForSubmit(): RoomPricing {

        const roomPricing: RoomPricing = cloneDeep(this);

        Object.values(roomPricing).forEach((pricing: Pricing) => {
            const { standard } = pricing;

            const {
                adults,
                children,
                children6,
                ultraAllInclusive,
                discount,
            } = standard || {};

            pricing.standard.adults = +(adults ?? 0);
            pricing.standard.children = pricing.childrenPricingActive ? +(children ?? 0) : 0;
            pricing.standard.children6 = pricing.children6PricingActive ? +(children6 ?? 0) : 0;
            pricing.standard.ultraAllInclusive = pricing.ultraAllInclusiveActive ? +(ultraAllInclusive ?? 0) : 0;
            pricing.standard.discount = pricing.discountsActive && pricing.baseDiscountActive ? +(discount ?? 0) : 0;
            // pricing.standard.thirdPersonSupplement = this.getServicePrice(pricing, ServicePricingType.thirdPersonSupplement);
            pricing.standard.thirdPersonBreakfastSupplement = this.getServicePrice(pricing, ServicePricingType.thirdPersonBreakfastSupplement);
            pricing.standard.halfBoardSupplement = this.getServicePrice(pricing, ServicePricingType.halfBoardSupplement);
            pricing.standard.fullBoardSupplement = this.getServicePrice(pricing, ServicePricingType.fullBoardSupplement);
            pricing.standard.allInclusiveSupplement = this.getServicePrice(pricing, ServicePricingType.allInclusiveSupplement);
            pricing.groups = pricing.groupPricingActive ? pricing.groups : [];      // Override to empty if not checked
        });

        return roomPricing;
    }

    public getServicePrice(pricing: Pricing, servicePricingField: ServicePricingType): number | null {
        return (pricing.servicesPricingActive && pricing.servicesPricings[servicePricingField].isActive) ? +(pricing.servicesPricings[servicePricingField].price ?? 0) : 0;
    }

    getPricingForClassification(classification: RoomVariantClassification): Pricing {
        switch (classification) {
            case RoomVariantClassification.SINGLE:
                return this.single;
            case RoomVariantClassification.DOUBLE:
                return this.double;
            case RoomVariantClassification.TRIPLE:
                return this.triple;
            case RoomVariantClassification.QUADRUPLE:
                return this.quadruple;
            case RoomVariantClassification.QUINTUPLE:
                return this.quintuple;
        }
    }

    getBasePriceForClassification(classification: RoomVariantClassification): Decimal {
        pricingDebugLog(() => {
            console.group("GetBasePriceForClassification", classification);
        });

        const standardPricing = this.getPricingForClassification(classification).standard;

        pricingDebugLog(() => {
            console.log("standardPricing for classification is", standardPricing);
        });

        let basePriceD = new Decimal(standardPricing.adults || standardPricing.ultraAllInclusive || 0);

        pricingDebugLog(() => {
            console.log("basePrice = (standardPricing.adults || standardPricing.ultraAllInclusive || 0)");
            console.log("basePrice is", basePriceD.toNumber());
            console.log("Checking Discount (standardPricing.discount && standardPricing.discount !== 0)");
        });

        if (standardPricing.discount && standardPricing.discount !== 0) {
            // gives us something like 0.85 for 15 percent, when we multiply with that, we get
            // base price - 15%

            const discountD = new Decimal(100).sub(standardPricing.discount).div(100);
            basePriceD = basePriceD.mul(discountD);
            pricingDebugLog(() => {
                console.log("We have a discount", standardPricing.discount);
                console.log("Adding discount to base price = discountD = (100 - discount) / 100", discountD.toNumber());
                console.log("Adding discount to base price = basePrice * discountD");
                console.log("Discounted base price is", basePriceD.toNumber());
            });
        }

        pricingDebugLog(() => {
            console.log("== Base price for", classification, "is", basePriceD.toNumber(), "==");
            console.groupEnd();
        });

        return basePriceD;
    }


    getPriceForClassificationAndServiceType(numPassengersForThisVariant: number, variant: RoomVariant, serviceType: RoomServiceType, agencyMarkup: number): number {
        pricingDebugLog(() => {
            console.group("getPriceForClassificationAndServiceType", variant.id);
            console.log("numPassengersForThisVariant", numPassengersForThisVariant);
            console.log("variant", variant);
            console.log("serviceType", serviceType);
        });

        const { classification, hotelMargin } = variant;

        const standardPricing = this.getPricingForClassification(classification).standard;

        let basePriceD = this.getBasePriceForClassification(classification);

        const isBasePrice0 = basePriceD.equals(0);

        pricingDebugLog(() => {
            console.log("== Base price is", basePriceD.toNumber(), "==");
            if (isBasePrice0) {
                console.log("%c Base price is 0, special case applies where the HB/FB/ALL, etc supplements are considered to be the base price. Calculation changes so instead of adding that supplement price per passenger we only add it once", "color:indianred;font-weight: bold;");
            }
        });

        const alternativeCapacities = [variant.alternativeCapacity.capacity1, variant.alternativeCapacity.capacity2, variant.alternativeCapacity.capacity3];

        pricingDebugLog(() => {
            console.group("Add third person breakfast supplement");
            console.log("numPassengersForThisVariant", numPassengersForThisVariant);
            console.log("variant.capacity.adults", variant.capacity.adults);

            alternativeCapacities.forEach(capacity => {
                console.log("variant.alternativeCapacity.adults", capacity.adults);
            });

            console.log("standardPricing.thirdPersonBreakfastSupplement", standardPricing.thirdPersonBreakfastSupplement);
            console.log("Check if (numPassengersForThisVariant !== variant.capacity.adults)", numPassengersForThisVariant !== variant.capacity.adults);
        });

        if (numPassengersForThisVariant !== variant.capacity.adults) {
            const alternativeCapacity = alternativeCapacities.find(capacity => capacity.adults === numPassengersForThisVariant);

            pricingDebugLog(() => {
                console.log("Using alternative capacity", alternativeCapacity);
                console.log("alternativeCapacity?.adults !== undefined && variant.capacity.adults !== undefined", alternativeCapacity?.adults, variant.capacity.adults);
            });

            if (alternativeCapacity?.adults !== undefined && variant.capacity.adults !== undefined) {
                const capacityDiff = alternativeCapacity.adults - variant.capacity.adults;

                pricingDebugLog(() => {
                    console.log("Diff between standard and alternative capacity (alternativeCapacity.adults - variant.capacity.adults) is", capacityDiff);
                    console.log("Check if (capacityDiff > 0 && standardPricing.thirdPersonBreakfastSupplement !== null)", capacityDiff, standardPricing.thirdPersonBreakfastSupplement);
                });

                if (capacityDiff > 0 && standardPricing.thirdPersonBreakfastSupplement !== null) {
                    basePriceD = basePriceD.add(standardPricing.thirdPersonBreakfastSupplement ?? 0).mul(capacityDiff);

                    pricingDebugLog(() => {
                        console.log("Calculated third person supplements to be basePrice + (standardPricing.thirdPersonBreakfastSupplement * capacityDiff)");
                        console.log("Breakfast supplement added to price is", basePriceD.toNumber());
                    });
                }
            }
        }

        pricingDebugLog(() => {
            console.groupEnd();
            console.log("== Base price is", basePriceD.toNumber(), "==");
        });

        pricingDebugLog(() => {
            console.group("Add Supplements");
            console.log("Loop from 0 to numPassengersForThisVariant");
            console.log("ServiceType is", serviceType);
            console.log("We check for BED_AND_BREAKFAST, HALF_BOARD, FULL_BOARD, ALL_INCLUSIVE");
        });

        // see special case for base price 0 above
        for (let i = 0; i < (isBasePrice0 ? 1 : numPassengersForThisVariant); i++) {
            switch (serviceType) {
                case RoomServiceType.BED_AND_BREAKFAST:
                    basePriceD = basePriceD.add(0);
                    pricingDebugLog(() => {
                        console.log("BED_AND_BREAKFAST, not adding anything");
                    });
                    break;
                case RoomServiceType.HALF_BOARD:
                    basePriceD = basePriceD.add(standardPricing.halfBoardSupplement ?? 0);
                    pricingDebugLog(() => {
                        console.log("HALF_BOARD, adding standardPricing.halfBoardSupplement ?? 0", standardPricing.halfBoardSupplement ?? 0);
                    });
                    break;
                case RoomServiceType.FULL_BOARD:
                    basePriceD = basePriceD.add(standardPricing.fullBoardSupplement ?? 0);
                    pricingDebugLog(() => {
                        console.log("FULL_BOARD, adding standardPricing.fullBoardSupplement ?? 0", standardPricing.fullBoardSupplement ?? 0);
                    });
                    break;
                case RoomServiceType.ALL_INCLUSIVE:
                    basePriceD = basePriceD.add(standardPricing.allInclusiveSupplement ?? 0);
                    pricingDebugLog(() => {
                        console.log("ALL_INCLUSIVE, adding standardPricing.allInclusiveSupplement ?? 0", standardPricing.allInclusiveSupplement ?? 0);
                    });
                    break;
            }
            pricingDebugLog(() => {
                console.log("basePrice is", basePriceD.toNumber());
            });
        }

        pricingDebugLog(() => {
            console.groupEnd();
            console.log("== Base price is", basePriceD.toNumber(), "==");
            console.group("Markups");
        });

        // const agencyMarkup = loginModule.agencyMarkup;
        const adminMarkup = hotelMargin ? hotelMargin : settingsModule.adminMarkup;

        pricingDebugLog(() => {
            console.log("AgencyMarkup", agencyMarkup);
            console.log("hotelMarkup", hotelMargin);
            console.log("settings.adminMarkup", settingsModule.adminMarkup);

            console.log("Final admin markup (Boolean(hotelMargin) ? hotelMargin : settingsModule.adminMarkup)", adminMarkup);
        });

        if (agencyMarkup && agencyMarkup !== 0) {
            // gives us something like 1.15 for 15 percent, when we multiply with that, we get
            // base price + 15%

            const agencyMarkupD = new Decimal(agencyMarkup).div(100).add(1);
            basePriceD = basePriceD.mul(agencyMarkupD);
            pricingDebugLog(() => {
                console.log("we have agency markup");
                console.log("agencyMarkupFactor", agencyMarkupD.toNumber());
                console.log("basePrice with agency markup", basePriceD.toNumber());
            });
        }

        if (adminMarkup && adminMarkup !== 0) {
            const adminMarkupD = new Decimal(adminMarkup).div(100).add(1);
            basePriceD = basePriceD.mul(adminMarkupD);
            pricingDebugLog(() => {
                console.log("we have admin markup");
                console.log("adminMarkupFactor", adminMarkupD.toNumber());
                console.log("basePrice with admin markup", basePriceD.toNumber());
            });
        }

        pricingDebugLog(() => {
            console.groupEnd();
            console.log("== Price for classification and service type is", basePriceD.toNumber(), "==");
            console.groupEnd();
        });

        return basePriceD.toNumber();
    }
}

export enum Currency {
    USD = "USD",
    BAM = "BAM",
    EUR = "EUR"
}
