import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { store } from "@/store";
import { fetchAmenitiesPage, fetchAllAmenities, apiAddNewAmenity } from "@/api/amenities/amenities";
import { Amenity } from "@/types/amenity";
import { cloneDeep } from "lodash";

@Module({ name: "AmenitiesModule", store: store, dynamic: true })
export default class AmenitiesModule extends VuexModule {
    fetchAsync = false;
    addAmenitiesAsync = false;
    amenities: Array<Amenity> = [];
    hasMore = true;
    currentPage = 0;
    perPage = 10;

    @Mutation
    setAddAmenitiesAsync(async: boolean): void {
        this.addAmenitiesAsync = async;
    }

    @Mutation
    setAmenitiesFetchAsync(async: boolean): void {
        this.fetchAsync = async;
    }

    @Mutation
    setCurrentPage(page: number): void {
        this.currentPage = page;
    }

    @Mutation
    setHasMore(hasMore: boolean): void {
        this.hasMore = hasMore;
    }

    @Mutation
    setPerPage(perPage: number): void {
        this.perPage = perPage;
    }

    @Mutation
    appendAmenities(amenities: Array<Amenity>): void {
        this.amenities = cloneDeep(this.amenities).concat(amenities);
    }

    @Mutation
    setAmenities(amenities: Array<Amenity>): void {
        this.amenities = amenities;
    }

    @Action
    async fetchAllAmenities(): Promise<void> {
        this.setAmenitiesFetchAsync(true);

        try {
            const r = await fetchAllAmenities();

            if (r !== null) {
                this.setAmenities(r);
                this.setCurrentPage(1);
                this.setHasMore(false);
            }
        } catch (e) {
            console.error(e);
        } finally {
            this.setAmenitiesFetchAsync(false);
        }
    }

    @Action
    async fetchAmenitiesFirstPage(): Promise<void> {
        this.setAmenitiesFetchAsync(true);

        try {
            const r = await fetchAmenitiesPage(1, this.perPage);

            if (r !== null) {
                this.setAmenities(r.data);
                this.setCurrentPage(1);
                this.setHasMore(r.hasMore);
            }
        } catch (e) {
            console.error(e);
        } finally {
            this.setAmenitiesFetchAsync(false);
        }
    }

    @Action
    async addNewAmenity(name: string): Promise<void> {
        this.setAddAmenitiesAsync(true);

        try {
            const r = await apiAddNewAmenity(name);

            if (r !== null) {
                this.appendAmenities([r]);
            }
        } catch (e) {
            console.error(e);
        } finally {
            this.setAddAmenitiesAsync(false);
        }
    }

    @Action
    async fetchNextAmenitiesPageAction(): Promise<void> {
        this.setAmenitiesFetchAsync(true);

        try {
            const r = await fetchAmenitiesPage(this.currentPage + 1, this.perPage);

            if (r !== null) {
                this.appendAmenities(r.data);
                this.setCurrentPage(this.currentPage + 1);
                this.setHasMore(r.hasMore);
            }
        } catch (e) {
            console.error(e);
        } finally {
            this.setAmenitiesFetchAsync(false);
        }
    }
}

export const amenitiesModule = getModule(AmenitiesModule);
