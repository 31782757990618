

import Component from "vue-class-component";
import Vue from "vue";
import { Prop } from "vue-property-decorator";

@Component
export default class TextareaField extends Vue {
    focus = false;

    @Prop() readonly icon?: string;
    @Prop() readonly value?: string;
    @Prop({default: 5}) readonly rows!: number;
    @Prop() readonly placeholder?: string;
}
