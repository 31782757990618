
import Button from '@/components/Ui/Button/Button.vue';
import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import { storage_url } from '@/api/apiCall';
import PlaceholderableImage from '@/components/Ui/PlaceholderableImage/PlaceholderableImage.vue';
import { Room } from '@/types/room';
import { MediaObject } from '@/types/mediaObject';
import ImageSlider from '@/components/Ui/ImageSlider/ImageSlider.vue';

@Component({
    components: { ImageSlider, PlaceholderableImage, Button },
})
export default class ContentGallery extends Vue {
    @Prop() readonly mediaObjects!: Array<MediaObject>;

    get rightColumnClass(): string {
        if (this.mediaObjects.length === 1) {
            return 'col-1';
        } else if (this.mediaObjects.length === 2) {
            return 'col-2';
        }

        return 'col-3';
    }

    slideTo(idx: number): void {
        (this.$refs.sliderRef as any).goToSlide(idx);
    }

}
