
import Vue from "vue";
import Component from "vue-class-component";
import { SettingsKey, SettingsKeyType } from "@/types/settings";
import Spinner from "@/components/Ui/Spinner/Spinner.vue";
import CustomTable, {
	TableField,
} from "@/components/Ui/CustomTable/CustomTable.vue";
import InputLabel from "@/components/Ui/InputLabel/InputLabel.vue";
import InputField, {
	InputFieldType,
} from "@/components/Ui/InputField/InputField.vue";
import TextareaField from "@/components/Ui/TextareaField/TextareaField.vue";
import Button, { ButtonType } from "@/components/Ui/Button/Button.vue";
import SelectField, {
	SelectFieldOption,
} from "@/components/Ui/SelectField/SelectField.vue";
import { apiAddSettingsKey, apiEditSettingsKey } from "@/api/settings/settings";
import { settingsModule } from "@/store/settings/settings";
import { cloneDeep } from "lodash";
import { difference } from "@/utils/utils";
import { Watch } from "vue-property-decorator";

const defaultKeyForm: SettingsKey = {
	key: "",
	value: "",
	type: SettingsKeyType.string,
};

@Component({
	components: {
		SelectField,
		Button,
		InputField,
		InputLabel,
		TextareaField,
		CustomTable,
		Spinner,
	},
})
export default class GeneralSettings extends Vue {
	InputType = InputFieldType;
	ButtonType = ButtonType;
	SettingsKeyType = SettingsKeyType;

	tableFields: Array<TableField<SettingsKey>> = [
		{
			name: "key",
			title: "Key",
			slot: "key",
		},
		{
			name: "value",
			title: "Value",
			slot: "value",
		},
		{
			name: "type",
			title: "Type",
			slot: "type",
		},
		{
			name: "actions",
			title: "Akcije",
			width: "15%",
			slot: "actions",
		},
	];

	erroredKeys: Array<string> = [];

	newKey = { ...defaultKeyForm };
	settings: Array<SettingsKey> = [];

	@Watch("originalSettings")
	onSettingsUpdate(): void {
		this.settings = [this.newKey, ...cloneDeep(this.originalSettings)];
	}

	get originalSettings(): Array<SettingsKey> {
		return settingsModule.settings;
	}

	get async(): boolean {
		return settingsModule.settingsAsync;
	}

	get typeOptions(): Array<SelectFieldOption> {
		return [
			{
				value: SettingsKeyType.float,
				label: "Float",
				topDash: false,
			},
			{
				value: SettingsKeyType.int,
				label: "Integer",
				topDash: false,
			},
			{
				value: SettingsKeyType.string,
				label: "String",
				topDash: false,
			},
			{
				value: SettingsKeyType.text,
				label: "Text Area",
				topDash: false,
			},
		];
	}

	onDelete(key: SettingsKey): void {
		this.erroredKeys = [];

		if (key.id) {
			settingsModule.deleteSettingsKey(key.id);
		}
	}

	onSubmit(): void {
		const promises: Array<Promise<unknown>> = [];
		this.erroredKeys = [];

		this.settings.forEach((s) => {
			if (s.id) {
				const originalKey: SettingsKey | null =
					this.originalSettings.find(
						(settingsKey) => settingsKey.id === s.id
					) ?? null;

				if (
					originalKey &&
					JSON.stringify(difference(originalKey, s)) !== "{}"
				) {
					promises.push(
						apiEditSettingsKey(s.id, {
							key: s.key,
							value:
								s.type === SettingsKeyType.string ||
								s.type === SettingsKeyType.text
									? s.value
									: Number(s.value),
							type: s.type,
						}).catch(() => this.erroredKeys.push(s.key))
					);
				}
			} else {
				// New key
				if (s.key && s.value) {
					promises.push(
						apiAddSettingsKey({
							...s,
							value:
								s.type === SettingsKeyType.string ||
								s.type === SettingsKeyType.text
									? s.value
									: Number(s.value),
						})
							.then(() => (this.newKey = { ...defaultKeyForm }))
							.catch(() => this.erroredKeys.push(s.key))
					);
				}
			}
		});

		Promise.allSettled(promises).then(() => {
			settingsModule.fetchSettings();
		});
	}

	beforeMount(): void {
		settingsModule.fetchSettings();
	}
}
