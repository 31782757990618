

import Vue from "vue";
import Component from "vue-class-component";
import Container from "@/components/Ui/Container/Container.vue";
import { Prop } from "vue-property-decorator";
import { AvailabilityRoom } from "@/types/availabilityAdditionals";
import { getStorageUrl } from "@/utils/utils";
import { Reservation, ReservationClass, ReservationClassRoom } from "@/types/reservation";
import PlaceholderableImage from "@/components/Ui/PlaceholderableImage/PlaceholderableImage.vue";

@Component({
    components: { PlaceholderableImage, Container },
})
export default class HotelCard extends Vue {
    @Prop({ default: [] }) readonly availabilityRooms!: Array<AvailabilityRoom>;
    @Prop() readonly reservation!: Reservation;

    get reservationClass(): ReservationClass {
        return new ReservationClass(this.reservation);
    }

    get getPrice(): number {
        return this.reservationClass.totalAccommodationPrice ?? 0;
    }

    get rooms(): ReservationClassRoom[] {
        return this.reservationClass.rooms;
    }

    get hotelName(): string {
        return this.firstAvailability?.hotel.name ?? "";
    }

    get firstAvailability(): AvailabilityRoom | null {
        return this.availabilityRooms[0] ?? null;
    }

    get firstImage(): string {
        return getStorageUrl(this.firstAvailability?.mediaObjects[0] ?? null);
    }
}
