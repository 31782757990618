
import Vue from "vue";
import Component from "vue-class-component";
import CustomModal from "../Ui/CustomModal/CustomModal.vue";
import CustomUploader from "../Ui/CustomUploader/CustomUploader.vue";
import { Prop } from "vue-property-decorator";
import { MediaObject, UploadedFile } from "@/types/mediaObject";

@Component({
    components: { CustomUploader, CustomModal }
})
export default class UploaderModal extends Vue {
    @Prop({ default: () => [] }) readonly mediaObjects!: Array<MediaObject>
    @Prop({ default: false }) readonly async !: boolean
    @Prop({ default: false }) readonly multiple !: boolean
    @Prop({ default: false }) readonly opened!: boolean
    @Prop() readonly value?: Array<UploadedFile>;
    @Prop({default: []}) readonly additionalTypes!: Array<string>;

    @Prop({ default: false }) readonly closeOnSelect !: boolean

    onInput(files: Array<UploadedFile>): void {
        this.$emit('input', files);
        if (this.closeOnSelect) {
            this.$emit('close');
        }
    }
}
