
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import Component from "vue-class-component";
import ReservationInfo from "@/views/Reservations/components/ReservationInfo.vue";
import ReservationStatusWidget from "@/views/Reservations/components/ReservationStatusWidget.vue";
import { ListReservation } from "@/types/reservation";

@Component({
    components: { ReservationStatusWidget, ReservationInfo },
})
export default class SingleActiveReservation extends Vue {
    @Prop() readonly reservation?: ListReservation;

    get totalAccomodationPrice(): number {
        return this.reservation?.totalAccommodationPrice ?? 0;
    }

    get allAdditionalContentItemsPrice(): number {
        return this.reservation?.additionalContentPrice ?? 0;
    }

    get totalPrice(): number {
        return this.allAdditionalContentItemsPrice + this.totalAccomodationPrice;
    }
}
