import { apiCall, jsonApiCall, login, me, users } from "../apiCall";
import { StorageKeys } from "@/utils/consts";
import { User, UserForm } from "@/types/user";
import { UpdateUser } from "@/types/updateUser";

export interface LoginResponse {
    token: string;
    refresh_token: string;
}

export async function doLogin(email: string, password: string): Promise<void> {
    return jsonApiCall<LoginResponse>({
        url: login,
        method: "POST",
        noAuth: true,
        jsonData: {
            email,
            password,
        },
    })
        .then(r => {
            // get token, set to storage, call /me, dispatch action
            if (r != null) {
                localStorage.setItem(StorageKeys.TOKEN, r.token);
                localStorage.setItem(StorageKeys.REFRESH_TOKEN, r.refresh_token);
            }
        });
}

export async function doMe(): Promise<User | null> {
    return jsonApiCall<User>({
        url: me,
        method: "GET",
    });
}

export async function editUser(userId: string, data: UserForm): Promise<User> {
    return apiCall({
        url: users + `/${userId}`,
        method: "PUT",
        jsonData: data as unknown as Record<string, unknown>,
    })
        .then(r => {
            return r?.json();
        });
}

export async function changeUserPassword(userId: string, password: string): Promise<User>  {
    return apiCall({
        url: users + `/${userId}/change_password`,
        method: "PUT",
        jsonData: {
            password,
        },
    })
        .then(r => {
            return r?.json();
        });
}
