
import Vue from "vue";
import Component from "vue-class-component";
import ActiveReservations from "@/views/Dashboard/components/ActiveReservations.vue";
import TopContent from "@/views/Dashboard/components/TopContent.vue";
import ReservationsChart from "@/views/Dashboard/components/ReservationsChart.vue";
import Button, { ButtonType } from "@/components/Ui/Button/Button.vue";
import { endpointNoApi } from "@/api/apiCall";
import SelectField, { SelectFieldOption } from "@/components/Ui/SelectField/SelectField.vue";
import InputLabel from "@/components/Ui/InputLabel/InputLabel.vue";
import moment from "moment";
import { capitalize } from "lodash";
import { format, isSameDay } from "date-fns";
import { bs } from "date-fns/locale";
import GIcon from "@/components/Ui/GIcon/GIcon.vue";
import * as XLSX from "xlsx";
import { WorkBook } from "xlsx";
import Spinner from "@/components/Ui/Spinner/Spinner.vue";
import { saveAs } from "file-saver";

@Component({
    components: { Spinner, GIcon, InputLabel, SelectField, Button, ReservationsChart, TopContent, ActiveReservations },
})
export default class Reports extends Vue {

    ButtonType = ButtonType;

    async = false;
    selectedType = "regular";
    selectedYear: string | null = null;
    selectedMonth: string | null = null;
    // monthFilters: Date[] = [new Date(Number(2022), Number(1), 1), new Date(Number(2022), Number(2), 1)];
    monthFilters: Date[] = [];
    workbook: WorkBook | null = null;
    sheetTabs: string[] = [];
    activeSheet: string | null = null;
    sheetHtmlObj: Record<string, string> = {};                  // {"sheet_name" => "html"}

    xlsxBlob: Blob | null = null;

    get activeSheetHtml(): string {
        if (this.activeSheet) {
            return this.sheetHtmlObj[this.activeSheet] ?? "";
        }

        return "";
    }

    resetWorkbookState(): void {
        this.workbook = null;
        this.sheetTabs = [];
        this.activeSheet = null;
        this.sheetHtmlObj = {};
    }

    async generateReport(): Promise<void> {

        if (!this.monthFilters.length) {
            return;
        }

        this.resetWorkbookState();

        this.setAsync(true);

        let url = new URL(`${endpointNoApi}/public/generate_report`);

        if (this.selectedType === "finantial") {
            url = new URL(`${endpointNoApi}/public/generate_finantial_report`);
        }

        const months = this.monthFilters.map(date => format(date, "yyyy-MM-dd")).join("|");

        url.searchParams.append("dates", months);

        const data = await (await fetch(url.toString())).arrayBuffer().catch(() => this.setAsync(false));

        if (!data) {
            return;
        }

        this.xlsxBlob = new Blob([data]);

        const workbook = XLSX.read(data, {
            cellStyles: true,
            cellHTML: true,
        });

        this.setAsync(false);

        if (workbook) {
            this.workbook = workbook;
            this.sheetTabs = workbook.SheetNames;
            this.changeSheet(workbook.SheetNames[0]);
        }

        // apiCall({
        //     url: url.toString(),
        // }).then(r => r?.body)
        //     .then(stream => {
        //         // const reader = stream?.getReader()
        //         // console.log(reader);
        //         const workbook = read(stream, {
        //             type: "buffer"
        //         });
        //         console.log(workbook);
        //     });

        // window.open(url.toString(), "_blank");
    }

    async downloadReport(): Promise<void> {
        if (this.xlsxBlob) {
            const now = format(new Date(), "yyyy-MM-dd_HH:mm");
            const filename = `beystravel-izvjestaj-${now}.xlsx`;

            console.log(this.xlsxBlob);
            saveAs(this.xlsxBlob, filename);

            // XLSX.writeFileXLSX(this.workbook, filename, {
            //     cellStyles: true,
            //
            // });
        }
    }

    changeSheet(sheet: string): void {
        if (sheet !== this.activeSheet) {
            this.activeSheet = sheet;

            if (!this.sheetHtmlObj[sheet]) {
                this.sheetToHtml();
            }
        }
    }

    sheetToHtml(): void {
        if (this.workbook && this.activeSheet) {
            this.sheetHtmlObj[this.activeSheet] = XLSX.utils.sheet_to_html(this.workbook.Sheets[this.activeSheet]);
        }
    }

    get addBtnDisabled(): boolean {
        return this.selectedYear === null || this.selectedMonth === null;
    }

    get typeOptions(): SelectFieldOption[] {
        return [{
            label: "Regularni",
            value: "regular",
            topDash: false,
        }, {
            label: "Finansijski",
            value: "finantial",
            topDash: false,
        }];
    }

    get yearOptions(): SelectFieldOption[] {
        let thisYear = this.$moment().year();
        const month = this.$moment().month();
        if (month > 7) {
            thisYear += 1;
        }

        const options: SelectFieldOption[] = [];

        for (let i = thisYear; i >= 2021; i--) {
            options.push({
                label: i.toString(),
                value: i.toString(),
                topDash: false,
            });
        }

        return options;
    }

    get monthOptions(): SelectFieldOption[] {

        return moment.months().map(longname => {

            const date = moment(longname, "MMMM").locale("bs");

            return {
                label: capitalize(date.format("MMMM")),
                value: date.month().toString(),
                topDash: false,
            };
        });
    }

    displayMonthFilter(date: Date): string {
        return date.getFullYear() + " " + capitalize(format(date, "MMM", { locale: bs }));
    }

    addMonthFilter(): void {
        if (this.selectedYear && this.selectedMonth) {
            const date = new Date(Number(this.selectedYear), Number(this.selectedMonth), 1);
            this.selectedMonth = null;
            this.selectedYear = null;

            if (this.monthFilters.some(filter => isSameDay(filter, date))) {
                return;
            }

            this.monthFilters.push(date);
        }
    }

    deleteMonthFilter(index: number): void {
        this.monthFilters.splice(index, 1);
    }

    setAsync(async: boolean): void {
        this.async = async;
    }
}

