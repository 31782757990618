import {
    PaginationResponse,
    RawHydraResponse,
    RawHydraResponseIntoPaginationResponse,
} from "@/types/paginationResponse";
import { additionalContentCategories, jsonApiCall } from "@/api/apiCall";
import { AdditionalContentCategory, IContentCategoryForm } from "@/types/additionalContentCategory";
import { AdditionalContentCategoryForm } from "@/types/AdditionalContentCategoryForm";

export async function fetchAdditionalContentCategoriesPage(page: number, perPage: number): Promise<PaginationResponse<AdditionalContentCategory> | null> {
    const r = await jsonApiCall<RawHydraResponse<AdditionalContentCategory>>({
        url: `${additionalContentCategories}?page=${page}&per_page=${perPage}`,
        method: "GET",
        headers: {
            "accept": "application/ld+json",
        },
    });

    if (r !== null) {
        return RawHydraResponseIntoPaginationResponse<AdditionalContentCategory>(r);
    } else {
        return null;
    }
}

export async function fetchAllAdditionalContentCategories(): Promise<Array<AdditionalContentCategory> | null> {
    const r = await jsonApiCall<Array<AdditionalContentCategory>>({
        url: additionalContentCategories,
        method: "GET",
    });

    return r;
}

export async function fetchAdditionalContentCategoryById(id: string): Promise<AdditionalContentCategory | null> {
    const r = await jsonApiCall<AdditionalContentCategory>({
        url: `${additionalContentCategories}/${id}`,
        method: "GET",
    });

    return r;
}

export async function postAddAdditionalContentCategory(data: AdditionalContentCategoryForm): Promise<AdditionalContentCategory | null> {
    const r = await jsonApiCall<AdditionalContentCategory>({
        url: additionalContentCategories,
        method: "POST",
        jsonData: data as Record<string, unknown>,
    });

    return r;
}

export async function editAdditionalContentCategory(categoryId: string, data: IContentCategoryForm): Promise<AdditionalContentCategory | null> {
    const r = await jsonApiCall<AdditionalContentCategory>({
        url: `${additionalContentCategories}/${categoryId}`,
        method: "PUT",
        jsonData: data as Record<string, unknown>
    });

    if (r !== null) {
        return r;
    }

    return null;
}

export async function deleteAdditionalContentCategory(categoryId: string): Promise<null> {

    return await jsonApiCall<null>({
        url: `${additionalContentCategories}/${categoryId}`,
        method: "DELETE"
    });
}
