
import Vue from "vue";
import Component from "vue-class-component";
import Button, { ButtonType } from "@/components/Ui/Button/Button.vue";
import { goBackOrPush } from "@/utils/utils";
import { RouteLink } from "@/router";
import {
    AdditionalContentCategory,
    ContentCategoryForm,
    IContentCategoryForm
} from "@/types/additionalContentCategory";
import { additionalContentCategoriesModule as categoriesModule } from "@/store/additional_content_categories/additional_content_categories";
import Spinner from "@/components/Ui/Spinner/Spinner.vue";
import CustomUploader from "@/components/Ui/CustomUploader/CustomUploader.vue";
import AddContentRenderer from "@/components/AddContentRenderer/AddContentRenderer.vue";
import { AddFormPage } from "@/types/addForm/addFormPage";
import AboutCategoryForm from "@/views/EditAdditionalContentCategory/components/AboutCategoryForm.vue";
import DeleteCategory from "@/views/EditAdditionalContentCategory/components/DeleteCategory.vue";
import FormEditor from "@/views/AddNew/AddNewAdditionalContentCategory/components/FormEditor.vue";
import { cloneDeep } from 'lodash'
import AdminPageHeader from "@/components/AdminPageHeader/AdminPageHeader.vue";

enum EditCategorySectionType {
    ABOUT = "ABOUT",
    EDITOR = "EDITOR",
    DELETE = "DELETE"
}

interface EditCategorySection {
    name: string,
    type: EditCategorySectionType
}

@Component({
    components: {
        AdminPageHeader,
        FormEditor,
        DeleteCategory,
        AboutCategoryForm,
        AddContentRenderer,
        CustomUploader,
        Spinner,
        Button,
    },
})
export default class EditAdditionalContentCategory extends Vue {
    EditCategorySectionType = EditCategorySectionType;
    ButtonType = ButtonType
    goBackOrPush = goBackOrPush;
    RouteLink = RouteLink;

    sections: Array<EditCategorySection> = [
        {
            name: 'Osnovne informacije',
            type: EditCategorySectionType.ABOUT
        },
    ];

    selectedSection = EditCategorySectionType.ABOUT;

    categoryForm = new ContentCategoryForm();
    selectedPageNumber = 0;

    get categorySteps(): AddFormPage[] {
        return this.categoryForm.config.pages;
    }

    get fetchAsync(): boolean {
        return categoriesModule.fetchAsync;
    }

    get editAsync(): boolean {
        return categoriesModule.addEditAsync;
    }

    addFormPage(): void {

        const config = cloneDeep(this.categoryForm.config);

        let newPage = new AddFormPage(config.pages.length, []);
        config.pages.push(newPage);

        this.categoryForm.config = config;

        this.selectedPageNumber = config.pages.length;
    }

    selectStep(index: number): void {
        this.selectedSection = EditCategorySectionType.EDITOR;
        this.selectedPageNumber = index;
    }

    selectSection(section: EditCategorySectionType): void {
        this.selectedSection = section;
    }

    backToCategories(): void {
        goBackOrPush(RouteLink.AdditionalContentCategories);
    }

    async onSubmit(): Promise<void> {
        if (!this.categoryForm.validate()) return;

        if (categoriesModule.selectedAdditionalContentCategory) {

            this.categoryForm.prepareFormForSending();

            const diff = this.categoryForm.difference(ContentCategoryForm.createFormWithCategory(categoriesModule.selectedAdditionalContentCategory)) as IContentCategoryForm

            try {

                await categoriesModule.editAdditionalContentCategory({
                    categoryId: categoriesModule.selectedAdditionalContentCategory.id,
                    data: diff
                });

                // this.backToCategories()
            } catch (ex) {
                //
            }
        }
    }

    async beforeMount() {

        const { params: { categoryId } } = this.$route;


        if (categoryId) {
            await categoriesModule.selectAdditionalContentCategoryById(categoryId);
        }

        if (categoriesModule.selectedAdditionalContentCategory) {
            this.setData(categoriesModule.selectedAdditionalContentCategory);
        }
    }

    setData(category: AdditionalContentCategory): void {
        this.categoryForm = ContentCategoryForm.createFormWithCategory(category);
    }

}
