import { jsonApiCall, settings } from "@/api/apiCall";
import { SettingsKey } from "@/types/settings";

export async function apiFetchSettings(): Promise<Array<SettingsKey> | null> {
    const r = await jsonApiCall<Array<SettingsKey>>({
        url: settings,
        method: "GET",
    });

    return r;
}

export async function apiEditSettingsKey(keyId: string, data: SettingsKey): Promise<SettingsKey | null> {
    return await jsonApiCall<SettingsKey | null>({
        url: `${settings}/${keyId}`,
        method: "PUT",
        jsonData: data as Record<string, any>,
    })
}

export async function apiAddSettingsKey(data: SettingsKey): Promise<SettingsKey | null> {
    return await jsonApiCall<SettingsKey | null>({
        url: `${settings}`,
        method: "POST",
        jsonData: data as Record<string, any>,
    })
}

export async function apiDeleteSettingsKey(keyId: string): Promise<null> {
    return await jsonApiCall<null>({
        url: `${settings}/${keyId}`,
        method: 'DELETE'
    })
}