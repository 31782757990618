
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { additionalContentModule } from "@/store/additional_content/additional_content";
import { ListAdditionalContent } from "@/types/additionalContent";
import Spinner from "@/components/Ui/Spinner/Spinner.vue";
import ReservationAdditionalContentCard
    from "@/views/SingleReservation/components/ReservationAdditionalContentCard.vue";
import { reservationFormModule } from "@/store/reservation_form/reservation_form";
import { ManageReservationSection } from "@/views/ManageReservation/ManageReservation.vue";
import { cloneDeep } from "lodash";

@Component({
    components: { ReservationAdditionalContentCard, Spinner },
})
export default class ReservationAdditionalContent extends Vue {
    @Prop() readonly selectedAdditionalContentCategoryId!: string;
    @Prop() readonly selectedSection!: ManageReservationSection | null;

    get additionalContentAsync(): boolean {
        return additionalContentModule.fetchAsync;
    }

    get isPerPerson(): boolean {
        return this.selectedSection?.isPerPerson ?? false;
    }

    get canPriceBeDoubled(): boolean {
        return this.selectedSection?.canPriceBeDoubled ?? false;
    }

    get additionalContent(): Array<ListAdditionalContent> {
        return additionalContentModule.additionalContent;
    }

    isItemSelected(item: ListAdditionalContent): boolean {
        return Boolean(reservationFormModule.reservation?.reservationAdditionalContents?.find(i => i.additionalContent.id === item.id)) ?? false;
    }

    getItemNumPersons(item: ListAdditionalContent): number {
        return reservationFormModule.reservation?.reservationAdditionalContents?.find(i => i.additionalContent.id === item.id)?.passengerCount ?? 1;
    }

    isPriceDoubled(item: ListAdditionalContent): boolean {
        return reservationFormModule.reservation?.reservationAdditionalContents?.find(i => i.additionalContent.id === item.id)?.isPriceDoubled ?? false;
    }

    agentValues(item: ListAdditionalContent): Record<string, unknown> {
        return reservationFormModule.reservation?.reservationAdditionalContents?.find(i => i.additionalContent.id === item.id)?.agentValues ?? {};
    }

    changeAgentValues(item: ListAdditionalContent, values: Record<string, unknown>): void {
        console.log(values);
        reservationFormModule.reservationFormChangeAdditionalContent({
            itemId: item.id,
            data: {
                agentValues: cloneDeep(values)
            }
        })
    }

    get numItemsOfThisCategorySelected(): number {
        return reservationFormModule.reservation?.reservationAdditionalContents?.filter(i => i.additionalContent.category.id === this.selectedAdditionalContentCategoryId).length ?? 0;
    }

    clickAdditionalContent(item: ListAdditionalContent): void {
        if (this.isItemSelected(item)) {
            reservationFormModule.reservationFormRemoveAdditionalContentItem(item.id);
        } else {
            if (this.selectedSection?.isMultiselect || this.numItemsOfThisCategorySelected === 0) {
                reservationFormModule.reservationFormAppendAdditionalContentItem({
                    additionalContent: item,
                    passengerCount: 1,
                    isPriceDoubled: false,
                    agentValues: {}
                });
            }
        }
    }

    changeNumPersons(item: ListAdditionalContent, numPersons: number): void {
        if (numPersons >= 1) {
            reservationFormModule.reservationFormChangeAdditionalContent({
                itemId: item.id,
                data: { passengerCount: numPersons }
            });
        }
    }

    changeIsPriceDoubled(item: ListAdditionalContent, isDoubled: boolean): void {
        reservationFormModule.reservationFormChangeAdditionalContent({
            itemId: item.id,
            data: {
                isPriceDoubled: isDoubled
            }
        });
    }

    fetchCategoryFirstPage(): void {
        additionalContentModule.fetchAdditionalContentFirstPageByCategoryIdAction(this.selectedAdditionalContentCategoryId);
    }

    @Watch("selectedAdditionalContentCategoryId")
    selectedCategoryUpdated(): void {
        this.fetchCategoryFirstPage();
    }

    mounted(): void {
        this.fetchCategoryFirstPage();
    }
}
