import {
    PaginationResponse,
    RawHydraResponse,
    RawHydraResponseIntoPaginationResponse,
} from "@/types/paginationResponse";
import { availabilities, hotels, jsonApiCall, roomBeds, rooms, roomVariants } from "@/api/apiCall";
import { Room, IRoomForm, ListRoom, RoomBed, AvailableRoomsForDateRangeResponse } from "@/types/room";
import { RoomBedForm } from "@/types/beds";
import { AvailabilityItem } from "@/types/availability";
import { getDatesBetweenRangeInclusive } from "@/utils/utils";
import moment from "moment";
import { RoomPricing } from "@/types/pricing";
import { RoomVariant, RoomVariantPostData, RoomVariantPutData } from "@/types/roomVariant";
import RoomVariants from "@/views/EditRoom/components/RoomVariants.vue";

export async function fetchRoomsPage(page: number, perPage: number): Promise<PaginationResponse<ListRoom> | null> {
    const r = await jsonApiCall<RawHydraResponse<ListRoom>>({
        url: `${rooms}?page=${page}&per_page=${perPage}`,
        method: "GET",
        headers: {
            "accept": "application/ld+json",
        },
    });

    if (r !== null) {
        return RawHydraResponseIntoPaginationResponse<ListRoom>(r);
    } else {
        return null;
    }
}

export async function apiFetchAllRooms(): Promise<Array<ListRoom> | null> {
    const r = await jsonApiCall<Array<ListRoom>>({
        url: `${rooms}`,
        method: "GET",
    });

    return r;
}

export async function apiFetchAllHotelRooms(hotelId: string): Promise<Array<ListRoom> | null> {
    const r = await jsonApiCall<Array<ListRoom>>({
        url: `${rooms}/types?hotel=${hotelId}`,
        method: "GET",
    });

    return r;
}

export async function apiFetchAvailableRoomsForDateRange(hotelId: string, roomId: string, {
    startDate,
    endDate,
}: { startDate: Date, endDate: Date }): Promise<number | null> {
    const url = new URL(`${rooms}/${roomId}/available`);
    url.searchParams.append("from", moment(startDate).format("YYYY-MM-DD"));
    url.searchParams.append("to", moment(endDate).format("YYYY-MM-DD"));

    const r = await jsonApiCall<AvailableRoomsForDateRangeResponse>({
        url: url.toString(),
        method: "GET",
    });

    return r?.max_q ?? null;
}

export async function apiFetchAllRoomAvailabilities(hotelId: string, roomName: string): Promise<Array<AvailabilityItem> | null> {
    const url = new URL(availabilities);
    url.searchParams.append("hotel_id", hotelId);
    url.searchParams.append("room_name", roomName);

    const r = await jsonApiCall<Array<AvailabilityItem>>({
        url: url.toString(),
        method: "GET",
    });

    return r;
}

export async function apiAddAvailabilitiesBulk(hotelId: string, roomId: string, roomQuantity: number, pricing: RoomPricing, {
    startDate,
    endDate,
}: { startDate: Date, endDate: Date }): Promise<Array<AvailabilityItem> | null> {
    const r = await jsonApiCall<Array<AvailabilityItem>>({
        url: `${availabilities}/bulk`,
        method: "POST",
        jsonData: {
            hotel_id: hotelId,
            room_id: roomId,
            q: roomQuantity,
            from: moment(startDate).format("YYYY-MM-DD"),
            to: moment(endDate).format("YYYY-MM-DD"),
            pricing,
        },
    });

    return r ?? null;
}

export async function apiDeleteAvailabilitiesBulk(groupId: string): Promise<null> {

    return jsonApiCall<null>({
        url: `${availabilities}/bulk`,
        method: "DELETE",
        jsonData: {
            group_id: groupId,
        },
    });
}

export async function apiEditHotelGlobalDiscount(groupId: string, discount: number): Promise<Array<AvailabilityItem> | null> {
    return await jsonApiCall<Array<AvailabilityItem>>({
        url: `${hotels}/bulk_discount`,
        method: "POST",
        jsonData: {
            group_id: groupId,
            discount,
        },
    });
}

export async function apiEditPromoCodeBulk(groupId: string, promoCode: string | null) {
    const r = await jsonApiCall<Array<AvailabilityItem>>({
        url: `${availabilities}/bulk`,
        method: "PUT",
        jsonData: {
            group_id: groupId,
            promo_code: promoCode,
        },
    }).catch(() => null);

    return r ?? null;
}

export async function apiEditAvailabilitiesBulk(groupId: string, pricing: RoomPricing): Promise<Array<AvailabilityItem> | null> {
    const r = await jsonApiCall<Array<AvailabilityItem>>({
        url: `${availabilities}/bulk`,
        method: "PUT",
        jsonData: {
            group_id: groupId,
            pricing,
        },
    });

    return r ?? null;
}

export async function apiRecalculateGroupRoomsQuantity(groupId: string): Promise<Array<AvailabilityItem> | null> {
    const r = await jsonApiCall<Array<AvailabilityItem>>({
        url: `${availabilities}/recalculate_q`,
        method: "PUT",
        jsonData: {
            groupId,
        },
    });

    return r ?? null;
}

export async function apiEditAvailabilitiesGroupEnabled(groupId: string, enabled: boolean): Promise<Array<AvailabilityItem> | null> {
    const r = await jsonApiCall<Array<AvailabilityItem>>({
        url: `${availabilities}/${(enabled ? "en" : "dis") + "able"}`,
        method: "PUT",
        jsonData: {
            group_id: groupId,
        },
    });

    return r ?? null;
}

export async function apiSplitAvailabilities(groupId: string, startDate: Date, endDate: Date): Promise<Array<AvailabilityItem> | null> {

    return jsonApiCall<Array<AvailabilityItem>>({
        url: `${availabilities}/split`,
        method: "POST",
        jsonData: {
            groupId,
            from: moment(startDate).format("YYYY-MM-DD"),
            to: moment(endDate).format("YYYY-MM-DD"),
        },
    });
}

export async function apiMergeAvailabilities(groupIds: Array<string>): Promise<Array<AvailabilityItem> | null> {

    return jsonApiCall<Array<AvailabilityItem>>({
        url: `${availabilities}/merge`,
        method: "POST",
        jsonData: {
            groupIds,
        },
    });
}

export async function addRoom(data: IRoomForm): Promise<Room | null> {
    const r = await jsonApiCall<Room>({
        url: rooms,
        method: "POST",
        jsonData: data as Record<string, any>,
    });

    if (r !== null) {
        return r;
    }

    return null;
}

export async function editRoom(roomId: string, data: IRoomForm): Promise<Room | null> {
    const r = await jsonApiCall<Room>({
        url: `${rooms}/${roomId}`,
        method: "PUT",
        jsonData: data as Record<string, any>,
    });

    if (r !== null) {
        return r;
    }

    return null;
}

export async function fetchRoomById(roomId: string): Promise<Room | null> {
    const r = await jsonApiCall<Room>({
        url: `${rooms}/${roomId}`,
    });

    if (r !== null) {
        return r;
    }

    return null;
}

export async function deleteRoom(roomId: string): Promise<null> {

    const r = await jsonApiCall<null>({
        url: `${rooms}/${roomId}`,
        method: "DELETE",
    });

    return r;
}


export async function editRoomBed(roomBedId: string, data: { quantity: number }): Promise<RoomBed | null> {
    return await jsonApiCall<RoomBed>({
        url: `${roomBeds}/${roomBedId}`,
        method: "PUT",
        jsonData: data as Record<string, any>,
    });
}

export async function addRoomBed(data: RoomBedForm): Promise<RoomBed | null> {

    return await jsonApiCall<null>({
        url: `${roomBeds}`,
        method: "POST",
        jsonData: data as Record<string, any>,
    });
}

export async function deleteRoomBed(roomBedId: string): Promise<null> {

    return await jsonApiCall<null>({
        url: `${roomBeds}/${roomBedId}`,
        method: "DELETE",
    });
}

export async function addRoomVariant(data: RoomVariantPostData): Promise<RoomVariant | null> {

    console.log(data as Record<string, any>);

    const r = await jsonApiCall<RoomVariant>({
        url: `${roomVariants}`,
        method: "POST",
        jsonData: data as Record<string, any>,
    });

    if (r !== null) {
        return r;
    }

    return null;
}

export async function editRoomVariant(roomVariantId: string, data: Partial<RoomVariantPutData>): Promise<RoomVariant | null> {
    const r = await jsonApiCall<RoomVariant>({
        url: `${roomVariants}/${roomVariantId}`,
        method: "PUT",
        jsonData: data as Record<string, any>,
    });

    if (r !== null) {
        return r;
    }

    return null;
}

export async function deleteRoomVariant(roomVariantId: string): Promise<null> {

    return await jsonApiCall<null>({
        url: `${roomVariants}/${roomVariantId}`,
        method: "DELETE",
    });
}
