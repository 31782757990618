
import Vue from "vue";
import Component from "vue-class-component";
import InputLabel from "../Ui/InputLabel/InputLabel.vue";
import SelectField from "../Ui/SelectField/SelectField.vue";
import DateRangePickerWrapper from "../DateRangePickerWrapper/DateRangePickerWrapper.vue";
import PassengerNumberWrapper from "../../views/Home/PassengerNumberWrapper/PassengerNumberWrapper.vue";
import Container from "../Ui/Container/Container.vue";
import { ReservationType } from "@/types/reservation";
import { homeFiltersModule } from "@/store/home_filters/home_filters";
import { DateRange } from "@/components/DateRangePickerWrapper/DateRangePickerWrapper.vue";
import Button from "@/components/Ui/Button/Button.vue";
import { Prop } from "vue-property-decorator";
import { hotelsModule } from "@/store/hotels/hotels";
import { apiFetchAvailableCities } from "@/api/hotels/hotels";
import { capitalize } from "lodash";
import { SelectFieldOption } from "@/components/Ui/SelectField/SelectField.vue";

@Component({
    components: { Button, Container, PassengerNumberWrapper, DateRangePickerWrapper, SelectField, InputLabel },
})
export default class HotelSearchForm extends Vue {
    @Prop({ default: false }) readonly compact!: boolean;

    locationOptions: Array<SelectFieldOption> = [];

    typeOptions = [{
        value: ReservationType.INDIVIDUAL,
        label: "Individualno",
    }, {
        value: ReservationType.GROUP,
        label: "Grupno",
    }];

    mounted(): void {
        apiFetchAvailableCities().then(cities => {
            if (cities) {
                this.locationOptions = cities.map(city => ({
                    label: capitalize(city),
                    value: city,
                    topDash: false,
                }));

                if (cities.includes("dubai") && !this.location) {
                    hotelsModule.setHotels([]);
                    homeFiltersModule.setLocation("dubai");
                }
            }
        });
    }

    onSearch(): void {
        hotelsModule.setHotels([]);
        homeFiltersModule.setDateRange(homeFiltersModule.dateRangeUnconfirmed);

        this.$emit("on-search");
    }

    get location(): string {
        return homeFiltersModule.location;
    }

    updateHomeFilterType(type: ReservationType): void {
        homeFiltersModule.setType(type);
    }

    onDateRangeChange(range: DateRange): void {
        homeFiltersModule.setDateRangeUnconfirmed(range);
    }

    onLocationChange(city: string): void {
        homeFiltersModule.setLocation(city);
    }

    get async(): boolean {
        return hotelsModule.fetchAsync;
    }

    get searchButtonDisabled(): boolean {
        return homeFiltersModule.invalid || this.async;
    }

    get homeFilterType(): ReservationType {
        return homeFiltersModule.type;
    }

    get dateRange(): DateRange {
        return homeFiltersModule.dateRangeUnconfirmed;
    }

    dateRangePickerOpen = false;

}
