import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { store } from "@/store";
import { MediaUploadEntityData, uploadMediaObject } from "@/api/media_objects/media_objects";
import { MediaObject, UploadedFile } from "@/types/mediaObject";

@Module({ name: "MediaModule", store: store, dynamic: true })
export default class MediaModule extends VuexModule {
    async = false;
    multipleAsync = false;

    @Mutation
    setAsync(async: boolean): void {
        this.async = async;
    }

    @Mutation
    setMultipleAsync(async: boolean): void {
        this.multipleAsync = async;
    }

    @Action
    async uploadMediaObject({
                                file,
                                entityData,
                            }: { entityData?: MediaUploadEntityData, file: UploadedFile }): Promise<MediaObject | null> {

        return uploadMediaObject(file, (progress) => {
            file.progress = progress;
        }, entityData)
            .finally(() => file.progress = null);
    }

    @Action
    async uploadMultipleMediaObjects({
                                         files,
                                         entityData,
                                     }: { entityData?: MediaUploadEntityData, files: Array<UploadedFile> }): Promise<Array<Promise<MediaObject | null>>> {

        const promises: Array<Promise<MediaObject | null>> = [];

        files.forEach(file => {
            promises.push(this.uploadMediaObject({ file, entityData }).catch(() => null));      // Handle failed uploads as null
        })

        return promises;
    }
}

export const mediaModule = getModule(MediaModule);
