
import Component from "vue-class-component";
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import {
    AddFormItem,
    AddFormItem_Address,
    AddFormItem_Amenities, AddFormItem_Datetime, AddFormItem_Gallery,
    AddFormItem_GoogleMap, AddFormItem_HotelNameAutocompleteInput, AddFormItem_HotelSelect, AddFormItem_Hours,
    AddFormItem_Input,
    AddFormItem_InputType, AddFormItem_RoomBeds,
    AddFormItem_Select,
    AddFormItem_TextArea,
    AddFormItem_Title,
} from "@/types/addForm/addFormItem";
import AddContentInput from "@/components/AddContentRenderer/AddContentItemRenderers/AddContentInput.vue";
import AddContentTextArea from "@/components/AddContentRenderer/AddContentItemRenderers/AddContentTextArea.vue";
import AddContentTitle from "@/components/AddContentRenderer/AddContentItemRenderers/AddContentTitle.vue";
import AddContentAddress from "@/components/AddContentRenderer/AddContentItemRenderers/AddContentAddress.vue";
import AddContentGoogleMap from "@/components/AddContentRenderer/AddContentItemRenderers/AddContentGoogleMap.vue";
import AddContentSelect from "@/components/AddContentRenderer/AddContentItemRenderers/AddContentSelect.vue";
import AddContentAmenities from "@/components/AddContentRenderer/AddContentItemRenderers/AddContentAmenities.vue";
import AddContentInputNumberPlusMinus
    from "@/components/AddContentRenderer/AddContentItemRenderers/AddContentInputNumberPlusMinus.vue";
import AddContentHours from "@/components/AddContentRenderer/AddContentItemRenderers/AddContentHours.vue";
import AddContentHotelSelect from "@/components/AddContentRenderer/AddContentItemRenderers/AddContentHotelSelect.vue";
import AddContentGallery from "@/components/AddContentRenderer/AddContentItemRenderers/AddContentGallery.vue";
import AddContentRoomBeds from "@/components/AddContentRenderer/AddContentItemRenderers/AddContentRoomBeds.vue";
import AddContentHotelNameAutocompleteInput
    from "@/components/AddContentRenderer/AddContentItemRenderers/AddContentHotelNameAutocompleteInput.vue";
import AddContentDatetime from "./AddContentItemRenderers/AddContentDatetime.vue";

@Component({
    components: {
        AddContentHotelNameAutocompleteInput,
        AddContentRoomBeds,
        AddContentGallery,
        AddContentHotelSelect,
        AddContentHours,
        AddContentInputNumberPlusMinus,
        AddContentAmenities,
        AddContentSelect,
        AddContentGoogleMap, AddContentAddress, AddContentInput, AddContentTitle, AddContentTextArea,
        AddContentDatetime
    },
})
export default class AddContentItem<T extends AddFormItem> extends Vue {
    @Prop() readonly item!: T;
    @Prop() readonly entity!: Record<string, unknown>;

    get isInputText(): boolean {
        return this.item instanceof AddFormItem_Input && this.item.inputType === AddFormItem_InputType.Text;
    }

    get isInputNumberPlusMinus(): boolean {
        return this.item instanceof AddFormItem_Input && this.item.inputType === AddFormItem_InputType.NumberPlusMinus;
    }

    get isInputNumber(): boolean {
        return this.item instanceof AddFormItem_Input && this.item.inputType === AddFormItem_InputType.Number;
    }

    get isTitle(): boolean {
        return this.item instanceof AddFormItem_Title;
    }

    get isTextArea(): boolean {
        return this.item instanceof AddFormItem_TextArea;
    }

    get isAddress(): boolean {
        return this.item instanceof AddFormItem_Address;
    }

    get isGallery(): boolean {
        return this.item instanceof AddFormItem_Gallery;
    }

    get isGoogleMap(): boolean {
        return this.item instanceof AddFormItem_GoogleMap;
    }

    get isSelect(): boolean {
        return this.item instanceof AddFormItem_Select;
    }

    get isHours(): boolean {
        return this.item instanceof AddFormItem_Hours;
    }

    get isDatetime(): boolean {
        return this.item instanceof AddFormItem_Datetime;
    }

    get isAmenities(): boolean {
        return this.item instanceof AddFormItem_Amenities;
    }

    get isHotelSelect(): boolean {
        return this.item instanceof AddFormItem_HotelSelect;
    }

    get isHotelNameAutocomplete(): boolean {
        return this.item instanceof AddFormItem_HotelNameAutocompleteInput;
    }

    get isRoomBeds(): boolean {
        return this.item instanceof AddFormItem_RoomBeds;
    }
}
