
import Vue from "vue";
import Component from "vue-class-component";
import { additionalContentCategoriesModule as categoriesModule } from "@/store/additional_content_categories/additional_content_categories";
import Spinner from "@/components/Ui/Spinner/Spinner.vue";
import CustomTable, { TableField } from "@/components/Ui/CustomTable/CustomTable.vue";
import { AdditionalContentUtils, ListAdditionalContent } from "@/types/additionalContent";
import Button from "@/components/Ui/Button/Button.vue";
import ListPagination from "@/components/ListPagination/ListPagination.vue";
import AdminPageHeader from "@/components/AdminPageHeader/AdminPageHeader.vue";
import { RouteName } from "@/router";
import { AdditionalContentCategory, ListAdditionalContentCategory } from "@/types/additionalContentCategory";
import { Language } from "@/types/language";

@Component({
    components: { AdminPageHeader, ListPagination, Button, CustomTable, Spinner },
    mounted() {
        categoriesModule.fetchAdditionalContentCategoryFirstPage();
    }
})
export default class AdditionalContentCategories extends Vue {
    tableFields: Array<TableField<ListAdditionalContent>> = [{
        name: "",
        title: "Sortiraj",
        slot: 'name'
    }, {
        name: "createdAt",
        title: "Kreirana",
        slot: "createdAt",
    }, {
        name: "actions",
        title: "Pretraga",
        width: '10%',
        slot: "actions",
    }];

    get currentPage(): number {
        return categoriesModule.currentPage;
    }

    get totalPages(): number {
        return categoriesModule.totalPages;
    }

    get categories(): Array<ListAdditionalContentCategory> {
        return categoriesModule.additionalContentCategories;
    }

    get async(): boolean {
        return categoriesModule.fetchAsync;
    }

    onPageChange(page: number): void {
        categoriesModule.fetchAdditionalContentCategoryPageAction(page)
    }

    editContentCategory(category: ListAdditionalContentCategory): void {
        this.$router.push({
            name: RouteName.EditAdditionalContentCategory,
            params: {
                categoryId: category.id,
            },
        });
    }

    getAdditionalContentCategoryName(cat: AdditionalContentCategory): string {
        return AdditionalContentUtils.resolveName(cat, this.$i18n.locale as Language) ?? "";
    }
}
