
import Vue from "vue";
import Component from "vue-class-component";
import CustomModal from "../../../components/Ui/CustomModal/CustomModal.vue";
import { Prop, Watch } from "vue-property-decorator";
import { MediaObject } from "@/types/mediaObject";
import Button, { ButtonType } from "@/components/Ui/Button/Button.vue";

@Component({
    components: { Button, CustomModal }
})
export default class MediaPicker extends Vue {
    @Prop({ default: false }) readonly async!: boolean
    @Prop({ default: false }) readonly opened!: boolean
    @Prop({ default: () => [] }) readonly mediaObjects!: Array<MediaObject>

    ButtonType = ButtonType;

    selectedMedia: Array<string> = []

    selectMedia(media: MediaObject): void {

        const index = this.selectedMedia.findIndex(id => id === media.id);

        if (index !== -1) {
            this.selectedMedia.splice(index, 1);
        } else {
            this.selectedMedia.push(media.id);
        }
    }

    isSelected(media: MediaObject): boolean {
        return this.selectedMedia.includes(media.id);
    }

    chooseImages(): void {
        this.$emit('chooseMedia', this.selectedMedia);
    }

    @Watch('opened')
    resetData(): void {
        this.selectedMedia = [];
    }
}
