<template>
    <div class="page-header">
        <slot name="left"/>
        <div class="right">
            <slot name="right"/>
        </div>
    </div>
</template>

<script>
export default {
    name: "AdminPageHeader"
}
</script>

<style scoped lang="scss">

.page-header {
    display: flex;
    align-items: center;
    margin-bottom: 3.125rem;

    .right {
        margin-left: auto;
    }
}

</style>