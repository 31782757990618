import {
    PaginationResponse,
    RawHydraResponse,
    RawHydraResponseIntoPaginationResponse,
} from '@/types/paginationResponse';
import { available_cities, cities, hotels, hotelSpecialOffers, jsonApiCall } from '@/api/apiCall';
import { Hotel, HotelsQuery, HotelWithSpecialOffers, HotelWithStatistics, IHotelForm } from '@/types/hotel';
import { HotelSpecialOffer, IHotelSpecialOfferForm } from '@/types/hotelSpecialOffer';
import { City } from '@/types/city';

export async function fetchHotelsPage(page: number, perPage: number): Promise<PaginationResponse<Hotel> | null> {
    const r = await jsonApiCall<RawHydraResponse<Hotel>>({
        url: `${hotels}?page=${page}&per_page=${perPage}`,
        method: 'GET',
        headers: {
            'accept': 'application/ld+json',
        },
    });

    if (r !== null) {
        return RawHydraResponseIntoPaginationResponse<Hotel>(r);
    } else {
        return null;
    }
}

export async function fetchHotelsPageOnlyWithOffers(page: number, perPage: number): Promise<PaginationResponse<Hotel> | null> {
    const r = await jsonApiCall<RawHydraResponse<Hotel>>({
        url: `${hotels}/with_offers?page=${page}&per_page=${perPage}`,
        method: 'GET',
        headers: {
            'accept': 'application/ld+json',
        },
    });

    if (r !== null) {
        return RawHydraResponseIntoPaginationResponse<Hotel>(r);
    } else {
        return null;
    }
}

export async function apiFetchAllHotels(): Promise<Array<Hotel> | null> {
    const r = await jsonApiCall<Array<Hotel>>({
        url: `${hotels}`,
        method: 'GET',
    });

    return r;
}

export async function apiFetchAllHotelsWithOffers(): Promise<Array<HotelWithSpecialOffers> | null> {
    return await jsonApiCall<Array<HotelWithSpecialOffers>>({
        url: `${hotels}/with_offers`,
        method: 'GET',
    });
}

export async function apiSearchHotels(queryData: HotelsQuery): Promise<PaginationResponse<Hotel> | null> {
    const url = new URL(`${hotels}/search`);

    url.searchParams.append('pagination', 'false');
    url.searchParams.append('from', queryData.dateRange.start.toISOString());
    url.searchParams.append('to', queryData.dateRange.end.toISOString());

    const { adults, childrenUnder6, children } = queryData.passengersData;

    url.searchParams.append('adults', adults.toString());
    url.searchParams.append('children', (children + childrenUnder6).toString());

    url.searchParams.append('rooms', (queryData.roomsNumber || 1).toString());
    url.searchParams.append('location', queryData.location);
    url.searchParams.append('type', queryData.type);
    url.searchParams.append('search', queryData.searchText);

    const r = await jsonApiCall<RawHydraResponse<Hotel>>({
        url: url.toString(),
        method: 'GET',
        headers: {
            'accept': 'application/ld+json',
        },
    });

    if (r !== null) {
        return RawHydraResponseIntoPaginationResponse<Hotel>(r);
    } else {
        return null;
    }
}

export async function apiGetTopReservedHotels(): Promise<Array<HotelWithStatistics> | null> {

    const url = new URL(`${hotels}/top_reserved?top=3`);

    return await jsonApiCall<Array<HotelWithStatistics>>({
        url: url.toString(),
        method: 'GET',
    });
}

export async function addHotel(data: IHotelForm): Promise<Hotel | null> {
    const r = await jsonApiCall<Hotel>({
        url: hotels,
        method: 'POST',
        jsonData: data as Record<string, unknown>,
    });

    if (r !== null) {
        return r;
    }

    return null;
}

export async function editHotel(hotelId: string, data: IHotelForm): Promise<Hotel | null> {
    const r = await jsonApiCall<Hotel>({
        url: `${hotels}/${hotelId}`,
        method: 'PUT',
        jsonData: data as Record<string, unknown>,
    });

    if (r !== null) {
        return r;
    }

    return null;
}

export async function fetchHotelById(hotelId: string): Promise<Hotel | null> {
    const r = await jsonApiCall<Hotel>({
        url: `${hotels}/${hotelId}`,
    });

    if (r !== null) {
        return r;
    }

    return null;
}

export async function deleteHotel(hotelId: string): Promise<null> {

    const r = await jsonApiCall<null>({
        url: `${hotels}/${hotelId}`,
        method: 'DELETE',
    });

    console.log(r);

    return r;
}

export async function addHotelSpecialOffer(data: IHotelSpecialOfferForm): Promise<HotelSpecialOffer | null> {
    return await jsonApiCall<HotelSpecialOffer>({
        url: hotelSpecialOffers,
        method: 'POST',
        jsonData: data as Record<string, unknown>,
    });
}

export async function editHotelSpecialOffer(offerId: string, data: IHotelSpecialOfferForm): Promise<HotelSpecialOffer | null> {
    return await jsonApiCall<HotelSpecialOffer>({
        url: `${hotelSpecialOffers}/${offerId}`,
        method: 'PUT',
        jsonData: data as Record<string, unknown>,
    });
}

export async function deleteHotelSpecialOffer(specialOfferId: string): Promise<null> {

    return await jsonApiCall<null>({
        url: `${hotelSpecialOffers}/${specialOfferId}`,
        method: 'DELETE',
    });
}

export async function apiDeleteAllHotelSpecialOffers(hotelId: string): Promise<null> {
    return await jsonApiCall<null>({
        url: `${hotels}/${hotelId}/delete_all_special_offers`,
        method: 'PUT',
        jsonData: {},
    });
}

export async function apiFetchSingleHotelOfferById(specialOfferId: string): Promise<HotelSpecialOffer | null> {
    return await jsonApiCall<HotelSpecialOffer>({
        url: `${hotelSpecialOffers}/${specialOfferId}`,
    });
}

export async function apiGetHotelByVariantId(variantId: string): Promise<Hotel | null> {
    return await jsonApiCall<Hotel>({
        method: 'GET',
        url: `${hotels}/get_by_variant?variant_id=${variantId}`,
    });
}

export async function apiFetchAvailableCities(): Promise<City[] | null> {
    return await jsonApiCall<City[]>({
        method: 'GET',
        url: `${available_cities}`,
    });
}
