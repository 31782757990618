
import Vue from "vue";
import GoogleMap, { CustomMapMarkerData } from "@/components/CustomGoogleMap/CustomGoogleMap.vue";
import Button, { ButtonType } from "@/components/Ui/Button/Button.vue";
import HotelListCard from "@/components/HotelListCard/HotelListCard.vue";
import ListPagination from "@/components/ListPagination/ListPagination.vue";
import { RouteName } from "@/router";
import Component from "vue-class-component";
import { hotelsModule } from "@/store/hotels/hotels";
import { Hotel } from "@/types/hotel";
import HotelFilters from "@/views/Hotels/components/HotelFilters.vue";
import InputLabel from "@/components/Ui/InputLabel/InputLabel.vue";
import InputField, { InputFieldType } from "@/components/Ui/InputField/InputField.vue";
import { homeFiltersModule } from "@/store/home_filters/home_filters";
import CustomGoogleMap from "@/components/CustomGoogleMap/CustomGoogleMap.vue";
import HotelSearchForm from "@/components/HotelSearchForm/HotelSearchForm.vue";
import { priceFormatFilter } from "@/utils/filters";
import Spinner from "@/components/Ui/Spinner/Spinner.vue";
import { settingsModule } from "@/store/settings/settings";
import { loginModule } from "@/store/login/login";
import Decimal from "decimal.js";
import { getHotelLowestPrice } from "@/utils/utils";

@Component({
    components: {
        Spinner,
        HotelSearchForm,
        CustomGoogleMap,
        InputField,
        InputLabel,
        HotelFilters,
        ListPagination,
        HotelListCard,
        Button,
        GoogleMap,
    },
})
export default class Hotels extends Vue {
    ButtonType = ButtonType;
    InputType = InputFieldType;

    priceRangeOpened = false;
    filtersOpened = false;

    priceFrom = null;
    priceTo = null;

    openSingleHotel(hotelId: string): void {
        this.$router.push({
            name: RouteName.SingleHotel,
            params: {
                hotelId,
            },
        });
    }

    onChangePage(nextPage: number): void {
        // hotelsModule.fetchHotelsPageAction(nextPage);
        // hotelsModule.fetchSearchHotelPage(nextPage);
        hotelsModule.setCurrentPage(nextPage);
    }

    updateHotelsSearchText(text: string): void {
        homeFiltersModule.setSearchText(text);
    }

    get hotelsSearchText(): string {
        return homeFiltersModule.searchText;
    }

    get async(): boolean {
        return hotelsModule.fetchAsync;
    }

    get hotels(): Array<Hotel> {
        return (hotelsModule.filteredHotels || hotelsModule.hotels).slice(10 * (this.currentPage - 1), 10 * this.currentPage);
    }

    get currentPage(): number {
        return hotelsModule.currentPage;
    }

    get numPages(): number {
        return hotelsModule.totalPages;
    }

    get mapMarkerData(): Array<CustomMapMarkerData> {
        const agencyMarkup = this.agencyMarkup;

        return this.hotels.map(h => {
            let basePrice = getHotelLowestPrice(h, this.adminMarkup, this.agencyMarkup);
            return {
                address: h.address,
                label: basePrice !== 0 ? priceFormatFilter(basePrice) : h.name,
            };
        });
    }

    get adminMarkup() {
        return settingsModule.adminMarkup;
    }

    get agencyMarkup() {
        return loginModule.agencyMarkup;
    }

    onSearch(): void {
        if (!homeFiltersModule.invalid) {
            hotelsModule.searchHotelsAction({
                queryData: {
                    type: homeFiltersModule.type,
                    roomsNumber: homeFiltersModule.roomsNumber,
                    passengersData: homeFiltersModule.passengersData,
                    location: homeFiltersModule.location,
                    dateRange: homeFiltersModule.dateRange,
                    searchText: homeFiltersModule.searchText,
                },
                agencyMarkup: this.agencyMarkup,
                adminMarkup: this.adminMarkup,
            });
        }
    }

    beforeMount(): void {
        if (homeFiltersModule.invalid) {
            this.$router.replace({
                name: RouteName.Home,
            });

            return;
        }
    }

    mounted(): void {
        if (this.hotels.length === 0) {
            this.onSearch();
        }
    }
}
