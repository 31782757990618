
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import Component from "vue-class-component";
import PassengerCard from "./PassengerCard.vue";
import { Passenger } from "@/types/Passenger";

@Component({
    components: { PassengerCard },
})
export default class PassengerList extends Vue {
    @Prop() readonly passengers!: Array<Passenger>;
}
