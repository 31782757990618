
import Component from "vue-class-component";
import Vue from "vue";
import Spinner from "@/components/Ui/Spinner/Spinner.vue";
import ImageSlider from "@/components/Ui/ImageSlider/ImageSlider.vue";
import SingleHotelRoomBeds from "@/views/SingleHotelRoom/components/SingleHotelRoomBeds.vue";
import SingleHotelRoomAmenities from "@/views/SingleHotelRoom/components/SingleHotelRoomAmenities.vue";
import Button from "@/components/Ui/Button/Button.vue";
import { Prop } from "vue-property-decorator";
import SingleHotelRoomImages from "@/views/SingleHotelRoom/components/ContentGallery.vue";
import { AdditionalContent, AdditionalContentUtils } from "@/types/additionalContent";
import ContentGallery from "@/views/SingleHotelRoom/components/ContentGallery.vue";
import { Language } from "@/types/language";

@Component({
    components: {
        ContentGallery,
        SingleHotelRoomImages,
        Button,
        SingleHotelRoomAmenities,
        SingleHotelRoomBeds,
        ImageSlider,
        Spinner
    },
})
export default class AdditionalContentDetailedView extends Vue {
    @Prop({ default: null }) readonly additionalContent!: AdditionalContent
    @Prop({
        default: () => {
            return {}
        }
    }) readonly reservationAgentValues!: Record<string, string>

    parsedAgentValues: Array<{ label: string, value: string }> | null = null;

    mounted(): void {
        if (this.reservationAgentValues) {

            const parsed: Array<{ label: string, value: string }> = [];

            Object.entries(this.reservationAgentValues).forEach(([key, value]) => {

                let label = key.replaceAll('_', ' ');
                label = label.charAt(0).toUpperCase() + label.slice(1);

                parsed.push({
                    label,
                    value
                })
            })

            this.parsedAgentValues = parsed;
        }
    }

    get description(): string {
        return this.$i18n.locale === Language.bs ? this.additionalContent.descriptionBs : this.additionalContent.descriptionEn;
    }

    get contentName(): string {
        return AdditionalContentUtils.resolveName(this.additionalContent, this.$i18n.locale as Language) ?? "";
    }
}
