
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { ListReservation, ReservationUtils } from "@/types/reservation";
import moment from "moment";
import { ReservationStatusEnum } from "@/types/reservationStatus";

@Component
export default class ReservationStatusWidget extends Vue {
    @Prop({ default: () => null }) readonly reservation!: ListReservation;
    @Prop({ default: false }) readonly shortDisplay!: boolean;

    getStatusText(reservation: ListReservation): string {
        let status = "";

        if (this.isProcessing(reservation)) {
            status = "reserved";
        }

        if (this.isValidated(reservation)) {
            status = "confirmed";
        }

        if (this.isAgentPaid(reservation)) {
            status = "agent_paid";
        }

        if (this.isPaid(reservation)) {
            status = "paid";
        }

        return status !== "" ? this.$tc(status) : "";
    }

    getStatusClass(reservation: ListReservation): string {
        if (this.isProcessing(reservation) || this.isValidated(reservation)) {
            return "processing";
        } else if (this.isPaid(reservation)) {
            return "paid";
        } else if (this.isAgentPaid(reservation)) {
            return "agent-paid";
        }

        return "";
    }

    isAgentPaid(reservation: ListReservation): boolean {
        return ReservationUtils.hasStatus(reservation, ReservationStatusEnum.AGENCY_MARKED_PAID);
    }

    isPaid(reservation: ListReservation): boolean {
        return ReservationUtils.hasStatus(reservation, ReservationStatusEnum.ADMIN_CONFIRMED_PAID);
    }

    isValidated(reservation: ListReservation): boolean {
        return !this.isProcessing(reservation);
    }

    isProcessing(reservation: ListReservation): boolean {
        return !ReservationUtils.hasStatus(reservation, ReservationStatusEnum.ADMIN_APPROVED);
    }

    didExpire(reservation: ListReservation): boolean {
        return moment(reservation.validThrough).diff(moment()) < 0;
    }

    getExpirationTime(reservation: ListReservation): string {
        const expireDate = reservation.validThrough;

        const hours = (moment(expireDate).diff(moment(), "hours"));
        const minutes = (moment(expireDate).diff(moment(), "minutes")) % 60;

        return `${hours}h${minutes}m`;
    }
}
