
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import { AddFormItem_GoogleMap } from "@/types/addForm/addFormItem";
import Component from "vue-class-component";
import CustomGoogleMap from "@/components/CustomGoogleMap/CustomGoogleMap.vue";

@Component({
    components: {CustomGoogleMap},
})
export default class AddContentGoogleMap extends Vue {
    @Prop() readonly item!: AddFormItem_GoogleMap;
    @Prop() readonly entity!: Record<string, unknown>;
}
