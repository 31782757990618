
import Vue from "vue";
import Component from "vue-class-component";
import dragula, { Drake } from "dragula";

@Component({})
export default class CustomDnD extends Vue {
    drake?: Drake;

    mounted() {
        this.drake = dragula({
            isContainer: function (el) {
                return el?.id === "drake-container"
            }
        });
    }
}
