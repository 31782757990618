
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { ListAdditionalContent } from "@/types/additionalContent";
import { storage_url } from "@/api/apiCall";

@Component
export default class AdditionalContentItemListCard extends Vue {
    @Prop() readonly additionalContentItem!: ListAdditionalContent;

    get additionalContentImage(): string | null {
        if (this.additionalContentItem.mediaObjects && this.additionalContentItem.mediaObjects.length > 0) {
            const thumbnail = this.additionalContentItem.mediaObjects[0]?.thumbnail;

            return thumbnail ? storage_url + thumbnail.thumbnailUrl : null;
        }

        return null;
    }
}
