
import Component from "vue-class-component";
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import { AddFormItem_Datetime } from "@/types/addForm/addFormItem";
import InputLabel from "@/components/Ui/InputLabel/InputLabel.vue";
import InputField from "@/components/Ui/InputField/InputField.vue";
import { snakeCase } from "snake-case";
import latinize from "latinize";

@Component({
    components: { InputField, InputLabel }
})
export default class AddContentDatetime extends Vue {
    @Prop() readonly item!: AddFormItem_Datetime;
    @Prop() readonly entity!: Record<string, unknown>;

    get accessor(): string {
        return snakeCase(latinize(this.item.name));
    }

    get modelConfig(): unknown {
        return {
            type: 'string',
            mask: 'YYYY-MM-DD HH:mm', // Uses 'iso' if missing
        }
    }
}
