import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "@/views/Home/Home.vue";
import Hotels from "@/views/Hotels/Hotels.vue";
import Login from "@/views/Login/Login.vue";
import SingleHotel from "@/views/SingleHotel/SingleHotel.vue";
import SingleHotelRoom from "@/views/SingleHotelRoom/SingleHotelRoom.vue";
import ManageAgency from "@/views/ManageAgency/ManageAgency.vue";
import Reservations from "@/views/Reservations/Reservations.vue";
import AddNew from "@/views/AddNew/AddNew.vue";
import AddNewRoom from "@/views/AddNew/Room/AddNewRoom.vue";
import AddNewHotel from "@/views/AddNew/Hotel/AddNewHotel.vue";
import Dashboard from "@/views/Dashboard/Dashboard.vue";
import Agencies from "@/views/Agencies/Agencies.vue";
import AddNewAdditionalContentCategory
    from "@/views/AddNew/AddNewAdditionalContentCategory/AddNewAdditionalContentCategory.vue";
import Content from "@/views/Content/Content.vue";
import AddNewAdditionalContent from "@/views/AddNew/AddNewAdditionalContent/AddNewAdditionalContent.vue";
import AdditionalContentTable from "@/views/AdditionalContent/AdditionalContent.vue";
import HotelsTable from "@/views/HotelsTable/HotelsTable.vue";
import EditHotel from "@/views/EditHotel/EditHotel.vue";
import EditRoom from "@/views/EditRoom/EditRoom.vue";
import EditAdditionalContent from "@/views/EditAdditionalContent/EditAdditionalContent.vue";
import AdditionalContentCategories from "@/views/AdditionalContentCategories/AdditionalContentCategories.vue";
import EditAdditionalContentCategory from "@/views/EditAdditionalContentCategory/EditAdditionalContentCategory.vue";
import Calendar from "@/views/Calendar/Calendar.vue";
import BedsTable from "@/views/BedsTable/BedsTable.vue";
import EditBed from "@/views/EditBed/EditBed.vue";
import Bugling from "@/views/Bugling/Bugling.vue";
import SingleAdditionalContentList from "@/views/SingleAdditionalContentList/SingleAdditionalContentList.vue";
import ManageReservation from "@/views/ManageReservation/ManageReservation.vue";
import ConfirmReservation from "@/views/ManageReservation/components/ConfirmReservation.vue";
import FinishReservation from "@/views/ManageReservation/components/FinishReservation.vue";
import ReservationComplete from "@/views/ManageReservation/components/ReservationComplete.vue";
import ManageUser from "@/views/ManageUser/ManageUser.vue";
import Settings from "@/views/Settings/Settings.vue";
import Users from "@/views/Users/Users.vue";
import Offers from "@/views/Offers/Offers.vue";
import HotelOffers from "@/views/HotelOffers/HotelOffersTable.vue";
import ManageHotelSpecialOffer from "@/views/ManageHotelSpecialOffer/ManageHotelSpecialOffer.vue";
import Reports from "@/views/Reports/Reports.vue";
import AgenciesReservations from "@/views/Reservations/components/AgenciesReservations.vue";
import ManualReservations from "@/views/Reservations/components/ManualReservations/ManualReservations.vue";
import ManageManualReservation
    from "@/views/Reservations/components/ManualReservations/ManageManualReservation/ManageManualReservation.vue";
import Payments from "@/views/Payments/Payments.vue";

Vue.use(VueRouter);

export enum RouteLink {
    Home = "/",
    Login = "/login",
    BUG = "/bugling",
    Hotels = "/hotels",
    Dashboard = "/admin/dashboad",
    Agencies = "/admin/agencies",
    Users = "/admin/users",
    Content = "/admin/content",
    HotelContent = "/admin/content/hotels",
    AdditionalContent = "/admin/content/additional",
    AdditionalContentCategories = "/admin/content/additional/categories",
    BedContent = "/admin/content/beds",
    Calendar = "/admin/calendar",
    ManageManualReservation = "/reservation/manual/manage",
    ManualReservations = "/reservations/manual",
    AgenciesReservations = "/reservations/agency",
    Reservations = "/reservations",
    SingleHotel = "/hotel/:hotelId",
    SingleHotelRoom = "/hotel/:hotelId/room/:roomName",

    SingleAdditionalContentList = "/list/:contentId",

    Add = "/admin/add",
    AddAdditionalContent = "/admin/add/additional_content/:categoryId",
    AddAdditionalContentCategory = "/admin/add/additional_content_category",
    AddHotel = "/admin/add/hotel",
    AddRoom = "/admin/add/room",

    ManageAgency = "/admin/agencies/manage",
    ManageUser = "/admin/users/manage",
    AgencyUsers = "/admin/agencies/:agencyId/users",
    AgencyDiscounts = "/admin/agencies/:agencyId/discounts",
    ManageAdditionalContent = "/admin/content/manage",

    UserSettings = "/admin/settings/user",
    GeneralSettings = "/admin/settings/general",
    GeneralSettingsAgent = "/admin/settings/agent_general",
    EditHotel = "/admin/content/hotels/edit/:hotelId",
    EditRoom = "/admin/content/rooms/edit/:roomId",
    EditAdditionalContent = "/admin/content/additional/edit/:contentId",
    EditAdditionalContentCategory = "/admin/content/additional/categories/edit/:categoryId",
    EditBed = "/admin/content/beds/edit/:bedId",
    SingleHotelAdmin = "/admin/content/hotels/preview/:hotelId",

    ManageReservation = "/reservations/manage",
    FinishReservation = "/reservations/finish",
    ConfirmReservation = "/reservations/confirm",
    ReservationComplete = "/reservations/complete/:reservationId",

    Offers = "/offers",

    HotelSpecialOffers = "/admin/content/hotel-offers",
    AddHotelSpecialOffer = "/admin/content/special-offers/add",
    EditHotelSpecialOffer = "/admin/content/hotel/:hotelId/special-offers/edit/:specialOfferId",

    Reports = "/admin/reports",
    Payments = "/admin/payments",
}

export enum RouteName {
    Home = "Home",
    Login = "Login",
    BUG = "BUG",
    Hotels = "Hotels",
    Dashboard = "Dashboard",
    Content = "Content",
    Agencies = "Agencies",
    Users = "Users",
    Calendar = "Calendar",
    Reservations = "Reservations",
    ManageManualReservation = "ManageManualReservation",
    AgenciesReservations = "AgenciesReservations",
    ManualReservations = "ManualReservations",
    SingleHotel = "SingleHotel",
    SingleHotelRoom = "SingleHotelRoom",

    SingleAdditionalContentList = "SingleAdditionalContentList",

    Add = "Add",
    AddAdditionalContent = "AddAdditionalContent",
    AddAdditionalContentCategory = "AddAdditionalContentCategory",
    AddHotel = "AddHotel",
    AddRoom = "AddRoom",
    ManageAgency = "ManageAgency",
    ManageUser = "ManageUser",
    AgencyUsers = "AgencyUsers",
    AgencyDiscounts = "AgencyDiscounts",
    ManageAdditionalContent = "ManageAdditionalContent",

    UserSettings = "UserSettings",
    GeneralSettings = "GeneralSettings",
    GeneralSettingsAgent = "GeneralSettingsAgent",
    EditHotel = "EditHotel",
    EditRoom = "EditRoom",
    EditAdditionalContent = "EditAdditionalContent",
    EditAdditionalContentCategory = "EditAdditionalContentCategory",
    EditBed = "EditBed",
    SingleHotelAdmin = "SingleHotelAdmin",

    HotelContent = "HotelContent",
    AdditionalContent = "AdditionalContent",
    AdditionalContentCategories = "AdditionalContentCategories",
    BedContent = "BedContent",
    ManageReservation = "ManageReservation",
    FinishReservation = "FinishReservation",
    ConfirmReservation = "ConfirmReservation",
    ReservationComplete = "ReservationComplete",

    Offers = "Offers",
    HotelSpecialOffers = "HotelSpecialOffers",
    EditHotelSpecialOffer = "EditHotelSpecialOffer",
    AddHotelSpecialOffer = "AddHotelSpecialOffer",

    Reports = "Reports",
    Payments = "Payments",
}


const routes: Array<RouteConfig> = [
    {
        path: RouteLink.Dashboard,
        name: RouteName.Dashboard,
        component: Dashboard,
    },
    {
        path: RouteLink.Home,
        name: RouteName.Home,
        component: Home,
    },
    {
        path: RouteLink.Login,
        name: RouteName.Login,
        component: Login,
    },
    {
        path: RouteLink.Hotels,
        name: RouteName.Hotels,
        component: Hotels,
    },
    {
        path: RouteLink.SingleHotel,
        name: RouteName.SingleHotel,
        component: SingleHotel,
    },
    {
        path: RouteLink.SingleHotelRoom,
        name: RouteName.SingleHotelRoom,
        component: SingleHotelRoom,
    },
    {
        path: RouteLink.Add,
        name: RouteName.Add,
        component: AddNew,
    },
    {
        path: RouteLink.AddRoom,
        name: RouteName.AddRoom,
        component: AddNewRoom,
    },
    {
        path: RouteLink.AddAdditionalContent,
        name: RouteName.AddAdditionalContent,
        component: AddNewAdditionalContent,
    },
    {
        path: RouteLink.SingleAdditionalContentList,
        name: RouteName.SingleAdditionalContentList,
        component: SingleAdditionalContentList,
    },
    {
        path: RouteLink.AddAdditionalContentCategory,
        name: RouteName.AddAdditionalContentCategory,
        component: AddNewAdditionalContentCategory,
    },
    {
        path: RouteLink.AddHotel,
        name: RouteName.AddHotel,
        component: AddNewHotel,
    },
    {
        path: RouteLink.Agencies,
        name: RouteName.Agencies,
        component: Agencies,
    },
    {
        path: RouteLink.ManageAgency + "/:agencyId?",
        name: RouteName.ManageAgency,
        component: ManageAgency,
    },
    {
        path: RouteLink.ManageUser + "/:userId?",
        name: RouteName.ManageUser,
        component: ManageUser,
    },
    {
        path: RouteLink.AgencyUsers,
        name: RouteName.AgencyUsers,
        component: ManageAgency,
    },
    {
        path: RouteLink.AgencyDiscounts,
        name: RouteName.AgencyDiscounts,
        component: ManageAgency,
    },
    {
        path: RouteLink.Reservations,
        name: RouteName.Reservations,
        component: Reservations,
        redirect: RouteLink.AgenciesReservations,
        children: [{
            path: RouteLink.AgenciesReservations,
            name: RouteName.AgenciesReservations,
            components: {
                reservationsRouter: AgenciesReservations,
            },
        }, {
            path: RouteLink.ManualReservations,
            name: RouteName.ManualReservations,
            components: {
                reservationsRouter: ManualReservations,
            },
        }],
    },
    {
        path: RouteLink.Content,
        name: RouteName.Content,
        component: Content,
        redirect: RouteLink.HotelContent,
        children: [{
            path: RouteLink.HotelContent,
            name: RouteName.HotelContent,
            components: {
                contentRouter: HotelsTable,
            },
        }, {
            path: RouteLink.AdditionalContent,
            name: RouteName.AdditionalContent,
            components: {
                contentRouter: AdditionalContentTable,
            },
        }, {
            path: RouteLink.AdditionalContentCategories,
            name: RouteName.AdditionalContentCategories,
            components: {
                contentRouter: AdditionalContentCategories,
            },
        }, {
            path: RouteLink.BedContent,
            name: RouteName.BedContent,
            components: {
                contentRouter: BedsTable,
            },
        }, {
            path: RouteLink.HotelSpecialOffers,
            name: RouteName.HotelSpecialOffers,
            components: {
                contentRouter: HotelOffers,
            },
        }],
    },
    {
        path: RouteLink.UserSettings,
        name: RouteName.UserSettings,
        component: Settings,
    },
    {
        path: RouteLink.GeneralSettings,
        name: RouteName.GeneralSettings,
        component: Settings,
    },
    {
        path: RouteLink.GeneralSettingsAgent,
        name: RouteName.GeneralSettingsAgent,
        component: Settings,
    },
    {
        path: RouteLink.EditHotel,
        name: RouteName.EditHotel,
        component: EditHotel,
    },
    {
        path: RouteLink.EditRoom,
        name: RouteName.EditRoom,
        component: EditRoom,
    },
    {
        path: RouteLink.EditAdditionalContent,
        name: RouteName.EditAdditionalContent,
        component: EditAdditionalContent,
    },
    {
        path: RouteLink.EditAdditionalContentCategory,
        name: RouteName.EditAdditionalContentCategory,
        component: EditAdditionalContentCategory,
    },
    {
        path: RouteLink.Calendar,
        name: RouteName.Calendar,
        component: Calendar,
    },
    {
        path: RouteLink.EditBed,
        name: RouteName.EditBed,
        component: EditBed,
    },
    {
        path: RouteLink.SingleHotelAdmin,
        name: RouteName.SingleHotelAdmin,
        component: SingleHotel,
    }, {
        path: RouteLink.ManageReservation,
        name: RouteName.ManageReservation,
        component: ManageReservation,
    }, {
        path: RouteLink.FinishReservation,
        name: RouteName.FinishReservation,
        component: FinishReservation,
    }, {
        path: RouteLink.ConfirmReservation,
        name: RouteName.ConfirmReservation,
        component: ConfirmReservation,
    }, {
        path: RouteLink.ReservationComplete,
        name: RouteName.ReservationComplete,
        component: ReservationComplete,
    }, {
        path: RouteLink.Users,
        name: RouteName.Users,
        component: Users,
    },
    {
        path: RouteLink.BUG,
        name: RouteName.BUG,
        component: Bugling,
    }, {
        path: RouteLink.Offers,
        name: RouteName.Offers,
        component: Offers,
    }, {
        path: RouteLink.EditHotelSpecialOffer,
        name: RouteName.EditHotelSpecialOffer,
        component: ManageHotelSpecialOffer,
    }, {
        path: RouteLink.AddHotelSpecialOffer,
        name: RouteName.AddHotelSpecialOffer,
        component: ManageHotelSpecialOffer,
    }, {
        path: RouteLink.Reports,
        name: RouteName.Reports,
        component: Reports,
    }, {
        path: RouteLink.Payments,
        name: RouteName.Payments,
        component: Payments,
    }, {
        path: RouteLink.ManageManualReservation,
        name: RouteName.ManageManualReservation,
        component: ManageManualReservation,
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;
