
import Vue from "vue";
import { IHeaderProps } from "vue-simple-calendar";
import { Emit, Prop } from "vue-property-decorator";
import Component from "vue-class-component";
import Button, { ButtonType } from "@/components/Ui/Button/Button.vue";
import { hotelsModule } from "@/store/hotels/hotels";
import InputLabel from "@/components/Ui/InputLabel/InputLabel.vue";
import SelectField, { SelectFieldOption } from "@/components/Ui/SelectField/SelectField.vue";
import { ListRoom } from "@/types/room";
import Spinner from "@/components/Ui/Spinner/Spinner.vue";
import { Hotel } from "@/types/hotel";
import { cloneDeep } from "lodash";

@Component({
    components: { Spinner, SelectField, InputLabel, Button },
})
export default class CalendarHeader extends Vue {
    @Prop() readonly headerProps!: IHeaderProps;
    @Prop() readonly selectedRoom!: string | null;
    @Prop() readonly selectedHotel!: string | null;
    @Prop({ default: [] }) readonly rooms!: Array<ListRoom>;

    ButtonType = ButtonType;

    @Emit("changeSelectedHotel")
    onSelectedHotelChange(value: string): string {
        return value;
    }

    @Emit("changeSelectedRoom")
    onSelectedRoomChange(value: string): string {
        return value;
    }

    get roomsOptions(): Array<SelectFieldOption> {
        return cloneDeep(this.rooms).map((room: ListRoom): SelectFieldOption => ({
            label: room.name,
            value: room.id,
            topDash: false,
        }));
    }

    get hotelsOptions(): Array<SelectFieldOption> {
        return cloneDeep(hotelsModule.hotels).map((hotel: Hotel): SelectFieldOption => ({
            label: hotel.name,
            value: hotel.id,
            topDash: false,
        }));
    }

    get hotelsAsync(): boolean {
        return hotelsModule.fetchAsync;
    }

    get roomsAsync(): boolean {
        return hotelsModule.fetchAsync;
    }

    beforeMount(): void {
        hotelsModule.fetchAllHotels();
    }
}
