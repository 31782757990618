
import {RoomServiceType} from "@/types/room";
import Vue from "vue";
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";
import {ReservationFormSelectedRoomVariant} from "@/store/reservation_form/reservation_form";
import {
  AvailabilityGroups,
  CalculatedRoomVariantPrice,
  getAvailabilityGroupsForRoomAvailabilities,
  pricingDebugLog,
} from "@/utils/price_utils";
import {RoomVariant} from "@/types/roomVariant";
import {RoomAvailability} from "@/types/availability";

@Component
export default class ReceiptRoom extends Vue {
    @Prop() readonly room!: { name: string, availabilities: RoomAvailability[] };
    @Prop() readonly isNewReservation!: boolean;
    @Prop() readonly variants!: Record<string, ReservationFormSelectedRoomVariant>;
    @Prop() readonly agencyMarkup!: number;

    priceForVariant(numPassengersForThisVariant: number, qOfThisVariant: number, variant: RoomVariant, serviceType: RoomServiceType): CalculatedRoomVariantPrice[] {
        pricingDebugLog(() => {
            console.group("Calculate price for room variant", this.room.name);
            console.log("Variant", variant);
            console.log("qOfThisVariant", qOfThisVariant);
            console.log("serviceType", serviceType);
        });
        let price = this.availabilityGroups.calculatePriceForRoomVariant(numPassengersForThisVariant, qOfThisVariant, variant, serviceType, this.agencyMarkup);
        pricingDebugLog(() => {
            console.log("Price for room variant is", price);
            console.groupEnd();
        });
        return price;
    }

    get availabilityGroups(): AvailabilityGroups {
        return getAvailabilityGroupsForRoomAvailabilities(this.room.availabilities);
    }
}
