
import Vue from "vue";
import Component from "vue-class-component";
import ActiveReservations from "@/views/Dashboard/components/ActiveReservations.vue";
import TopContent from "@/views/Dashboard/components/TopContent.vue";
import ReservationsChart from "@/views/Dashboard/components/ReservationsChart.vue";
import Button from "@/components/Ui/Button/Button.vue";
import { endpointNoApi } from "@/api/apiCall";

@Component({
    components: { Button, ReservationsChart, TopContent, ActiveReservations }
})
export default class Dashboard extends Vue {
}

