
import Vue from "vue";
import Component from "vue-class-component";
import Button, { ButtonType } from "@/components/Ui/Button/Button.vue";
import { loginModule } from "@/store/login/login";
import { notificationsModule } from "@/store/notifications/notifications";
import { Notification } from "@/types/notification";
import SingleNotification from "@/components/Header/components/NotificationsDropdown/SingleNotification.vue";
import ClickOutside from "vue-click-outside";
import DropdownMenu from "@/components/Ui/DropdownMenu/DropdownMenu.vue";
import DropdownMenuItem from "@/components/Ui/DropdownMenu/DropdownMenuItem.vue";
import SelectField, { SelectFieldOption } from "@/components/Ui/SelectField/SelectField.vue";
import { ListAgency } from "@/types/agency";
import { agenciesModule } from "@/store/agencies/agencies";
import { ListUser } from "@/types/user";
import { usersModule } from "@/store/users/users";
import InputField from "@/components/Ui/InputField/InputField.vue";
import InputLabel from "@/components/Ui/InputLabel/InputLabel.vue";
import { mergeName } from "@/utils/utils";
import { Watch } from "vue-property-decorator";

import TextareaField from "@/components/Ui/TextareaField/TextareaField.vue";
import { deleteNotification, markNotificationAsRead } from "@/api/notifications/notifications";
import Spinner from "@/components/Ui/Spinner/Spinner.vue";

enum NotificationRecipientType {
    ALL = "ALL",
    AGENCIES = "AGENCIES",
    USERS = "USERS",
}

@Component({
    components: {
        TextareaField,
        InputLabel,
        InputField,
        Spinner,
        SelectField,
        DropdownMenuItem,
        DropdownMenu,
        Button,
        SingleNotification,
    },
    directives: {
        ClickOutside,
    },
})
export default class NotificationsDropdown extends Vue {
    open = false;

    markAllAsync = false;
    deleteAllAsync = false;

    recipientType: NotificationRecipientType = NotificationRecipientType.ALL;
    NotificationRecipientType = NotificationRecipientType;
    ButtonType = ButtonType;
    isCreateNotificationActive = false;

    notificationRecipientAgencies: string[] = [];
    notificationRecipientUsers: string[] = [];
    notificationText = "";

    isNotificationSendSuccess: boolean | null = null;

    @Watch("recipientType")
    onRecipientTypeChange(): void {
        this.notificationRecipientUsers = [];
        this.notificationRecipientAgencies = [];
    }

    get numUnreadNotifications(): number {
        return this.notifications.filter(notification => notification.readAt === null).length;
    }

    recipientTypeOptions: SelectFieldOption[] = [{
        label: "Svi",
        value: NotificationRecipientType.ALL,
        topDash: false,
    }, {
        label: "Agencije",
        value: NotificationRecipientType.AGENCIES,
        topDash: false,
    }, {
        label: "Korisnici",
        value: NotificationRecipientType.USERS,
        topDash: false,
    }];

    get async(): boolean {
        return notificationsModule.fetchAsync;
    }

    get shouldShowMoreDropdown(): boolean {
        return this.numUnreadNotifications > 0 || this.notifications.length > 0;
    }

    get hasMore(): boolean {
        return notificationsModule.hasMore;
    }

    get allAgencies(): ListAgency[] {
        return agenciesModule.agencies;
    }

    get allUsers(): ListUser[] {
        return usersModule.users;
    }

    get usersOptions(): SelectFieldOption[] {
        return this.allUsers.map(user => ({ value: user.id, label: mergeName(user), topDash: false }));
    }

    get agenciesOptions(): SelectFieldOption[] {
        return this.allAgencies.map(agency => ({ value: agency.id, label: agency.name, topDash: false }));
    }

    mounted(): void {
        (this as any).popupItem = this.$el;
        notificationsModule.fetchNotificationsFirstPage();
    }

    closeCreateNotification(): void {
        this.isCreateNotificationActive = false;
        this.isNotificationSendSuccess = null;
    }

    get sendNotificationAsync(): boolean {
        return notificationsModule.addEditAsync;
    }

    get sendNotificationButtonDisabled(): boolean {
        return this.notificationText.length === 0
            || (this.recipientType === NotificationRecipientType.AGENCIES && !this.notificationRecipientAgencies.length)
            || (this.recipientType === NotificationRecipientType.USERS && !this.notificationRecipientUsers.length);
    }

    async sendNotification(): Promise<void> {
        let ret = false;

        if (this.recipientType === NotificationRecipientType.AGENCIES) {
            ret = await notificationsModule.createNotificationForAgencies({
                data: { content: this.notificationText },
                agenciesIds: this.notificationRecipientAgencies,
            });
        } else if (this.recipientType === NotificationRecipientType.USERS) {
            ret = await notificationsModule.createNotificationForUsers({
                data: { content: this.notificationText },
                usersIds: this.notificationRecipientUsers,
            });
        } else {
            ret = await notificationsModule.createNotificationGlobal({ content: this.notificationText });
        }

        this.isNotificationSendSuccess = ret;
    }

    markAllRead(): void {

        const promises: Array<Promise<Notification | null>> = [];

        this.markAllAsync = true;

        notificationsModule.notifications.forEach(notification => {
            if (notification.readAt === null) {
                promises.push(markNotificationAsRead(notification.id).then(r => {
                    if (r !== null) {
                        notificationsModule.updateNotification({ notificationId: r.id, data: r });
                    }

                    return r;
                }));
            }
        });

        Promise.allSettled(promises).then(() => this.markAllAsync = false);
    }

    deleteAll(): void {
        const promises: Array<Promise<void>> = [];

        this.deleteAllAsync = true;

        notificationsModule.notifications.forEach(notification => {
            promises.push(deleteNotification(notification.id).then(() => notificationsModule.removeNotification(notification.id)));
        });

        Promise.allSettled(promises).then(() => this.deleteAllAsync = false);
    }

    close(): void {
        this.isCreateNotificationActive = false;
        this.isNotificationSendSuccess = null;
        this.open = false;
    }

    onClickOutside(): void {
        if (this.open && !this.isCreateNotificationActive) {
            this.close();
        }
    }

    loadMoreNotifications(): void {
        notificationsModule.fetchNotificationsPageAction(notificationsModule.currentPage + 1);
    }

    get notifications(): Notification[] {
        return notificationsModule.notifications;
    }

    get isSuperAdmin(): boolean {
        return loginModule.isSuperAdmin;
    }

    get createNotificationAsync(): boolean {
        return agenciesModule.fetchAsync || usersModule.fetchAsync;
    }

    createNotification(): void {
        this.isCreateNotificationActive = true;

        this.notificationRecipientAgencies = [];
        this.notificationRecipientUsers = [];
        this.recipientType = NotificationRecipientType.ALL;
        this.notificationText = "";

        agenciesModule.fetchAllAgencies();
        usersModule.fetchAllUsers();
    }
}
