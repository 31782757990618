import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { store } from "@/store";
import { User, UserForm, ListUser } from "@/types/user";
import { addUser, deleteUser, editUser, fetchUsersPage, fetchUserById, fetchAllUsers } from "@/api/users/users";
import { cloneDeep, merge } from "lodash";
import { SelectFieldOption } from "@/components/Ui/SelectField/SelectField.vue";
import { UserRoles, UserRoleText } from "@/utils/consts";
import { changeUserPassword } from "@/api/login/login";

@Module({ name: "UsersModule", store: store, dynamic: true, namespaced: true })
export default class UsersModule extends VuexModule {
    fetchAsync = false;
    users: Array<ListUser> = [];
    hasMore = true;
    currentPage = 0;
    totalPages = 1;
    perPage = 10;
    selectedUser: User | null = null;
    addEditAsync = false;

    search = "";

    @Mutation
    setUsersFetchAsync(async: boolean): void {
        this.fetchAsync = async;
    }

    @Mutation
    setCurrentPage(page: number): void {
        this.currentPage = page;
    }

    @Mutation
    setHasMore(hasMore: boolean): void {
        this.hasMore = hasMore;
    }

    @Mutation
    setPerPage(perPage: number): void {
        this.perPage = perPage;
    }

    @Mutation
    setTotalPages(totalPages: number): void {
        this.totalPages = totalPages;
    }

    @Mutation
    removeUser(userId: string): void {
        this.users = cloneDeep(this.users).filter((a) => a.id !== userId);
    }

    @Mutation
    setUsers(users: Array<ListUser>): void {
        this.users = users;
    }

    @Mutation
    selectUser(user: User | null): void {
        this.selectedUser = user;
    }

    @Mutation
    setAddEditAsync(async: boolean): void {
        this.addEditAsync = async;
    }

    @Mutation
    updateUserById({
                       userId,
                       data,
                   }: { userId: string, data: Partial<User> }): void {
        const index = this.users.findIndex(u => u.id === userId);

        if (index !== -1) {
            this.users[index] = merge(this.users[index], data);
            this.users = [...this.users];
        }

        // if (userId === this.selecteduser?.id) {
        //     this.selecteduser = merge(this.selecteduser, data);
        // }
    }

    @Mutation
    setSearchText(search: string): void {
        this.search = search;
    }

    @Action
    async fetchAllUsers(): Promise<Array<User> | null> {
        this.setUsersFetchAsync(true);

        try {
            const r = await fetchAllUsers();

            if (r !== null) {
                this.setUsers(r);
            }

            return r;
        } catch (e) {
            console.error(e);
        } finally {
            this.setUsersFetchAsync(false);
        }

        return null;
    }

    @Action
    async fetchUsersFirstPage(): Promise<void> {
        return this.fetchUsersPageAction(1);
    }

    @Action
    async fetchUsersPageAction(page: number): Promise<void> {
        this.setUsersFetchAsync(true);

        try {
            const r = await fetchUsersPage(page, this.perPage, this.search);

            if (r !== null) {
                this.setUsers(r.data);
                this.setTotalPages(Math.ceil(r.totalItems / this.perPage));
                this.setCurrentPage(page);
                this.setHasMore(r.hasMore);
            }
        } catch (e) {
            console.error(e);
        } finally {
            this.setUsersFetchAsync(false);
        }
    }

    @Action
    async addUserAction(data: UserForm): Promise<boolean> {
        this.setAddEditAsync(true);

        try {
            const newUser = await addUser(data);

            if (newUser !== null) {
                // this.appendUsers([newUser])
            }

            return newUser !== null;
        } catch (e) {
            return false;
        } finally {
            this.setAddEditAsync(false);
        }
    }

    @Action
    async editUserByIdAction({ userId, data }: { userId: string, data: UserForm }): Promise<void> {

        this.setAddEditAsync(true);

        try {
            const updatedUser = await editUser(userId, data);

            if (updatedUser !== null) {
                this.updateUserById({ userId, data: updatedUser });
                this.selectUser(updatedUser);
            }
        } catch (e) {
            console.error(e);
        } finally {
            this.setAddEditAsync(false);
        }
    }

    @Action
    async changeUserPasswordByIdAction({ userId, password }: { userId: string, password: string }): Promise<void> {
        this.setAddEditAsync(true);

        try {
            const updatedUser = await changeUserPassword(userId, password);
            this.updateUserById({ userId, data: updatedUser });
        } finally {
            this.setAddEditAsync(false);
        }
    }

    @Action
    async fetchUserByIdAction(userId: string): Promise<void> {
        this.setUsersFetchAsync(true);

        try {
            const user = await fetchUserById(userId);

            if (user !== null) {
                this.selectUser(user);
            }
        } catch (e) {
            console.error(e);
        } finally {
            this.setUsersFetchAsync(false);
        }
    }

    @Action
    async deleteUser(userId: string): Promise<void> {
        this.setAddEditAsync(true);

        try {
            const r = await deleteUser(userId);

            if (r !== null) {
                this.removeUser(userId);
            }
        } catch (e) {
            console.error(e);
        } finally {
            this.setAddEditAsync(false);
        }
    }

    get roleOptions(): Array<SelectFieldOption> {
        return Object.keys(UserRoles).map((value) => ({
            value: value,
            label: UserRoleText[value as UserRoles],
            topDash: false,
        }));
    }
}

export const usersModule = getModule(UsersModule);
