
import Vue from 'vue';
import InputLabel from '@/components/Ui/InputLabel/InputLabel.vue';
import InputField from '@/components/Ui/InputField/InputField.vue';
import ClickOutside from 'vue-click-outside';
import PassengerNumberCategory from '@/views/Home/PassengerNumberWrapper/PassengerNumberCategory.vue';
import Component from 'vue-class-component';
import { homeFiltersModule, PassengersNumbers } from '@/store/home_filters/home_filters';

@Component({
    components: { PassengerNumberCategory, InputField, InputLabel },
    directives: { ClickOutside },
})
export default class PassengerNumberWrapper extends Vue {
    dropdownOpen = false;

    get passengersData(): PassengersNumbers {
        return homeFiltersModule.passengersData;
    }

    get roomsNumber(): number {
        return homeFiltersModule.roomsNumber;
    }

    updateChildrenUnder6Number(num: number): void {
        homeFiltersModule.updatePassengersData({ childrenUnder6: num });
    }

    updateChildrenNumber(num: number): void {
        homeFiltersModule.updatePassengersData({ children: num });
    }

    updateAdultsNumber(num: number): void {
        homeFiltersModule.updatePassengersData({ adults: num });
    }

    updateRoomsNumber(num: number): void {
        homeFiltersModule.setRoomsNumber(num);
    }

    popupItem: unknown;

    hideDropdown(): void {
        this.dropdownOpen = false;
    }

    get totalPassengers(): number {
        return this.passengersData.childrenUnder6 + this.passengersData.children + this.passengersData.adults;
    }

    mounted(): void {
        this.popupItem = this.$el;
    }
}
