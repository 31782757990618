
import Vue from "vue";
import Component from "vue-class-component";
import InputLabel from "../../../components/Ui/InputLabel/InputLabel.vue";
import InputField from "../../../components/Ui/InputField/InputField.vue";
import { Prop } from "vue-property-decorator";
import { AdditionalContentForm } from "@/types/additionalContent";
import TextareaField from "@/components/Ui/TextareaField/TextareaField.vue";
import { Language } from "@/types/language";
import { LANGUAGES } from "@/utils/consts";

@Component({
    components: { TextareaField, InputField, InputLabel }
})
export default class AboutContentForm extends Vue {
    @Prop() contentForm!: AdditionalContentForm;

    languages = LANGUAGES;
    activeLang = Language.bs;

    get isBsActive(): boolean {
        return this.activeLang === Language.bs;
    }

    get description(): string {
        return (this.isBsActive ? this.contentForm.descriptionBs : this.contentForm.descriptionEn) ?? "";
    }

    set description(val: string) {
        if (this.isBsActive) {
            this.contentForm.descriptionBs = val;
        } else {
            this.contentForm.descriptionEn = val;
        }
    }

    get name(): string {
        return (this.isBsActive ? this.contentForm.nameBs : this.contentForm.nameEn) ?? "";
    }

    set name(val: string) {
        if (this.isBsActive) {
            this.contentForm.nameBs = val;
        } else {
            this.contentForm.nameEn = val;
        }
    }
}
