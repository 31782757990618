import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { User, UserForm } from "@/types/user";
import { store } from "@/store";
import { StorageKeys, UserRoles, UserRolesAccessLevels } from "@/utils/consts";
import { getAccessLevel, showSwalToast } from "@/utils/utils";
import { LoginErrors } from "@/types/loginErrors";
import router, { RouteLink, RouteName } from "@/router";
import { changeUserPassword, doLogin, doMe, editUser } from "@/api/login/login";
import { Agency } from "@/types/agency";
import Vue from "vue";
import { settingsModule } from "@/store/settings/settings";
import { hotelsModule } from "@/store/hotels/hotels";

@Module({ name: "LoginModule", store: store, dynamic: true })
export default class LoginModule extends VuexModule {
    user: User | null = null;
    loginAsync = false;
    userAsync = false;
    loginErrors: LoginErrors = {};
    signupErrors: Array<string> = [];

    @Mutation
    setLoginAsync(async: boolean): void {
        this.loginAsync = async;
    }

    @Mutation
    setUserAsync(async: boolean): void {
        this.userAsync = async;
    }

    @Mutation
    setSignupErrors(errors: Array<string>): void {
        this.signupErrors = errors;
    }

    @Mutation
    setLoginErrors(errors: LoginErrors): void {
        this.loginErrors = errors;
    }

    @Mutation
    setUser(user: User): void {
        this.user = user;
    }

    @Mutation
    clearState(): void {
        this.user = null;
        this.loginAsync = false;
        this.userAsync = false;
        this.signupErrors = [];
        this.loginErrors = {};
    }

    @Mutation
    updateUser(user: User): void {
        Object.assign(this.user, user);
    }

    @Mutation
    updateUserAgency(agency: Agency): void {
        if (this.user !== null) {
            Vue.set(this.user, "agency", agency);
        }
    }

    get userAccessLevel(): UserRolesAccessLevels {
        if (!this.user) return UserRolesAccessLevels.ROLE_UNKNOWN;

        return getAccessLevel(this.user);
    }

    get isAgent(): boolean {
        return this.user?.roles.includes(UserRoles.ROLE_AGENCY_ADMIN) || false;
    }

    get isSuperAdmin(): boolean {
        return this.user?.roles.includes(UserRoles.ROLE_SUPER_ADMIN) || false;
    }

    get isLoggedIn(): boolean {
        return this.user !== undefined && this.user !== null;
    }

    @Action
    async doMeAction(): Promise<void> {
        this.setLoginAsync(true);

        try {
            const me = await doMe();

            this.setLoginErrors({});

            if (me !== null) {
                this.setUser(me);

                const isSuperAdmin = me.roles.includes(UserRoles.ROLE_SUPER_ADMIN);

                if (!isSuperAdmin) {
                    // router.replace({
                    //     name: RouteName.Home,
                    // }).catch(() => {/**/
                    // });
                }
            } else {
                this.setLoginErrors({ generic: "Could not log in" });
            }
        } catch (ex) {
            if (typeof ex === "string") {
                this.setLoginErrors({
                    generic: ex,
                });
            } else {
                this.setLoginErrors({
                    generic: "Internal Error",
                });
            }
        }

        this.setLoginAsync(false);
    }

    @Action
    async performLoginAction({ email, password }: { email: string, password: string }): Promise<void> {
        const errors: LoginErrors = {};

        if (!email) {
            errors.email = "Unesite email";
        }

        if (!password) {
            errors.password = "Unesite šifru";
        }

        if (email && !email.includes("@") || email.length === 0) {
            errors.email = "Unesite validan email.";
        }

        if (password && password.length < 8) {
            errors.password = "Unesite validan password.";
        }

        if (Object.keys(errors).length > 0) {
            this.setLoginErrors(errors);
            return;
        }

        this.setLoginAsync(true);

        try {
            await doLogin(email, password);

            const me = await doMe();

            this.setLoginErrors({});

            if (me !== null) {
                this.setUser(me);

                const isSuperAdmin = me.roles.includes(UserRoles.ROLE_SUPER_ADMIN);

                settingsModule.fetchSettings();

                await router.push(isSuperAdmin ? RouteLink.Dashboard : RouteLink.Home).catch(() => {
                    /**/
                });

            } else {
                this.setLoginErrors({ generic: "Could not log in" });
                return;
            }
        } catch (ex) {
            if (typeof ex === "string") {
                this.setLoginErrors({
                    generic: ex,
                });
            } else {
                this.setLoginErrors({
                    generic: "Internal Error",
                });
            }

            showSwalToast(this.loginErrors.generic || "", "error");
        } finally {
            this.setLoginAsync(false);
        }
    }

    @Action
    performLogoutAction(): void {
        localStorage.removeItem(StorageKeys.TOKEN);
        localStorage.removeItem(StorageKeys.REFRESH_TOKEN);

        window.location.reload();
    }

    @Action
    async editUserAction({ userId, data }: { userId: string, data: UserForm }): Promise<void> {
        this.setUserAsync(true);

        try {
            const updatedUser = await editUser(userId, data);
            this.updateUser(updatedUser);
        } finally {
            this.setUserAsync(false);
        }
    }

    @Action
    async changeUserPasswordAction({ userId, password }: { userId: string, password: string }): Promise<void> {
        this.setUserAsync(true);

        try {
            const updatedUser = await changeUserPassword(userId, password);
            this.updateUser(updatedUser);
        } finally {
            this.setUserAsync(false);
        }
    }

    get agencyMarkup(): number {
        return this.user?.agency?.agencyMarkup ?? 0;
    }
}

export const loginModule = getModule(LoginModule);
