import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { store } from "@/store";
import { Bed, IBedForm } from "@/types/beds";
import {
    addBed,
    editBed,
    fetchBedsPage,
    fetchBedById,
    fetchAllBeds,
    deleteBed
} from "@/api/beds/beds";

@Module({ name: "BedsModule", store: store, dynamic: true })
export default class BedsModule extends VuexModule {
    fetchAsync = false;
    beds: Array<Bed> = [];
    hasMore = true;
    currentPage = 0;
    totalPages = 1;
    perPage = 10;
    selectedBed: Bed | null = null;
    addEditAsync = false;
    deleteAsync = false;

    @Mutation
    setBedsFetchAsync(async: boolean): void {
        this.fetchAsync = async;
    }

    @Mutation
    setBedDeleteAsync(async: boolean): void {
        this.deleteAsync = async;
    }

    @Mutation
    setCurrentPage(page: number): void {
        this.currentPage = page;
    }

    @Mutation
    setHasMore(hasMore: boolean): void {
        this.hasMore = hasMore;
    }

    @Mutation
    setPerPage(perPage: number): void {
        this.perPage = perPage;
    }

    @Mutation
    setTotalPages(totalPages: number): void {
        this.totalPages = totalPages;
    }

    @Mutation
    setBeds(beds: Array<Bed>): void {
        this.beds = beds;
    }

    @Mutation
    selectBed(bed: Bed | null): void {
        this.selectedBed = bed;
    }

    @Mutation
    setAddEditAsync(async: boolean): void {
        this.addEditAsync = async;
    }

    @Action
    async fetchBedsFirstPage(): Promise<void> {
        return this.fetchBedsPageAction(1)
    }

    @Action
    async fetchAllBeds(): Promise<Array<Bed> | null> {
        this.setBedsFetchAsync(true);

        try {
            const r = await fetchAllBeds();

            if (r !== null) {
                this.setBeds(r)
            }

            return r;
        } catch (e) {
            console.error(e);
        } finally {
            this.setBedsFetchAsync(false);
        }

        return null;
    }

    @Action
    async fetchBedsPageAction(page: number): Promise<void> {
        this.setBedsFetchAsync(true);

        try {
            const r = await fetchBedsPage(page, this.perPage);

            if (r !== null) {
                this.setBeds(r.data)
                this.setTotalPages(Math.ceil(r.totalItems / this.perPage));
                this.setCurrentPage(page);
                this.setHasMore(r.hasMore);
            }
        } catch (e) {
            console.error(e);
        } finally {
            this.setBedsFetchAsync(false);
        }
    }

    @Action
    async addBedAction(data: IBedForm): Promise<void> {
        this.setAddEditAsync(true);

        try {
            const newBed = await addBed(data);

            if (newBed !== null) {
                // this.appendBeds([newBed])
            }
        } catch (e) {
            console.error(e);
        } finally {
            this.setAddEditAsync(false);
        }
    }

    @Action
    async editBedAction({ bedId, data }: { bedId: string, data: IBedForm }): Promise<void> {

        this.setAddEditAsync(true);

        try {
            const updatedBed = await editBed(bedId, data as IBedForm);

            if (updatedBed !== null) {
                // this.updateBed(updatedBed);
                this.selectBed(updatedBed);
            }
        } catch (e) {
            console.error(e);
        } finally {
            this.setAddEditAsync(false);
        }
    }

    @Action
    async fetchBedByIdAction(bedId: string): Promise<void> {
        this.setBedsFetchAsync(true);

        try {
            const bed = await fetchBedById(bedId);

            if (bed !== null) {
                this.selectBed(bed);
            }
        } catch (e) {
            console.error(e);
        } finally {
            this.setBedsFetchAsync(false);
        }
    }

    @Action({ rawError: true })
    async deleteBedAction(bedId: string): Promise<null | void> {

        if (this.deleteAsync) return;

        this.setBedDeleteAsync(true);

        return deleteBed(bedId).finally(() => this.setBedDeleteAsync(false));
    }
}

export const bedsModule = getModule(BedsModule);
