import { render, staticRenderFns } from "./SingleHotelRooms.vue?vue&type=template&id=0ffad554&scoped=true&"
import script from "./SingleHotelRooms.vue?vue&type=script&lang=ts&"
export * from "./SingleHotelRooms.vue?vue&type=script&lang=ts&"
import style0 from "./SingleHotelRooms.vue?vue&type=style&index=0&id=0ffad554&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ffad554",
  null
  
)

export default component.exports