
import Component from "vue-class-component";
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import { AddFormItem_Hours } from "@/types/addForm/addFormItem";
import InputLabel from "@/components/Ui/InputLabel/InputLabel.vue";
import InputField from "@/components/Ui/InputField/InputField.vue";
import { snakeCase } from "snake-case";
import latinize from "latinize";
import { CleaveOptions } from "cleave.js/options";

@Component({
    components: { InputField, InputLabel }
})
export default class AddContentHours extends Vue {
    @Prop() readonly item!: AddFormItem_Hours;
    @Prop() readonly entity!: Record<string, unknown>;

    get accessor(): string {
        return snakeCase(latinize(this.item.name));
    }

    get cleaveOptions(): CleaveOptions {
        return {
            time: true,
            timePattern: ['h', 'm']
        }
    }
}
