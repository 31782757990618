
import Vue from "vue";
import Component from "vue-class-component";
import { reservationsModule } from "@/store/reservations/reservations";
import { ListReservation } from "@/types/reservation";
import PlaceholderableImage from "@/components/Ui/PlaceholderableImage/PlaceholderableImage.vue";
import ReservationStatusWidget from "@/views/Reservations/components/ReservationStatusWidget.vue";
import ReservationInfo from "@/views/Reservations/components/ReservationInfo.vue";
import SingleActiveReservation from "@/views/Dashboard/components/SingleActiveReservation.vue";

@Component({
    components: { SingleActiveReservation, ReservationInfo, ReservationStatusWidget, PlaceholderableImage },
})
export default class ActiveReservations extends Vue {
    get reservations(): Array<ListReservation> {
        return reservationsModule.reservations;
    }

    get displayedReservations(): Array<ListReservation> {
        return reservationsModule.dashboardReservations;
    }

    beforeMount(): void {
        reservationsModule.fetchAllReservations();
    }
}

