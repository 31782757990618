
import Vue from "vue";
import Component from "vue-class-component";
import { bedsModule } from "@/store/beds/beds";
import Spinner from "@/components/Ui/Spinner/Spinner.vue";
import CustomTable, { TableField } from "@/components/Ui/CustomTable/CustomTable.vue";
import { Bed } from "@/types/beds";
import Button from "@/components/Ui/Button/Button.vue";
import ListPagination from "@/components/ListPagination/ListPagination.vue";
import { RouteName } from "@/router";
import AdminPageHeader from "@/components/AdminPageHeader/AdminPageHeader.vue";
import RoomsTable from "@/views/RoomsTable/RoomsTable.vue";

@Component({
    components: { RoomsTable, AdminPageHeader, ListPagination, Button, CustomTable, Spinner },
    mounted() {
        bedsModule.fetchBedsFirstPage();
    }
})
export default class BedsTable extends Vue {
    tableFields: Array<TableField<Bed>> = [{
        name: "",
        title: "Sortiraj",
        slot: 'name'
    }, {
        name: "description",
        title: "Opis",
    }, {
        name: "capacity",
        title: "Kapacitet",
    }, {
        name: "actions",
        title: "Pretraga",
        width: '10%',
        slot: "actions",
    }];

    get currentPage(): number {
        return bedsModule.currentPage;
    }

    get totalPages(): number {
        return bedsModule.totalPages;
    }

    get beds(): Array<Bed> {
        return bedsModule.beds;
    }

    get async(): boolean {
        return bedsModule.fetchAsync;
    }

    onPageChange(page: number): void {
        bedsModule.fetchBedsPageAction(page)
    }

    editBed(bed: Bed): void {
        this.$router.push({
            name: RouteName.EditBed,
            params: {
                bedId: bed.id,
            },
        });
    }
}
