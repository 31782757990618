
import Component from "vue-class-component";
import Vue from "vue";
import Button, { ButtonType } from "@/components/Ui/Button/Button.vue";
import InputLabel from "@/components/Ui/InputLabel/InputLabel.vue";
import InputField, { InputFieldDisplayType } from "@/components/Ui/InputField/InputField.vue";
import Uploader from "@/components/Ui/Uploader/Uploader.vue";
import { RouteLink, RouteName } from "@/router";
import { agenciesModule } from "@/store/agencies/agencies";
import { cloneDeep } from 'lodash';
import { difference, goBackOrPush, mergeName } from "@/utils/utils";
import { Agency, AgencyForm } from "@/types/agency";
import Spinner from "@/components/Ui/Spinner/Spinner.vue";
import AdminPageHeader from "@/components/AdminPageHeader/AdminPageHeader.vue";
import CustomUploader from "@/components/Ui/CustomUploader/CustomUploader.vue";
import { storage_url } from "@/api/apiCall";
import { mediaModule } from "@/store/media_objects/media_objects";
import { EntityNameEnum } from "@/api/media_objects/media_objects";
import userPlaceholder from '../../assets/images/userPlaceholder.png';
import { MediaObject, UploadedFile } from "@/types/mediaObject";
import AgencyUsers from "@/views/ManageAgency/components/AgencyUsers.vue";
import AgencyDiscounts from "@/views/ManageAgency/components/AgencyDiscounts.vue";
import { User } from "@/types/user";
import SelectField, { SelectFieldOption } from "@/components/Ui/SelectField/SelectField.vue";
import { usersModule } from "@/store/users/users";
import UploaderModal from "@/components/UploaderModal/UploaderModal.vue";
import PlaceholderableImage from "@/components/Ui/PlaceholderableImage/PlaceholderableImage.vue";
import { loginModule } from "@/store/login/login";

interface FormErrors {
    name?: string,
    address?: string,
    phone?: string,
}

@Component({
    components: {
        PlaceholderableImage,
        UploaderModal,
        SelectField,
        AgencyUsers,
        AgencyDiscounts,
        CustomUploader,
        AdminPageHeader,
        Spinner,
        Uploader,
        InputField,
        InputLabel,
        Button
    },
})
export default class ManageAgency extends Vue {
    errors: FormErrors = {}
    images: Array<UploadedFile> = []
    form: AgencyForm = {};
    selectedAgent = null;

    activeButtonType = ButtonType.PLAINWHITE;
    inactiveButtonType = ButtonType.TRANSPARENT;

    InputFieldDisplayType = InputFieldDisplayType
    RouteLink = RouteLink;
    RouteName = RouteName;

    uploaderOpened = false;

    get isSuperAdmin(): boolean {
        return loginModule.isSuperAdmin;
    }

    get routeName(): string {
        return this.$route.name || RouteName.ManageAgency;
    }

    get async(): boolean {
        return agenciesModule.fetchAsync || agenciesModule.addEditAsync;
    }

    get selectedAgency(): Agency | null {
        return agenciesModule.selectedAgency
    }

    get agencyImage(): UploadedFile | MediaObject {
        return this.images[0] ?? agenciesModule.selectedAgency?.image;
    }

    get agencyUsers(): Array<User> {
        return agenciesModule.selectedAgency?.agents ?? [];
    }

    get agentsIds(): Array<string> {
        return this.agencyUsers.map(a => a.id);
    }

    get agentOptions(): Array<SelectFieldOption> {
        return usersModule.users.filter(u => !this.agentsIds.includes(u.id)).map(u => ({
            value: u.id,
            label: mergeName(u),
            topDash: false
        }));
    }

    onSelectNewAgent(agentId: string): void {

        if (!agentId) return;

        const assignedAgents = this.selectedAgency ? [...this.selectedAgency.agents.map(a => a.id), agentId] : [agentId];

        if (this.selectedAgency) {
            agenciesModule.editAgencyAction({
                agencyId: this.selectedAgency.id,
                data: { agents: assignedAgents }
            }).then(() => {
                this.selectedAgent = null;
            })
        }
    }

    addNewAgent(): void {
        const { params: { agencyId } } = this.$route;

        this.$router.push({
            name: RouteName.ManageUser,
            params: {
                agencyId
            }
        });
    }

    goToAgencies(): void {
        goBackOrPush(RouteLink.Agencies);
    }

    onRemoveImage(file: UploadedFile): void {
        this.images = this.images.filter(i => i.url !== file.url);
    }

    switchTabs(routeName: string): void {
        const { params: { agencyId } } = this.$route;

        this.$router.replace({
            name: routeName,
            params: {
                agencyId
            }
        }).catch(() => {/**/
        });
    }

    async deleteAgency(): Promise<void> {
        const r = await this.$swal.fire<boolean>({
            icon: "error",
            title: "Da li ste sigurni ?",
            text: "Ova akcija nije reverzibilna!",
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: "Obriši",
            cancelButtonText: "Odustani",
            confirmButtonColor: "#EB5E55",
            reverseButtons: true,
        });

        if (r.value && agenciesModule.selectedAgency !== null) {
            await agenciesModule.deleteAgency(agenciesModule.selectedAgency.id);
            this.$router.back();
        }
    }

    validateFields(): boolean {
        const errors: FormErrors = {}

        if (!this.form.name) {
            errors.name = 'Naziv je obavezan';
        }

        this.errors = errors;

        return Object.keys(errors).length === 0;
    }

    async onSubmit(): Promise<void> {
        if (!this.validateFields()) return;

        if (agenciesModule.selectedAgency) {

            const diff = difference(this.form, AgencyForm.createFormWithAgency(agenciesModule.selectedAgency))

            try {
                if (this.images.length) {
                    await mediaModule.uploadMediaObject({
                        file: this.images[0],
                        entityData: {
                            entityName: EntityNameEnum.Agency,
                            entityId: agenciesModule.selectedAgency.id
                        }
                    })
                }

                delete diff.email;

                await agenciesModule.editAgencyAction({ agencyId: agenciesModule.selectedAgency.id, data: diff });

                this.form.password = '';

                // this.goToAgencies();
            } catch (ex) {
                //
            }
        } else {
            // //adding
            try {
                await agenciesModule.addAgencyAction({ ...this.form }).then(this.goToAgencies);
            } catch (ex) {
                //
            }
        }
    }

    async beforeMount() {

        const { params: { agencyId } } = this.$route;

        if (!agenciesModule.selectedAgency) {

            if (agencyId) {
                await agenciesModule.fetchAgencyByIdAction(agencyId);
            }
        }

        if (agenciesModule.selectedAgency) {
            this.setData(agenciesModule.selectedAgency);
        }
    }

    beforeDestroy(): void {
        agenciesModule.selectAgency(null);
    }

    transformFormFields(form: AgencyForm): AgencyForm {
        return form;
    }

    setData(agency: Agency): void {
        this.form = this.transformFormFields(cloneDeep(AgencyForm.createFormWithAgency(agency)));
    }
}
