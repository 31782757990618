
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import {
    AddFormItem_HotelNameAutocompleteInput,
} from "@/types/addForm/addFormItem";
import Component from "vue-class-component";
import InputLabel from "@/components/Ui/InputLabel/InputLabel.vue";
import InputField from "@/components/Ui/InputField/InputField.vue";
import { snakeCase } from "snake-case";
import { PlacesWrapper } from "@/api/google/PlacesWrapper";
import SelectField, { SelectFieldOption } from "@/components/Ui/SelectField/SelectField.vue";
import debounce from "lodash/debounce";
import VueSelect from "vue-select";
import { cloneDeep } from "lodash";
import latinize from "latinize";

interface AddContentHotelNameAutocompleteSelectFieldOption extends SelectFieldOption {
    labelMain: string,
    labelExtra?: string,
}

@Component({
    components: { VueSelect, SelectField, InputField, InputLabel },
})
export default class AddContentHotelNameAutocompleteInput extends Vue {
    @Prop() readonly item!: AddFormItem_HotelNameAutocompleteInput;
    @Prop() readonly entity!: Record<string, unknown>;

    options: Array<AddContentHotelNameAutocompleteSelectFieldOption> = [];

    async resolveGooglePlace(placeId: string): Promise<void> {
        const r = await PlacesWrapper.placeIdToAddress(placeId);

        if (r.isOk) {
            if (r.results.length > 0) {
                const addr = PlacesWrapper.parseGeocoderToAddress(r.results[0]);

                Vue.set(this.entity, "address", addr);
            }
        }
    }

    onCreateOption(input: string): AddContentHotelNameAutocompleteSelectFieldOption {
        return {
            label: input,
            labelMain: input,
            value: input,
            topDash: false,
        };
    }

    onSelectOption(option: AddContentHotelNameAutocompleteSelectFieldOption): void {
        if (option.value.startsWith("GOOGLE-")) {
            // this is google places, fetch place id and fill address and lt lng
            const correctedValue = option.value.replaceAll("GOOGLE-", "");

            this.resolveGooglePlace(correctedValue);

            Vue.set(this.entity, this.accessor, option.labelMain);
        } else {
            Vue.set(this.entity, this.accessor, option.value);
        }
    }

    public debouncedSearchGooglePlaces = debounce(this.searchGooglePlaces, 1000);

    searchGooglePlaces(input: string, loading: (l: boolean) => void): void {
        if (input.length > 3) {
            loading(true);

            PlacesWrapper.getPlacePredictions({
                input: input,
                types: ["establishment"],
            }).then(result => {
                loading(false);
                if (result.isOk) {
                    this.$nextTick(() => {
                        this.options = cloneDeep(result.predictions).map(p => {
                            console.log(p);
                            return {
                                topDash: false,
                                label: latinize(p.structured_formatting.main_text + " " + p.structured_formatting.secondary_text),
                                labelExtra: p.structured_formatting.secondary_text,
                                labelMain: p.structured_formatting.main_text,
                                value: `GOOGLE-${p.place_id}`,
                            };
                        });
                    });
                }
            }).catch(e => {
                loading(false);
            });
        }
    }

    get accessor(): string {
        return snakeCase("name");
    }
}
