

import { Dictionary } from "vue-router/types/router";
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import Component from "vue-class-component";
import { goBackOrPush } from "@/utils/utils";

export interface Breadcrumb {
    label: string,
    route: string,
    routeMeta?: Dictionary<string>,
    disabled?: boolean
}

@Component({})
export default class Breadcrumbs extends Vue {
    @Prop({ default: () => [] }) readonly breadcrumbs!: Array<Breadcrumb>

    isActive(crumb: Breadcrumb): boolean {
        return crumb.route === this.$route.name || crumb.route === this.$route.path;
    }

    onCrumbClick(crumb: Breadcrumb): void {
        if (crumb.disabled) {
            return;
        }

        if (crumb.routeMeta) {
            this.$router.push({
                name: crumb.route,
                params: crumb.routeMeta
            })
        } else {
            goBackOrPush(crumb.route);
        }
    }
}
