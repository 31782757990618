
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import { AddFormItem_HotelSelect } from "@/types/addForm/addFormItem";
import Component from "vue-class-component";
import InputLabel from "@/components/Ui/InputLabel/InputLabel.vue";
import SelectField, { SelectFieldOption } from "@/components/Ui/SelectField/SelectField.vue";
import { hotelsModule } from "@/store/hotels/hotels";
import cloneDeep from "lodash/cloneDeep";
import AddContentSelect from "@/components/AddContentRenderer/AddContentItemRenderers/AddContentSelect.vue";
import Spinner from "@/components/Ui/Spinner/Spinner.vue";

@Component({
    components: { Spinner, AddContentSelect, SelectField, InputLabel },
})
export default class AddContentHotelSelect extends Vue {
    @Prop() readonly item!: AddFormItem_HotelSelect;
    @Prop() readonly entity!: Record<string, unknown>;

    get async(): boolean {
        return hotelsModule.fetchAsync;
    }

    async beforeMount(): Promise<void> {
        const hotels = await hotelsModule.fetchAllHotels();

        if (hotels !== null) {
            this.item.options = cloneDeep(hotels).map((hotel) => {
                const o: SelectFieldOption = {
                    value: hotel.id,
                    label: hotel.name,
                    topDash: false,
                };

                return o;
            });
        }
    }
}
