
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { HotelSpecialOffer } from "@/types/hotelSpecialOffer";
import SingleHotelOfferListItem from "@/views/HotelOffers/SingleHotelOfferListItem.vue";
import { showAreYouSureSwal } from "@/utils/utils";
import { hotelsModule } from "@/store/hotels/hotels";

@Component({
    components: { SingleHotelOfferListItem }
})
export default class SingleHotelOfferList extends Vue {
    @Prop({ default: () => [] }) readonly hotelOffers!: HotelSpecialOffer[];
    @Prop() readonly hotelId!: string;
}
