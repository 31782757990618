
import Vue from 'vue';
import router, { RouteLink } from '@/router';
import Header from '@/components/Header/Header.vue';
import Component from 'vue-class-component';
import { DISABLE_SOCKET_CONNECTION, GOOGLE_ANALYTICS_TAG, IS_DEV, StorageKeys } from '@/utils/consts';
import { loginModule } from '@/store/login/login';
import { User } from '@/types/user';
import { uiModule } from '@/store/ui';
import { settingsModule } from '@/store/settings/settings';
import VueGtag from 'vue-gtag';
import { Watch } from 'vue-property-decorator';

if (!IS_DEV && GOOGLE_ANALYTICS_TAG) {
    Vue.use(VueGtag, {
        config: { id: GOOGLE_ANALYTICS_TAG },
    }, router);
}

@Component({
    components: { Header },
})
export default class App extends Vue {
    connectSocket(): void {
        if (DISABLE_SOCKET_CONNECTION) return;

        if (this.$socket.connected) {
            this.$socket.client.close();
        }

        if (this.user) {
            this.$socket.client.io.opts.query = { iam: 'main', id: this.user.id };
            this.$socket.client.open();
        }
    }

    beforeMount(): void {

        // Global guard
        router.beforeEach((to, from, next) => {
            uiModule.setPreviousRoute(from.path);
            next();
        });

        if (this.user) {
            if (this.$gtag) {
                this.$gtag.set({ 'user_id': this.user.email });
                this.$gtag.config({ 'user_id': this.user.email });
            }
            return;
        }

        const token = localStorage.getItem(StorageKeys.TOKEN);

        if (!token) {
            this.$router.push(RouteLink.Login).catch(() => {
                /**/
            });
            return;
        }

        if (token && !this.user) {
            loginModule.doMeAction().then(() => {
                settingsModule.fetchSettings();
                this.checkAccess();
            });
        }
    }

    mounted(): void {
        this.checkAccess();
    }

    checkAccess(route = location.pathname): void {
        if (loginModule.isAgent && route.includes('/admin')) {
            this.$router.push(RouteLink.Home);
        }
    }

    get isLoggedIn(): boolean {
        return loginModule.isLoggedIn;
    }

    get async(): boolean {
        return loginModule.loginAsync;
    }

    get user(): User | null {
        return loginModule.user;
    }

    get shouldDisplayCover(): boolean {
        return this.$route.path === RouteLink.Login || this.$route.path === RouteLink.Home && this.isLoggedIn;
    }

    @Watch('user')
    onUserChanged(newUser: User | null, oldUser: User | null): void {
        let oldUserId = oldUser ? oldUser.id : null;
        if (oldUserId !== (newUser !== null && newUser.id)) {
            this.connectSocket();

            // update user gtag id?
            if (this.$gtag) {
                this.$gtag.set({ 'user_id': newUser?.email });
                this.$gtag.config({ 'user_id': newUser?.email });
            }
        }
    }
}
