
import Vue from "vue";
import Component from "vue-class-component";
import Spinner from "@/components/Ui/Spinner/Spinner.vue";
import Button, { ButtonType } from "@/components/Ui/Button/Button.vue";
import { Prop } from "vue-property-decorator";
import { ManualReservation } from "@/types/manualReservation";
import PassengerList from "@/views/SingleReservation/components/PassengerList.vue";
import { manualReservationsModule } from "@/store/manual_reservations/manual_reservations";
import MRHotelCard
    from "@/views/Reservations/components/ManualReservations/SingleManualReservation/components/MRHotelCard.vue";
import MRAdditionalContentCard
    from "@/views/Reservations/components/ManualReservations/SingleManualReservation/components/MRAdditionalContentCard.vue";
import MRReceipt
    from "@/views/Reservations/components/ManualReservations/SingleManualReservation/components/MRReceipt.vue";
import DeleteReservation from "@/views/Reservations/DeleteReservation.vue";
import { ManualReservationsApi } from "@/api/manual_reservations/manual_reservations";

@Component({
    components: { DeleteReservation, MRReceipt, MRAdditionalContentCard, MRHotelCard, Button, PassengerList, Spinner }
})
export default class SingleReservation extends Vue {
    @Prop() readonly listReservation!: ManualReservation;

    ButtonType = ButtonType;

    deleteVisible = false;
    invoiceAsync = false;

    mounted(): void {
        if (manualReservationsModule.selectedReservation === null || manualReservationsModule.selectedReservation?.id !== this.listReservation.id) {
            manualReservationsModule.fetchReservationByIdAction(this.listReservation.id);
        }
    }

    get reservation(): ManualReservation | null {
        if (manualReservationsModule.selectedReservation !== null) {
            return manualReservationsModule.selectedReservation;
        } else {
            return null;
        }
    }

    get invoiceFileExists(): boolean {
        return Boolean(this.reservation?.invoiceGeneratedAt);
    }

    generateReservationInvoice(): void {

        if (this.reservation !== null) {

            const reservationId = this.reservation.id;

            this.invoiceAsync = true;

            ManualReservationsApi.apiGenerateManualReservationInvoice(reservationId)
                .then(reservation => {
                    if(reservation) {
                        manualReservationsModule.selectReservation(reservation);
                    }
                }).finally(() => this.invoiceAsync = false);
        }
    }

    printReceipt(): void {
        if (this.reservation) {
            const reservationId = this.reservation.id;

            this.invoiceAsync = true;

            ManualReservationsApi.apiReadReservationInvoice(reservationId).then(invoiceHtml => {
                if (invoiceHtml) {
                    const win = window.open("", "_blank");
                    win?.document.write(invoiceHtml);
                    win?.document.close();
                }
            }).catch(() => manualReservationsModule.fetchReservationByIdAction(reservationId))    // refresh on fail
                .finally(() => {
                    this.invoiceAsync = false;
                });

        }
    }

    deleteReservation(): void {
        if (manualReservationsModule.selectedReservation) {
            manualReservationsModule.deleteReservationAction(manualReservationsModule.selectedReservation.id).then(() => this.deleteVisible = false);
        }
    }

}
