import { Action, getModule, Module, Mutation, VuexModule, } from "vuex-module-decorators";
import { store } from "@/store";
import { merge } from "lodash";
import { SortOrder } from "@/utils/utils";
import { ManualReservation, ManualReservationForm } from "@/types/manualReservation";
import { ManualReservationsApi } from "@/api/manual_reservations/manual_reservations";

@Module({ name: "ManualReservationsModule", store: store, dynamic: true, namespaced: true })
export default class ManualReservationsModule extends VuexModule {
    fetchAsync = false;
    singleFetchAsync = false;
    reservations: Array<ManualReservation> = [];
    hasMore = true;
    currentPage = 0;
    totalPages = 1;
    perPage = 10;
    reservationValidateAsync = false;
    confirmPaidAsync = false;
    sendHotelEmailAsync = false;
    selectedReservation: ManualReservation | null = null;
    addEditAsync = false;
    deleteAsync = false;
    sendTransferEmailAsync = false;

    sortField: string | null = "createdAt";
    sortOrder: SortOrder = SortOrder.DESC;

    @Mutation
    setFetchAsync(async: boolean): void {
        this.fetchAsync = async;
    }

    @Mutation
    setReservationValidateAsync(async: boolean): void {
        this.reservationValidateAsync = async;
    }

    @Mutation
    setSendHotelEmailAsync(async: boolean): void {
        this.sendHotelEmailAsync = async;
    }

    @Mutation
    setTransferEmailAsync(async: boolean): void {
        this.sendTransferEmailAsync = async;
    }

    @Mutation
    setReservationConfirmPaidAsync(async: boolean): void {
        this.confirmPaidAsync = async;
    }

    @Mutation
    setSingleFetchAsync(async: boolean): void {
        this.singleFetchAsync = async;
    }

    @Mutation
    setReservationDeleteAsync(async: boolean): void {
        this.deleteAsync = async;
    }

    @Mutation
    setCurrentPage(page: number): void {
        this.currentPage = page;
    }

    @Mutation
    setHasMore(hasMore: boolean): void {
        this.hasMore = hasMore;
    }

    @Mutation
    setPerPage(perPage: number): void {
        this.perPage = perPage;
    }

    @Mutation
    setTotalPages(totalPages: number): void {
        this.totalPages = totalPages;
    }

    @Mutation
    setReservations(reservations: Array<ManualReservation>): void {
        this.reservations = reservations;
    }

    @Mutation
    removeReservation(id: string): void {
        this.reservations = this.reservations.filter((r) => r.id !== id);
    }

    @Mutation
    selectReservation(reservation: ManualReservation | null): void {
        this.selectedReservation = reservation;
    }

    @Mutation
    setAddEditAsync(async: boolean): void {
        this.addEditAsync = async;
    }

    @Mutation
    setReservationsSortField(sortField: keyof ManualReservation | null): void {
        this.sortField = sortField;
    }

    @Mutation
    setReservationsSortOrder(sortOrder: SortOrder): void {
        this.sortOrder = sortOrder;
    }

    @Action
    async fetchAllReservations(): Promise<Array<ManualReservation> | null> {
        this.setFetchAsync(true);

        try {
            const r = await ManualReservationsApi.apiFetchAllReservations();

            if (r !== null) {
                this.setReservations(r);
            }

            return r;
        } catch (e) {
            console.error(e);
        } finally {
            this.setFetchAsync(false);
        }

        return null;
    }

    @Action
    async fetchReservationsFirstPage(): Promise<void> {
        return this.fetchReservationsPageAction(1);
    }

    @Mutation
    updateListReservationById({
                                  reservationId,
                                  data,
                              }: {
        reservationId: string;
        data: Partial<ManualReservation>;
    }): void {
        const index = this.reservations.findIndex(
            (reservation) => reservation.id === reservationId
        );
        if (index !== -1) {
            this.reservations[index] = merge(this.reservations[index], data);
            this.reservations = [...this.reservations];
        }

        if (reservationId === this.selectedReservation?.id) {
            this.selectedReservation = merge(this.selectedReservation, data);
        }
    }

    @Action
    async fetchReservationsPageAction(page: number): Promise<void> {
        this.setFetchAsync(true);

        try {
            const r = await ManualReservationsApi.fetchReservationsPage(page, this.perPage, this.sortField, this.sortOrder);

            if (r !== null) {
                this.setReservations(r.data);
                this.setTotalPages(Math.ceil(r.totalItems / this.perPage));
                this.setCurrentPage(page);
                this.setHasMore(r.hasMore);
            }
        } catch (e) {
            console.error(e);
        } finally {
            this.setFetchAsync(false);
        }
    }

    @Action({ rawError: true })
    async deleteReservationAction(reservationId: string): Promise<null | void> {
        if (this.deleteAsync) return;

        this.setReservationDeleteAsync(true);

        return ManualReservationsApi.deleteReservation(reservationId).finally(() => {
            this.removeReservation(reservationId);
            this.setReservationDeleteAsync(false);
        });
    }

    @Action
    async confirmReservationValidated({
                                          reservationId,
                                      }: {
        reservationId: string;
    }): Promise<boolean> {
        this.setReservationValidateAsync(true);

        try {
            const r = await ManualReservationsApi.apiValidateReservation(reservationId);

            if (r !== null) {
                this.updateListReservationById({
                    reservationId,
                    data: {
                        status: r.status,
                    },
                });
            }

            return true;
        } catch (e) {
            return false;
        } finally {
            this.setReservationValidateAsync(false);
        }
    }

    @Action
    async confirmReservationPaid({
                                     reservationId,
                                     confirmationNumber,
                                 }: {
        reservationId: string;
        confirmationNumber?: string;
    }): Promise<boolean> {
        this.setReservationConfirmPaidAsync(true);

        try {
            const r = await ManualReservationsApi.apiConfirmReservation(reservationId, {
                confirmationNumber,
            });

            if (r !== null) {
                this.updateListReservationById({
                    reservationId,
                    data: {
                        status: r.status,
                        confirmationNumber: r.confirmationNumber,
                        isPaid: r.isPaid,
                    },
                });
            }

            return true;
        } catch (e) {
            return false;
        } finally {
            this.setReservationConfirmPaidAsync(false);
        }
    }

    @Action
    async fetchReservationByIdAction(reservationId: string): Promise<void> {
        this.setSingleFetchAsync(true);

        try {
            const reservation = await ManualReservationsApi.fetchReservationById(reservationId);

            if (reservation !== null) {
                this.selectReservation(reservation);
            }
        } catch (e) {
            console.error(e);
        } finally {
            this.setSingleFetchAsync(false);
        }
    }

    @Action({ rawError: true })
    async addReservationAction(form: ManualReservationForm): Promise<ManualReservation | null | void> {
        if (this.addEditAsync) return;

        this.setAddEditAsync(true);

        return ManualReservationsApi.addReservation(form).finally(() => {
            this.setAddEditAsync(false);
        });
    }
}

export const manualReservationsModule = getModule(ManualReservationsModule);
