import {
    PaginationResponse,
    RawHydraResponse,
    RawHydraResponseIntoPaginationResponse,
} from "@/types/paginationResponse";
import { apiCall, jsonApiCall, reservations, users } from "@/api/apiCall";
import {
    ListReservation,
    Reservation,
    ReservationType,
} from "@/types/reservation";
import { Passenger } from "@/types/Passenger";
import { RoomServiceType } from "@/types/room";
import { ReservationFormAdditionalContentItem } from "@/types/additionalContent";
import { SortOrder } from "@/utils/utils";
import { ReservationStatusEnum } from "@/types/reservationStatus";

export async function apiFetchAllReservations(): Promise<Array<ListReservation> | null> {
    return await jsonApiCall<Array<ListReservation>>({
        url: `${reservations}?sort_by=createdAt&sort_order=DESC`,
        method: "GET",
    });
}

export async function fetchReservationsPage(page: number, perPage: number, sortField: string | null, sortOrder: SortOrder, search: string): Promise<PaginationResponse<ListReservation> | null> {

    const url = new URL(reservations);

    url.searchParams.append("page", page.toString());
    url.searchParams.append("per_page", perPage.toString());
    url.searchParams.append("search", search);

    if (sortField) {
        url.searchParams.append("sort_by", sortField);
        url.searchParams.append("sort_order", sortOrder);
    }

    const r = await jsonApiCall<RawHydraResponse<ListReservation>>({
        url: url.toString(),
        method: "GET",
        headers: {
            "accept": "application/ld+json",
        },
    });

    if (r !== null) {
        return RawHydraResponseIntoPaginationResponse<ListReservation>(r);
    } else {
        return null;
    }
}

export async function fetchReservationById(reservationId: string): Promise<Reservation | null> {
    const r = await jsonApiCall<Reservation>({
        url: `${reservations}/${reservationId}`,
    });

    if (r !== null) {
        return r;
    }

    return null;
}

export interface ReservationPostVariantData {
    id: string,
    q: number,
    serviceType: RoomServiceType,
    passengers: number
}

export interface ReservationPostData {
    type: ReservationType;
    from: string;
    to: string;
    variantsData: Array<ReservationPostVariantData>;
    passengers: Array<Passenger>;
    reservationAdditionalContents: Array<ReservationFormAdditionalContentItem>;
    specialRequest?: string;
}

export async function addReservationStatus(reservationId: string, status: ReservationStatusEnum): Promise<ListReservation | null> {
    const r = await jsonApiCall<ListReservation>({
        url: `${reservations}/${reservationId}/status`,
        method: "PUT",
        jsonData: {
            status,
        },
    });

    if (r !== null) {
        return r;
    }

    return null;

}

export async function apiConfirmReservation(reservationId: string, data: { confirmationNumber?: string }): Promise<ListReservation | null> {
    const r = await jsonApiCall<ListReservation>({
        url: `${reservations}/${reservationId}/confirm`,
        method: "PUT",
        jsonData: data as Record<string, any>,
    });

    if (r !== null) {
        return r;
    }

    return null;
}
export async function editListReservation(reservationId: string, data: Partial<ListReservation>): Promise<ListReservation | null> {
    const r = await jsonApiCall<ListReservation>({
        url: `${reservations}/${reservationId}`,
        method: "PUT",
        jsonData: data as Record<string, any>,
    });

    if (r !== null) {
        return r;
    }

    return null;

}

export async function apiHotelSendReservation(reservationId: string, additionalContent: string[]): Promise<ListReservation | null> {
    const r = await jsonApiCall<ListReservation>({
        url: `${reservations}/${reservationId}/send_email_to_hotel`,
        method: "PUT",
        jsonData: {
            additional_content: additionalContent,
        } as Record<string, any>,
    });

    if (r !== null) {
        return r;
    }

    return null;
}

export async function apiSendEmailToReservationTransfer(reservationId: string): Promise<ListReservation | null> {
    const r = await jsonApiCall<ListReservation>({
        url: `${reservations}/${reservationId}/send_email_to_transfer`,
        method: "PUT",
        jsonData: {} as Record<string, any>,
    });

    if (r !== null) {
        return r;
    }

    return null;
}

export async function addReservation(data: ReservationPostData): Promise<Reservation | null> {
    const r = await jsonApiCall<Reservation>({
        url: reservations,
        method: "POST",
        jsonData: data as Record<string, any>,
    });

    if (r !== null) {
        return r;
    }

    return null;
}

export async function deleteReservation(reservationId: string): Promise<null> {
    const r = await jsonApiCall<null>({
        url: `${reservations}/${reservationId}`,
        method: "DELETE",
    });

    console.log(r);

    return r;
}

export async function apiGenerateReservationInvoice(reservationId: string): Promise<null> {
    return await jsonApiCall<null>({
        url: `${reservations}/${reservationId}/generate_confirmation_pdf`,
        method: "PUT",
        jsonData: {},
    });
}

export async function apiReadReservationInvoice(reservationId: string): Promise<string | null> {
    return await jsonApiCall<string>({
        url: `${reservations}/${reservationId}/pdf`,
    });
}
