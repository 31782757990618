
import { Prop, Vue } from "vue-property-decorator";
import Component from "vue-class-component";
import CalendarDaySelectionType from "@/views/Calendar/CalendarDaySelectionType.vue";
import moment from "moment";
import { CalendarRibbon, TmpCalendarRibbon } from "@/types/availabilityRibbon";
import { cloneDeep } from "lodash";
import { uiModule } from "@/store/ui";

export enum DaySelectionType {
    START,
    MIDDLE,
    END,
    START_END,
}

@Component({
    components: { CalendarDaySelectionType },
})
export default class CalendarDay extends Vue {
    @Prop() readonly day!: Date;

    @Prop({ default: false }) readonly tmpActive!: boolean;
    @Prop({ default: null }) readonly selectedRibbonId!: string;
    @Prop({ default: () => [] }) readonly selectedRibbonsIds!: Array<string>;

    @Prop({ default: [] }) readonly selections!: Array<CalendarRibbon>;
    @Prop({ default: null }) readonly tmpSelection!: TmpCalendarRibbon | null;

    @Prop({ default: null }) readonly selectionType!: DaySelectionType | null;

    getDaySelectionType(start: Date, end: Date, day: Date): DaySelectionType | null {
        let mDay = moment(day);
        let mStart = moment(cloneDeep(start)).startOf("day");
        let mEnd = moment(cloneDeep(end)).startOf("day");

        if (mStart.isSame(day) && mEnd.isSame(day)) {
            return DaySelectionType.START_END;
        }

        if (mStart.isSame(mDay)) {
            return DaySelectionType.START;
        } else if (mEnd.isSame(mDay)) {
            return DaySelectionType.END;
        } else if (mDay.isBetween(mStart, mEnd)) {

            return DaySelectionType.MIDDLE;
        }

        return null;
    }

    get maxRibbonRow(): number {
        let max = 0;

        this.selections.forEach(ribbon => {

            if (this.getDaySelectionType(ribbon.startDate, ribbon.endDate, this.day) !== null) {
                if (ribbon.ribbonRow > max) {
                    max = ribbon.ribbonRow;
                }
            }
        })

        return max;
    }

    get minHeightRems(): number {

        const { calendarDayContentBaseHeight, calendarRibbonHeight } = uiModule;

        const marginBottom = this.maxRibbonRow > 0 ? 1 : 0;

        return calendarDayContentBaseHeight + (this.maxRibbonRow * (calendarRibbonHeight + marginBottom));
    }
}
