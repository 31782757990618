
import Vue from "vue";
import Component from "vue-class-component";
import Button, { ButtonType } from "@/components/Ui/Button/Button.vue";
import Checkbox, { CheckboxType } from "@/components/Ui/Checkbox/Checkbox.vue";
import { amenitiesModule } from "@/store/amenities/amenities";
import { Amenity } from "@/types/amenity";
import { hotelsModule } from "@/store/hotels/hotels";
import { HotelFilterTerms, HotelRatingFilterType } from "@/types/hotel";
import InputLabel from "@/components/Ui/InputLabel/InputLabel.vue";
import InputField from "@/components/Ui/InputField/InputField.vue";

@Component({
    components: { InputField, InputLabel, Checkbox, Button },
})
export default class HotelFilters extends Vue {
    CheckboxType = CheckboxType;
    ButtonType = ButtonType;
    HotelRatingFilterType = HotelRatingFilterType;

    checkedRating: HotelRatingFilterType | null = null;

    amenityFilters: Record<string, boolean> = {};

    get amenities(): Array<Amenity> {
        return amenitiesModule.amenities;
    }

    onChangeRatingCheckbox(value: boolean, type: HotelRatingFilterType): void {
        console.log(this.checkedRating, type);
        if (this.checkedRating === type) {
            this.checkedRating = null;
            return;
        }

        this.checkedRating = type;
    }

    applyFilters(): void {
        if (hotelsModule.queryData !== null) {
            let amenitiesIds = Object.entries(this.amenityFilters).filter(([_id, enabled]) => enabled).map(([id, _enabled]) => id);
            let queryData: HotelFilterTerms | null = {
                ...hotelsModule.filterTerms,
                amenities: amenitiesIds,
                rating: this.checkedRating,
            };

            if (queryData.amenities.length === 0 && queryData.rating === null) {
                queryData = null;
            }

            hotelsModule.filterHotelsAction(queryData);
        }
    }

    mounted(): void {
        amenitiesModule.fetchAllAmenities();
    }
}
