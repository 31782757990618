
import Button from "@/components/Ui/Button/Button.vue";
import Component from "vue-class-component";
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import { Hotel } from "@/types/hotel";
import { storage_url } from "@/api/apiCall";
import { loginModule } from "@/store/login/login";
import { RouteName } from "@/router";
import { EditHotelSectionType } from "@/views/EditHotel/EditHotel.vue";
import PlaceholderableImage from "@/components/Ui/PlaceholderableImage/PlaceholderableImage.vue";

@Component({
    components: { PlaceholderableImage, Button },
})
export default class SingleHotelImages extends Vue {
    lightboxIndex: number | null = null;

    @Prop() readonly hotel!: Hotel;

    fallbackImage = this.hotel?.image || null;

    get isAdmin(): boolean {
        return loginModule.isSuperAdmin;
    }

    get buttonMessage(): string {
        return this.isAdmin ? this.$tc("edit_photos") : this.$tc('view_photos');
    }

    get images(): Array<string | File> {
        return [...(this.hotel?.mediaObjects || []).map(m => storage_url + m.contentUrl)];
    }

    handleButtonClick(): void {

        if (this.isAdmin) {
            this.$router.push({
                name: RouteName.EditHotel,
                params: {
                    hotelId: this.hotel.id,
                    tab: EditHotelSectionType.GALLERY
                },
            });
        } else {
            this.lightboxIndex = 0;
        }
    }
}
