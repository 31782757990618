import { apiCall, statistics } from "@/api/apiCall";
import { DashboardStats } from "@/types/dashboardStats";

export async function fetchDashboardStatistics(): Promise<DashboardStats | null> {
    return apiCall({
        url: statistics + `/dashboard`,
        method: "GET",
    })
        .then(r => {
            return r?.json();
        }).catch(() => null);
}
