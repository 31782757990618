export enum HotelSponsoredLevel {
    Level1 = 1,
    Level2 = 2,
    Level3 = 3
}

export interface HotelSpecialOffer {
    id: string,
    fromDate?: string,
    toDate?: string,
    sponsoredLevel: HotelSponsoredLevel
}

export interface IHotelSpecialOfferForm {
    fromDate?: string,
    toDate?: string,
    sponsoredLevel?: HotelSponsoredLevel,
    hotel?: string
}

export class HotelSpecialOfferUtils {
    public static validateForm(hotelSpecialOfferForm: IHotelSpecialOfferForm): boolean {
        return Boolean(hotelSpecialOfferForm.hotel);
    }
}