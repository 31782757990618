

import Component from "vue-class-component";
import Vue from "vue";
import { AddForm } from "@/types/addForm/addForm";
import { AddFormPage } from "@/types/addForm/addFormPage";
import {
    AddFormItem_Address,
    AddFormItem_Amenities,
    AddFormItem_Gallery, AddFormItem_HotelNameAutocompleteInput,
    AddFormItem_Input,
    AddFormItem_InputType, AddFormItem_TextArea,
    AddFormItem_Title,
} from "@/types/addForm/addFormItem";
import AddContentRenderer from "@/components/AddContentRenderer/AddContentRenderer.vue";
import { HotelForm } from "@/types/hotel";
import { Address } from "@/types/address";
import { hotelsModule } from "@/store/hotels/hotels";
import { MediaObject } from "@/types/mediaObject";
import { goBackOrPush } from "@/utils/utils";
import { RouteLink } from "@/router";

@Component({
    components: { AddContentRenderer },
})
export default class AddNewHotel extends Vue {
    entity: Record<string, unknown> = {};
    activePage = 0;

    form = new AddForm([
        new AddFormPage(0, [
            new AddFormItem_Title("Osnovni podaci"),
            new AddFormItem_HotelNameAutocompleteInput("Naziv hotela"),
            new AddFormItem_Address(),
            new AddFormItem_Input("Email Hotela", AddFormItem_InputType.Text),
        ]),
        new AddFormPage(1, [
            new AddFormItem_Title("Čime se hotel karakteriše"),
            new AddFormItem_Amenities(),
        ]),
        new AddFormPage(2, [
            new AddFormItem_Title("Postavite fotografije hotela"),
            new AddFormItem_Gallery(),
        ]),
        new AddFormPage(3, [
            new AddFormItem_Title("Opišite i ocijenite hotel."),
            new AddFormItem_Input("Broj zvjezdica hotela", AddFormItem_InputType.Number),
            new AddFormItem_TextArea("Dodatni opis hotela"),
            // new AddFormItem_Input("Ocjena prema BeysTravel", AddFormItem_InputType.Number),
        ]),
        new AddFormPage(4, [
            new AddFormItem_Title("Administracija"),
            new AddFormItem_Input("Procenat marže", AddFormItem_InputType.Number, {
                min: 0,
                nullable: true,
            }),
        ]),
    ]);

    transformEntityToHotelForm(): HotelForm {
        let amenities: Array<string> = Object.keys(this.entity["amenities"] as Record<string, unknown> || {}).map(amenity => `/api/amenities/${amenity}`);
        let mediaObjects: Array<string> = ((this.entity["images"] as Array<MediaObject>) || []).map((o) => `/api/media_objects/${o.id}`);
        const margin = this.entity["procenat_marze"];

        return new HotelForm({
            name: this.entity["name"] as string,
            email: this.entity["email_hotela"] as string,
            descriptionBs: this.entity["dodatni_opis_hotela"] as string,
            margin: margin !== null ? +(margin as string) : null,
            rating: +(this.entity["ocjena_prema_beys_travel"] as string),
            stars: +(this.entity["broj_zvjezdica_hotela"] as string),
            address: this.entity["address"] as Address,
            mediaObjects,
            amenities,
        });
    }

    changePage(page: number): void {
        this.activePage = page;
    }

    async onSubmit(): Promise<void> {
        let hotel = this.transformEntityToHotelForm();

        const success = await hotelsModule.addHotelAction(hotel);

        if (success) {
            goBackOrPush(RouteLink.HotelContent);
        }
    }

    get addAsync(): boolean {
        return hotelsModule.addEditAsync;
    }

    get disablePageSwitching(): boolean {
        let hotel = this.transformEntityToHotelForm();

        if(this.activePage === 0) {
            return !hotel.name;
        }

        if(this.activePage === 3) {
            return !hotel.stars
        }

        return false;
    }

}
