
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { HotelSpecialOffer } from "@/types/hotelSpecialOffer";
import moment from "moment";
import GIcon from "@/components/Ui/GIcon/GIcon.vue";
import { showAreYouSureSwal } from "@/utils/utils";
import { offersModule } from "@/store/offers/offers";
import { hotelsModule } from "@/store/hotels/hotels";
import Spinner from "@/components/Ui/Spinner/Spinner.vue";
import { RouteLink, RouteName } from "@/router";

@Component({
    components: { Spinner, GIcon }
})
export default class SingleHotelOfferListItem extends Vue {
    @Prop() readonly hotelOffer!: HotelSpecialOffer;
    @Prop() readonly hotelId!: string;

    hovered = false;
    deleteAsync = false;

    get fromDate(): string {
        return moment(this.hotelOffer.fromDate).format('D.M.YYYY');
    }

    get toDate(): string {
        return moment(this.hotelOffer.toDate).format('D.M.YYYY');
    }

    async deleteOffer(): Promise<void> {
        const prompt = await showAreYouSureSwal({ text: 'Da, izbriši izdvojenost.' });

        if (prompt.value) {

            this.deleteAsync = true;

            hotelsModule.deleteSpecialOffer({ hotelId: this.hotelId, offerId: this.hotelOffer.id })
                .finally(() => this.deleteAsync = false);
        }
    }

    editOffer(): void {
        this.$router.push({
            name: RouteName.EditHotelSpecialOffer,
            params: {
                hotelId: this.hotelId,
                specialOfferId: this.hotelOffer.id,
            },
        });
    }
}
