
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { ManualReservationRoom, RoomFormErrors } from "@/types/manualReservation";
import InputLabel from "@/components/Ui/InputLabel/InputLabel.vue";
import InputField, { InputFieldType } from "@/components/Ui/InputField/InputField.vue";
import ExpandableContainer from "@/components/ExpandableContainer/ExpandableContainer.vue";
import SelectField, { SelectFieldOption } from "@/components/Ui/SelectField/SelectField.vue";
import { RoomVariantClassification } from "@/types/roomVariant";
import { getRoomServiceTypeText, getRoomVariantText } from "@/utils/utils";
import { RoomServiceType } from "@/types/room";
import Button, { ButtonType } from "@/components/Ui/Button/Button.vue";
import { isEmpty } from "lodash";

@Component({
    components: { Button, SelectField, ExpandableContainer, InputField, InputLabel }
})
export default class MRRoomForm extends Vue {
    @Prop({ default: {} }) roomForm!: ManualReservationRoom;
    @Prop({ default: false }) editMode!: boolean;
    @Prop({
        default: () => {
            return {}
        }
    }) errors!: RoomFormErrors;

    InputType = InputFieldType;
    ButtonType = ButtonType;

    formExpanded = false;

    get roomVariantOptions(): Array<SelectFieldOption> {
        const options: Array<SelectFieldOption> = [];

        for (const variant in RoomVariantClassification) {
            options.push({
                value: variant,
                label: getRoomVariantText(variant),
                topDash: false,
            });
        }

        return options;
    }

    get roomServiceTypeOptions(): Array<SelectFieldOption> {
        const options: Array<SelectFieldOption> = [];

        for (const serviceType in RoomServiceType) {
            options.push({
                value: serviceType,
                label: getRoomServiceTypeText(serviceType, this.$i18n),
                topDash: false,
            });
        }

        return options;
    }

    get hasError(): boolean {
        return !isEmpty(this.errors);
    }

    onClickSave(): void {
        this.formExpanded = false;
    }
}
