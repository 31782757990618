
import Vue from "vue";
import Component from "vue-class-component";
import { roomsModule } from "@/store/rooms/rooms";
import Spinner from "@/components/Ui/Spinner/Spinner.vue";
import CustomTable, { TableField } from "@/components/Ui/CustomTable/CustomTable.vue";
import { ListRoom, Room } from "@/types/room";
import Button from "@/components/Ui/Button/Button.vue";
import ListPagination from "@/components/ListPagination/ListPagination.vue";
import { RouteName } from "@/router";
import AdminPageHeader from "@/components/AdminPageHeader/AdminPageHeader.vue";
import { Prop } from "vue-property-decorator";
import { ListHotel } from "@/types/hotel";
import { storage_url } from "@/api/apiCall";
import noImage from '@/assets/images/no_image.jpg';
import PlaceholderableImage from "@/components/Ui/PlaceholderableImage/PlaceholderableImage.vue";

@Component({
    components: { PlaceholderableImage, AdminPageHeader, ListPagination, Button, CustomTable, Spinner },
})
export default class RoomsTable extends Vue {
    @Prop() readonly hotel?: ListHotel;

    tableFields: Array<TableField<Room>> = [{
        name: "",
        title: '',
        width: '60%',
        slot: 'name'
    }, {
        name: "capacity",
        title: '',
        width: '30%',
        slot: "capacity",
    }, {
        name: "actions",
        title: '',
        width: '10%',
        slot: "actions",
    }];

    get rooms(): Array<ListRoom> {
        return roomsModule.rooms
    }

    getRoomImage(room: Room): string | null {
        const roomUrl = room.mediaObjects[0]?.contentUrl;
        return roomUrl ? (storage_url +  roomUrl) : noImage;
    }

    get async(): boolean {
        return roomsModule.fetchAsync;
    }

    editRoom(room: Room): void {
        this.$router.push({
            name: RouteName.EditRoom,
            params: {
                roomId: room.id,
            },
        });
    }

    beforeMount() {
        if (this.hotel) {
            roomsModule.fetchAllHotelRooms(this.hotel.id);
        }
    }
}
