
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import { HotelForm } from "@/types/hotel";
import Component from "vue-class-component";
import AddContentInput from "@/components/AddContentRenderer/AddContentItemRenderers/AddContentInput.vue";
import InputLabel from "@/components/Ui/InputLabel/InputLabel.vue";
import InputField, { InputFieldType } from "@/components/Ui/InputField/InputField.vue";

@Component({
    components: { InputField, InputLabel, AddContentInput },
})
export default class HotelAdministrationForm extends Vue {
    @Prop() hotelForm!: HotelForm;

    InputFieldType = InputFieldType
}
