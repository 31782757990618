
import Vue from "vue";
import Component from "vue-class-component";
import Spinner from "@/components/Ui/Spinner/Spinner.vue";
import CustomTable, { TableField } from "@/components/Ui/CustomTable/CustomTable.vue";
import { User } from "@/types/user";
import Button from "@/components/Ui/Button/Button.vue";
import ListPagination from "@/components/ListPagination/ListPagination.vue";
import AdminPageHeader from "@/components/AdminPageHeader/AdminPageHeader.vue";
import { Prop } from "vue-property-decorator";
import { agenciesModule } from "@/store/agencies/agencies";
import { usersModule } from "@/store/users/users";
import { RouteName } from "@/router";

@Component({
    components: { AdminPageHeader, ListPagination, Button, CustomTable, Spinner },
})
export default class AgencyUsers extends Vue {
    @Prop({ default: () => [] }) readonly agents!: Array<User>;

    tableFields: Array<TableField<User>> = [{
        name: "",
        title: "Agent",
        slot: "info",
    }, {
        name: "email",
        title: "Email",
    }, {
        name: "phone",
        title: "Telefon",
    }, {
        name: "actions",
        title: "Akcije",
        width: "13%",
        slot: "actions",
    }];

    get async(): boolean {
        return agenciesModule.fetchAsync || agenciesModule.addEditAsync;
    }

    editUser(user: User): void {
        this.$router.push({
            name: RouteName.ManageUser,
            params: {
                userId: user.id,
            },
        });
    }

    async removeAgentFromAgency(user: User): Promise<void> {
        const r = await this.$swal.fire<boolean>({
            icon: "error",
            title: "Da li ste sigurni?",
            text: "Ukloni agenta iz agencije!",
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: "Obriši",
            cancelButtonText: "Odustani",
            confirmButtonColor: "#EB5E55",
            reverseButtons: true,
        });

        if (r.value && agenciesModule.selectedAgency !== null) {
            const assignedAgents = this.agents.filter(a => a.id !== user.id).map(a => `/api/users/${a.id}`);
            await agenciesModule.editAgencyAction({
                agencyId: agenciesModule.selectedAgency.id,
                data: { agents: assignedAgents },
            });
        }
    }

    beforeMount(): void {
        usersModule.fetchAllUsers();
    }
}
