
import Component from "vue-class-component";
import Vue from "vue";
import { loginModule } from "@/store/login/login";
import Button, { ButtonType } from "@/components/Ui/Button/Button.vue";
import { goBackOrPush } from "@/utils/utils";
import { RouteLink } from "@/router";
import { storage_url } from "@/api/apiCall";
import userPlaceholder from "@/assets/images/userPlaceholder.png";
import { User } from "@/types/user";
import { reservationFormModule } from "@/store/reservation_form/reservation_form";
import { Prop } from "vue-property-decorator";
import NotificationsDropdown from "@/components/Header/components/NotificationsDropdown/NotificationsDropdown.vue";
import { notificationsModule } from "@/store/notifications/notifications";
import { Language } from "@/types/language";
import { StorageKeys } from "@/utils/consts";

@Component({
    components: { NotificationsDropdown, Button },
})
export default class HeaderRightPartUser extends Vue {
    ButtonType = ButtonType;

    @Prop({ default: true }) readonly isAgentRoot!: boolean;

    notificationsDropdownOpen = false;

    userPhoto = this.profilePicture;

    mounted(): void {
        notificationsModule.fetchNotificationsFirstPage();
        const lang = localStorage.getItem(StorageKeys.LANGUAGE) ?? "bs";
        this.$i18n.locale = lang;
    }

    onLogoutClicked(): void {
        loginModule.performLogoutAction();
    }

    toggleNotificationsPopup(): void {
        this.notificationsDropdownOpen = !this.notificationsDropdownOpen;
    }

    goToSettings(): void {
        goBackOrPush(RouteLink.UserSettings);
    }

    goToActiveReservation(): void {
        goBackOrPush(RouteLink.ManageReservation);
    }

    toggleLang(): void {
        const nextLang = this.$i18n.locale === Language.en ? Language.bs : Language.en;
        localStorage.setItem(StorageKeys.LANGUAGE, nextLang);
        this.$i18n.locale = nextLang;
    }

    get checkReservationButtonDisabled(): boolean {
        return reservationFormModule.remainingPassengersToAssign !== 0 || reservationFormModule.remainingRoomsToAssign !== 0;
    }

    get reservationInProgress(): boolean {
        return reservationFormModule.reservation !== null;
    }

    get user(): User | null {
        return loginModule.user;
    }

    replaceByUserPlaceholder(): void {
        this.userPhoto = userPlaceholder;
    }

    get profilePicture(): string {
        return this.user?.profilePicture?.thumbnail ? (storage_url + this.user?.profilePicture.thumbnail.thumbnailUrl) : userPlaceholder;
    }

    get langSwitchButtonText(): string {
        return this.$i18n.locale === Language.bs ? "EN" : "BS";
    }
}
