
import Component from "vue-class-component";
import Vue from "vue";
import Button, { ButtonType } from "@/components/Ui/Button/Button.vue";
import InputLabel from "@/components/Ui/InputLabel/InputLabel.vue";
import InputField, { InputFieldDisplayType } from "@/components/Ui/InputField/InputField.vue";
import Uploader from "@/components/Ui/Uploader/Uploader.vue";
import { RouteLink, RouteName } from "@/router";
import { loginModule } from "@/store/login/login";
import { User } from "@/types/user";
import Spinner from "@/components/Ui/Spinner/Spinner.vue";
import AdminPageHeader from "@/components/AdminPageHeader/AdminPageHeader.vue";
import CustomUploader from "@/components/Ui/CustomUploader/CustomUploader.vue";
import ManageUser from "@/views/ManageUser/ManageUser.vue";
import { usersModule } from "@/store/users/users";
import { Watch } from "vue-property-decorator";
import GeneralSettings from "@/views/Settings/components/GeneralSettings.vue";
import GeneralSettingsAgent from "@/views/Settings/components/GeneralSettingsAgent.vue";

@Component({
    components: {
        GeneralSettingsAgent,
        GeneralSettings,
        ManageUser,
        CustomUploader,
        AdminPageHeader,
        Spinner,
        Uploader,
        InputField,
        InputLabel,
        Button
    },
})
export default class Settings extends Vue {

    ButtonType = ButtonType;
    activeButtonType = ButtonType.PLAINWHITE;
    inactiveButtonType = ButtonType.TRANSPARENT;
    InputFieldDisplayType = InputFieldDisplayType
    RouteLink = RouteLink;
    RouteName = RouteName;

    get userSettingsActive(): boolean {
        return this.$route.name === RouteName.UserSettings;
    }

    get async(): boolean {
        return loginModule.userAsync || loginModule.loginAsync;
    }

    get user(): User | null {
        return loginModule.user;
    }

    get isAgent(): boolean {
        return loginModule.isAgent;
    }

    get isAdmin(): boolean {
        return loginModule.isSuperAdmin;
    }

    switchTabs(routeLink: string): void {
        this.$router.replace(routeLink).catch(() => {/**/
        });
    }

    @Watch("user")
    onUserUpdated(): void {
        usersModule.selectUser(this.user);
    }

    beforeMount(): void {
        if (this.user) {
            usersModule.selectUser(this.user);
        }
    }
}
