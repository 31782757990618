import { MediaObject } from "@/types/mediaObject";
import { User } from "@/types/user";

export enum AgencyStatus {
    ACTIVE = "ACTIVE",
    DISABLED = "DISABLED",
}

export interface IAgencyForm {
    name?: string;
    address?: string;
    discount?: AgencyDiscount;
    phone?: string;
    email?: string;
    password?: string;
    contractLength?: number;
    agencyMarkup?: number;
    agents?: Array<string>
    idNumber?: string
}

export class AgencyForm implements IAgencyForm {
    name?: string;
    address?: string;
    discount?: AgencyDiscount;
    phone?: string;
    email?: string;
    password?: string;
    contractLength?: number;
    agencyMarkup?: number;
    agents?: Array<string>
    idNumber?: string

    static createFormWithAgency(agency: Agency): IAgencyForm {

        const { name, address, discount, phone, email, agencyMarkup, idNumber } = agency;

        return {
            name,
            phone: phone || '',
            address: address || '',
            discount: {
                additionalContentCategories: discount.additionalContentCategories || {},
            },
            email: email || '',
            password: '',
            contractLength: 0,
            agencyMarkup: agencyMarkup,
            idNumber: idNumber || '',
        }
    }
}

export interface AdditionalContentCategoryDiscount {
    [key: string]: number,
}

export interface AgencyDiscount {
    additionalContentCategories: AdditionalContentCategoryDiscount;
}

export interface ListAgency {
    id: string,
    createdAt: string,
    name: string,
    address: string | null,
    city: string | null,
    postalCode: string | null,
    state: string | null,
    phone: string | null,
    status: AgencyStatus,
    agencyMarkup: number,
    image: MediaObject | null
}

export interface Agency {
    id: string,
    createdAt: string,
    name: string,
    discount: AgencyDiscount,
    address: string | null,
    city: string | null,
    postalCode: string | null,
    state: string | null,
    phone: string | null,
    email: string | null,
    website: string | null,
    trn: string | null,
    idNumber: string | null,
    vatNumber: string | null,
    agencyMarkup: number,
    status: AgencyStatus,
    image: MediaObject | null,
    agents: Array<User>
}
