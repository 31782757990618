var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_vm.async)?_c('div',{staticClass:"agency-discounts"},[_c('div',{staticClass:"additional-content"},[_c('h4',[_vm._v("Dodatni Sadržaj")]),(_vm.agencyForm.discount)?_c('div',{staticClass:"additional-content-discount-list"},_vm._l((_vm.additionalContentCategories),function(additionalContentCategory){return _c('div',{key:additionalContentCategory.id,staticClass:"single-additional-content-discount"},[_c('input-label',{attrs:{"label":additionalContentCategory.nameBs + ' (%)'}},[_c('input-field',{attrs:{"type":_vm.InputFieldType.NUMBER,"max":100},model:{value:(
								_vm.agencyForm.discount.additionalContentCategories[
									additionalContentCategory.id
								]
							),callback:function ($$v) {_vm.$set(_vm.agencyForm.discount.additionalContentCategories, 
									additionalContentCategory.id
								, $$v)},expression:"\n\t\t\t\t\t\t\t\tagencyForm.discount.additionalContentCategories[\n\t\t\t\t\t\t\t\t\tadditionalContentCategory.id\n\t\t\t\t\t\t\t\t]\n\t\t\t\t\t\t\t"}})],1)],1)}),0):_vm._e()]),_c('Button',{staticClass:"save-btn",attrs:{"loading":_vm.async,"type":_vm.ButtonType.GREEN},on:{"click":_vm.onSubmit}},[_vm._v(" Spasi ")])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }