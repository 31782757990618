
import Vue from "vue";
import Component from "vue-class-component";
import InputLabel from "../../../components/Ui/InputLabel/InputLabel.vue";
import InputField from "../../../components/Ui/InputField/InputField.vue";
import { Prop } from "vue-property-decorator";
import { AdditionalContentForm } from "@/types/additionalContent";
import TextareaField from "@/components/Ui/TextareaField/TextareaField.vue";
import { AdditionalContentCategory } from "@/types/additionalContentCategory";
import AddContentRenderer from "@/components/AddContentRenderer/AddContentRenderer.vue";
import { AddForm } from "@/types/addForm/addForm";

@Component({
    components: { AddContentRenderer, TextareaField, InputField, InputLabel }
})
export default class DynamicFieldsForm extends Vue {
    @Prop() contentForm!: AdditionalContentForm;
    @Prop() category?: AdditionalContentCategory;
    @Prop({ default: 0 }) readonly activeStepNumber!: number;

    get formFields(): AddForm | null {
        console.log(this.contentForm);
        console.log(this.category);
        if (this.category) {
            return AddForm.parse(JSON.stringify(this.category.fields));
        }

        return null;
    }
}
