import { beds, jsonApiCall } from "@/api/apiCall";
import {
    PaginationResponse,
    RawHydraResponse,
    RawHydraResponseIntoPaginationResponse,
} from "@/types/paginationResponse";
import { Bed, IBedForm } from "@/types/beds";

export async function fetchAllBeds(): Promise<Array<Bed> | null> {
    const r = await jsonApiCall<Array<Bed>>({
        url: beds,
        method: "GET",
    });

    return r;
}

export async function fetchBedsPage(page: number, perPage: number): Promise<PaginationResponse<Bed> | null> {
    const r = await jsonApiCall<RawHydraResponse<Bed>>({
        url: `${beds}?page=${page}&per_page=${perPage}`,
        method: "GET",
        headers: {
            "accept": "application/ld+json",
        },
    });

    if (r !== null) {
        return RawHydraResponseIntoPaginationResponse<Bed>(r);
    } else {
        return null;
    }
}

export async function addBed(data: IBedForm): Promise<Bed | null> {
    const r = await jsonApiCall<Bed>({
        url: beds,
        method: "POST",
        jsonData: data as Record<string, unknown>
    });

    if (r !== null) {
        return r;
    }

    return null;
}

export async function editBed(bedId: string, data: IBedForm): Promise<Bed | null> {
    const r = await jsonApiCall<Bed>({
        url: `${beds}/${bedId}`,
        method: "PUT",
        jsonData: data as Record<string, unknown>
    });

    if (r !== null) {
        return r;
    }

    return null;
}

export async function fetchBedById(bedId: string): Promise<Bed | null> {
    const r = await jsonApiCall<Bed>({
        url: `${beds}/${bedId}`,
    });

    if (r !== null) {
        return r;
    }

    return null;
}

export async function deleteBed(bedId: string): Promise<null> {

    const r = await jsonApiCall<null>({
        url: `${beds}/${bedId}`,
        method: "DELETE"
    })

    console.log(r);

    return r;
}
