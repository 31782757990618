
import Component from "vue-class-component";
import Vue from "vue";
import { additionalContentCategoriesModule } from "@/store/additional_content_categories/additional_content_categories";
import { AdditionalContentCategory } from "@/types/additionalContentCategory";
import { AddForm } from "@/types/addForm/addForm";
import AddContentRenderer from "@/components/AddContentRenderer/AddContentRenderer.vue";
import Spinner from "@/components/Ui/Spinner/Spinner.vue";
import InputLabel from "@/components/Ui/InputLabel/InputLabel.vue";
import InputField from "@/components/Ui/InputField/InputField.vue";
import TextareaField from "@/components/Ui/TextareaField/TextareaField.vue";
import Button, { ButtonType } from "@/components/Ui/Button/Button.vue";
import { AdditionalContentForm } from "@/types/additionalContent";
import cloneDeep from "lodash/cloneDeep";
import { additionalContentModule } from "@/store/additional_content/additional_content";
import { MediaObject } from "@/types/mediaObject";

@Component({
    components: { Button, TextareaField, InputField, InputLabel, Spinner, AddContentRenderer },
})
export default class AddNewAdditionalContent extends Vue {
    additionalContentForm = new AdditionalContentForm({
        nameBs: "",
        nameEn: "",
        descriptionBs: "",
        priceInCents: 0,
    });

    values: Record<string, unknown> = {};

    form: AddForm | null = null;

    basicDataEntered = false;
    showSubmitButton = false;

    ButtonType = ButtonType;

    get additionalContentCategory(): AdditionalContentCategory | null {
        return additionalContentCategoriesModule.selectedAdditionalContentCategory;
    }

    get additionalContentCategoryName(): string {
        return this.additionalContentCategory?.nameBs ?? "";
    }

    get submitAsync(): boolean {
        return additionalContentModule.addEditAsync;
    }

    get async(): boolean {
        return additionalContentCategoriesModule.fetchAsync;
    }

    rendererPageChange(page: number): void {
        this.showSubmitButton = page === ((this.form?.pages.length ?? 0) - 1);
    }

    beforeDestroy(): void {
        additionalContentCategoriesModule.selectAdditionalContentCategory(null);
    }

    validateForm(): boolean {
        if ((this.additionalContentForm.nameBs && (this.additionalContentForm.nameBs as string).length > 0)
            && this.additionalContentForm.descriptionBs && (this.additionalContentForm.descriptionBs as string).length > 0) {
            return false;
        }

        return true;
    }

    onBasicDataNextClick(): void {
        if (!this.validateForm()) {
            this.basicDataEntered = true;
        }
    }

    async beforeMount(): Promise<void> {
        const { params: { categoryId } } = this.$route;

        let selectedCategory = additionalContentCategoriesModule.selectedAdditionalContentCategory;

        if (!selectedCategory) {
            selectedCategory = await additionalContentCategoriesModule.selectAdditionalContentCategoryById(categoryId);
        }

        if (selectedCategory !== null) {
            this.form = AddForm.parse(JSON.stringify(selectedCategory.fields));
        }
    }

    async onSubmitForm(): Promise<void> {
        const data: AdditionalContentForm = cloneDeep(this.additionalContentForm);

        data.category = this.additionalContentCategory?.id;

        if (this.values.images !== undefined) {
            data.mediaObjects = ((this.values.images ?? []) as Array<MediaObject>).map(i => i.id);
            delete this.values.images;
        }

        data.priceInCents = +(data.priceInCents ?? 0);
        data.values = cloneDeep(this.values);
        if (data.category) {
            data.category = `/api/additional_content_categories/${data.category}`;
        }

        await additionalContentModule.addAdditionalContentAction(data);

        this.$router.back();
    }
}
