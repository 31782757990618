
import Vue from "vue";
import Component from "vue-class-component";
import InputLabel from "../../../components/Ui/InputLabel/InputLabel.vue";
import InputField from "../../../components/Ui/InputField/InputField.vue";
import { Prop } from "vue-property-decorator";
import { RoomBed, RoomForm } from "@/types/room";
import TextareaField from "../../../components/Ui/TextareaField/TextareaField.vue";
import NumberPlusMinus from "@/components/NumberPlusMinus/NumberPlusMinus.vue";
import { Bed, RoomBedForm } from "@/types/beds";
import { addRoomBed, deleteRoomBed } from "@/api/rooms/rooms";
import { roomsModule } from "@/store/rooms/rooms";
import SelectField, { SelectFieldOption } from "@/components/Ui/SelectField/SelectField.vue";
import cloneDeep from "lodash/cloneDeep";
import Button, { ButtonType } from "@/components/Ui/Button/Button.vue";
import { bedsModule } from "@/store/beds/beds";

@Component({
    components: { Button, SelectField, NumberPlusMinus, TextareaField, InputField, InputLabel },
})
export default class RoomRatingForm extends Vue {
    @Prop() roomForm!: RoomForm;

    addMode = false;
    selectedBed: string | null = null;

    nBedsInRoom = 0;
    bedCapacity = 0;
    bedDescription = '';

    addAsync = false;

    ButtonType = ButtonType;

    get bedsAsync(): boolean {
        return bedsModule.fetchAsync;
    }

    get bedOptions(): Array<SelectFieldOption> {
        if (this.bedsAsync) {
            return [];
        } else {
            return cloneDeep(bedsModule.beds).map((bed: Bed): SelectFieldOption => {
                return {
                    label: bed.name,
                    value: bed.id,
                    topDash: false,
                };
            });
        }
    }

    bedCapacityText(bed: Bed | string): string {
        if (typeof bed === "string") return '';

        return bed.capacity + (bed.capacity === 1 ? ' gost' : ' gostiju');
    }

    newBedOption(bedName: string): SelectFieldOption {
        return {
            label: bedName,
            value: bedName,
            topDash: false,
        }
    }

    selectBed(value: string | null): void {
        this.addMode = value !== null && this.bedOptions.findIndex(b => b.value === value) === -1;      // Set or reset add mode
        this.selectedBed = value;
    }

    onAddRoomBed(): void {

        if (!this.selectedBed) {
            return;
        }

        const bed = this.addMode ? {
            name: this.selectedBed,
            capacity: this.bedCapacity,
            description: this.bedDescription,
        } as Bed : this.selectedBed as string

        const newRoomBed: RoomBedForm = {
            bed,
            quantity: this.nBedsInRoom,
            roomVariant: roomsModule.selectedRoom?.id
        }

        this.addAsync = true;

        addRoomBed(newRoomBed).then(() => {
            this.$emit('refreshRoomData');
            this.resetState();
        }).finally(() => this.addAsync = false)
    }

    onRemoveRoomBed(roomBed: RoomBed & RoomBedForm): void {

        // Atomic (neki confirm dialog)
        if (roomBed.id) {
            deleteRoomBed(roomBed.id).then(() => {
                this.$emit('refreshRoomData');
            })
        }
    }

    resetState(): void {
        this.addMode = false;
        this.nBedsInRoom = 0;
        this.bedCapacity = 0;
        this.bedDescription = '';
        this.selectedBed = null;
    }

    beforeMount(): void {
        bedsModule.fetchAllBeds();
    }
}
