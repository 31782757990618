import Vue from 'vue';
import VueI18n from "vue-i18n";
import translationEn from "./en/en.json";
import translationBs from "./bs/bs.json";

export type Exact<T, SHAPE> = T extends SHAPE ? (Exclude<keyof T, keyof SHAPE> extends never ? T : never) : never;

export const isExact =
    <ExpectedShape>() =>
        <ActualShape>(x: Exact<ActualShape, ExpectedShape>): ExpectedShape => {
            return x;
        };


const hasSameKeysAsBs = isExact<typeof translationBs>();

hasSameKeysAsBs(translationEn);

const messages = {
    en: translationEn,
    bs: translationBs
}

Vue.use(VueI18n)

// Create VueI18n instance with options
const i18n = new VueI18n({
    locale: 'bs',
    messages,
    escapeParameterHtml: false
})

export default i18n;