
import Vue from "vue";
import Component from "vue-class-component";
import Button, { ButtonType } from "@/components/Ui/Button/Button.vue";
import HotelRoomCard from "@/views/SingleHotel/components/HotelRoomCard.vue";
import Receipt from "@/views/SingleReservation/components/Receipt.vue";
import { ReservationForm } from "@/types/reservation";
import ReservationRoomCard from "@/views/SingleReservation/components/ReservationRoomCard.vue";
import ReservationHeader from "@/views/ManageReservation/components/ReservationHeader.vue";
import FinishReservation from "@/views/ManageReservation/components/FinishReservation.vue";
import { RouteLink, RouteName } from "@/router";
import { reservationFormModule, ReservationFormSelectedRoomVariant } from "@/store/reservation_form/reservation_form";
import Breadcrumbs, { Breadcrumb } from "@/components/Ui/Breadcrumbs/Breadcrumbs.vue";
import { AdditionalContentCategory } from "@/types/additionalContentCategory";
import { additionalContentCategoriesModule } from "@/store/additional_content_categories/additional_content_categories";
import Spinner from "@/components/Ui/Spinner/Spinner.vue";
import ReservationAdditionalContent from "@/views/ManageReservation/components/ReservationAdditionalContent.vue";
import { goBackOrPush, showAreYouSureSwal } from "@/utils/utils";
import { Room } from "@/types/room";
import OfferCreationModal from "@/views/Offers/components/OfferCreationModal.vue";
import InputLabel from "@/components/Ui/InputLabel/InputLabel.vue";
import TextareaField from "@/components/Ui/TextareaField/TextareaField.vue";
import { AdditionalContentUtils } from "@/types/additionalContent";
import { Language } from "@/types/language";

export enum ManageReservationSectionType {
    ACCOMODATION = "ACCOMODATION",
    MISC = "MISC"
}

export interface ManageReservationSection {
    name: string,
    type: string,
    isMultiselect: boolean,
    isPerPerson: boolean,
    canPriceBeDoubled: boolean
}

@Component({
    components: {
        TextareaField,
        InputLabel,
        OfferCreationModal,
        ReservationAdditionalContent,
        Spinner,
        Breadcrumbs,
        FinishReservation,
        ReservationHeader,
        Button,
        ReservationRoomCard,
        Receipt,
        HotelRoomCard,
    },
})
export default class ManageReservation extends Vue {
    ButtonType = ButtonType;
    ManageReservationSectionType = ManageReservationSectionType;

    selectedSection: string = ManageReservationSectionType.ACCOMODATION;

    createOfferModalOpened = false;

    get offerCreatedForCurrentData(): boolean {
        return reservationFormModule.offerCreatedForCurrentData;
    }

    get offerAsync(): boolean {
        return reservationFormModule.offerAsync;
    }

    get generatePdfAsync(): boolean {
        return reservationFormModule.generatePdfAsync;
    }

    get rooms(): Array<{ room: Room, variants: Record<string, ReservationFormSelectedRoomVariant> }> {
        return reservationFormModule.rooms;
    }

    get sections(): Array<ManageReservationSection> {
        let sections: Array<ManageReservationSection> = [{
            name: this.$tc('accomodation'),
            type: ManageReservationSectionType.ACCOMODATION,
            isMultiselect: false,
            isPerPerson: false,
            canPriceBeDoubled: false,
        }];

        this.additionalContentCategories.forEach((category) => {
            sections.push({
                name: AdditionalContentUtils.resolveName(category, this.$i18n.locale as Language) ?? "",
                type: category.id,
                isMultiselect: category.isMultiSelect,
                isPerPerson: category.isPerPerson,
                canPriceBeDoubled: category.canPriceBeDoubled,
            });
        });

        sections.push({
            name: "MISC",
            type: ManageReservationSectionType.MISC,
            isMultiselect: false,
            isPerPerson: false,
            canPriceBeDoubled: false,
        })

        return sections;
    }

    get selectedSectionObj(): ManageReservationSection | null {
        return this.sections.find(sec => sec.type === this.selectedSection) ?? null;
    }

    get additionalContentCategoriesAsync(): boolean {
        return additionalContentCategoriesModule.fetchAsync;
    }

    get additionalContentCategories(): Array<AdditionalContentCategory> {
        return additionalContentCategoriesModule.additionalContentCategories;
    }

    get reservationForm(): ReservationForm | null {
        return reservationFormModule.reservation;
    }

    get breadcrumbs(): Array<Breadcrumb> {
        return reservationFormModule.breadcrumbs;
    }

    selectSection(section: string): void {
        this.selectedSection = section;
    }

    async cancel(): Promise<void> {
        const r = await showAreYouSureSwal({
            text: "Ovo će poništiti sve trenutne odabire",
        });

        if (r.isConfirmed) {
            reservationFormModule.cancelReservation();
            goBackOrPush(RouteLink.Hotels);
        }
    }

    mounted(): void {
        additionalContentCategoriesModule.fetchAllAdditionalContentCategories();
    }

    get currentSectionIndex(): number {
        return this.sections.findIndex(s => s.type === this.selectedSection);
    }

    get hasPreviousStep(): boolean {
        return this.currentSectionIndex !== -1 && this.currentSectionIndex > 0;
    }

    get hasNextStep(): boolean {
        return this.currentSectionIndex !== -1 && this.currentSectionIndex < this.sections.length - 1;
    }

    async generatePartialPdf(): Promise<void> {

        const htmlResponse = await reservationFormModule.generatePartialPdf();

        if (htmlResponse) {
            const win = window.open("", "_blank");
            win?.document.write(htmlResponse);
            win?.document.close();
        }
    }

    createOffer(): void {
        this.createOfferModalOpened = true;
    }

    goToPreviousStep(): void {
        if (this.currentSectionIndex > 0) {
            const nextSection = this.sections[this.currentSectionIndex - 1];
            if (nextSection !== undefined) {
                this.selectedSection = nextSection.type;
            }
        } else if (reservationFormModule.hotel?.id) {
            this.$router.push({
                name: RouteName.SingleHotel,
                params: {
                    hotelId: reservationFormModule.hotel.id,
                },
            });
        }
    }

    goToNextStep(): void {
        if (this.currentSectionIndex !== -1) {
            if (this.currentSectionIndex < this.sections.length - 1) {
                const nextSection = this.sections[this.currentSectionIndex + 1];
                if (nextSection !== undefined) {
                    this.selectedSection = nextSection.type;
                }
            }
        }
    }

    finishReservation(): void {
        this.$router.push(RouteLink.FinishReservation);
    }


    beforeMount(): void {
        if (reservationFormModule.hotel === null) {
            this.$router.replace({
                name: RouteName.Home,
            });
        }
    }
}
