
import Component from "vue-class-component";
import Vue from "vue";
import {RoomCapacity, RoomVariant} from "@/types/roomVariant";
import {Prop} from "vue-property-decorator";
import SelectField, {SelectFieldOption} from "@/components/Ui/SelectField/SelectField.vue";
import {RoomAvailability} from "@/types/availability";
import {Pricing} from "@/types/pricing";
import {RoomServiceType} from "@/types/room";
import {AvailabilityGroups} from "@/utils/price_utils";
import {reservationFormModule} from "@/store/reservation_form/reservation_form";
import {getRoomVariantObjectMaxCapacities,} from "@/utils/utils";
import {loginModule} from "@/store/login/login";

@Component({
    components: { SelectField },
})
export default class HotelRoomVariantCard extends Vue {
    @Prop() readonly variant!: RoomVariant;
    @Prop({ default: [] }) readonly availability!: Array<RoomAvailability>;
    @Prop() readonly availabilityGroups!: AvailabilityGroups;
    @Prop({ default: 0 }) readonly maxVariantRooms!: number;
    @Prop({ default: 0 }) readonly pickedVariantRooms!: number;
    @Prop({ default: 0 }) readonly pickedVariantPassengers!: number;
    @Prop({}) readonly pickedVariantOption!: RoomServiceType;

    mounted(): void {
        if (this.roomVariantOptions.length) {
            this.$emit("changePickedRoomVariantOption", this.roomVariantOptions[0].value);
        }
    }

    get remainingRoomsToAssign(): number {
        return reservationFormModule.remainingRoomsToAssign;
    }

    get remainingPassengersToAssign(): number {
        return reservationFormModule.remainingPassengersToAssign;
    }

    // when we have two overlaping ranges of prices
    get isOverlapingAvailabilityRange(): boolean {
        return this.availabilityGroups.isOverlapingAvailabilityRange();
    }

    get roomNumberOptions(): Array<SelectFieldOption> {
        const arr: Array<SelectFieldOption> = [];

        const limit = this.remainingRoomsToAssign === 0 ? this.pickedVariantRooms : Math.min(this.remainingRoomsToAssign + this.pickedVariantRooms, this.maxVariantRooms);

        for (let i = 1; i <= limit; i++) {
            arr.push({
                label: i.toString(),
                value: i.toString(),
                topDash: false,
            });
        }

        return arr;
    }

    get maxCapacities(): RoomCapacity[] {
        return getRoomVariantObjectMaxCapacities(this.variant);
    }

    get maxTotalCapacity(): number {

        if (!this.maxCapacities[0]) {
            return 0;
        }

        return (this.maxCapacities[0].adults ?? 0) + (this.maxCapacities[0].children ?? 0);
    }

    get passengersOptions(): Array<SelectFieldOption> {
        const arr: Array<SelectFieldOption> = [];

        const limit = this.remainingPassengersToAssign === 0 ? this.pickedVariantPassengers : Math.min(this.remainingPassengersToAssign + this.pickedVariantPassengers, this.maxTotalCapacity * this.pickedVariantRooms);

        for (let i = 1; i <= limit; i++) {
            arr.push({
                label: i.toString(),
                value: i.toString(),
                topDash: false,
            });
        }

        return arr;
    }

    get variantNightAveragePrice(): number {
        if (this.pickedVariantOption === RoomServiceType.BED_AND_BREAKFAST && this.variant.variantNightAveragePrice) {      // for optimization
            return this.variant.variantNightAveragePrice;
        }
        return this.availabilityGroups.calculateVariantNightAveragePrice(this.variant, this.availability, this.pickedVariantOption, loginModule.agencyMarkup);
    }

    get roomVariantOptions(): Array<SelectFieldOption> {
        const thisVariant = this.variant.classification.toLowerCase();

        const firstAvailability = this.availability[0];

        const ret: Array<SelectFieldOption> = [];

        if (firstAvailability) {
            const availPricing = ((firstAvailability.pricing as unknown as Record<string, unknown>)[thisVariant] as Pricing);
            if (availPricing) {

                if (availPricing.standard.adults) {
                    ret.push({
                        label: this.$tc('bed_and_breakfast'),
                        value: RoomServiceType.BED_AND_BREAKFAST,
                        topDash: false,
                    });
                }

                if (availPricing.standard.ultraAllInclusive) {
                    ret.push({
                        label: "Ultra All Inclusive",
                        value: RoomServiceType.ULTRA_ALL_INCLUSIVE,
                        topDash: false,
                    });
                }

                if (availPricing.standard.halfBoardSupplement) {
                    ret.push({
                        label: this.$tc('half_board'),
                        value: RoomServiceType.HALF_BOARD,
                        topDash: false,
                    });
                }

                if (availPricing.standard.fullBoardSupplement) {
                    ret.push({
                        label: this.$tc('full_board'),
                        value: RoomServiceType.FULL_BOARD,
                        topDash: false,
                    });
                }

                if (availPricing.standard.allInclusiveSupplement) {
                    ret.push({
                        label: "All Inclusive",
                        value: RoomServiceType.ALL_INCLUSIVE,
                        topDash: false,
                    });
                }
            }
        }

        return ret;
    }

    get averagePriceTooltipMessage(): string {
        return `${this.$tc('the_average_price_due_to_the_overlap_of_different_price_periods_is_shown')}. <br/> ${this.$tc('on_the_right_side_of_the_calculation_are_individual_data')}.`
    }
}
