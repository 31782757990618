

import Component from "vue-class-component";
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import { Language } from "@/types/language";

@Component({})
export default class InputLabel extends Vue {

    @Prop({ default: false }) readonly noPadding!: boolean;
    @Prop() readonly label?: string;
    @Prop({ default: false }) readonly required!: boolean;
    @Prop() readonly error?: string;
    @Prop() readonly languages?: Array<Language>;
    @Prop() readonly activeLanguage?: Language;
}
