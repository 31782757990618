
import Component from "vue-class-component";
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import { AddFormItem_Amenities } from "@/types/addForm/addFormItem";
import { amenitiesModule } from "@/store/amenities/amenities";
import { Amenity } from "@/types/amenity";
import Spinner from "@/components/Ui/Spinner/Spinner.vue";
import Checkbox, { CheckboxType } from "@/components/Ui/Checkbox/Checkbox.vue";
import { loginModule } from "@/store/login/login";
import InputField from "@/components/Ui/InputField/InputField.vue";
import Button, { ButtonType } from "@/components/Ui/Button/Button.vue";

@Component({
    components: { Button, InputField, Checkbox, Spinner },
})
export default class AddContentAmenities extends Vue {
    @Prop() readonly item!: AddFormItem_Amenities;
    @Prop() readonly entity!: Record<string, unknown>;

    addAmenityName: string | null = null;

    addMode = false;

    CheckboxType = CheckboxType;
    ButtonType = ButtonType;

    mounted(): void {
        if (!this.entity.amenities) {
            Vue.set(this.entity, "amenities", {});
        }

        amenitiesModule.fetchAllAmenities();
    }

    cancelAddNewAmenity(): void {
        this.addAmenityName = null;
        this.addMode = false;
    }

    async addNewAmenity(): Promise<void> {
        if (this.addAmenityName !== null && this.addAmenityName.length > 0) {
            await amenitiesModule.addNewAmenity(this.addAmenityName);

            this.addMode = false;
            this.addAmenityName = null;
        }
    }

    addAmenity(): void {
        this.addMode = true;
    }

    get addAmenityAsync(): boolean {
        return amenitiesModule.addAmenitiesAsync;
    }

    get async(): boolean {
        return amenitiesModule.fetchAsync;
    }

    get amenities(): Array<Amenity> {
        return amenitiesModule.amenities;
    }

    get isSuperAdmin(): boolean {
        return loginModule.isSuperAdmin;
    }
}
