import {
    PaginationResponse,
    RawHydraResponse,
    RawHydraResponseIntoPaginationResponse,
} from "@/types/paginationResponse";
import { apiCall, endpoint, jsonApiCall, offers, users } from "@/api/apiCall";
import {
    ListOffer,
    Offer, OfferClientData,
} from "@/types/offer";
import { Passenger } from "@/types/Passenger";
import { RoomServiceType } from "@/types/room";
import { ReservationFormAdditionalContentItem } from "@/types/additionalContent";

export async function apiFetchAllOffers(): Promise<Array<ListOffer> | null> {
    return await jsonApiCall<Array<ListOffer>>({
        url: offers,
        method: "GET",
    });
}

export async function fetchOffersPage(page: number, perPage: number): Promise<PaginationResponse<ListOffer> | null> {
    const r = await jsonApiCall<RawHydraResponse<ListOffer>>({
        url: `${offers}?page=${page}&per_page=${perPage}`,
        method: "GET",
        headers: {
            "accept": "application/ld+json",
        },
    });

    if (r !== null) {
        return RawHydraResponseIntoPaginationResponse<ListOffer>(r);
    } else {
        return null;
    }
}

export async function fetchOfferById(offerId: string): Promise<Offer | null> {
    const r = await jsonApiCall<Offer>({
        url: `${offers}/${offerId}`,
    });

    if (r !== null) {
        return r;
    }

    return null;
}

export interface OfferPostVariantData {
    id: string,
    q: number,
    serviceType: RoomServiceType,
    passengers: number
}

export interface OfferPostData extends OfferClientData {
    from: string;
    to: string;
    variantsData: Array<OfferPostVariantData>;
    passengers: Array<Passenger>;
    offerAdditionalContents: Array<ReservationFormAdditionalContentItem>;
}

export async function editListOffer(offerId: string, data: Partial<ListOffer>): Promise<ListOffer | null> {
    const r = await jsonApiCall<ListOffer>({
        url: `${offers}/${offerId}`,
        method: "PUT",
        jsonData: data as Record<string, any>,
    });

    if (r !== null) {
        return r;
    }

    return null;

}

export async function addOffer(data: OfferPostData): Promise<Offer | null> {
    const r = await jsonApiCall<Offer>({
        url: offers,
        method: "POST",
        jsonData: data as Record<string, any>,
    });

    if (r !== null) {
        return r;
    }

    return null;
}

export async function deleteOffer(offerId: string): Promise<null> {
    const r = await jsonApiCall<null>({
        url: `${offers}/${offerId}`,
        method: "DELETE",
    });

    console.log(r);

    return r;
}

export async function printOffer(offerId: string): Promise<string | null> {
    return await apiCall({
        url: `${offers}/${offerId}/pdf`,
        method: "GET"
    })
        .then(r => r?.text() ?? null);
}
