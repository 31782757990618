
import Vue from "vue";
import SingleHotelHeader from "@/views/SingleHotel/components/SingleHotelHeader.vue";
import Spinner from "@/components/Ui/Spinner/Spinner.vue";
import SingleHotelImages from "@/views/SingleHotel/components/SingleHotelImages.vue";
import SingleHotelRatings from "@/views/SingleHotel/components/SingleHotelRatings.vue";
import SingleHotelLocation from "@/views/SingleHotel/components/SingleHotelLocation.vue";
import SingleHotelRooms from "@/views/SingleHotel/components/SingleHotelRooms.vue";
import Component from "vue-class-component";
import { Hotel } from "@/types/hotel";
import { hotelsModule } from "@/store/hotels/hotels";
import { loginModule } from "@/store/login/login";
import { goBackOrPush } from "@/utils/utils";
import { RouteLink } from "@/router";
import Receipt from "@/views/SingleReservation/components/Receipt.vue";
import { reservationFormModule } from "@/store/reservation_form/reservation_form";
import { Language } from "@/types/language";
import { clone, cloneDeep } from "lodash";

@Component({
    components: {
        Receipt,
        SingleHotelRooms,
        SingleHotelLocation,
        SingleHotelRatings,
        SingleHotelImages,
        Spinner,
        SingleHotelHeader,
    },
})
export default class SingleHotel extends Vue {

    Language = Language;

    get isAdmin(): boolean {
        return loginModule.isSuperAdmin;
    }

    get hotel(): Hotel | null {
        return hotelsModule.selectedHotel;
    }

    async beforeMount(): Promise<void> {
        const { params: { hotelId } } = this.$route;

        if (reservationFormModule.hotel?.id !== hotelId) {
            reservationFormModule.cancelReservation();
        }

        let oldHotel = hotelsModule.hotels.find((h) => h.id === hotelId) ?? null;

        if (oldHotel) {
            oldHotel = cloneDeep(oldHotel);
        }

        let hotel = await hotelsModule.fetchHotelByIdAction({ hotelId, oldHotel });

        if (hotel) {
            reservationFormModule.setReservationFormHotel(hotel);
        } else {
            goBackOrPush(RouteLink.Home);
        }
    }

    get language(): string {
        return this.$i18n.locale;
    }

    beforeDestroy(): void {
        hotelsModule.selectHotel(null);
    }
}
