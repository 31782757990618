import { PostRoomBed, RoomBed } from "@/types/room";

export enum RoomVariantClassification {
    SINGLE = "SINGLE",
    DOUBLE = "DOUBLE",
    TRIPLE = "TRIPLE",
    QUADRUPLE = "QUADRUPLE",
    QUINTUPLE = "QUINTUPLE"
}

export interface AlternativeCapacity {
    capacity1: RoomCapacity,
    capacity2: RoomCapacity,
    capacity3: RoomCapacity,
}

export interface RoomCapacity {
    adults?: number,
    children?: number
}

export interface RoomVariant {
    createdAt?: string,
    id?: string,
    classification: RoomVariantClassification,
    capacity: RoomCapacity,
    alternativeCapacity: AlternativeCapacity,
    roomBeds: Array<RoomBed>,
    extraRoomBeds: Array<RoomBed>
    hotelMargin?: number
    variantNightAveragePrice?: number,
    roomId?: string
}

export interface RoomVariantPostData {
    roomBeds?: Array<PostRoomBed>,
    extraRoomBeds?: Array<PostRoomBed>
    capacity?: RoomCapacity,
    alternativeCapacity?: AlternativeCapacity,

    classification: RoomVariantClassification,
    hotelId: string,
    roomName: string,
}

export interface RoomVariantPutData {
    roomBeds: Array<RoomBed | string>,
    extraRoomBeds: Array<RoomBed | string>,
    capacity: RoomCapacity,
    alternativeCapacity: AlternativeCapacity,
}
