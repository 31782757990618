
import Vue from "vue";
import Component from "vue-class-component";
import Container from "@/components/Ui/Container/Container.vue";
import { Prop } from "vue-property-decorator";
import { Passenger, PassengerAge } from "@/types/Passenger";
import { getStorageUrl } from "@/utils/utils";
import { MediaObject } from "@/types/mediaObject";

@Component({
    components: { Container },
})
export default class PassengerCard extends Vue {
    @Prop() readonly passenger!: Passenger;

    openPassportImage(): void {
        if (this.passenger.passportImage) {
            window.open(getStorageUrl(this.passenger.passportImage as MediaObject), "_blank");
        }
    }

    openPassengerImage(): void {
        if (this.passenger.passengerImage) {
            window.open(getStorageUrl(this.passenger.passengerImage as MediaObject), "_blank");
        }
    }

    get displayAge(): string {
        switch (this.passenger.age) {
            case PassengerAge.ADULT:
                return "Odrasli";
            case PassengerAge.CHILD_UNDER_6:
                return "Dijete (do 6 godina)";
            case PassengerAge.CHILD_UNDER_12:
                return "Dijete";
            default:
                return "";
        }
    }
}
