
import ClickOutside from 'vue-click-outside';
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import InputLabel from "@/components/Ui/InputLabel/InputLabel.vue";
import InputField from "@/components/Ui/InputField/InputField.vue";
import { addDays } from "date-fns";

export interface DateRange {
    start: Date,
    end: Date
}

@Component({
    components: { InputField, InputLabel },
    directives: {
        ClickOutside,
    },
})
export default class DateRangePickerWrapper extends Vue {
    @Prop() readonly value?: DateRange | null;
    @Prop({ default: false }) readonly compact!: boolean;
    @Prop({}) readonly startDateLabel!: string;
    @Prop({}) readonly endDateLabel!: string;

    dateRangePickerOpen = false;

    get minDate(): Date {
        return addDays(new Date(), 1);
    }

    get startDate(): Date {
        return this.value?.start || new Date();
    }

    get endDate(): Date {
        return this.value?.end || new Date();
    }

    hideDatePicker(): void {
        this.dateRangePickerOpen = false;
    }

    onDateRangeChange(dateRange: DateRange): void {
        console.log(dateRange);
        this.$emit('input', dateRange);
    }
}
