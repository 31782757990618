import { Address } from "@/types/address";
import { Amenity } from "@/types/amenity";
import { Room } from "@/types/room";
import { cloneDeep, merge } from "lodash";
import { difference } from "@/utils/utils";
import { MediaObject } from "@/types/mediaObject";
import { DateRange } from "@/components/DateRangePickerWrapper/DateRangePickerWrapper.vue";
import { PassengersNumbers } from "@/store/home_filters/home_filters";
import { ReservationType } from "@/types/reservation";
import { Pricing } from "@/types/pricing";
import { EarlyBooking, IEarlyBookingForm } from "@/types/earlyBooking";
import {
    HotelSpecialOffer,
    HotelSponsoredLevel,
    IHotelSpecialOfferForm,
} from "@/types/hotelSpecialOffer";
import moment, { Moment } from "moment";

export interface ListHotel {
    id: string;
    name: string;
    createdAt: string;
    address: Address;
    stars: number;
    amenities: Array<Amenity>;
    featuredImage: MediaObject | null;
}

export enum HotelRatingFilterType {
    OVER4,
    MIDDLE3TO4,
    LESS3,
}

export interface HotelFilterTerms {
    amenities: Array<string>;
    rating: HotelRatingFilterType | null;
}

export interface Hotel {
    id: string;
    name: string;
    sponsored_level?: number;
    descriptionBs?: string;
    descriptionEn?: string;
    address: Address;
    stars: number;
    rating?: number;
    amenities: Array<Amenity>;
    mediaObjects: Array<MediaObject>;
    rooms: Array<Room>;
    image?: File;
    margin: number;
    featuredImage: MediaObject | null;
    earlyBookings: EarlyBooking[];
    specialOffers: HotelSpecialOffer[];
}

export interface IHotelForm {
    name?: string;
    email?: string;
    sponsored_level?: number;
    descriptionBs?: string;
    descriptionEn?: string;
    address?: Address;
    rating?: number;
    stars?: number;
    price?: number;
    amenities?: Array<string>;
    margin?: number | null;
    rooms?: Array<string>;
    mediaObjects?: Array<string | MediaObject>;
    earlyBookings?: Array<IEarlyBookingForm>;
    specialOffers?: Array<IHotelSpecialOfferForm>;
}

export class HotelForm implements IHotelForm {
    name?: string;
    email?: string;
    sponsored_level?: number;
    descriptionBs?: string;
    descriptionEn?: string;
    address?: Address = {
        street: "",
        city: "",
        country: "",
        lat: "",
        lng: "",
        postalCode: undefined,
    };
    rating?: number;
    margin: number | null = null;
    stars?: number;
    price?: number;
    rooms: Array<string> = [];
    amenities: Array<string> = [];
    mediaObjects: Array<string | MediaObject> = [];
    earlyBookings: Array<IEarlyBookingForm> = [];
    specialOffers: Array<IHotelSpecialOfferForm> = [];

    constructor(props: IHotelForm = {}) {
        merge(this, props); // TODO, myb this is better
    }

    static createFormWithHotel(hotel: Hotel): HotelForm {
        const { rooms, amenities, mediaObjects, ...needed } = hotel;

        return new HotelForm({
            ...needed,
            amenities: amenities.map((a) => a.id),
            rooms: rooms.map((r) => r.id),
            mediaObjects: cloneDeep(mediaObjects),
        });
    }

    public validate(): boolean {
        return this.name !== "" && this.email !== ""; // TODO
    }

    public prepareFormForSending(): void {
        this.rating = this.rating && +this.rating;
        this.stars = this.stars && +this.stars;
        this.margin = this.margin && +this.margin;
    }

    public difference(anotherForm: IHotelForm): IHotelForm {
        const diff = difference(this as IHotelForm, anotherForm) as IHotelForm;

        // Override diff
        if (diff.amenities !== undefined) {
            diff.amenities = diff.amenities.filter(
                (a) => a !== null && a !== undefined,
            );
        }
        if (diff.mediaObjects !== undefined) {
            diff.mediaObjects = this.mediaObjects.map((m) =>
                typeof m === "string" ? m : m.id,
            );
        }
        if (diff.address !== undefined) {
            diff.address = merge(cloneDeep(anotherForm.address), this.address);
        }

        if (diff.earlyBookings !== undefined) {
            diff.earlyBookings = this.earlyBookings.map((m) => {
                if (!m.id?.includes("tempid")) {
                    m["@id"] = m.id; // For deep update
                }

                delete m.id;

                return m;
            });
        }

        delete diff.specialOffers;
        delete diff.rooms;

        return diff;
    }
}

export interface HotelsQuery {
    dateRange: DateRange;
    passengersData: PassengersNumbers;
    roomsNumber: number;
    location: string;
    type: ReservationType;
    searchText: string;
}

export function getHotelPrice(hotel: Hotel): number {
    let min = Number.MAX_SAFE_INTEGER;

    (hotel.rooms || []).forEach((room) => {
        room.availabilities.forEach((avail) => {
            Object.values(avail.pricing).forEach((variantPricing: Pricing) => {
                let p = variantPricing.standard?.adults ?? 0;

                if (p === 0) {
                    p = variantPricing.standard?.halfBoardSupplement ?? 0;
                }
                if (p === 0) {
                    p = variantPricing.standard?.fullBoardSupplement ?? 0;
                }
                if (p === 0) {
                    p = variantPricing.standard?.allInclusiveSupplement ?? 0;
                }

                if (min > p && p !== 0) {
                    min = p;
                }
            });
        });
    });

    return min === Number.MAX_SAFE_INTEGER ? 0 : min;
}

export function getSponsoredLevelForHotel(
    hotel: Hotel,
    fromDate: string | Moment,
    toDate: string | Moment,
): HotelSponsoredLevel | null {
    if (!hotel.specialOffers.length) {
        return null;
    }

    let sponsoredLevel: HotelSponsoredLevel | null = null;

    hotel.specialOffers.forEach((offer) => {
        const areReservationDatesInsideOfferDates =
            moment(fromDate).isSameOrAfter(moment(offer.fromDate), "day") &&
            moment(toDate).isSameOrBefore(moment(offer.toDate), "day");

        if (
            areReservationDatesInsideOfferDates &&
            offer.sponsoredLevel < (sponsoredLevel ?? Infinity)
        ) {
            sponsoredLevel = offer.sponsoredLevel;
        }
    });

    return sponsoredLevel;
}

export interface HotelWithStatistics extends Hotel {
    reservationCount: number;
}

export interface HotelWithSpecialOffers extends ListHotel {
    specialOffers: Array<HotelSpecialOffer>;
    // specialOffersLevel1: Array<HotelSpecialOffer>;
    // specialOffersLevel2: Array<HotelSpecialOffer>;
    // specialOffersLevel3: Array<HotelSpecialOffer>;
}
