

import Vue from "vue";
import Component from "vue-class-component";
import InputLabel from "@/components/Ui/InputLabel/InputLabel.vue";
import InputField from "@/components/Ui/InputField/InputField.vue";
import TextareaField from "@/components/Ui/TextareaField/TextareaField.vue";
import { Passenger } from "@/types/Passenger";
import SelectField from "@/components/Ui/SelectField/SelectField.vue";
import Checkbox, { CheckboxType } from "@/components/Ui/Checkbox/Checkbox.vue";
import ReservationHeader from "@/views/ManageReservation/components/ReservationHeader.vue";
import Button, { ButtonType } from "@/components/Ui/Button/Button.vue";
import { RouteLink } from "@/router";
import Receipt from "@/views/SingleReservation/components/Receipt.vue";
import Breadcrumbs, { Breadcrumb } from "@/components/Ui/Breadcrumbs/Breadcrumbs.vue";
import { reservationFormModule } from "@/store/reservation_form/reservation_form";
import { ReservationForm } from "@/types/reservation";
import { goBackOrPush } from "@/utils/utils";
import { UploadedFile } from "@/types/mediaObject";
import CustomUploader from "@/components/Ui/CustomUploader/CustomUploader.vue";
import CustomModal from "@/components/Ui/CustomModal/CustomModal.vue";
import UploaderModal from "@/components/UploaderModal/UploaderModal.vue";
import PlaceholderableImage from "@/components/Ui/PlaceholderableImage/PlaceholderableImage.vue";
import PassengersForm from "@/views/ManageReservation/components/PassengerForm.vue";

enum ImagePickerMode {
    Passenger = 'Passenger',
    Passport = 'Passport'
}

@Component({
    components: {
        PassengersForm,
        PlaceholderableImage,
        UploaderModal,
        CustomModal,
        CustomUploader,
        Breadcrumbs,
        Receipt,
        Button,
        ReservationHeader,
        Checkbox,
        SelectField,
        TextareaField,
        InputField,
        InputLabel
    },
})
export default class FinishReservation extends Vue {
    ButtonType = ButtonType;
    CheckboxType = CheckboxType;

    currentPassengerIndex: number | null = null;

    imagePickerMode: ImagePickerMode | null = null;

    images: Array<UploadedFile> = [];

    get generatePdfAsync(): boolean {
        return reservationFormModule.generatePdfAsync;
    }

    get email(): string {
        return reservationFormModule.mainPassengerEmail;
    }

    get phone(): string {
        return reservationFormModule.mainPassengerPhone;
    }

    get notices(): string {
        return reservationFormModule.mainPassengerNotices;
    }

    get passengers(): Array<Passenger> {
        return this.reservationForm?.passengers ?? [new Passenger({ isMainPassenger: true })];
    }

    get reservationForm(): ReservationForm | null {
        return reservationFormModule.reservation ?? null;
    }

    get breadcrumbs(): Array<Breadcrumb> {
        return reservationFormModule.breadcrumbs;
    }

    async generatePartialPdf(): Promise<void> {

        const htmlResponse = await reservationFormModule.generatePartialPdf();

        if (htmlResponse) {
            const win = window.open("", "_blank");
            win?.document.write(htmlResponse);
            win?.document.close();
        }
    }

    cancel(): void {
        reservationFormModule.cancelReservation();
        goBackOrPush(RouteLink.Hotels);
    }

    goToPreviousStep(): void {
        this.$router.push(RouteLink.ManageReservation);
    }

    goToNextStep(): void {
        this.$router.push(RouteLink.ConfirmReservation);
    }

    changeMainPassenger(idx: number): void {
        this.passengers.forEach((passenger, index) => {
            passenger.isMainPassenger = idx === index;
        })
    }
}

