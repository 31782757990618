
import Vue from "vue";
import Component from "vue-class-component";
import HotelSearchForm from "@/components/HotelSearchForm/HotelSearchForm.vue";
import { RouteLink } from "@/router";
import { loginModule } from "@/store/login/login";
import Spinner from "@/components/Ui/Spinner/Spinner.vue";
import InputField from "@/components/Ui/InputField/InputField.vue";
import AddContentDatetime from "@/components/AddContentRenderer/AddContentItemRenderers/AddContentDatetime.vue";
import InputLabel from "@/components/Ui/InputLabel/InputLabel.vue";

@Component({
    components: {
        InputLabel,
        AddContentDatetime,
        InputField,
        Spinner,
        HotelSearchForm,
    },
})
export default class Home extends Vue {
    onSearch(): void {
        this.$router.push(RouteLink.Hotels).catch(() => {/**/
        });
    }

    get isLoggedIn(): boolean {
        return loginModule.isLoggedIn;
    }
}
