
import Vue from "vue";
import Component from "vue-class-component";
import Button, { ButtonType } from "@/components/Ui/Button/Button.vue";
import { goBackOrPush } from "@/utils/utils";
import { RouteLink } from "@/router";
import { AdditionalContent, AdditionalContentForm, IAdditionalContentForm } from "@/types/additionalContent";
import { additionalContentModule as contentModule } from "@/store/additional_content/additional_content";
import Spinner from "@/components/Ui/Spinner/Spinner.vue";
import { MediaObject, UploadedFile } from "@/types/mediaObject";
import CustomUploader from "@/components/Ui/CustomUploader/CustomUploader.vue";
import { mediaModule } from "@/store/media_objects/media_objects";
import { EntityNameEnum } from "@/api/media_objects/media_objects";
import DeleteContent from "@/views/EditAdditionalContent/components/DeleteContent.vue";
import AboutContentForm from "@/views/EditAdditionalContent/components/AboutContentForm.vue";
import AddContentRenderer from "@/components/AddContentRenderer/AddContentRenderer.vue";
import DynamicFieldsForm from "@/views/EditAdditionalContent/components/DynamicFieldsForm.vue";
import { AdditionalContentCategory } from "@/types/additionalContentCategory";
import { AddForm } from "@/types/addForm/addForm";
import { AddFormPage } from "@/types/addForm/addFormPage";
import AdminPageHeader from "@/components/AdminPageHeader/AdminPageHeader.vue";

enum EditContentSectionType {
    ABOUT = "ABOUT",
    EDITOR = "EDITOR",
    GALLERY = "GALLERY",
    DELETE = "DELETE"
}

interface EditContentSection {
    name: string,
    type: EditContentSectionType
}

@Component({
    components: {
        AdminPageHeader,
        DynamicFieldsForm,
        AddContentRenderer,
        DeleteContent,
        CustomUploader,
        Spinner,
        AboutContentForm,
        Button,
    },
})
export default class EditAdditionalContent extends Vue {
    EditContentSectionType = EditContentSectionType;
    ButtonType = ButtonType;
    goBackOrPush = goBackOrPush;
    RouteLink = RouteLink;

    sections: Array<EditContentSection> = [
        {
            name: "Osnovne informacije",
            type: EditContentSectionType.ABOUT,
        },
        {
            name: "Fotografije",
            type: EditContentSectionType.GALLERY,
        },
    ];

    selectedSection = EditContentSectionType.ABOUT;

    contentForm = new AdditionalContentForm();
    newUploads: Array<UploadedFile> = [];
    uploadAsync = false;
    selectedStep = 0;

    get contentCategory(): AdditionalContentCategory | null {
        return contentModule.selectedAdditionalContent?.category || null;
    }

    get categorySteps(): Array<AddFormPage> | undefined {
        return this.contentCategory?.fields;
    }

    get fetchAsync(): boolean {
        return contentModule.fetchAsync;
    }

    get editAsync(): boolean {
        return contentModule.addEditAsync;
    }

    selectStep(index: number): void {
        console.log(index);
        this.selectedSection = EditContentSectionType.EDITOR;
        this.selectedStep = index;
    }

    selectSection(section: EditContentSectionType): void {
        this.selectedSection = section;
    }

    backToContents(): void {
        goBackOrPush(RouteLink.HotelContent);
    }

    removeUpload(file: UploadedFile): void {
        this.newUploads = this.newUploads.filter(i => i.url !== file.url);
    }

    onRemoveMediaObject(media: MediaObject): void {
        this.contentForm.mediaObjects = this.contentForm.mediaObjects.filter(m => typeof m === "string" ? m !== media.id : m.id !== media.id);
    }

    addNewUploads(files: Array<UploadedFile>): void {
        this.newUploads.push(...files);
    }

    async onSubmit(): Promise<void> {
        if (!this.contentForm.validate()) return;

        if (contentModule.selectedAdditionalContent) {

            this.contentForm.prepareFormForSending();

            const diff = this.contentForm.difference(AdditionalContentForm.createFormWithAdditionalContent(contentModule.selectedAdditionalContent)) as IAdditionalContentForm;

            if (diff.mediaObjects && diff.mediaObjects.length > 0) {
                diff.mediaObjects = diff.mediaObjects.map(obj => {
                    if (typeof obj === "string") {
                        return `/api/media_objects/${obj}`;
                    }

                    return obj;
                });
            }

            try {

                // First edit existing data
                await contentModule.editAdditionalContentAction({
                    additionalContentId: contentModule.selectedAdditionalContent.id,
                    data: diff,
                });

                // Then we upload new images to prevent edit action override mediaObjects
                if (this.newUploads.length) {

                    const promises: Array<Promise<MediaObject | null>> = [];

                    const contentId = contentModule.selectedAdditionalContent.id;

                    this.uploadAsync = true;

                    this.newUploads.forEach(upload => {
                        if (!upload.progress) {                            // Check if already uploading
                            promises.push(mediaModule.uploadMediaObject({
                                file: upload,
                                entityData: {
                                    entityName: EntityNameEnum.AdditionalContent,
                                    entityId: contentId,
                                },
                            }).then(mediaObj => {

                                if (mediaObj !== null) {          // successful upload
                                    this.contentForm.mediaObjects.push(mediaObj);
                                    this.removeUpload(upload);
                                }

                                return mediaObj;
                            }));
                        }
                    });

                    await Promise.allSettled(promises).then(() => this.uploadAsync = false);
                }

                // this.backToContents();
            } catch (ex) {
                //
            }
        }
    }

    async beforeMount() {

        const { params: { contentId } } = this.$route;


        if (contentId) {
            await contentModule.fetchAdditionalContentByIdAction(contentId);
        }


        if (contentModule.selectedAdditionalContent) {
            this.setData(contentModule.selectedAdditionalContent);
        }
    }

    setData(content: AdditionalContent): void {
        this.contentForm = AdditionalContentForm.createFormWithAdditionalContent(content);
    }

}
