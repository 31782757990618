

import Vue from "vue";
import Component from "vue-class-component";
import CustomTable from "@/components/Ui/CustomTable/CustomTable.vue";
import ListPagination from "@/components/ListPagination/ListPagination.vue";
import Button, { ButtonType } from "@/components/Ui/Button/Button.vue";
import { RouteLink } from "@/router";

@Component({
    components: { Button, ListPagination, CustomTable },
})
export default class Content extends Vue {
    ButtonType = ButtonType;
    activeButtonType = ButtonType.PLAINWHITE;
    inactiveButtonType = ButtonType.TRANSPARENT;
    RouteLink = RouteLink;

    get hotelsActive(): boolean {
        return this.$route.path === RouteLink.HotelContent;
    }

    get contentActive(): boolean {
        return this.$route.path === RouteLink.AdditionalContent;
    }

    get contentCategoriesActive(): boolean {
        return this.$route.path === RouteLink.AdditionalContentCategories;
    }

    get bedsActive(): boolean {
        return this.$route.path === RouteLink.BedContent;
    }

    get specialOffersActive(): boolean {
        return this.$route.path === RouteLink.HotelSpecialOffers;
    }

    get newEntityBtnText(): string | null {
        if (this.hotelsActive) {
            return "Novi hotel";
        } else if (this.contentCategoriesActive) {
            return "Nova kategorija";
        } else if (this.specialOffersActive) {
            return "Nova izdvojenost";
        }

        return null;
    }

    switchTabs(routeLink: string): void {
        this.$router.replace(routeLink).catch(() => {/**/
        });
    }

    addNewEntityAction(): void {

        let route = null;

        if (this.hotelsActive) {
            route = RouteLink.AddHotel;
        } else if (this.contentCategoriesActive) {
            route = RouteLink.AddAdditionalContentCategory;
        } else if (this.specialOffersActive) {
            route = RouteLink.AddHotelSpecialOffer;
        }

        if (route) {
            this.$router.push(route);
        }
    }
}
