
import Vue from "vue";
import Component from "vue-class-component";
import { additionalContentModule } from "@/store/additional_content/additional_content";
import Spinner from "@/components/Ui/Spinner/Spinner.vue";
import CustomTable, { TableField } from "@/components/Ui/CustomTable/CustomTable.vue";
import { AdditionalContent, AdditionalContentUtils, ListAdditionalContent } from "@/types/additionalContent";
import Button from "@/components/Ui/Button/Button.vue";
import ListPagination from "@/components/ListPagination/ListPagination.vue";
import AdminPageHeader from "@/components/AdminPageHeader/AdminPageHeader.vue";
import { RouteName } from "@/router";
import { Language } from "@/types/language";

@Component({
    components: { AdminPageHeader, ListPagination, Button, CustomTable, Spinner },
    mounted() {
        additionalContentModule.fetchAdditionalContentFirstPage();
    }
})
export default class AdditionalContentTable extends Vue {
    tableFields: Array<TableField<ListAdditionalContent>> = [{
        name: "",
        title: "Sortiraj",
        slot: 'name'
    }, {
        name: "category",
        title: "Kategorija",
        slot: "category",
    }, {
        name: "city",
        title: "Grad",
        slot: "city",
    }, {
        name: "actions",
        title: "Pretraga",
        width: '10%',
        slot: "actions",
    }];

    get currentPage(): number {
        return additionalContentModule.currentPage;
    }

    get totalPages(): number {
        return additionalContentModule.totalPages;
    }

    get additionalContent(): Array<ListAdditionalContent> {
        return additionalContentModule.additionalContent;
    }

    get async(): boolean {
        return additionalContentModule.fetchAsync;
    }

    getCategoryName(content: ListAdditionalContent): string {
        return AdditionalContentUtils.resolveName(content.category) ?? "";
    }

    onPageChange(page: number): void {
        additionalContentModule.fetchAdditionalContentPageAction(page)
    }

    editContent(content: ListAdditionalContent): void {
        this.$router.push({
            name: RouteName.EditAdditionalContent,
            params: {
                contentId: content.id,
            },
        });
    }

    getAdditionalContentName(ac: AdditionalContent): string {
        return AdditionalContentUtils.resolveName(ac, this.$i18n.locale as Language) ?? "";
    }
}
