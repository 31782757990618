
import Component from "vue-class-component";
import Vue from "vue";
import { AddFormPage } from "@/types/addForm/addFormPage";
import { Prop } from "vue-property-decorator";
import AddContentItem from "@/components/AddContentRenderer/AddContentItem.vue";

@Component({
    components: {AddContentItem},
})
export default class AddContentPage extends Vue {
    @Prop() readonly page!: AddFormPage;
    @Prop() readonly entity!: Record<string, unknown>;
}
