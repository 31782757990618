
import Vue from "vue";
import Component from "vue-class-component";
import { reservationsModule } from "@/store/reservations/reservations";
import Spinner from "@/components/Ui/Spinner/Spinner.vue";
import CustomTable, { TableField } from "@/components/Ui/CustomTable/CustomTable.vue";
import Button, { ButtonType } from "@/components/Ui/Button/Button.vue";
import ListPagination from "@/components/ListPagination/ListPagination.vue";
import AdminPageHeader from "@/components/AdminPageHeader/AdminPageHeader.vue";
import { ListReservation, Reservation } from "@/types/reservation";
import SingleReservation from "@/views/SingleReservation/SingleReservation.vue";
import PlaceholderableImage from "@/components/Ui/PlaceholderableImage/PlaceholderableImage.vue";
import ReservationStatusWidget from "@/views/Reservations/components/ReservationStatusWidget.vue";
import ReservationInfo from "@/views/Reservations/components/ReservationInfo.vue";
import { SortOrder } from "@/utils/utils";
import { loginModule } from "@/store/login/login";
import SingleReservationModal from "@/components/SingleReservationModal/SingleReservationModal.vue";


@Component({
    components: {
        SingleReservationModal,
        ReservationInfo,
        ReservationStatusWidget,
        PlaceholderableImage,
        SingleReservation,
        AdminPageHeader,
        ListPagination,
        Button,
        CustomTable,
        Spinner,
    },
    mounted() {
        reservationsModule.fetchReservationsFirstPage();
    },
})
export default class AgenciesReservations extends Vue {

    singleReservationModalOpen = false;

    ButtonType = ButtonType;

    get tableFields(): Array<TableField<ListReservation>> {
        return [{
            name: "agency",
            title: this.$tc("sort"),
            width: "55%",
            slot: "info",
            sortEnabled: this.isSuperAdmin,
        }, {
            name: "createdAt",
            title: this.$tc("created_at"),
            width: "15%",
            slot: "createdAt",
            sortEnabled: true,
        }, {
            name: "status",
            title: "Status",
            width: "17%",
            slot: "status",
            sortEnabled: true,
        }, {
            name: "action",
            slot: "action",
            width: "13%",
            title: "",
        }];
    }

    openReservationModal(resId: string) {
        reservationsModule.fetchReservationByIdAction(resId);
        this.singleReservationModalOpen = true;
    }

    get sortField(): string | null {
        return reservationsModule.sortField;
    }

    get sortOrder(): SortOrder {
        return reservationsModule.sortOrder;
    }

    get currentPage(): number {
        return reservationsModule.currentPage;
    }

    get totalPages(): number {
        return reservationsModule.totalPages;
    }

    get reservations(): Array<ListReservation> {
        return reservationsModule.reservations;
    }

    get async(): boolean {
        return reservationsModule.fetchAsync;
    }

    get isSuperAdmin(): boolean {
        return loginModule.isSuperAdmin;
    }

    onSortChange(sortField: keyof ListReservation, sortOrder: SortOrder): void {
        reservationsModule.setReservationsSortField(sortField);
        reservationsModule.setReservationsSortOrder(sortOrder);
        reservationsModule.fetchReservationsPageAction(1);
    }

    onPageChange(page: number): void {
        reservationsModule.fetchReservationsPageAction(page);
    }
}
