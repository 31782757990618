
import Vue from "vue";
import Component from "vue-class-component";
import { Hotel, HotelWithStatistics } from "@/types/hotel";
import { hotelsModule } from "@/store/hotels/hotels";
import PlaceholderableImage from "@/components/Ui/PlaceholderableImage/PlaceholderableImage.vue";
import { apiGetTopReservedHotels } from "@/api/hotels/hotels";
import Spinner from "@/components/Ui/Spinner/Spinner.vue";

@Component({
    components: { Spinner, PlaceholderableImage }
})
export default class TopContent extends Vue {

    get async(): boolean {
        return hotelsModule.fetchAsync;
    }

    get topReservedHotels(): Array<HotelWithStatistics> {
        return hotelsModule.topReservedHotels;
    }

    beforeMount(): void {
        if (!this.topReservedHotels.length) {
            hotelsModule.fetchTopReservedHotels();
        }
    }
}
