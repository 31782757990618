
import Component from "vue-class-component";
import CustomModal from "@/components/Ui/CustomModal/CustomModal.vue";
import Button, { ButtonType } from "@/components/Ui/Button/Button.vue";
import { Prop } from "vue-property-decorator";
import Vue from "vue";
import InputLabel from "@/components/Ui/InputLabel/InputLabel.vue";
import InputField, { InputFieldDisplayType } from "@/components/Ui/InputField/InputField.vue";
import { User, UserForm } from "@/types/user";
import { usersModule } from "@/store/users/users";
import { difference, showSwalToast } from "@/utils/utils";
import { loginModule } from "@/store/login/login";

interface FormErrors {
    password?: string,
    confirmPassword?: string
}


@Component({
    components: { CustomModal, InputLabel, InputField, Button },
})
export default class ChangePasswordModal extends Vue {
    @Prop() readonly open!: boolean;

    form: UserForm = {};
    errors: FormErrors = {};
    InputFieldDisplayType = InputFieldDisplayType;
    ButtonType = ButtonType;

    get async(): boolean {
        return loginModule.userAsync || loginModule.loginAsync || usersModule.addEditAsync;
    }

    get user(): User | null {
        return usersModule.selectedUser;
    }

    closeModal(): void {
        this.form = {};
        this.$emit("close");
    }

    validateFields(): boolean {
        const errors: FormErrors = {};

        const { password, confirmPassword } = this.form;

        if (password) {
            if (password.length > 0 && password.length < 8) {
                errors.password = "Dužina šifre mora biti veća od 8.";
            } else if (password !== confirmPassword) {
                errors.confirmPassword = "Šifre se ne slažu.";
            }
        }

        this.errors = errors;

        return Object.keys(errors).length === 0;
    }

    async onSubmit(): Promise<void> {
        if (!this.validateFields()) return;

        if (this.user) {
            const diff = difference(this.form, UserForm.createFormWithUser(this.user)) as UserForm;

            try {
                if (diff.password) {
                    await usersModule.changeUserPasswordByIdAction({
                        userId: this.user.id,
                        password: diff.password,
                    }).then(() => {
                        delete this.form.password;
                        delete this.form.confirmPassword;
                    });
                }

                showSwalToast(this.$tc("password_change_successful"), "success");
                this.closeModal();
            } catch (ex) {
                //
            }
        }
    }

}
