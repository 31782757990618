

import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import { Hotel } from '@/types/hotel';

@Component({})
export default class SingleHotelRatings extends Vue {
    @Prop() readonly hotel!: Hotel;
}
