import { render, staticRenderFns } from "./AboutHotelForm.vue?vue&type=template&id=310aed30&scoped=true&"
import script from "./AboutHotelForm.vue?vue&type=script&lang=ts&"
export * from "./AboutHotelForm.vue?vue&type=script&lang=ts&"
import style0 from "./AboutHotelForm.vue?vue&type=style&index=0&id=310aed30&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "310aed30",
  null
  
)

export default component.exports