import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { store } from "@/store";
import { Room, IRoomForm, ListRoom} from "@/types/room";
import {
    addRoom,
    editRoom,
    fetchRoomsPage,
    fetchRoomById,
    apiFetchAllRooms,
    deleteRoom, apiFetchAllHotelRooms,
} from "@/api/rooms/rooms";

@Module({ name: "RoomsModule", store: store, dynamic: true })
export default class RoomsModule extends VuexModule {
    fetchAsync = false;
    rooms: Array<ListRoom> = [];
    hasMore = true;
    currentPage = 0;
    totalPages = 1;
    perPage = 10;
    selectedRoom: Room | null = null;
    addEditAsync = false;
    deleteAsync = false;

    @Mutation
    setRoomsFetchAsync(async: boolean): void {
        this.fetchAsync = async;
    }

    @Mutation
    setRoomDeleteAsync(async: boolean): void {
        this.deleteAsync = async;
    }

    @Mutation
    setCurrentPage(page: number): void {
        this.currentPage = page;
    }

    @Mutation
    setHasMore(hasMore: boolean): void {
        this.hasMore = hasMore;
    }

    @Mutation
    setPerPage(perPage: number): void {
        this.perPage = perPage;
    }

    @Mutation
    setTotalPages(totalPages: number): void {
        this.totalPages = totalPages;
    }

    @Mutation
    setRooms(rooms: Array<ListRoom>): void {
        this.rooms = rooms;
    }

    @Mutation
    selectRoom(room: Room | null): void {
        this.selectedRoom = room;
    }

    @Mutation
    setAddEditAsync(async: boolean): void {
        this.addEditAsync = async;
    }

    @Action
    async fetchRoomsFirstPage(): Promise<void> {
        return this.fetchRoomsPageAction(1)
    }


    @Action
    async fetchAllHotelRooms(hotelId: string): Promise<Array<ListRoom> | null> {
        this.setRoomsFetchAsync(true);

        try {
            const r = await apiFetchAllHotelRooms(hotelId);

            if (r !== null) {
                this.setRooms(r)
            }

            return r;
        } catch (e) {
            console.error(e);
        } finally {
            this.setRoomsFetchAsync(false);
        }

        return null;
    }

    @Action
    async fetchAllRooms(): Promise<Array<ListRoom> | null> {
        this.setRoomsFetchAsync(true);

        try {
            const r = await apiFetchAllRooms();

            if (r !== null) {
                this.setRooms(r)
            }

            return r;
        } catch (e) {
            console.error(e);
        } finally {
            this.setRoomsFetchAsync(false);
        }

        return null;
    }

    @Action
    async fetchRoomsPageAction(page: number): Promise<void> {
        this.setRoomsFetchAsync(true);

        try {
            const r = await fetchRoomsPage(page, this.perPage);

            if (r !== null) {
                this.setRooms(r.data)
                this.setTotalPages(Math.ceil(r.totalItems / this.perPage));
                this.setCurrentPage(page);
                this.setHasMore(r.hasMore);
            }
        } catch (e) {
            console.error(e);
        } finally {
            this.setRoomsFetchAsync(false);
        }
    }

    @Action
    async addRoomAction(data: IRoomForm): Promise<Room | null> {
        this.setAddEditAsync(true);

        try {
            const newRoom = await addRoom(data);

            return newRoom
        } catch (e) {
            console.error(e);
        } finally {
            this.setAddEditAsync(false);
        }

        return null;
    }

    @Action
    async editRoomAction({ roomId, data }: { roomId: string, data: IRoomForm }): Promise<void> {

        this.setAddEditAsync(true);

        try {
            const updatedRoom = await editRoom(roomId, data as IRoomForm);

            if (updatedRoom !== null) {
                // this.updateRoom(updatedRoom);
                this.selectRoom(updatedRoom);
            }
        } catch (e) {
            console.error(e);
        } finally {
            this.setAddEditAsync(false);
        }
    }

    @Action
    async fetchRoomByIdAction(roomId: string): Promise<void> {
        this.setRoomsFetchAsync(true);

        try {
            const room = await fetchRoomById(roomId);

            if (room !== null) {
                this.selectRoom(room);
            }
        } catch (e) {
            console.error(e);
        } finally {
            this.setRoomsFetchAsync(false);
        }
    }

    @Action({ rawError: true })
    async deleteRoomAction(roomId: string): Promise<null | void> {

        if (this.deleteAsync) return;

        this.setRoomDeleteAsync(true);

        return deleteRoom(roomId).finally(() => this.setRoomDeleteAsync(false));
    }
}

export const roomsModule = getModule(RoomsModule);
