
import Vue from "vue";
import Component from "vue-class-component";
import AdminPageHeader from "@/components/AdminPageHeader/AdminPageHeader.vue";
import { manualReservationsModule } from "@/store/manual_reservations/manual_reservations";
import {
    ManualReservation,
    ManualReservationForm,
    ManualReservationFormErrors,
    ManualReservationUtils,
} from "@/types/manualReservation";
import InputField from "@/components/Ui/InputField/InputField.vue";
import InputLabel from "@/components/Ui/InputLabel/InputLabel.vue";
import DateRangePickerWrapper, { DateRange } from "@/components/DateRangePickerWrapper/DateRangePickerWrapper.vue";
import moment from "moment";
import PassengersForm from "@/views/ManageReservation/components/PassengerForm.vue";
import Button, { ButtonType } from "@/components/Ui/Button/Button.vue";
import { Passenger } from "@/types/Passenger";
import MRRoomForm from "@/views/Reservations/components/ManualReservations/ManageManualReservation/MRRoomForm.vue";
import { RoomServiceType } from "@/types/room";
import { RoomVariantClassification } from "@/types/roomVariant";
import MRAdditionalContentForm
    from "@/views/Reservations/components/ManualReservations/ManageManualReservation/MRAdditionalContentForm.vue";
import { cloneDeep, isEmpty } from "lodash";
import { RouteLink } from "@/router";

@Component({
    components: {
        MRAdditionalContentForm,
        MRRoomForm,
        Button,
        PassengersForm,
        DateRangePickerWrapper,
        InputLabel,
        InputField,
        AdminPageHeader,
    },
})
export default class ManageManualReservation extends Vue {

    ButtonType = ButtonType;

    reservationForm: ManualReservationForm = ManualReservationUtils.createForm();
    formErrors: ManualReservationFormErrors = {
        passengers: {},
        rooms: {},
        additionalContent: {},
    };

    dateRangePickerOpen = false;
    dateRange: DateRange = {
        start: moment().add(1, "days").toDate(),
        end: moment().add(2, "days").toDate(),
    };

    get selectedReservation(): ManualReservation | null {
        return manualReservationsModule.selectedReservation;
    }

    get editMode(): boolean {
        return this.selectedReservation !== null;
    }

    get async(): boolean {
        return manualReservationsModule.addEditAsync;
    }

    mounted(): void {
        this.reservationForm = ManualReservationUtils.createForm(this.selectedReservation);
    }

    onAddPassenger(): void {
        const passenger = new Passenger({ isMainPassenger: this.reservationForm.passengers.length === 0 });
        console.log(passenger);
        this.reservationForm.passengers.push(passenger);
    }

    onRemovePassenger(index: number): void {
        this.reservationForm.passengers.splice(index, 1);
    }

    onAddRoom(): void {
        this.reservationForm.rooms.push({
            name: "",
            priceInCents: 0,
            service: RoomServiceType.BED_AND_BREAKFAST,
            variant: RoomVariantClassification.SINGLE,
            quantity: 1,
        });
    }

    onDeleteRoom(idx: number): void {
        this.reservationForm.rooms.splice(idx, 1);
    }

    onSubmitRoomForm(roomForm: ManualReservationForm): void {
        // TODO, no need ?
    }

    onAddAdditionalContent(): void {
        this.reservationForm.additionalContents.push({
            name: "",
            priceInCents: 0,
            passengerCount: 1,
        });
    }

    onDeleteAdditionalContent(idx: number): void {
        this.reservationForm.additionalContents.splice(idx, 1);
    }

    onCancel(): void {
        this.$router.back();
    }

    changeMainPassenger(idx: number): void {
        this.reservationForm.passengers.forEach((passenger, index) => {
            passenger.isMainPassenger = idx === index;
        });
    }

    isFormValid(form: ManualReservationForm): boolean {

        const errors: ManualReservationFormErrors = {
            passengers: {},
            rooms: {},
            additionalContent: {},
        };

        if (!form.hotel) {
            errors.hotel = "Naziv hotela je potreban.";
        }

        form.passengers.forEach((p, index) => {

            errors.passengers[index] = {};

            if (!p.firstName) {
                errors.passengers[index].firstName = "Ime je potrebno";
            }
            if (!p.lastName) {
                errors.passengers[index].lastName = "Prezime je potrebno";
            }
            if (!p.age) {
                errors.passengers[index].age = "Izaberite kategoriju";
            }

            if (isEmpty(errors.passengers[index])) {
                delete errors.passengers[index];
            }
        });
        form.rooms.forEach((room, index) => {
            errors.rooms[index] = {};

            if (!(form.rooms[index].name)) {
                errors.rooms[index].name = "Naziv je potreban";
            }
            if (form.rooms[index].priceInCents === 0) {
                errors.rooms[index].priceInCents = "Nije validno";
            }

            if (isEmpty(errors.rooms[index])) {
                delete errors.rooms[index];
            }
        });
        form.additionalContents.forEach((additionalContent, index) => {
            errors.additionalContent[index] = {};

            if (!(form.additionalContents[index].name)) {
                errors.additionalContent[index].name = "Naziv je potreban";
            }
            if (form.additionalContents[index].priceInCents <= 0) {
                errors.additionalContent[index].priceInCents = "Nije validno";
            }
            if (form.additionalContents[index].passengerCount <= 0) {
                errors.additionalContent[index].passengerCount = "Nije validno";
            }

            if (isEmpty(errors.additionalContent[index])) {
                delete errors.additionalContent[index];
            }
        });

        this.formErrors = errors;

        const hasErrors = Boolean(errors.hotel) || !isEmpty(errors.passengers) || !isEmpty(errors.rooms) || !isEmpty(errors.additionalContent);

        return !hasErrors;
    }

    async onSubmit(): Promise<void> {
        const finalForm = cloneDeep(this.reservationForm);

        finalForm.fromDate = moment(this.dateRange.start).format("YYYY-MM-DD");
        finalForm.toDate = moment(this.dateRange.end).format("YYYY-MM-DD");

        if (!this.isFormValid(finalForm)) {
            return;
        }

        const r = await manualReservationsModule.addReservationAction(finalForm);

        if (r) {
            await this.$router.push(RouteLink.ManualReservations);
        }
    }
}
