

import Vue from "vue";
import Component from "vue-class-component";
import Checkbox from "@/components/Ui/Checkbox/Checkbox.vue";
import Button, { ButtonType } from "@/components/Ui/Button/Button.vue";
import { goBackOrPush } from "@/utils/utils";
import { RouteLink } from "@/router";
import { roomsModule } from "@/store/rooms/rooms";
import { additionalContentModule as contentModule } from "@/store/additional_content/additional_content";

@Component({
    components: { Button, Checkbox }
})
export default class DeleteContent extends Vue {
    ButtonType = ButtonType

    deleteConfirmed = false;

    get async(): boolean {
        return roomsModule.deleteAsync;
    }

    backToContent(): void {
        goBackOrPush(RouteLink.AdditionalContent);
    }

    deleteContent(): void {
        if (!this.deleteConfirmed) return;

        if (contentModule.selectedAdditionalContent) {
            contentModule.deleteAdditionalContentAction(contentModule.selectedAdditionalContent.id).then(this.backToContent);
        }
    }
}
