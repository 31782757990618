
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import { HotelForm } from "@/types/hotel";
import Component from "vue-class-component";
import AddContentInput from "@/components/AddContentRenderer/AddContentItemRenderers/AddContentInput.vue";
import InputLabel from "@/components/Ui/InputLabel/InputLabel.vue";
import InputField, { InputFieldType } from "@/components/Ui/InputField/InputField.vue";
import SingleEarlyBooking from "@/views/EditHotel/components/HotelEarlyBooking/SingleEarlyBooking.vue";
import { IEarlyBookingForm } from "@/types/earlyBooking";
import { ButtonType } from "@/components/Ui/Button/Button.vue";

@Component({
    components: { SingleEarlyBooking, InputField, InputLabel, AddContentInput },
})
export default class HotelEarlyBooking extends Vue {
    @Prop() hotelForm!: HotelForm;

    InputFieldType = InputFieldType;
    ButtonType = ButtonType;

    newBooking: IEarlyBookingForm = {};

    get addBtnDisabled(): boolean {
        return !this.newBooking.discount || !this.newBooking.daysBefore;
    }

    addNewEarlyBooking(): void {
        this.hotelForm.earlyBookings.push({
            ...this.newBooking,
            id: `tempid_${this.newBooking.daysBefore}_${this.newBooking.discount}_${Date.now()}`
        });
        this.newBooking = {};
    }

    removeEarlyBooking(booking: IEarlyBookingForm): void {
        const idx = this.hotelForm.earlyBookings.findIndex(eb => eb.id === booking.id);

        if (idx !== -1) {
            this.hotelForm.earlyBookings.splice(idx, 1);
        }
    }
}
