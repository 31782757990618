
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { HotelForm } from "@/types/hotel";
import Checkbox, { CheckboxType } from "@/components/Ui/Checkbox/Checkbox.vue";
import { amenitiesModule } from "@/store/amenities/amenities";
import { Amenity } from "@/types/amenity";
import Spinner from "@/components/Ui/Spinner/Spinner.vue";
import Button, { ButtonType } from "@/components/Ui/Button/Button.vue";
import InputField from "@/components/Ui/InputField/InputField.vue";

@Component({
    components: { Button, InputField, Spinner, Checkbox }
})
export default class HotelAmenitiesForm extends Vue {
    @Prop() hotelForm!: HotelForm;

    CheckboxType = CheckboxType

    addAmenityName: string | null = null;

    addMode = false;

    ButtonType = ButtonType;

    async mounted() {
        await amenitiesModule.fetchAllAmenities();
    }

    cancelAddNewAmenity(): void {
        this.addAmenityName = null;
        this.addMode = false;
    }

    async addNewAmenity(): Promise<void> {
        if (this.addAmenityName !== null && this.addAmenityName.length > 0) {
            await amenitiesModule.addNewAmenity(this.addAmenityName);

            this.addMode = false;
            this.addAmenityName = null;
        }
    }

    addAmenity(): void {
        this.addMode = true;
    }

    get addAmenityAsync(): boolean {
        return amenitiesModule.addAmenitiesAsync;
    }

    hasAmenity(amenityId: string): boolean {
        return this.hotelForm.amenities.includes(amenityId);
    }

    toggleAmenity(amenityId: string): void {
        const idx = this.hotelForm.amenities?.findIndex(a => a === amenityId);

        if (idx !== -1) {
            this.hotelForm.amenities.splice(idx, 1);
        } else {
            this.hotelForm.amenities.push(amenityId);
        }
    }

    get async(): boolean {
        return amenitiesModule.fetchAsync;
    }

    get amenities(): Array<Amenity> {
        return amenitiesModule.amenities;
    }
}
