
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import Component from "vue-class-component";
import Vue from "vue";
import { Prop } from "vue-property-decorator";

@Component({
    components: {ClipLoader},
})
export default class Spinner extends Vue {
    @Prop({default: false}) readonly invert!: boolean;
    @Prop({default: false}) readonly small!: boolean;
    @Prop() readonly color?: string;

    get spinnerColor(): string {
        return this.color ?? (this.invert ? "#FFFFFF" : "#2ac084");
    }
}
