
import Component from "vue-class-component";
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import { AddFormItem_Gallery } from "@/types/addForm/addFormItem";
import Uploader from "@/components/Ui/Uploader/Uploader.vue";
import CustomUploader from "@/components/Ui/CustomUploader/CustomUploader.vue";
import { UploadedFile } from "@/types/mediaObject";

@Component({
    components: { CustomUploader, Uploader },
})
export default class AddContentGallery extends Vue {
    @Prop() readonly item!: AddFormItem_Gallery;
    @Prop() readonly entity!: Record<string, unknown>;

    removeImage(file: UploadedFile): void {
        if (this.entity?.images) {
            this.entity.images = (this.entity.images as Array<UploadedFile>).filter(i => i.url !== file.url);
        }
    }

    addNewUploads(files: Array<UploadedFile>): void {

        if (!this.entity?.images) {
            this.$set(this.entity, 'images', []);
        }

        (this.entity.images as Array<UploadedFile>).push(...files);
    }
}
