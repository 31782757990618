
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import Component from "vue-class-component";

@Component
export default class NumberPlusMinus extends Vue {
    @Prop({ default: "" }) readonly title!: string;
    @Prop({ default: 0 }) readonly value?: number;

    decrement(): void {
        if (!this.value) {
            this.$emit("input", 1);
            return;
        }

        if (this.value > 0) {
            this.$emit("input", this.value - 1);
        }
    }

    increment(): void {
        if (!this.value) {
            this.$emit("input", 1);
            return;
        }

        this.$emit("input", this.value + 1);
    }
}
