
import { ManualReservationAdditionalContent } from "@/types/manualReservation";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class MRAdditionalContentCard extends Vue {
    @Prop() additionalContent!: ManualReservationAdditionalContent;

    get getPrice(): number {
        return (this.additionalContent?.priceInCents ?? 0) * this.additionalContent.passengerCount;
    }
}
