
import Button from "@/components/Ui/Button/Button.vue";
import Component from "vue-class-component";
import Vue from "vue";
import { homeFiltersModule } from "@/store/home_filters/home_filters";

@Component({
    components: { Button },
})
export default class ReservationHeader extends Vue {
    dateRangeText = homeFiltersModule.dateRangeText;
    numOfPassengers = homeFiltersModule.numOfPassengers

    get numOfPassengersText(): string {
        return `${this.numOfPassengers + (this.numOfPassengers === 1 ? ` ${this.$tc('passenger')}` : ` ${this.$tc('passengers', 1)}`)}`;
    }
}
