
import { Component, Vue } from "vue-property-decorator";
import { Chart, registerables } from "chart.js";
import moment from "moment";
import { fetchDashboardStatistics } from "@/api/statistics/statistics";
import { getMonth } from "date-fns";

Chart.register(...registerables);

@Component
export default class ReservationsChart extends Vue {
    confirmedReservationsData: Array<number> = Array(9).fill(0);
    totalReservationsData: Array<number> = Array(9).fill(0);
    colors: Array<string> = [];

    monthFrom = 0;
    monthTo = 0;

    mounted(): void {
        this.createChart();
    }

    get labels(): Array<string> {
        const labels = [];

        for (let i = this.monthFrom; i <= this.monthTo; i++) {
            labels.push(moment().month(i).format("MMMM"));
        }

        return labels;
    }


    async parseData(): Promise<void> {
        await fetchDashboardStatistics().then(stats => {
            if (stats) {
                this.monthFrom = getMonth(new Date(stats.from));
                this.monthTo = getMonth(new Date(stats.to));

                stats.confirmed.map(confirmed => {
                    this.confirmedReservationsData[parseInt(confirmed.month) - this.monthFrom - 1] = confirmed.count;
                });

                stats.total.map(total => {
                    this.totalReservationsData[parseInt(total.month) - this.monthFrom - 1] = total.count;
                });
            }
        });
    }

    async createChart(): Promise<void> {
        const canvas: CanvasRenderingContext2D | null = (this.$refs.reservationsChart as HTMLCanvasElement)?.getContext("2d");

        await this.parseData();

        if (canvas) {
            const chart = new Chart(canvas, {
                type: "line",
                data: {
                    labels: this.labels,
                    datasets: [{
                        label: "Rezervacija",
                        data: this.totalReservationsData,
                        fill: 1,
                        backgroundColor: "rgba(235, 94, 85, 0.143357)",
                        borderColor: "rgb(235,94,85)",
                        tension: 0.1,
                    }, {
                        label: "Zaključeno",
                        data: this.confirmedReservationsData,
                        fill: "origin",
                        borderColor: "#2ac084",
                        backgroundColor: "rgba(42, 192, 132, 0.111642)",
                        tension: 0.1,
                    }],
                },
                options: {
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            labels: {
                                boxWidth: 12,
                                boxHeight: 12,
                                pointStyle: {},
                            },
                        },
                    },
                    scales: {
                        x: {
                            grid: {
                                borderDash: [8, 4],
                            },
                        },
                        y: {
                            ticks: {
                                display: false,
                            },
                            grid: {
                                display: false,
                            },
                        },
                    },
                },
            });
        }
    }
}
