import { jsonApiCall, media_objects } from "../apiCall";
import { MediaObject, UploadedFile } from "@/types/mediaObject";
import { progressFetch } from "../progressFetch";
import { showSwalToast } from "@/utils/utils";

export enum EntityNameEnum {
    Hotel = "Hotel",
    Agency = "Agency",
    Reservation = "Reservation",
    Room = "Room",
    AdditionalContent = "AdditionalContent"
}

export interface MediaUploadEntityData {
    entityName: EntityNameEnum,
    entityId: string
}

export async function uploadMediaObject(
    media: UploadedFile,
    onProgressChange: (progress: number) => void,
    entityData?: MediaUploadEntityData): Promise<MediaObject | null> {

    const url = new URL(`${media_objects}`);

    const formData = new FormData();

    if (entityData) {
        formData.append("entityName", entityData.entityName);
        formData.append("entityId", entityData.entityId);
    }

    formData.append("file", media.file);

    try {
        return await progressFetch<MediaObject>({
            url: url.href,
            method: "POST",
            body: formData,
            signal: media.abortToken.signal,
            uploadProgress: onProgressChange,
        });
    } catch (ex) {
        if (ex?.detail) {
            showSwalToast(ex.detail, 'error');
        } else {
            showSwalToast("Desila se greška prilikom uploadanja fajla.");
        }
        throw ex;
    }
}

export async function copyMediaObject(mediaObjectId: string, entityData?: MediaUploadEntityData): Promise<MediaObject | null> {

    const data = entityData ?? {};

    const r = await jsonApiCall<MediaObject>({
        url: `${media_objects}/${mediaObjectId}/copy`,
        method: "POST",
        jsonData: data as Record<string, unknown>,
    });

    if (r !== null) {
        return r;
    }

    return null;
}
