
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component
export default class GIcon extends Vue {
    @Prop() readonly icon!: string;
    @Prop({ default: false }) readonly outlined !: boolean

    get className(): string {
        return this.outlined ? 'material-icons-outlined' : 'material-icons';
    }
}
