

import Component from "vue-class-component";
import Vue from "vue";
import AddContentRenderer from "../../../components/AddContentRenderer/AddContentRenderer.vue";
import { AddForm } from "@/types/addForm/addForm";
import { AddFormPage } from "@/types/addForm/addFormPage";
import {
    AddFormItem_Amenities,
    AddFormItem_Gallery,
    AddFormItem_HotelSelect,
    AddFormItem_Input,
    AddFormItem_InputType,
    AddFormItem_TextArea,
    AddFormItem_Title,
} from "@/types/addForm/addFormItem";
import { roomsModule } from "@/store/rooms/rooms";
import { IRoomForm } from "@/types/room";
import { MediaObject } from "@/types/mediaObject";
import { RouteLink } from "@/router";
import { goBackOrPush } from "@/utils/utils";

@Component({
    components: { AddContentRenderer },
})
export default class AddNewRoom extends Vue {
    formModel: AddForm = new AddForm([
        new AddFormPage(0, [
            new AddFormItem_Title("Osnovni podaci"),
            new AddFormItem_HotelSelect(),
            new AddFormItem_Input("Naziv sobe"),
            new AddFormItem_Input("Broj soba", AddFormItem_InputType.Number),
        ]),
        new AddFormPage(1, [
            new AddFormItem_Title("Čime se soba karakteriše?"),
            new AddFormItem_Amenities(),
        ]),
        new AddFormPage(2, [
            new AddFormItem_Title("Postavite fotografije sobe"),
            new AddFormItem_Gallery(),
        ]),
        new AddFormPage(3, [
            new AddFormItem_Title("Opišite sobu"),
            new AddFormItem_TextArea("Dodatni opis sobe"),
        ]),
    ]);

    entity: Record<string, unknown> = {};

    get addAsync(): boolean {
        return false;
    }

    transformEntityToRoomForm(): IRoomForm {

        let amenities: Array<string> = Object.keys(this.entity["amenities"] as Record<string, unknown> || {}).map(amenity => `/api/amenities/${amenity}`);
        let mediaObjects: Array<string> = (this.entity["images"] as Array<MediaObject> || []).map((o) => `/api/media_objects/${o.id}`);

        const hotel = this.entity["hotel"] as string;

        return {
            hotel: `/api/hotels/${hotel}`,
            name: this.entity["naziv_sobe"] as string,
            descriptionBs: this.entity["dodatni_opis_sobe"] as string,
            q: +(this.entity["broj_soba"] as number),
            mediaObjects,
            amenities,
        };
    }

    onSubmit(): void {
        const room = this.transformEntityToRoomForm();

        roomsModule.addRoomAction(room).finally(() => goBackOrPush(RouteLink.HotelContent));
    }
}
