
import Vue from 'vue';
import Component from 'vue-class-component';
import Button, { ButtonType } from '@/components/Ui/Button/Button.vue';
import { goBackOrPush } from '@/utils/utils';
import { RouteLink } from '@/router';
import AboutHotelForm from '@/views/EditHotel/components/AboutHotelForm.vue';
import { Hotel, HotelForm, IHotelForm } from '@/types/hotel';
import { hotelsModule } from '@/store/hotels/hotels';
import Spinner from '@/components/Ui/Spinner/Spinner.vue';
import HotelRatingForm from '@/views/EditHotel/components/HotelRatingForm.vue';
import HotelAmenitiesForm from '@/views/EditHotel/components/HotelAmenitiesForm.vue';
import HotelGalleryForm from '@/views/EditHotel/components/HotelGalleryForm.vue';
import { MediaObject, UploadedFile } from '@/types/mediaObject';
import CustomUploader from '@/components/Ui/CustomUploader/CustomUploader.vue';
import { mediaModule } from '@/store/media_objects/media_objects';
import { EntityNameEnum } from '@/api/media_objects/media_objects';
import DeleteHotel from '@/views/EditHotel/components/DeleteHotel.vue';
import AdminPageHeader from '@/components/AdminPageHeader/AdminPageHeader.vue';
import RoomsTable from '@/views/RoomsTable/RoomsTable.vue';
import HotelAdministrationForm from '@/views/EditHotel/components/HotelAdministrationForm.vue';
import HotelEarlyBooking from '@/views/EditHotel/components/HotelEarlyBooking/HotelEarlyBooking.vue';

export enum EditHotelSectionType {
    ABOUT = 'ABOUT',
    ADMINISTRATION = 'ADMINISTRATION',
    RATING = 'RATING',
    CONTENT = 'CONTENT',
    GALLERY = 'GALLERY',
    DELETE = 'DELETE',
    ROOMS = 'ROOMS',
    EARLY_BOOKING = 'EARLY_BOOKING',
}

interface EditHotelSection {
    name: string,
    type: EditHotelSectionType
}

@Component({
    components: {
        HotelEarlyBooking,
        HotelAdministrationForm,
        RoomsTable,
        AdminPageHeader,
        DeleteHotel,
        CustomUploader,
        HotelGalleryForm,
        HotelAmenitiesForm,
        HotelRatingForm,
        Spinner,
        AboutHotelForm,
        Button,
    },
})
export default class EditHotel extends Vue {
    EditHotelSectionType = EditHotelSectionType;
    ButtonType = ButtonType;
    goBackOrPush = goBackOrPush;
    RouteLink = RouteLink;

    sections: Array<EditHotelSection> = [
        {
            name: 'Naziv i Adresa',
            type: EditHotelSectionType.ABOUT,
        },
        {
            name: 'Opis i Ocjena',
            type: EditHotelSectionType.RATING,
        },
        {
            name: 'Sadržaj',
            type: EditHotelSectionType.CONTENT,
        },
        {
            name: 'Fotografije',
            type: EditHotelSectionType.GALLERY,
        },
        {
            name: 'Sobe',
            type: EditHotelSectionType.ROOMS,
        },
        {
            name: 'Administracija',
            type: EditHotelSectionType.ADMINISTRATION,
        },
        {
            name: 'Early Booking',
            type: EditHotelSectionType.EARLY_BOOKING,
        },
    ];
    selectedSection = EditHotelSectionType.ABOUT;

    hotelForm = new HotelForm();
    newUploads: Array<UploadedFile> = [];
    uploadAsync = false;

    get fetchAsync(): boolean {
        return hotelsModule.fetchAsync;
    }

    get editAsync(): boolean {
        return hotelsModule.addEditAsync;
    }

    get selectedHotel(): Hotel | null {
        return hotelsModule.selectedHotel;
    }

    selectSection(section: EditHotelSectionType): void {
        this.selectedSection = section;
    }

    backToHotels(): void {
        goBackOrPush(RouteLink.HotelContent);
    }

    removeUpload(file: UploadedFile): void {
        this.newUploads = this.newUploads.filter(i => i.url !== file.url);
    }

    onRemoveMediaObject(media: MediaObject): void {
        this.hotelForm.mediaObjects = this.hotelForm.mediaObjects.filter(m => typeof m === 'string' ? m !== media.id : m.id !== media.id);
    }

    addNewUploads(files: Array<UploadedFile>): void {
        this.newUploads.push(...files);
    }

    async onSubmit(): Promise<void> {
        if (!this.hotelForm.validate()) return;

        if (this.selectedHotel) {
            try {
                let promises: Array<Promise<MediaObject | null>> = [];

                // Then we upload new images to prevent edit action override mediaObjects
                if (this.newUploads.length) {

                    const hotelId = this.selectedHotel.id;

                    this.uploadAsync = true;

                    this.newUploads.forEach(upload => {
                        if (!upload.progress) {                            // Check if already uploading
                            promises.push(mediaModule.uploadMediaObject({
                                file: upload,
                                entityData: {
                                    entityName: EntityNameEnum.Hotel,
                                    entityId: hotelId,
                                },
                            }).then(mediaObj => {
                                if (mediaObj !== null) {          // successful upload
                                    this.hotelForm.mediaObjects.push(`/api/media_objects/${mediaObj.id}`);
                                    this.removeUpload(upload);
                                }

                                return mediaObj;
                            }));
                        }
                    });
                }

                this.hotelForm.prepareFormForSending();

                const diff = this.hotelForm.difference(HotelForm.createFormWithHotel(this.selectedHotel)) as IHotelForm;
                
                diff.amenities = this.hotelForm.amenities.map(amenity => `/api/amenities/${amenity}`);

                if (Array.isArray(diff.mediaObjects)) {
                    diff.mediaObjects = diff.mediaObjects.map(obj => {
                        if (typeof obj === 'string') {
                            if (obj.startsWith('/api')) {
                                // already an iri;

                                return obj;
                            } else {
                                return `/api/media_objects/${obj}`;
                            }
                        }

                        return `/api/media_objects/${obj.id}`;
                    });
                }

                await Promise.allSettled(promises).then(() => this.uploadAsync = false);

                await hotelsModule.editHotelAction({ hotelId: this.selectedHotel.id, data: diff });

                // this.backToHotels();
            } catch (ex) {
                //
            }
        }
    }

    async beforeMount() {

        const { params: { hotelId, tab } } = this.$route;

        if (hotelId) {
            await hotelsModule.fetchHotelByIdAction({ hotelId });
        }

        if (tab) {
            const key = tab as keyof typeof EditHotelSectionType;
            this.selectedSection = EditHotelSectionType[key] || EditHotelSectionType.ABOUT;
        }

        if (this.selectedHotel) {
            this.setData(this.selectedHotel);
        }
    }

    setData(hotel: Hotel): void {
        this.hotelForm = HotelForm.createFormWithHotel(hotel);
    }

}
