import { render, staticRenderFns } from "./AboutContentForm.vue?vue&type=template&id=016a339b&scoped=true&"
import script from "./AboutContentForm.vue?vue&type=script&lang=ts&"
export * from "./AboutContentForm.vue?vue&type=script&lang=ts&"
import style0 from "./AboutContentForm.vue?vue&type=style&index=0&id=016a339b&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "016a339b",
  null
  
)

export default component.exports