
import Vue from "vue";
import Component from "vue-class-component";
import InputLabel from "../../../components/Ui/InputLabel/InputLabel.vue";
import InputField from "../../../components/Ui/InputField/InputField.vue";
import { Prop } from "vue-property-decorator";
import { HotelForm } from "@/types/hotel";
import AddContentHotelNameAutocompleteInput
    from "@/components/AddContentRenderer/AddContentItemRenderers/AddContentHotelNameAutocompleteInput.vue";
import { AddFormItem_HotelNameAutocompleteInput } from "@/types/addForm/addFormItem";
import CustomGoogleMap from "@/components/CustomGoogleMap/CustomGoogleMap.vue";
import { Address } from "@/types/address";

@Component({
    components: { CustomGoogleMap, AddContentHotelNameAutocompleteInput, InputField, InputLabel },
})
export default class AboutHotelForm extends Vue {
    @Prop() hotelForm!: HotelForm;

    item: AddFormItem_HotelNameAutocompleteInput = new AddFormItem_HotelNameAutocompleteInput("Ime Hotela");

    advancedHotelNameOpen = false;

    onClickLocation(address: Address): void {
        this.hotelForm.address = address;
    }
}
