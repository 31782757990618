
import Vue from "vue";
import Checkbox from "@/components/Ui/Checkbox/Checkbox.vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { getDeepProp, SortOrder } from "@/utils/utils";
import TransitionExpand from "@/components/TransitionExpand/TransitionExpand.vue";
import Spinner from "@/components/Ui/Spinner/Spinner.vue";


export interface TableField<T> {
    enableClick?: boolean,
    rowClass?: string,
    lightText?: boolean,
    slot?: string,
    headerSlot?: string,
    accessor?: string,
    sortEnabled?: boolean
    width?: string,
    headerClass?: string,
    name: string,
    title: string,
    disabled?: boolean;
}

@Component({
    components: { Spinner, TransitionExpand, Checkbox },
})
export default class CustomTable<T> extends Vue {

    selectAllActive = false;
    expandedRowId: string | null = null;

    SortOrder = SortOrder;

    @Prop({ default: false }) readonly isExpandable!: boolean;
    @Prop({ default: false }) readonly select!: boolean;
    @Prop({ default: () => ([]) }) readonly fields!: Array<TableField<T>>;
    @Prop({ default: () => ([]) }) readonly data!: Array<T>;
    @Prop({ default: () => ([]) }) readonly selectedIds!: Array<string>;
    @Prop({ default: undefined }) readonly expandedRowIdOverride?: null | string;
    @Prop({ default: () => null }) readonly sortField!: keyof T | null;
    @Prop({ default: () => SortOrder.DESC }) readonly sortOrder!: null | SortOrder;
    @Prop({ default: () => false }) readonly async !: boolean;

    get activeFields(): Array<TableField<T>> {
        return (this.fields || []).filter(field => field.disabled !== undefined ? !field.disabled : true);
    }

    get renderData(): Array<T> {
        return this.data;
    }

    getDeepProp = getDeepProp;

    onHeaderFieldClick(field: TableField<T>): void {
        if (field.sortEnabled) {
            this.$emit("changeSort", field.name, this.sortOrder === SortOrder.DESC ? SortOrder.ASC : SortOrder.DESC);
        }
    }

    isItemExpanded(item: T & { id: string }): boolean {
        const actualExpandedRowId = this.expandedRowIdOverride !== undefined ? this.expandedRowIdOverride : this.expandedRowId;
        return this.isExpandable && actualExpandedRowId === item.id;
    }

    toggleRowExpanded(rowId: string): void {

        const expandedRowId = this.expandedRowId !== rowId ? rowId : null;

        this.expandedRowId = expandedRowId;
        this.$emit("expandRow", expandedRowId);
    }
}
