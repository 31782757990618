

import Vue from "vue";
import { Prop } from "vue-property-decorator";
import Component from "vue-class-component";
import { DaySelectionType } from "@/views/Calendar/CalendarDay.vue";
import { CalendarRibbon } from "@/types/availabilityRibbon";
import { uiModule } from "@/store/ui";

@Component
export default class CalendarDaySelectionType extends Vue {
    @Prop() readonly date!: Date;
    @Prop({ default: () => null }) readonly selectionType!: DaySelectionType | null;
    @Prop({ default: false }) readonly selected!: boolean;
    @Prop({ default: () => null }) readonly selection!: CalendarRibbon | null;

    roomsOccupied: number | null = null;
    roomsAvailable: number | null = null;


    get ribbonRow(): number {
        return this.selection?.ribbonRow ?? 0;
    }

    get roomNumber(): string {
        return this.selection?.availabilities?.length?.toString() ?? "";
    }

    get availabilitiesPrice(): string {
        return this.selection?.availabilities?.[0].pricing?.single?.standard?.adults?.toString() || "";
    }

    get isStart(): boolean {
        return this.selectionType === DaySelectionType.START;
    }

    get isMiddle(): boolean {
        return this.selectionType === DaySelectionType.MIDDLE;
    }

    get isStartEnd(): boolean {
        return this.selectionType === DaySelectionType.START_END;
    }

    get isEnd(): boolean {
        return this.selectionType === DaySelectionType.END;
    }

    get isEnabled(): boolean {
        let enabled = this.selection?.availabilities?.[0]?.enabled;
        return enabled !== undefined ? enabled : true;
    }

    getItemTop(ribbonRow: number): string {

        const marginBottom = ribbonRow > 0 ? 1 : 0;
        const dayNumberHeight = 1.25;

        return dayNumberHeight + ((uiModule.calendarRibbonHeight + marginBottom) * ribbonRow) + "rem";
    }

    get getShouldShowAvailabilitiesText(): boolean {
        let dateParsed = new Date(this.date);
        dateParsed.setHours(0, 0, 0, 0);
        let dateString = dateParsed.toString();

        const currentAvailability = this.selection?.availabilities.find((availability) => availability.date === dateString);
        if (currentAvailability) {
            if (currentAvailability.roomsOccupied > 0) {
                this.roomsOccupied = currentAvailability.roomsOccupied;
                this.roomsAvailable = currentAvailability.roomQuantity;

                return true;
            }
        }

        return false;
    }

}

