

import Vue from "vue";
import Component from "vue-class-component";
import TransitionExpand from "@/components/TransitionExpand/TransitionExpand.vue";
import { Prop, Watch } from "vue-property-decorator";

@Component({
    components: { TransitionExpand }
})
export default class ExpandableContainer extends Vue {
    @Prop({ default: '' }) readonly label!: string
    @Prop({ default: false }) readonly expanded!: boolean

    isExpanded = false;

    toggleExpanded(): void {
        this.isExpanded = !this.isExpanded;
        this.$emit("updateExpanded", this.isExpanded);
    }

    @Watch("expanded")
    overrideExpanded(val: boolean): void {
        this.isExpanded = val;
    }
}

