export enum ReservationStatusEnum {
    ADMIN_APPROVED = "ADMIN_APPROVED",
    AGENCY_MARKED_PAID = "AGENCY_MARKED_PAID",
    ADMIN_SENT_EMAIL_TO_HOTEL = "EMAIL_TO_HOTEL_SENT",
    ADMIN_CONFIRMED_PAID = "ADMIN_CONFIRMED_PAID",
    ADMIN_PAID_HOTEL = "ADMIN_PAID_HOTEL",
    ADMIN_SENT_EMAIL_TO_TRANSFER = "ADMIN_SENT_EMAIL_TO_TRANSFER",
    ADMIN_CONFIRMED_TRANSFER = "ADMIN_CONFIRMED_TRANSFER",
    ADMIN_PAID_TRANSFER = "ADMIN_PAID_TRANSFER",
    ADMIN_SENT_VISA = "ADMIN_SENT_VISA",
    ADMIN_CONFIRMED_VISA = "ADMIN_CONFIRMED_VISA",
    ADMIN_PAID_VISA = "ADMIN_PAID_VISA",
}

export interface ReservationStatus {
    status: ReservationStatusEnum,
    createdAt: string,
}
