import { PaymentHistoryItem } from "@/types/PaymentHistoryItem";
import * as API from "../apiCall";

export default class PaymentHistoryApi {
    static async fetchPaymentHistory(from: string, to: string): Promise<PaymentHistoryItem[] | null> {
        const url = new URL(API.paymentHistory);

        url.searchParams.append("from", from);
        url.searchParams.append("to", to);

        return API.jsonApiCall({
            url: url.toString(),
        });
    }
}
