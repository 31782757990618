
import Vue from "vue";
import Component from "vue-class-component";
import AdminPageHeader from "@/components/AdminPageHeader/AdminPageHeader.vue";
import Button, { ButtonType } from "@/components/Ui/Button/Button.vue";
import GIcon from "@/components/Ui/GIcon/GIcon.vue";
import { RouteLink } from "@/router";
import Breadcrumbs, { Breadcrumb } from "@/components/Ui/Breadcrumbs/Breadcrumbs.vue";
import InputLabel from "@/components/Ui/InputLabel/InputLabel.vue";
import SelectField, { SelectFieldOption } from "@/components/Ui/SelectField/SelectField.vue";
import DateRangePickerWrapper, { DateRange } from "@/components/DateRangePickerWrapper/DateRangePickerWrapper.vue";
import {
    HotelSpecialOffer,
    HotelSpecialOfferUtils,
    HotelSponsoredLevel,
    IHotelSpecialOfferForm
} from "@/types/hotelSpecialOffer";
import moment from "moment";
import { hotelsModule } from "@/store/hotels/hotels";
import Spinner from "@/components/Ui/Spinner/Spinner.vue";
import HotelSpecialOfferPlaceholderCard
    from "@/views/ManageHotelSpecialOffer/HotelSpecialOfferPlaceholderCard/HotelSpecialOfferPlaceholderCard.vue";
import Checkbox, { CheckboxType } from "@/components/Ui/Checkbox/Checkbox.vue";
import { apiFetchSingleHotelOfferById } from "@/api/hotels/hotels";
import { Hotel } from "@/types/hotel";
import { cloneDeep } from "lodash";

@Component({
    components: {
        Checkbox,
        HotelSpecialOfferPlaceholderCard,
        Spinner,
        DateRangePickerWrapper,
        SelectField,
        InputLabel,
        Breadcrumbs,
        GIcon,
        Button,
        AdminPageHeader
    }
})
export default class ManageHotelSpecialOffer extends Vue {
    ButtonType = ButtonType;
    CheckboxType = CheckboxType;

    breadcrumbs: Array<Breadcrumb> = [];
    dateRangePickerOpen = false;

    editMode = false;

    hotelError = '';

    originalOffer: HotelSpecialOffer | null = null;

    form: IHotelSpecialOfferForm = {
        sponsoredLevel: HotelSponsoredLevel.Level1,
        fromDate: moment().add(1, 'day').format(),
        toDate: moment().add(2, 'days').format(),
    };

    goBack(): void {
        this.$router.push(RouteLink.HotelSpecialOffers);
    }

    onDateRangeChange(dateRange: DateRange): void {
        this.form.fromDate = dateRange.start.toISOString();
        this.form.toDate = dateRange.end.toISOString();
    }

    get sponsoredLevels(): HotelSponsoredLevel[] {
        return [HotelSponsoredLevel.Level1, HotelSponsoredLevel.Level2, HotelSponsoredLevel.Level3];
    }

    get addEditSpecialOfferAsync(): boolean {
        return hotelsModule.addEditSpecialOfferAsync;
    }

    get async(): boolean {
        return hotelsModule.fetchAsync;
    }

    get dateRange(): DateRange {
        return {
            start: moment(this.form.fromDate).toDate(),
            end: moment(this.form.toDate).toDate(),
        };
    }

    get hotels(): Hotel[] {
        return hotelsModule.hotels;
    }

    get hotelOptions(): Array<SelectFieldOption> {
        return this.hotels.map(h => ({
            value: h.id,
            label: h.name,
            topDash: false
        }));
    }

    prepareForm(form: IHotelSpecialOfferForm): IHotelSpecialOfferForm {
        const cloned = cloneDeep(form);

        cloned.hotel = `/api/hotels/${form.hotel}`;

        return cloned;
    }

    async onSubmit(): Promise<void> {

        if (!HotelSpecialOfferUtils.validateForm(this.form)) {
            this.hotelError = '*';
            return;
        }

        let success = false;

        const preparedForm = this.prepareForm(this.form);

        if (this.editMode && this.originalOffer) {
            success = await hotelsModule.editSpecialOfferAction({ offerId: this.originalOffer.id, data: preparedForm });
        } else {
            success = await hotelsModule.addSpecialOfferAction(preparedForm);
        }

        if (success) {
            this.goBack();
        }
    }

    async beforeMount(): Promise<void> {
        const { params: { specialOfferId, hotelId } } = this.$route;

        if (specialOfferId && hotelId) {
            const offer = await apiFetchSingleHotelOfferById(specialOfferId);

            this.form = {
                hotel: hotelId,
                sponsoredLevel: offer?.sponsoredLevel,
                fromDate: offer?.fromDate,
                toDate: offer?.toDate
            };

            if (offer) {
                this.originalOffer = { ...offer };
            }
        }

        this.editMode = Boolean(specialOfferId) && Boolean(hotelId);

        this.breadcrumbs = [
            {
                label: 'Sadržaji',
                route: RouteLink.Content
            },
            {
                label: 'Izdvojenosti',
                route: RouteLink.HotelSpecialOffers,
            },
            {
                label: specialOfferId ? "Uređivanje izdvojenosti" : "Dodavanje izdvojenosti",
                route: this.$route.path
            },
        ];

        if (!this.hotels.length) {
            await hotelsModule.fetchAllHotels();
        }
    }
}
