import {
    PaginationResponse,
    RawHydraResponse,
    RawHydraResponseIntoPaginationResponse,
} from "@/types/paginationResponse";
import { jsonApiCall, agencies, apiCall } from "@/api/apiCall";
import { Agency, AgencyForm, ListAgency } from "@/types/agency";

export async function apiFetchAllAgencies(): Promise<Array<ListAgency> | null> {
    const r = await jsonApiCall<Array<ListAgency>>({
        url: `${agencies}`,
        method: "GET",
    });

    return r;
}

export async function fetchAgenciesPage(page: number, perPage: number, search: string): Promise<PaginationResponse<ListAgency> | null> {


    const r = await jsonApiCall<RawHydraResponse<ListAgency>>({
        url: `${agencies}?page=${page}&per_page=${perPage}&search=${search}`,
        method: "GET",
        headers: {
            "accept": "application/ld+json",
        },
    });

    if (r !== null) {
        return RawHydraResponseIntoPaginationResponse<ListAgency>(r);
    } else {
        return null;
    }
}

export async function addAgency(data: AgencyForm): Promise<Agency | null> {
    const r = await jsonApiCall<Agency>({
        url: agencies,
        method: "POST",
        jsonData: data as Record<string, unknown>,
    });

    if (r !== null) {
        return r;
    }

    return null;
}

export async function editAgency(agencyId: string, data: AgencyForm): Promise<Agency | null> {
    const r = await jsonApiCall<Agency>({
        url: `${agencies}/${agencyId}`,
        method: "PUT",
        jsonData: data as Record<string, unknown>,
    });

    if (r !== null) {
        return r;
    }

    return null;
}

export async function deleteAgency(agencyId: string): Promise<boolean | null> {
    const r = await apiCall({
        url: `${agencies}/${agencyId}`,
        method: "DELETE",
        jsonData: {},
    });

    if (r !== undefined) {
        return true;
    }

    return null;
}

export async function fetchAgencyById(agencyId: string): Promise<Agency | null> {
    const r = await jsonApiCall<Agency>({
        url: `${agencies}/${agencyId}`,
    });

    if (r !== null) {
        return r;
    }

    return null;
}
