import { ADD_FORM_PAGE_TYPE, AddFormPage } from "@/types/addForm/addFormPage";

export const ADD_FORM_TYPE = "ADD_FORM";

export class AddForm {
    type = ADD_FORM_TYPE;
    pages: AddFormPage[];

    public constructor(pages: AddFormPage[]) {
        this.pages = (pages || [new AddFormPage(0, [])]).sort((a, b) => a.pageNumber - b.pageNumber);
    }

    static parse(rawData: string): AddForm {
        const data: Array<Record<string, unknown>> = JSON.parse(rawData);

        const pages: AddFormPage[] = [];

        data.forEach((item: Record<string, unknown>) => {
            if (item["type"] === ADD_FORM_PAGE_TYPE) {
                pages.push(AddFormPage.parse(item));
            }
        });

        return new AddForm(pages);
    }
}
