import { StorageKeys } from "@/utils/consts";
import merge from "lodash/merge";
import { ApiCallParams, DefaultApiCallParams, endpoint, handleErrors, logOut } from "@/api/apiCall";
import { LoginResponse } from "@/api/login/login";

interface ProgressFetchParams extends ApiCallParams {
    signal: AbortSignal | null,
    uploadProgress: (progress: number) => void,
}

export async function progressFetch<T>(params: ProgressFetchParams = {
    url: "",
    body: undefined,
    jsonData: undefined,
    method: "GET",
    noAuth: false,
    signal: null,
    uploadProgress: () => {/**/
    },
}): Promise<T | null> {
    if (params.signal) {
        if (params.signal.aborted) {
            return Promise.reject(new DOMException("Aborted", "AbortError"));
        }
    }

    const token = localStorage.getItem(StorageKeys.TOKEN);

    const defaultParams: DefaultApiCallParams = {
        headers: {
            "content-type": "application/json",
        },
        method: "GET",
        body: null,
    };

    if (params.body) {
        delete defaultParams.headers["content-type"];
    }

    if (params.jsonData) {
        params.body = JSON.stringify(params.jsonData);
        delete params.jsonData;
    }

    if (token) {
        defaultParams.headers["Authorization"] = `Bearer ${token}`;
    }

    const actualParams = merge(defaultParams, params);

    return new Promise((resolve, reject) => {
        const reqListener = function (this: XMLHttpRequest, ev: ProgressEvent<XMLHttpRequestEventTarget>): any {
            if (this.status >= 400) {
                if (this.status === 401) {
                    try {
                        const json = JSON.parse(this.responseText);
                        const message = json.message;

                        if (message === "Invalid credentials.") {
                            // this is login, propagate this error up
                            reject(message);
                        } else if (message === "Expired JWT Token") {
                            // we should probs refresh token here
                            const refreshToken = localStorage.getItem(StorageKeys.REFRESH_TOKEN);
                            if (refreshToken) {
                                // proceed
                                const data = new FormData();
                                data.append("refresh_token", refreshToken);
                                return fetch(endpoint + "/api/token/refresh", {
                                    method: "post",
                                    body: data,
                                }).then(handleErrors)
                                    .then(r => r?.json())
                                    .then((r: LoginResponse) => {
                                        localStorage.setItem(StorageKeys.TOKEN, r.token);
                                        localStorage.setItem(StorageKeys.REFRESH_TOKEN, r.refresh_token);
                                        //redo api call
                                        progressFetch<T>(params)
                                            .then(resolve);
                                    })
                                    .catch(() => {
                                        logOut();
                                    });
                            } else {
                                logOut();
                            }
                        } else if (message === "An authentication exception occurred.") {
                            // USER PROBABLY GOT DISABLED
                            reject("User disabled.");
                        }
                    } catch (ex) {
                        // no json
                        reject("Error no json");
                    }
                } else {
                    const json = JSON.parse(this.responseText);
                    actualParams.uploadProgress(100);
                    reject(json);
                }
            } else {
                const json = JSON.parse(this.responseText);
                actualParams.uploadProgress(100);
                resolve(json);
            }
        };

        function fail() {
            reject("Error");
        }

        function aborted() {
            reject(new DOMException("Aborted", "AbortError"));
        }

        function progress(ev: ProgressEvent) {
            if (ev.lengthComputable) {
                const percentComplete = ev.loaded / ev.total * 100;
                actualParams.uploadProgress(Math.round(percentComplete));
            } else {
                actualParams.uploadProgress(50);
            }
        }

        const oReq = new XMLHttpRequest();

        if (typeof actualParams.uploadProgress === "function") {
            oReq.upload.addEventListener("progress", progress, false);
        }

        oReq.addEventListener("load", reqListener);
        oReq.addEventListener("error", fail);
        oReq.addEventListener("abort", aborted);

        oReq.open(actualParams.method, actualParams.url);

        if (actualParams.signal) {
            actualParams.signal.addEventListener("abort", () => {
                oReq.abort();
                aborted();
            });
        }

        for (const [name, value] of Object.entries(actualParams.headers)) {
            oReq.setRequestHeader(name, value);
        }

        oReq.send(actualParams.body || null);
    });
}
