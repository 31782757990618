

import Vue from "vue";
import Component from "vue-class-component";
import Container from "@/components/Ui/Container/Container.vue";
import { Prop } from "vue-property-decorator";
import PlaceholderableImage from "@/components/Ui/PlaceholderableImage/PlaceholderableImage.vue";
import { ManualReservation, ManualReservationUtils } from "@/types/manualReservation";

@Component({
    components: { PlaceholderableImage, Container },
})
export default class MRHotelCard extends Vue {
    @Prop() readonly reservation!: ManualReservation;

    get getPrice(): number {
        return ManualReservationUtils.getTotalAccommodationPrice(this.reservation)
    }
}
