
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import { Room, RoomServiceType } from "@/types/room";
import Component from "vue-class-component";
import { loginModule } from "@/store/login/login";
import { RouteName } from "@/router";
import { storage_url } from "@/api/apiCall";
import noImage from "@/assets/images/no_image.jpg";
import { Hotel } from "@/types/hotel";
import SelectField from "@/components/Ui/SelectField/SelectField.vue";
import HotelRoomVariantCard from "@/views/SingleHotel/components/HotelRoomVariantCard.vue";
import { reservationFormModule, ReservationFormSelectedRoomVariant } from "@/store/reservation_form/reservation_form";
import PlaceholderableImage from "@/components/Ui/PlaceholderableImage/PlaceholderableImage.vue";
import { MediaObject } from "@/types/mediaObject";
import { AvailabilityGroups, getAvailabilityGroupsForRoomAvailabilities } from "@/utils/price_utils";
import { getRoomVariantAdultsCapacity } from "@/utils/utils";

@Component({
    components: { PlaceholderableImage, HotelRoomVariantCard, SelectField },
})
export default class HotelRoomCard extends Vue {
    @Prop() readonly room!: Room;
    @Prop() readonly hotel!: Hotel;
    @Prop() readonly hotelName!: string;

    hasVariantsWithValidPrices = true;

    $refs!: {
        variants: HTMLElement
    }

    mounted(): void {

        if (this.$refs.variants.childElementCount === 0) {
            this.hasVariantsWithValidPrices = false;
        }
    }

    updateVariantPickedOption(variantId: string, pickedOption: RoomServiceType | null): void {
        reservationFormModule.updateRoomVariant({
            roomId: this.room.id,
            variantId,
            variant: {
                serviceType: pickedOption,
            },
        });
    }

    updateVariantPickedPassengers(variantId: string, passengers: number | null): void {
        reservationFormModule.updateRoomVariant({
            roomId: this.room.id,
            variantId,
            variant: {
                passengers: passengers !== null ? +passengers : null,
            },
        });
    }

    updateVariantPickedNumber(variantId: string, pickedNumber: number | null): void {

        const currentRoomCountForVariant = this.pickedVariantRooms(variantId);
        const currentPassengerCountForVariant = this.pickedVariantPassengers(variantId)

        let passengers = currentPassengerCountForVariant;

        pickedNumber = pickedNumber !== null ? +pickedNumber : 0;

        if (pickedNumber === 0) {
            passengers = 0;
        } else if (pickedNumber ?? 0 < currentRoomCountForVariant) {        // recalculate number of passengers
            const variant = this.room.roomVariants.find(v => v.id === variantId);

            const maxAdultCap = getRoomVariantAdultsCapacity(variant ?? null);

            if (currentPassengerCountForVariant > maxAdultCap) {
                passengers = maxAdultCap;
            }
        }

        reservationFormModule.updateRoomVariant({
            roomId: this.room.id,
            variantId,
            variant: {
                q: pickedNumber,
                passengers,
            },
        });
    }

    get roomFirstImage(): MediaObject | null {
        return this.room.mediaObjects[0] ?? null;
    }

    get pickedVariantById(): ((variantId: string) => ReservationFormSelectedRoomVariant | null) {
        return variantId => {
            return reservationFormModule.selectedRooms?.[this.room.id]?.[variantId] ?? null;
        };
    }

    get numRoomsSelected(): number {
        const room = reservationFormModule.selectedRooms?.[this.room.id];
        if (room) {
            return Object.values(room).reduce<number>((previousValue, currentValue) => {
                if (currentValue) {
                    if (currentValue.q) {
                        previousValue += +currentValue.q ?? 0;
                    }
                }

                return previousValue;
            }, 0);
        }

        return 0;
    }

    get pickedVariantOption(): ((variantId: string) => string) {
        return variantId => {
            let selected = this.pickedVariantById(variantId);

            return selected?.serviceType ?? "";
        };
    }

    get pickedVariantRooms(): ((variantId: string) => number) {
        return variantId => {
            let selected = this.pickedVariantById(variantId);

            return selected?.q ?? 0;
        };
    }

    get pickedVariantPassengers(): ((variantId: string) => number) {
        return variantId => {
            let selected = this.pickedVariantById(variantId);

            return selected?.passengers ?? 0;
        };
    }

    get getMaxVariantRooms(): ((variantId: string) => number) {
        return variantId => {
            return this.availableRoomsToPick + this.pickedVariantRooms(variantId);
        };
    }

    get availableRoomsToPick(): number {
        return this.maxRoomNumber - this.numRoomsSelected;
    }

    get maxRoomNumber(): number {
        return this.room.availabilities.reduce<number>((previousValue, currentValue) => {
            let availableRooms = currentValue.roomQuantity - currentValue.roomsOccupied;
            if (availableRooms < previousValue) {
                return availableRooms;
            } else {
                return previousValue;
            }
        }, Number.MAX_SAFE_INTEGER);
    }

    get availabilityGroups(): AvailabilityGroups {
        return getAvailabilityGroupsForRoomAvailabilities(this.room.availabilities);
    }

    get isAdmin(): boolean {
        return loginModule.isSuperAdmin;
    }

    get roomImage(): string | null {
        const roomUrl = (this.room.mediaObjects ?? [])[0]?.contentUrl;
        return roomUrl ? (storage_url + roomUrl) : noImage;
    }

    onCardClick(): void {
        if (!this.isAdmin) {
            this.$emit("click");
        } else {
            this.editRoom();
        }
    }

    editRoom(): void {
        this.$router.push({
            name: RouteName.EditRoom,
            params: {
                roomId: this.room.id,
            },
        });
    }
}
