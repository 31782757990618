import {Action, getModule, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {store} from "@/store";
import {Reservation, ReservationForm} from "@/types/reservation";
import {RouteLink, RouteName} from "@/router";
import {EditHotelSectionType} from "@/views/EditHotel/EditHotel.vue";
import {Breadcrumb} from "@/components/Ui/Breadcrumbs/Breadcrumbs.vue";
import {ReservationAdditionalContent,} from "@/types/additionalContent";
import Vue from "vue";
import {homeFiltersModule} from "@/store/home_filters/home_filters";
import {Passenger, PassengerAge} from "@/types/Passenger";
import {addReservation, ReservationPostData} from "@/api/reservations/reservations";
import moment from "moment";
import {Hotel} from "@/types/hotel";
import {Room, RoomServiceType} from "@/types/room";
import {clone, cloneDeep, merge} from "lodash";
import {PartialDeep} from "type-fest";
import {mediaModule} from "@/store/media_objects/media_objects";
import {MediaObject, UploadedFile} from "@/types/mediaObject";
import {RoomVariant} from "@/types/roomVariant";
import {getAvailabilityGroupsForRoomAvailabilities, getEarlyBookingDiscountForHotel,} from "@/utils/price_utils";
import {apiCall, endpoint} from "@/api/apiCall";
import Decimal from "decimal.js";
import i18n from "@/i18n/i18n";
import {loginModule} from "@/store/login/login";

export interface ReservationFormSelectedRooms<T> {
    [roomId: string]: T | undefined;
}

export interface ReservationFormSelectedRoomVariantDict {
    [variantId: string]: ReservationFormSelectedRoomVariant | undefined;
}

export interface ReservationFormSelectedRoomVariant {
    q: number | null,
    rawVariant: RoomVariant | null,
    serviceType: RoomServiceType | null,
    passengers: number | null
}

@Module({ name: "ReservationFormModule", store: store, dynamic: true })
export default class ReservationFormModule extends VuexModule {

    hotel: Hotel | null = null;
    reservation: ReservationForm | null = null;
    selectedRooms: ReservationFormSelectedRooms<ReservationFormSelectedRoomVariantDict> | null = null;
    addEditAsync = false;
    generatePdfAsync = false;
    offerAsync = false;

    mainPassengerIndex = 0;
    mainPassengerPhone = "";
    mainPassengerEmail = "";
    mainPassengerNotices = "";

    offerCreatedForCurrentData = false;

    @Mutation
    setReservationFormHotel(hotel: Hotel | null): void {
        this.hotel = hotel;

        if (this.hotel?.id !== hotel?.id || hotel === null) {
            this.reservation = null;
            this.addEditAsync = false;

            this.mainPassengerIndex = 0;
            this.mainPassengerPhone = "";
            this.mainPassengerEmail = "";
            this.mainPassengerNotices = "";

            // Allow offer creation when new reservation process is initiated
            this.offerCreatedForCurrentData = false;
        } else {

            const { adults, children, childrenUnder6 } = homeFiltersModule.passengersData;

            this.reservation = new ReservationForm({
                passengers: [
                    ...Array.from(Array(adults).keys()).map(() => new Passenger({ age: PassengerAge.ADULT })),
                    ...Array.from(Array(children).keys()).map(() => new Passenger({ age: PassengerAge.CHILD_UNDER_12 })),
                    ...Array.from(Array(childrenUnder6).keys()).map(() => new Passenger({ age: PassengerAge.CHILD_UNDER_6 })),
                ],
            });

            if (this.reservation.passengers?.[0]) {
                this.reservation.passengers[0].isMainPassenger = true;
            }
        }
    }

    @Mutation
    setMainPassengerIndex(index: number): void {
        this.mainPassengerIndex = index;
    }

    @Mutation
    setMainPassengerPhone(phone: string): void {
        this.mainPassengerPhone = phone;
    }

    @Mutation
    setMainPassengerEmail(email: string): void {
        this.mainPassengerEmail = email;
    }

    @Mutation
    setMainPassengerNotices(notices: string): void {
        this.mainPassengerNotices = notices;
    }

    @Mutation
    setAddEditAsync(async: boolean): void {
        this.addEditAsync = async;
    }

    @Mutation
    setPdfAsync(async: boolean): void {
        this.generatePdfAsync = async;
    }

    @Mutation
    setOfferAsync(async: boolean): void {
        this.offerAsync = async;
    }

    @Mutation
    reservationFormChangeAdditionalContent({
                                               itemId,
                                               data,
                                           }: { itemId: string, data: Omit<Partial<ReservationAdditionalContent>, "additionalContent"> }): void {
        if (this.reservation && this.reservation.reservationAdditionalContents) {
            const index = this.reservation.reservationAdditionalContents.findIndex(i => i.additionalContent.id === itemId) ?? -1;

            if (index !== -1) {
                // if we got an index we got everything else also
                const next = [...this.reservation.reservationAdditionalContents];
                merge(next[index], data);

                this.reservation.reservationAdditionalContents = next;
            }
        }
    }

    @Mutation
    reservationFormAppendAdditionalContentItem(item: ReservationAdditionalContent): void {
        if (this.reservation !== null) {
            if (this.reservation.reservationAdditionalContents === undefined) {
                Vue.set(this.reservation, "reservationAdditionalContents", [item]);
            } else {
                this.reservation.reservationAdditionalContents.push(item);
            }
        }

    }

    @Mutation
    reservationFormRemoveAdditionalContentItem(itemId: string): void {
        if (this.reservation !== null && this.reservation.reservationAdditionalContents !== null) {
            this.reservation.reservationAdditionalContents = this.reservation.reservationAdditionalContents?.filter(i => i.additionalContent.id !== itemId);
        }
    }

    @Mutation
    updateReservationValue(reservation: PartialDeep<ReservationForm>): void {
        let newReservation = clone(this.reservation);

        if (newReservation === null) {
            newReservation = new ReservationForm({});
        }

        newReservation = merge(newReservation, reservation);
        this.reservation = newReservation;
    }

    @Mutation
    setReservationForm(reservation: ReservationForm | null): void {
        this.reservation = reservation;
    }

    @Mutation
    updateReservation(reservation: ReservationForm): void {
        this.reservation = reservation;
    }

    @Mutation
    cancelReservation(): void {
        this.reservation = null;
        this.selectedRooms = null;
        this.hotel = null;
        this.mainPassengerIndex = 0;
        this.mainPassengerPhone = "";
        this.mainPassengerEmail = "";
        this.mainPassengerNotices = "";
        this.offerCreatedForCurrentData = false;
    }

    @Mutation
    updateRoomVariant({
                          roomId,
                          variantId,
                          variant,
                      }: { roomId: string, variantId: string, variant: Partial<ReservationFormSelectedRoomVariant> }): void {

        const thisVariant = this.selectedRooms?.[roomId]?.[variantId];

        this.selectedRooms = {
            ...this.selectedRooms,
            [roomId]: {
                ...(this.selectedRooms ?? {})[roomId],
                [variantId]: {
                    ...{
                        q: thisVariant?.q ?? null,
                        rawVariant: thisVariant?.rawVariant ?? null,
                        serviceType: thisVariant?.serviceType ?? null,
                        passengers: thisVariant?.passengers ?? null,
                    },
                    ...variant,
                },
            },
        };
    }

    @Mutation
    setSelectedRooms(selectedRooms: ReservationFormSelectedRooms<ReservationFormSelectedRoomVariantDict> | null): void {
        this.selectedRooms = selectedRooms;
    }

    @Mutation
    setOfferCreatedForCurrentData(isCreated: boolean): void {
        this.offerCreatedForCurrentData = isCreated;
    }

    // @Action
    // convertOfferToReservation(offer: Offer): void {
    //     this.setReservationFormHotel(offer.hotel ?? null);
    // }

    @Action
    async addReservationAction(): Promise<Reservation | null> {
        this.setAddEditAsync(true);

        const mainPassengers = this.passengers[this.mainPassengerIndex ?? 0];

        mainPassengers.isMainPassenger = true;
        mainPassengers.phone = this.mainPassengerPhone;
        mainPassengers.email = this.mainPassengerEmail;
        mainPassengers.notices = this.mainPassengerNotices;

        // Handle passenger passport images

        const imgPromises: Array<Promise<MediaObject | null>> = [];

        this.passengers.forEach(p => {
            if ((p.passportImage as UploadedFile)?.file) {
                imgPromises.push(mediaModule.uploadMediaObject({
                    file: (p.passportImage as UploadedFile),
                }).then(mediaObj => p.passportImage = mediaObj)
                    .catch(() => p.passportImage = null));
            }
            if ((p.passengerImage as UploadedFile)?.file) {
                imgPromises.push(mediaModule.uploadMediaObject({
                    file: (p.passengerImage as UploadedFile),
                }).then(mediaObj => p.passengerImage = mediaObj)
                    .catch(() => p.passengerImage = null));
            }
        });

        await Promise.allSettled(imgPromises);

        if (this.reservation !== null && this.selectedRooms !== null) {

            const data: ReservationPostData = this.reservationPostData;

            try {
                return await addReservation(data).finally(() => this.setAddEditAsync(false));
            } catch (e) {
                console.error(e);
            }
        }

        this.setAddEditAsync(false);
        return null;
    }

    @Action
    async generatePartialPdf(): Promise<string | void> {
        if (this.reservation !== null && this.selectedRooms !== null) {

            this.setPdfAsync(true);

            const data: ReservationPostData = cloneDeep(this.reservationPostData);

            data.reservationAdditionalContents = data.reservationAdditionalContents.map(content => {
                const split = content.additionalContent.split("/");
                content.additionalContent = split.pop() ?? "";
                return content;
            });

            return await apiCall({
                url: `${endpoint}/reservations/generate_partial_pdf`,
                method: "POST",
                jsonData: data as Record<string, any>,
            })
                .then(r => r?.text())
                .finally(() => this.setPdfAsync(false));
        }
    }

    get reservationPostData(): ReservationPostData {
        return {
            type: homeFiltersModule.type,
            passengers: this.passengers,
            from: moment(homeFiltersModule.dateRange.start).format("YYYY-MM-DD"),
            to: moment(homeFiltersModule.dateRange.end).format("YYYY-MM-DD"),
            variantsData: Object.values(this.selectedRooms ?? []).flatMap((variants) => {
                if (variants) {
                    return Object.entries(variants).flatMap(([id, variant]) => {
                        if (variant?.q && variant?.serviceType && variant?.passengers) {
                            return [{
                                id,
                                q: variant.q,
                                serviceType: variant.serviceType,
                                passengers: variant.passengers,
                            }];
                        }

                        return [];
                    });
                }
                return [];
            }),
            reservationAdditionalContents: this.reservation?.reservationAdditionalContents?.map(ac => {
                return {
                    additionalContent: `/api/additional_contents/${ac.additionalContent.id}`,
                    passengerCount: ac.passengerCount,
                    isPriceDoubled: ac.isPriceDoubled,
                    agentValues: ac.agentValues,
                };
            }) ?? [],
            specialRequest: this.reservation?.specialRequest,
        };
    }

    get reservationCheckInDate(): string {
        return moment(homeFiltersModule.dateRange.start).format("DD.MM.YYYY");
    }

    get reservationCheckOutDate(): string {
        return moment(homeFiltersModule.dateRange.end).format("DD.MM.YYYY");
    }

    get selectedHotel(): Hotel | null {
        return this.hotel;
    }

    get passengers(): Array<Passenger> {
        return this.reservation?.passengers ?? [];
    }

    get breadcrumbs(): Array<Breadcrumb> {
        return [
            {
                label: i18n.tc("hotels"),
                route: RouteLink.Hotels,
            },
            {
                label: this.selectedHotel?.name ?? "",
                route: RouteName.SingleHotel,
                routeMeta: {
                    hotelId: this.selectedHotel?.id ?? "",
                    tab: EditHotelSectionType.ROOMS,
                },
            },
            {
                label: i18n.tc("edit_reservation"),
                route: RouteLink.ManageReservation,
                disabled: !this.bookRoomsButtonEnabled,
            },
            {
                label: i18n.tc("finish_reservation"),
                route: RouteLink.FinishReservation,
                disabled: !this.bookRoomsButtonEnabled,
            },
            {
                label: i18n.tc("confirm"),
                route: RouteLink.ConfirmReservation,
                disabled: !this.bookRoomsButtonEnabled,
            },
        ];
    }

    get rooms(): Array<{ room: Room, variants: Record<string, ReservationFormSelectedRoomVariant> }> {
        const rooms: { [id: string]: Room | undefined } = {};

        for (const room of (this.hotel?.rooms ?? [])) {
            rooms[room.id] = room;
        }

        const ret: Array<{ room: Room, variants: Record<string, ReservationFormSelectedRoomVariant> }> = [];

        if (this.selectedRooms) {
            for (const [roomId, variants] of Object.entries(this.selectedRooms)) {
                const room = rooms[roomId];

                if (variants && room) {
                    const variantsObj: Record<string, ReservationFormSelectedRoomVariant> = {};

                    for (const [variantId, variant] of Object.entries(variants)) {
                        if (variant && variant.q && variant.serviceType) {
                            const rawVariant = room.roomVariants.find(v => v.id === variantId);

                            if (rawVariant) {
                                variantsObj[variantId] = {
                                    rawVariant,
                                    q: variant.q,
                                    serviceType: variant.serviceType,
                                    passengers: variant.passengers,
                                };
                            }
                        }
                    }

                    if (Object.keys(variantsObj).length > 0) {
                        ret.push({
                            room,
                            variants: variantsObj,
                        });
                    }
                }
            }
        }

        return ret;
    }

    get bookRoomsButtonEnabled(): boolean {
        return (this.rooms && Object.keys(this.rooms).length > 0 && this.remainingRoomsToAssign === 0 && this.remainingPassengersToAssign === 0) ?? false;
    }

    get totalAccommodationPrice(): number {
        let total = this.rooms.reduce<number>((previousValue, currentValue) => {
            const availabilityGroups = getAvailabilityGroupsForRoomAvailabilities(currentValue.room.availabilities);
            const currentArrTotal = availabilityGroups.calculatePriceForVariantsArray(Object.values(currentValue.variants), loginModule.agencyMarkup);

            previousValue += currentArrTotal;

            return previousValue;
        }, 0);

        if (this.earlyBookingDiscount !== null) {
            total = new Decimal(total).mul(1 - new Decimal(this.earlyBookingDiscount).div(100).toNumber()).toNumber();
        }

        return total;
    }

    get maxPassengers(): number {
        return homeFiltersModule.numOfPassengers;
    }

    get assignedPassengersCount(): number {

        let count = 0;

        Object.values(this.selectedRooms ?? {}).forEach((variants) => {
            Object.values(variants ?? {}).forEach(variant => {
                count += variant?.passengers ?? 0;
            });
        });

        return count;
    }

    get remainingPassengersToAssign(): number {
        return this.maxPassengers - this.assignedPassengersCount;
    }

    get numRoomsSelected(): number {

        let count = 0;

        Object.values(this.selectedRooms ?? {}).forEach((variants) => {
            Object.values(variants ?? {}).forEach(variant => {
                count += variant?.q ?? 0;
            });
        });

        return count;
    }

    get remainingRoomsToAssign(): number {
        return homeFiltersModule.roomsNumber - this.numRoomsSelected;
    }

    get earlyBookingDiscount(): number | null {

        if (!this.hotel) {
            return null;
        }

        return getEarlyBookingDiscountForHotel(this.hotel, homeFiltersModule.startDate);
    }
}

export const reservationFormModule = getModule(ReservationFormModule);
