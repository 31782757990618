import { notifications, jsonApiCall } from "@/api/apiCall";
import {
    PaginationResponse,
    RawHydraResponse,
    RawHydraResponseIntoPaginationResponse,
} from "@/types/paginationResponse";
import { Notification, INotificationForm } from "@/types/notification";

export async function fetchAllNotifications(): Promise<Array<Notification> | null> {
    const r = await jsonApiCall<Array<Notification>>({
        url: notifications,
        method: "GET",
    });

    return r;
}

export async function fetchNotificationsPage(page: number, perPage: number): Promise<PaginationResponse<Notification> | null> {
    const r = await jsonApiCall<RawHydraResponse<Notification>>({
        url: `${notifications}?page=${page}&per_page=${perPage}`,
        method: "GET",
        headers: {
            "accept": "application/ld+json",
        },
    });

    if (r !== null) {
        return RawHydraResponseIntoPaginationResponse<Notification>(r);
    } else {
        return null;
    }
}

export async function addNotification(data: INotificationForm): Promise<Notification | null> {
    const r = await jsonApiCall<Notification>({
        url: notifications,
        method: "POST",
        jsonData: data as unknown as Record<string, unknown>
    });

    if (r !== null) {
        return r;
    }

    return null;
}

export async function editNotification(notificationId: string, data: INotificationForm): Promise<Notification | null> {
    const r = await jsonApiCall<Notification>({
        url: `${notifications}/${notificationId}`,
        method: "PUT",
        jsonData: data as unknown as Record<string, unknown>
    });

    if (r !== null) {
        return r;
    }

    return null;
}

export async function readNotification(notificationId: string): Promise<Notification | null> {
    const r = await jsonApiCall<Notification>({
        url: `${notifications}/${notificationId}/read`,
        method: "PUT",
    });

    if (r !== null) {
        return r;
    }

    return null;
}

export async function fetchNotificationById(notificationId: string): Promise<Notification | null> {
    const r = await jsonApiCall<Notification>({
        url: `${notifications}/${notificationId}`,
    });

    if (r !== null) {
        return r;
    }

    return null;
}

export async function deleteNotification(notificationId: string): Promise<null> {

    const r = await jsonApiCall<null>({
        url: `${notifications}/${notificationId}`,
        method: "DELETE"
    });

    return r;
}

export async function markNotificationAsRead(notificationId: string): Promise<Notification | null> {

    return await jsonApiCall<null>({
        url: `${notifications}/${notificationId}/read`,
        method: "PUT",
        jsonData: {},
    });
}

export async function createNotificationGlobal(data: INotificationForm): Promise<Notification | null> {
    return await jsonApiCall<Notification>({
        url: `${notifications}/create_global`,
        method: "POST",
        jsonData: data as unknown as Record<string, unknown>
    });
}

export async function createNotificationForAgencies(data: INotificationForm, agenciesIds: string[]): Promise<Notification[] | null> {
    return await jsonApiCall<Notification[]>({
        url: `${notifications}/create_agency`,
        method: "POST",
        jsonData: { content: data.content, agencies: agenciesIds } as unknown as Record<string, unknown>
    });
}

export async function createNotificationForUsers(data: INotificationForm, usersIds: string[]): Promise<Notification[] | null> {
    return await jsonApiCall<Notification[]>({
        url: `${notifications}/create_user`,
        method: "POST",
        jsonData: { content: data.content, users: usersIds } as unknown as Record<string, unknown>
    });
}

