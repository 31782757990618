

import Vue from "vue";
import Component from "vue-class-component";
import Checkbox from "@/components/Ui/Checkbox/Checkbox.vue";
import Button, { ButtonType } from "@/components/Ui/Button/Button.vue";
import { goBackOrPush } from "@/utils/utils";
import { RouteLink } from "@/router";
import { roomsModule } from "@/store/rooms/rooms";
import { additionalContentCategoriesModule as categoriesModule } from "@/store/additional_content_categories/additional_content_categories";

@Component({
    components: { Button, Checkbox }
})
export default class DeleteCategory extends Vue {
    ButtonType = ButtonType

    deleteConfirmed = false;

    get async(): boolean {
        return roomsModule.deleteAsync;
    }

    backToCategories(): void {
        goBackOrPush(RouteLink.AdditionalContentCategories);
    }

    deleteCategory(): void {
        if (!this.deleteConfirmed) return;

        if (categoriesModule.selectedAdditionalContentCategory) {
            categoriesModule.deleteAdditionalContentCategoryAction(categoriesModule.selectedAdditionalContentCategory.id).then(this.backToCategories);
        }
    }
}
