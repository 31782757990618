import { AdditionalContentCategory } from "@/types/additionalContentCategory";
import { difference } from "@/utils/utils";
import { merge } from "lodash";
import { MediaObject } from "@/types/mediaObject";
import { Language } from "@/types/language";

export interface ReservationFormAdditionalContentItem {
    additionalContent: string,
    passengerCount: number,
    isPriceDoubled: boolean,
    agentValues: Record<string, unknown>
}

export interface ReservationAdditionalContent {
    additionalContent: ListAdditionalContent,
    passengerCount: number,
    isPriceDoubled: boolean,
    agentValues: Record<string, unknown>
}

export interface ListAdditionalContent {
    id: string,
    createdAt: string,
    email: string | null,
    nameBs: string,
    nameEn: string | null,
    descriptionBs: string,
    descriptionEn: string,
    values?: Record<string, unknown>,
    category: AdditionalContentCategory,
    mediaObjects: Array<MediaObject>,
    priceInCents: number,
}

export interface AdditionalContent {
    id: string,
    createdAt: string,
    nameBs: string,
    nameEn: string | null,
    email: string | null;
    descriptionBs: string,
    descriptionEn: string,
    values?: Record<string, unknown>,
    category: AdditionalContentCategory,
    priceInCents: number,
    mediaObjects: Array<MediaObject>
}

export interface IAdditionalContentForm {
    nameBs?: string,
    nameEn?: string | null,
    email?: string | null;
    descriptionBs?: string
    descriptionEn?: string
    category?: string,
    values?: Record<string, unknown>,
    mediaObjects?: Array<MediaObject | string>,
    priceInCents?: number,
}

export class AdditionalContentUtils {

    public static resolveName(ac: AdditionalContent|AdditionalContentCategory, language: Language = Language.bs): string|null {
        return language === Language.bs ? ac.nameBs : ac.nameEn;
    }
}

export class AdditionalContentForm implements IAdditionalContentForm {
    nameBs?: string;
    nameEn?: string | null;
    email?: string | null;
    descriptionBs?: string;
    descriptionEn?: string;
    category?: string;
    values?: Record<string, unknown>;
    priceInCents?: number;
    mediaObjects: Array<MediaObject | string> = [];

    constructor(props: IAdditionalContentForm = {}) {
        merge(this, props);
    }

    static createFormWithAdditionalContent(additionalContent: AdditionalContent): AdditionalContentForm {

        const { category, ...needed } = additionalContent;

        return new AdditionalContentForm({
            ...needed,
            category: category.id,
        });
    }

    public validate(): boolean {
        return this.nameBs !== "";            // TODO
    }

    public prepareFormForSending(): void {
        //
    }

    public difference(anotherForm: IAdditionalContentForm): IAdditionalContentForm {

        const diff = difference(this as IAdditionalContentForm, anotherForm) as IAdditionalContentForm;

        diff.mediaObjects = this.mediaObjects.map(m => typeof m === "string" ? m : m.id);

        return diff;
    }
}

export interface AdditionalContentQuery {
    name: string | null,
}
