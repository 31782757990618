
import Vue from "vue";
import Component from "vue-class-component";
import Spinner from "@/components/Ui/Spinner/Spinner.vue";
import CustomTable, { TableField } from "@/components/Ui/CustomTable/CustomTable.vue";
import Button from "@/components/Ui/Button/Button.vue";
import ListPagination from "@/components/ListPagination/ListPagination.vue";
import AdminPageHeader from "@/components/AdminPageHeader/AdminPageHeader.vue";
import SingleReservation from "@/views/SingleReservation/SingleReservation.vue";
import PlaceholderableImage from "@/components/Ui/PlaceholderableImage/PlaceholderableImage.vue";
import ReservationStatusWidget from "@/views/Reservations/components/ReservationStatusWidget.vue";
import ReservationInfo from "@/views/Reservations/components/ReservationInfo.vue";
import { SortOrder } from "@/utils/utils";
import { loginModule } from "@/store/login/login";
import { manualReservationsModule } from "@/store/manual_reservations/manual_reservations";
import { ManualReservation } from "@/types/manualReservation";
import { ListReservation } from "@/types/reservation";
import moment from "moment";
import SingleManualReservation
    from "@/views/Reservations/components/ManualReservations/SingleManualReservation/SingleManualReservation.vue";


@Component({
    components: {
        SingleManualReservation,
        ReservationInfo,
        ReservationStatusWidget,
        PlaceholderableImage,
        SingleReservation,
        AdminPageHeader,
        ListPagination,
        Button,
        CustomTable,
        Spinner,
    },
    mounted() {
        manualReservationsModule.fetchReservationsFirstPage();
    },
})
export default class ManualReservations extends Vue {

    get tableFields(): Array<TableField<ManualReservation>> {
        return [{
            name: "hotel",
            title: "Hotel",
            width: "60%",
            slot: "info",
            sortEnabled: this.isSuperAdmin,
        }, {
            name: "createdAt",
            title: this.$tc("created_at"),
            width: "15%",
            slot: "createdAt",
            sortEnabled: true,
        }, {
            name: "status",
            title: "Status",
            width: "25%",
            slot: "status",
            sortEnabled: true,
        }];
    }

    get sortField(): string | null {
        return manualReservationsModule.sortField;
    }

    get sortOrder(): SortOrder {
        return manualReservationsModule.sortOrder;
    }

    get currentPage(): number {
        return manualReservationsModule.currentPage;
    }

    get totalPages(): number {
        return manualReservationsModule.totalPages;
    }

    get reservations(): Array<ManualReservation> {
        return manualReservationsModule.reservations;
    }

    get async(): boolean {
        return manualReservationsModule.fetchAsync;
    }

    get isSuperAdmin(): boolean {
        return loginModule.isSuperAdmin;
    }

    onSortChange(sortField: keyof ManualReservation, sortOrder: SortOrder): void {
        manualReservationsModule.setReservationsSortField(sortField);
        manualReservationsModule.setReservationsSortOrder(sortOrder);
        manualReservationsModule.fetchReservationsPageAction(1);
    }

    onPageChange(page: number): void {
        manualReservationsModule.fetchReservationsPageAction(page);
    }

    getFromToText(reservation: ManualReservation): string {
        if (reservation.fromDate !== null && reservation.toDate !== null) {
            return `${moment(reservation.fromDate).format("DD MMMM YYYY")} - ${moment(reservation.toDate).format("DD MMMM YYYY")}`;
        }

        return "";
    }
}
