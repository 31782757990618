
import Vue from "vue";
import Component from "vue-class-component";
import { offersModule } from "@/store/offers/offers";
import Spinner from "@/components/Ui/Spinner/Spinner.vue";
import CustomTable, { TableField } from "@/components/Ui/CustomTable/CustomTable.vue";
import Button from "@/components/Ui/Button/Button.vue";
import ListPagination from "@/components/ListPagination/ListPagination.vue";
import AdminPageHeader from "@/components/AdminPageHeader/AdminPageHeader.vue";
import { ListOffer, Offer } from "@/types/offer";
import PlaceholderableImage from "@/components/Ui/PlaceholderableImage/PlaceholderableImage.vue";
import ReservationInfo from "@/views/Reservations/components/ReservationInfo.vue";
import SingleReservation from "@/views/SingleReservation/SingleReservation.vue";
import { getStorageUrl, showAreYouSureSwal, showSwalToast } from "@/utils/utils";
import {
    reservationFormModule,
    ReservationFormSelectedRooms,
    ReservationFormSelectedRoomVariantDict,
} from "@/store/reservation_form/reservation_form";
import { RouteName } from "@/router";
import { hotelsModule } from "@/store/hotels/hotels";
import { homeFiltersModule } from "@/store/home_filters/home_filters";
import { fetchOfferById } from "@/api/offers/offers";
import { Hotel } from "@/types/hotel";
import { Language } from "@/types/language";
import { settingsModule } from "@/store/settings/settings";
import { loginModule } from "@/store/login/login";

@Component({
    components: {
        SingleReservation,
        ReservationInfo,
        PlaceholderableImage,
        AdminPageHeader,
        ListPagination,
        Button,
        CustomTable,
        Spinner,
    },
    mounted() {
        offersModule.fetchOffersFirstPage();
    },
})
export default class Offers extends Vue {

    get tableFields(): Array<TableField<ListOffer>> {
        return [{
            name: "info",
            title: this.$tc("sort"),
            slot: "info",
            width: "30%",
        }, {
            name: "client",
            title: this.$tc("client"),
            slot: "client",
        }, {
            name: "createdAt",
            title: this.$tc("created_at"),
            slot: "createdAt",
            width: "10%",
        }, {
            name: "from",
            title: this.$tc("arrival_date"),
            slot: "from",
            width: "10%",
        }, {
            name: "to",
            title: this.$tc("departure_date"),
            slot: "to",
            width: "10%",
        }, {
            name: "actions",
            title: this.$tc("search"),
            slot: "actions",
            rowClass: "offer-actions",
            headerClass: "offer-actions",
        }];
    }

    convertingOfferToReservationAsync = false;

    get currentPage(): number {
        return offersModule.currentPage;
    }

    get totalPages(): number {
        return offersModule.totalPages;
    }

    get offers(): Array<ListOffer> {
        return offersModule.offers;
    }

    get async(): boolean {
        return offersModule.fetchAsync || offersModule.deleteAsync || this.convertingOfferToReservationAsync;
    }

    async deleteOffer(offer: Offer): Promise<void> {
        const prompt = await showAreYouSureSwal({ text: this.$tc("yes_delete_offer") + "." }, this.$i18n.locale as Language);

        if (prompt.value) {
            offersModule.deleteOfferAction(offer.id);
        }
    }

    async printOffer(offer: Offer): Promise<void> {

        const htmlResponse = await offersModule.printOfferAction(offer.id);

        if (htmlResponse !== null) {
            const win = window.open("", "_blank");
            win?.document.write(htmlResponse);
            win?.document.close();
        }
    }

    async convertOfferToReservation(listOffer: ListOffer): Promise<void> {

        const r = await showAreYouSureSwal({ text: this.$tc("are_you_sure_you_want_to_convert_this_offer_to_a_reservation") }, this.$i18n.locale as Language);

        if (!r.value) {
            return;
        }

        this.convertingOfferToReservationAsync = true;

        // full offer entity
        try {
            const offer = await fetchOfferById(listOffer.id);

            if (offer === null) {
                return;
            }

            let possible = false;

            const selectedRooms: ReservationFormSelectedRooms<ReservationFormSelectedRoomVariantDict> = {};
            let offerHotel: Hotel | null = null;

            if (offer.hotel) {

                // Query hotels to check if its actually possible to convert an offer to reservation

                const hotels = await hotelsModule.searchHotelsAction({
                    queryData: {
                        type: homeFiltersModule.type,
                        location: homeFiltersModule.location,
                        roomsNumber: homeFiltersModule.roomsNumber,
                        passengersData: homeFiltersModule.passengersData,
                        dateRange: {
                            start: new Date(offer.fromDate),
                            end: new Date(offer.toDate),
                        },
                        searchText: homeFiltersModule.searchText,
                    },
                    adminMarkup: this.adminMarkup,
                    agencyMarkup: this.agencyMarkup,
                });

                offerHotel = hotels?.find(h => h.id === offer.hotel?.id) ?? null;

                if (offerHotel !== null) {

                    // check if all rooms are available TODO

                    offer.offerRoomVariants.forEach(orv => {

                        const roomId = orv.variant.roomId;
                        const variantId = orv.variant.id;

                        if (roomId && variantId) {
                            selectedRooms[roomId] = {
                                ...(selectedRooms ?? {})[roomId],
                                [variantId]: {
                                    ...{
                                        q: orv.quantity ?? null,
                                        rawVariant: orv.variant ?? null,
                                        serviceType: orv.serviceType ?? null,
                                        passengers: orv.passengers ?? null,
                                    },
                                },
                            };
                        }
                    });

                    possible = true;

                } else {
                    showSwalToast(this.$tc("there_are_no_available_hotels") + ".");
                }
            }

            if (possible && offerHotel) {
                reservationFormModule.setReservationFormHotel(offerHotel);
                reservationFormModule.setSelectedRooms(selectedRooms);
                offer.offerAdditionalContents.forEach(offerAdditionalContent => {
                    reservationFormModule.reservationFormAppendAdditionalContentItem(offerAdditionalContent);
                });
                this.$router.push({
                    name: RouteName.FinishReservation,
                });
            }
        } finally {
            this.convertingOfferToReservationAsync = false;
        }
    }

    getHotelImage(offer: Offer): string | null {
        return getStorageUrl(offer?.hotel?.mediaObjects?.[0] ?? null);
    }

    getHotelName(offer: Offer): string {
        return offer.hotel?.name ?? "";
    }

    onPageChange(page: number): void {
        offersModule.fetchOffersPageAction(page);
    }

    get adminMarkup() {
        return settingsModule.adminMarkup;
    }

    get agencyMarkup() {
        return loginModule.agencyMarkup;
    }
}
