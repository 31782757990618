
import { RouteLink } from "@/router";
import Component from "vue-class-component";
import Vue from "vue";
import { loginModule } from "@/store/login/login";
import HeaderRightPartAdmin from "@/components/Header/components/HeaderRightPartAdmin.vue";
import HeaderRightPartUser from "@/components/Header/components/HeaderRightPartUser.vue";
import { additionalContentCategoriesModule } from "@/store/additional_content_categories/additional_content_categories";
import { User } from "@/types/user";
import { homeFiltersModule } from "@/store/home_filters/home_filters";

export interface HeaderLink {
    name: string,
    path: RouteLink | string,
}

@Component({
    components: { HeaderRightPartUser, HeaderRightPartAdmin },
})
export default class Header extends Vue {
    get headerLinks(): Array<HeaderLink> {
        if (this.isSuperAdmin) {
            return [{
                name: "Naslovna",
                path: RouteLink.Dashboard,
            }, {
                name: "Agencije",
                path: RouteLink.Agencies,
            }, {
                name: "Korisnici",
                path: RouteLink.Users,
            }, {
                name: "Sadržaji",
                path: RouteLink.Content,
            }, {
                name: "Rezervacije",
                path: RouteLink.Reservations,
            }, {
                name: "Kalendar",
                path: RouteLink.Calendar,
            }, {
                name: "Izvještaji",
                path: RouteLink.Reports,
            }, {
                name: "Uplate",
                path: RouteLink.Payments,
            }];
        } else if (loginModule.isLoggedIn && this.isAgent) {
            const items = [];

            if (!homeFiltersModule.invalid) {
                items.push({
                    name: this.$tc("hotels"),
                    path: RouteLink.Hotels,
                });
            } else {
                items.push({
                    name: this.$tc("search"),
                    path: RouteLink.Home,
                });
            }

            items.push({
                name: this.$tc("reservations"),
                path: RouteLink.Reservations,
            });

            items.push({
                name: this.$tc("offers"),
                path: RouteLink.Offers,
            });

            return items;
        }

        return [];
    }

    RouteLink = RouteLink;

    get isSuperAdmin(): boolean {
        return loginModule.isSuperAdmin;
    }

    get isAgent(): boolean {
        return loginModule.isAgent;
    }

    get isAgentRoot(): boolean {
        return this.user !== null && loginModule.isAgent && this.$route.path === "/";
    }

    get user(): User | null {
        return loginModule.user;
    }

    mounted(): void {
        if (additionalContentCategoriesModule.additionalContentCategories.length === 0) {
            additionalContentCategoriesModule.fetchAllAdditionalContentCategories();
        }
    }
}
