
import Vue from "vue";
import { Prop, Watch } from "vue-property-decorator";
import { AdditionalContent, AdditionalContentUtils } from "@/types/additionalContent";
import { getStorageUrl } from "@/utils/utils";
import Component from "vue-class-component";
import PlaceholderableImage from "@/components/Ui/PlaceholderableImage/PlaceholderableImage.vue";
import Button, { ButtonType } from "@/components/Ui/Button/Button.vue";
import CustomModal from "@/components/Ui/CustomModal/CustomModal.vue";
import AdditionalContentDetailedView from "@/views/AdditionalContentDetailedView/AdditionalContentDetailedView.vue";
import InputField from "@/components/Ui/InputField/InputField.vue";
import InputLabel from "@/components/Ui/InputLabel/InputLabel.vue";
import NumberPlusMinus from "@/components/NumberPlusMinus/NumberPlusMinus.vue";
import Checkbox, { CheckboxType } from "@/components/Ui/Checkbox/Checkbox.vue";
import { TRANSFER_CATEGORY_NAME } from "@/utils/consts";
import TransitionExpand from "@/components/TransitionExpand/TransitionExpand.vue";
import { AddFormItem } from "@/types/addForm/addFormItem";
import AddContentItem from "@/components/AddContentRenderer/AddContentItem.vue";
import { AddForm } from "@/types/addForm/addForm";
import { Agency } from "@/types/agency";
import { Language } from "@/types/language";

@Component({
    components: {
        AddContentItem,
        TransitionExpand,
        Checkbox,
        NumberPlusMinus,
        InputLabel,
        InputField,
        AdditionalContentDetailedView,
        CustomModal,
        Button,
        PlaceholderableImage,
    },
})
export default class ReservationAdditionalContentCard extends Vue {
    @Prop() additionalContent!: AdditionalContent;
    @Prop({ default: 1 }) numPersons!: number;
    @Prop({ default: false }) isPriceDoubled!: boolean;
    @Prop({ default: false }) selected!: boolean;
    @Prop({ default: false }) isPerPerson!: boolean;
    @Prop({ default: false }) canPriceBeDoubled!: boolean;
    @Prop({ default: {} }) agentValues!: Record<string, unknown>;

    detailsOpened = false;

    ButtonType = ButtonType;
    CheckboxType = CheckboxType;

    categoryAgentFields: AddFormItem[] | null = null;
    agentVals: Record<string, unknown> = {};

    @Watch("agentValues")
    onAgentValuesUpdated(vals: Record<string, unknown>): void {
        this.agentVals = vals;
    }

    @Watch("agentVals")
    onAgentValuesFormUpdated(newValues: Record<string, unknown>): void {
        this.$emit("changeAgentValues", newValues)
    }

    mounted(): void {
        if (this.additionalContent.category.agentFields !== undefined) {
            this.categoryAgentFields = AddForm.parse(JSON.stringify(this.additionalContent.category.agentFields)).pages[0]?.items ?? null;
        }
    }

    noop(): void {
        /**/
    }

    get getPrice(): number {
        return this.additionalContent?.priceInCents ?? 0;
    }

    get image(): string {
        if (this.additionalContent?.mediaObjects) {
            let firstImg = this.additionalContent?.mediaObjects[0];

            return getStorageUrl(firstImg ?? null);
        } else {
            return "";
        }
    }

    get contentName(): string {
        return AdditionalContentUtils.resolveName(this.additionalContent, this.$i18n.locale as Language) ?? "";
    }

    get contentDescription(): string {
        return (this.$i18n.locale === Language.bs ? this.additionalContent?.descriptionBs : this.additionalContent.descriptionEn) ?? "";
    }

    get doublePriceLabel(): string {
        return this.additionalContent.category.doublePriceLabel ?? 'Poduplaj cijenu';
    }

    get isTransfer(): boolean {
        return this.additionalContent.category.nameBs === TRANSFER_CATEGORY_NAME;
    }

    get showCategoryAgentFields(): boolean {
        return this.isTransfer && this.selected && this.categoryAgentFields !== null;
    }
}
