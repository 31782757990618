
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { HotelSponsoredLevel } from "@/types/hotelSpecialOffer";
import HotelListCard from "@/components/HotelListCard/HotelListCard.vue";
import { Hotel } from "@/types/hotel";
import PlaceholderableImage from "@/components/Ui/PlaceholderableImage/PlaceholderableImage.vue";
import hotelPlaceholderImg from '../../../assets/images/hotelPlaceholder.png';
import moment from "moment";

@Component({
    components: { PlaceholderableImage, HotelListCard }
})
export default class HotelSpecialOfferPlaceholderCard extends Vue {
    @Prop() readonly sponsoredLevel!: HotelSponsoredLevel;

    get placeholderImage(): string {
        return hotelPlaceholderImg;
    }

    get placeholderHotel(): Hotel {
        return {
            id: 'placeholder_id',
            name: 'Hampton by Hilton Dubai Airport',
            specialOffers: [
                {
                    id: 'placeholder_offer_id',
                    sponsoredLevel: this.sponsoredLevel,
                }
            ],
            earlyBookings: [],
            address: { street: 'Damascus Street - Al QusaisAl Qusais 1 ' },
            amenities: [
                { id: '1', name: 'Free Wifi', createdAt: '' },
                { id: '2', name: 'Pool', createdAt: '' },
                { id: '3', name: 'Free Parking', createdAt: '' }
            ],
            stars: 4.2,
            mediaObjects: [],
            featuredImage: null,
            margin: 0,
            rooms: [],
        };
    }
}
