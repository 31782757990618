
import Vue from "vue";
import Component from "vue-class-component";
import { reservationsModule } from "@/store/reservations/reservations";
import Spinner from "@/components/Ui/Spinner/Spinner.vue";
import CustomTable from "@/components/Ui/CustomTable/CustomTable.vue";
import Button, { ButtonType } from "@/components/Ui/Button/Button.vue";
import ListPagination from "@/components/ListPagination/ListPagination.vue";
import AdminPageHeader from "@/components/AdminPageHeader/AdminPageHeader.vue";
import SingleReservation from "@/views/SingleReservation/SingleReservation.vue";
import PlaceholderableImage from "@/components/Ui/PlaceholderableImage/PlaceholderableImage.vue";
import ReservationStatusWidget from "@/views/Reservations/components/ReservationStatusWidget.vue";
import ReservationInfo from "@/views/Reservations/components/ReservationInfo.vue";
import { RouteLink } from "@/router";
import { manualReservationsModule } from "@/store/manual_reservations/manual_reservations";
import InputField from "@/components/Ui/InputField/InputField.vue";
import InputLabel from "@/components/Ui/InputLabel/InputLabel.vue";
import { loginModule } from "@/store/login/login";

@Component({
    components: {
        InputLabel,
        InputField,
        ReservationInfo,
        ReservationStatusWidget,
        PlaceholderableImage,
        SingleReservation,
        AdminPageHeader,
        ListPagination,
        Button,
        CustomTable,
        Spinner,
    },
})
export default class Reservations extends Vue {

    ButtonType = ButtonType;
    activeButtonType = ButtonType.PLAINWHITE;
    inactiveButtonType = ButtonType.TRANSPARENT;
    RouteLink = RouteLink;

    get agencyReservationsActive(): boolean {
        return this.$route.path === RouteLink.AgenciesReservations;
    }

    get manualReservationsActive(): boolean {
        return this.$route.path === RouteLink.ManualReservations;
    }

    get isSuperAdmin() {
        return loginModule.isSuperAdmin;
    }

    get reservationsSearch(): string {
        return reservationsModule.search;
    }

    switchTabs(routeLink: string): void {
        this.$router.replace(routeLink).catch(() => {/**/
        });
    }

    onClickAddManualReservation(): void {
        manualReservationsModule.selectReservation(null);
        this.$router.push(RouteLink.ManageManualReservation);
    }

    updateReservationsSearchText(text: string): void {
        reservationsModule.setSearchText(text);
    }

    onSearch(): void {
        reservationsModule.fetchReservationsFirstPage();
    }
}
