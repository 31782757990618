import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { store } from "@/store";
import { ListReservation, Reservation, ReservationUtils } from "@/types/reservation";
import {
    addReservationStatus, apiConfirmReservation,
    apiFetchAllReservations,
    apiHotelSendReservation,
    apiSendEmailToReservationTransfer,
    deleteReservation,
    editListReservation,
    fetchReservationById,
    fetchReservationsPage,
} from "@/api/reservations/reservations";
import { cloneDeep, merge } from "lodash";
import { SortOrder } from "@/utils/utils";
import { ReservationStatusEnum } from "@/types/reservationStatus";

@Module({ name: "ReservationsModule", store: store, dynamic: true })
export default class ReservationsModule extends VuexModule {
    fetchAsync = false;
    singleFetchAsync = false;
    reservations: Array<ListReservation> = [];
    hasMore = true;
    currentPage = 0;
    totalPages = 1;
    perPage = 10;
    reservationConfirmAsync = false;
    confirmPaidAsync = false;
    sendHotelEmailAsync = false;
    selectedReservation: Reservation | null = null;
    addEditAsync = false;
    deleteAsync = false;
    sendTransferEmailAsync = false;

    isVisaSentAsync = false;
    isVisaPaidAsync = false;
    isVisaApprovedAsync = false;

    isTransferSentAsync = false;
    isTransferPaidAsync = false;
    isTransferApprovedAsync = false;

    isHotelPaidAsync = false;

    sortField: string | null = "createdAt";
    sortOrder: SortOrder = SortOrder.DESC;
    search = "";

    @Mutation
    setFetchAsync(async: boolean): void {
        this.fetchAsync = async;
    }

    @Mutation
    setReservationConfirmAsync(async: boolean): void {
        this.reservationConfirmAsync = async;
    }

    @Mutation
    setSendHotelEmailAsync(async: boolean): void {
        this.sendHotelEmailAsync = async;
    }

    @Mutation
    setTransferEmailAsync(async: boolean): void {
        this.sendTransferEmailAsync = async;
    }

    @Mutation
    setReservationConfirmPaidAsync(async: boolean): void {
        this.confirmPaidAsync = async;
    }

    @Mutation
    setReservationVisaApprovedAsync(async: boolean): void {
        this.isVisaApprovedAsync = async;
    }

    @Mutation
    setReservationVisaPaidAsync(async: boolean): void {
        this.isVisaPaidAsync = async;
    }

    @Mutation
    setReservationVisaSentAsync(async: boolean): void {
        this.isVisaSentAsync = async;
    }

    @Mutation
    setReservationTransferApprovedAsync(async: boolean): void {
        this.isTransferApprovedAsync = async;
    }

    @Mutation
    setReservationTransferPaidAsync(async: boolean): void {
        this.isTransferPaidAsync = async;
    }

    @Mutation
    setReservationTransferSentAsync(async: boolean): void {
        this.isTransferSentAsync = async;
    }

    @Mutation
    setReservationHotelPaidAsync(async: boolean): void {
        this.isHotelPaidAsync = async;
    }

    @Mutation
    setSingleFetchAsync(async: boolean): void {
        this.singleFetchAsync = async;
    }

    @Mutation
    setReservationDeleteAsync(async: boolean): void {
        this.deleteAsync = async;
    }

    @Mutation
    setCurrentPage(page: number): void {
        this.currentPage = page;
    }

    @Mutation
    setHasMore(hasMore: boolean): void {
        this.hasMore = hasMore;
    }

    @Mutation
    setPerPage(perPage: number): void {
        this.perPage = perPage;
    }

    @Mutation
    setTotalPages(totalPages: number): void {
        this.totalPages = totalPages;
    }

    @Mutation
    setReservations(reservations: Array<ListReservation>): void {
        this.reservations = reservations;
    }

    @Mutation
    removeReservation(id: string): void {
        this.reservations = this.reservations.filter((r) => r.id !== id);
    }

    @Mutation
    selectReservation(reservation: Reservation | null): void {
        this.selectedReservation = reservation;
    }

    @Mutation
    setAddEditAsync(async: boolean): void {
        this.addEditAsync = async;
    }

    @Mutation
    setReservationsSortField(sortField: keyof ListReservation | null): void {
        this.sortField = sortField;
    }

    @Mutation
    setReservationsSortOrder(sortOrder: SortOrder): void {
        this.sortOrder = sortOrder;
    }

    @Mutation
    setSearchText(search: string): void {
        this.search = search;
    }

    @Action
    async fetchAllReservations(): Promise<Array<ListReservation> | null> {
        this.setFetchAsync(true);

        try {
            const r = await apiFetchAllReservations();

            if (r !== null) {
                this.setReservations(r);
            }

            return r;
        } catch (e) {
            console.error(e);
        } finally {
            this.setFetchAsync(false);
        }

        return null;
    }

    @Action
    async fetchReservationsFirstPage(): Promise<void> {
        return this.fetchReservationsPageAction(1);
    }

    @Mutation
    updateListReservationById({
                                  reservationId,
                                  data,
                              }: {
        reservationId: string;
        data: Partial<ListReservation>;
    }): void {
        const index = this.reservations.findIndex(
            (reservation) => reservation.id === reservationId,
        );
        if (index !== -1) {
            this.reservations[index] = merge(this.reservations[index], data);
            this.reservations = [...this.reservations];
        }

        if (reservationId === this.selectedReservation?.id) {
            this.selectedReservation = merge(this.selectedReservation, data);
            if (data.statuses) {
                this.selectedReservation.statuses = cloneDeep(this.selectedReservation.statuses);
            }
        }
    }

    @Action
    async fetchReservationsPageAction(page: number): Promise<void> {
        this.setFetchAsync(true);

        try {
            const r = await fetchReservationsPage(page, this.perPage, this.sortField, this.sortOrder, this.search);

            if (r !== null) {
                this.setReservations(r.data);
                this.setTotalPages(Math.ceil(r.totalItems / this.perPage));
                this.setCurrentPage(page);
                this.setHasMore(r.hasMore);
            }
        } catch (e) {
            console.error(e);
        } finally {
            this.setFetchAsync(false);
        }
    }

    @Action({ rawError: true })
    async deleteReservationAction(reservationId: string): Promise<null | void> {
        if (this.deleteAsync) return;

        this.setReservationDeleteAsync(true);

        return deleteReservation(reservationId).finally(() => {
            this.removeReservation(reservationId);
            this.setReservationDeleteAsync(false);
        });
    }

    @Action
    async confirmReservation({
                                 reservationId,
                             }: {
        reservationId: string;
    }): Promise<boolean> {
        this.setReservationConfirmAsync(true);

        try {
            const r = await addReservationStatus(reservationId, ReservationStatusEnum.ADMIN_APPROVED);

            if (r !== null) {
                this.updateListReservationById({
                    reservationId,
                    data: {
                        statuses: r.statuses,
                    },
                });
            }

            return true;
        } catch (e) {
            return false;
        } finally {
            this.setReservationConfirmAsync(false);
        }
    }

    @Action
    async sendReservationHotelEmail({
                                        reservationId,
                                        additionalContent,
                                    }: {
        reservationId: string;
        additionalContent: string[];
    }): Promise<boolean> {
        this.setSendHotelEmailAsync(true);

        try {
            const r = await apiHotelSendReservation(reservationId, additionalContent);

            if (r !== null) {
                this.updateListReservationById({
                    reservationId,
                    data: {
                        isEmailToHotelSent: true,
                    },
                });
            }

            return true;
        } catch (e) {
            return false;
        } finally {
            this.setSendHotelEmailAsync(false);
        }
    }

    @Action
    async sendReservationTransferEmail({
                                           reservationId,
                                       }: {
        reservationId: string;
    }): Promise<boolean> {
        this.setTransferEmailAsync(true);

        try {
            await apiSendEmailToReservationTransfer(reservationId);
            return true;
        } catch (e) {
            return false;
        } finally {
            this.setTransferEmailAsync(false);
        }
    }

    @Action
    async confirmHotelIsPaid({ reservationId, amount }: {
        reservationId: string;
        amount: number,
    }): Promise<boolean> {
        this.setReservationHotelPaidAsync(true);

        try {
            const res = await addReservationStatus(reservationId, ReservationStatusEnum.ADMIN_PAID_HOTEL);

            if (res !== null) {
                const r = await editListReservation(reservationId, {
                    hotelPaidPrice: amount,
                });

                if (r !== null) {
                    this.updateListReservationById({
                        reservationId,
                        data: {
                            hotelPaidPrice: amount,
                            statuses: res.statuses,
                        },
                    });
                }
            }

            return true;
        } catch (e) {
            return false;
        } finally {
            this.setReservationHotelPaidAsync(false);
        }
    }

    @Action
    async confirmTransferIsPaid({ reservationId, price }: {
        reservationId: string;
        price: number,
    }): Promise<boolean> {
        this.setReservationTransferPaidAsync(true);

        try {
            const r = await addReservationStatus(reservationId, ReservationStatusEnum.ADMIN_PAID_TRANSFER);

            if (r !== null) {
                const res = await editListReservation(reservationId, {
                    transferPaidPrice: price,
                });

                if (res) {
                    this.updateListReservationById({
                        reservationId,
                        data: {
                            statuses: r.statuses,
                            transferPaidPrice: res.transferPaidPrice,
                        },
                    });
                }
            }

            return true;
        } catch (e) {
            return false;
        } finally {
            this.setReservationTransferPaidAsync(false);
        }
    }

    @Action
    async confirmTransferIsApproved({ reservationId }: {
        reservationId: string;
    }): Promise<boolean> {
        this.setReservationTransferApprovedAsync(true);

        try {
            const r = await addReservationStatus(reservationId, ReservationStatusEnum.ADMIN_CONFIRMED_TRANSFER);

            if (r !== null) {
                this.updateListReservationById({
                    reservationId,
                    data: {
                        statuses: r.statuses,
                    },
                });
            }

            return true;
        } catch (e) {
            return false;
        } finally {
            this.setReservationTransferApprovedAsync(false);
        }
    }

    @Action
    async confirmTransferIsSent({ reservationId }: {
        reservationId: string;
    }): Promise<boolean> {
        this.setReservationTransferSentAsync(true);

        try {
            const r = await addReservationStatus(reservationId, ReservationStatusEnum.ADMIN_SENT_EMAIL_TO_TRANSFER);

            if (r !== null) {
                this.updateListReservationById({
                    reservationId,
                    data: {
                        statuses: r.statuses,
                    },
                });
            }

            return true;
        } catch (e) {
            return false;
        } finally {
            this.setReservationTransferSentAsync(false);
        }
    }

    @Action
    async confirmVisaIsPaid({ reservationId, price, paidAt }: {
        reservationId: string;
        price: number,
        paidAt: string,
    }): Promise<boolean> {
        this.setReservationVisaPaidAsync(true);

        try {
            const r = await addReservationStatus(reservationId, ReservationStatusEnum.ADMIN_PAID_VISA);

            if (r !== null) {
                const res = await editListReservation(reservationId, {
                    visaPaidPrice: price,
                    visaPaidAt: paidAt,
                });

                if (res) {
                    this.updateListReservationById({
                        reservationId,
                        data: {
                            statuses: r.statuses,
                            visaPaidPrice: res.visaPaidPrice,
                            visaPaidAt: res.visaPaidAt,
                        },
                    });
                }
            }

            return true;
        } catch (e) {
            return false;
        } finally {
            this.setReservationVisaPaidAsync(false);
        }
    }

    @Action
    async confirmVisaIsApproved({ reservationId }: {
        reservationId: string;
    }): Promise<boolean> {
        this.setReservationVisaApprovedAsync(true);

        try {
            const r = await addReservationStatus(reservationId, ReservationStatusEnum.ADMIN_CONFIRMED_VISA);

            if (r !== null) {
                this.updateListReservationById({
                    reservationId,
                    data: {
                        statuses: r.statuses,
                    },
                });
            }

            return true;
        } catch (e) {
            return false;
        } finally {
            this.setReservationVisaApprovedAsync(false);
        }
    }

    @Action
    async confirmVisaIsSent({ reservationId }: {
        reservationId: string;
    }): Promise<boolean> {
        this.setReservationVisaSentAsync(true);

        try {
            const r = await addReservationStatus(reservationId, ReservationStatusEnum.ADMIN_SENT_VISA);

            if (r !== null) {
                this.updateListReservationById({
                    reservationId,
                    data: {
                        statuses: r.statuses,
                    },
                });
            }

            return true;
        } catch (e) {
            return false;
        } finally {
            this.setReservationVisaSentAsync(false);
        }
    }

    @Action
    async confirmReservationPaidAgent({
                                          reservationId,
                                          agencyPaidAt,
                                      }: {
        reservationId: string;
        agencyPaidAt?: string;
    }): Promise<boolean> {
        this.setReservationConfirmPaidAsync(true);

        try {
            const r = await editListReservation(reservationId, {
                agencyPaidAt,
            });

            if (r !== null) {
                const statusRes = await addReservationStatus(reservationId, ReservationStatusEnum.AGENCY_MARKED_PAID);

                if (statusRes !== null) {
                    this.updateListReservationById({
                        reservationId,
                        data: {
                            statuses: statusRes.statuses,
                            agencyPaidAt: r.agencyPaidAt,
                            isPaid: r.isPaid,
                        },
                    });
                }
            }

            return true;
        } catch (e) {
            return false;
        } finally {
            this.setReservationConfirmPaidAsync(false);
        }
    }

    @Action
    async confirmReservationPaid({
                                     reservationId,
                                     confirmationNumber,
                                 }: {
        reservationId: string;
        confirmationNumber?: string;
    }): Promise<boolean> {
        this.setReservationConfirmPaidAsync(true);

        try {
            const r = await apiConfirmReservation(reservationId, {
                confirmationNumber,
            });

            if (r !== null) {
                this.updateListReservationById({
                    reservationId,
                    data: {
                        confirmationNumber: r.confirmationNumber,
                        isPaid: r.isPaid,
                    },
                });
            }

            return true;
        } catch (e) {
            return false;
        } finally {
            this.setReservationConfirmPaidAsync(false);
        }
    }

    @Action
    async fetchReservationByIdAction(reservationId: string): Promise<void> {
        this.setSingleFetchAsync(true);

        try {
            const reservation = await fetchReservationById(reservationId);

            if (reservation !== null) {
                this.selectReservation(reservation);
            }
        } catch (e) {
            console.error(e);
        } finally {
            this.setSingleFetchAsync(false);
        }
    }

    get activeReservations(): Array<ListReservation> {
        return this.reservations.filter(r => {
            return !ReservationUtils.hasStatus(r, ReservationStatusEnum.ADMIN_PAID_HOTEL)
                && ReservationUtils.hasStatus(r, ReservationStatusEnum.ADMIN_APPROVED);
        });
    }

    get dashboardReservations(): Array<ListReservation> {
        return this.activeReservations.length > 0
            ? this.activeReservations.slice(0, 3)
            : this.reservations.slice(0, 3);
    }
}

export const reservationsModule = getModule(ReservationsModule);

