
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Room } from "@/types/room";
import { Amenity } from "@/types/amenity";

@Component({})
export default class SingleHotelRoomAmenities extends Vue {
    @Prop() readonly room?: Room;

    get amenities(): Array<Amenity> {
        return this.room?.amenities ?? [];
    }
}
