import { render, staticRenderFns } from "./ReservationRoomCard.vue?vue&type=template&id=447756b3&scoped=true&"
import script from "./ReservationRoomCard.vue?vue&type=script&lang=ts&"
export * from "./ReservationRoomCard.vue?vue&type=script&lang=ts&"
import style0 from "./ReservationRoomCard.vue?vue&type=style&index=0&id=447756b3&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "447756b3",
  null
  
)

export default component.exports