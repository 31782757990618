
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { AddFormItem_RoomBeds } from "@/types/addForm/addFormItem";
import Button, { ButtonType } from "@/components/Ui/Button/Button.vue";
import { bedsModule } from "@/store/beds/beds";
import SelectField, { SelectFieldOption } from "@/components/Ui/SelectField/SelectField.vue";
import { Bed, RoomBedForm } from "@/types/beds";
import cloneDeep from "lodash/cloneDeep";
import InputLabel from "@/components/Ui/InputLabel/InputLabel.vue";
import NumberPlusMinus from "@/components/NumberPlusMinus/NumberPlusMinus.vue";
import TextareaField from "@/components/Ui/TextareaField/TextareaField.vue";
import InputField from "@/components/Ui/InputField/InputField.vue";
import { RoomBed } from "@/types/room";

@Component({
    components: { InputField, TextareaField, NumberPlusMinus, InputLabel, SelectField, Button },
})
export default class AddContentRoomBeds extends Vue {
    @Prop() readonly item!: AddFormItem_RoomBeds;
    @Prop() readonly entity!: Record<string, unknown>;

    selectedBed: string | null = null;
    nBedsInRoom = 0;

    bedCapacity = 0;
    bedDescription = '';
    addMode = false;

    ButtonType = ButtonType;

    get roomBeds(): Array<RoomBedForm> {
        return (this.entity?.['roomBeds'] as Array<RoomBedForm>)?.constructor === Array ? this.entity['roomBeds'] as Array<RoomBedForm> : [];
    }

    getBedName(roomBed: RoomBedForm): string {
        return typeof roomBed.bed !== 'string' ? roomBed.bed.name : this.bedOptions.find(b => b.value === roomBed.bed)?.label || 'N/A';
    }

    isAdded(roomBed: RoomBedForm): boolean {
        return typeof roomBed.bed !== 'string';
    }

    newBedOption(bedName: string): SelectFieldOption {
        return {
            label: bedName,
            value: bedName,
            topDash: false,
        }
    }

    selectBed(value: string | null): void {
        this.addMode = value !== null && this.bedOptions.findIndex(b => b.value === value) === -1;      // Set or reset add mode
        this.selectedBed = value;
    }

    onRemoveRoomBed(index: number): void {

        const filteredRoomBeds = (cloneDeep(this.roomBeds) as Array<RoomBedForm>).filter((rb, idx) => {
            return idx !== index;
        });

        Vue.set(this.entity, "roomBeds", filteredRoomBeds);
    }

    onAddRoomBed(): void {

        if (!this.selectedBed) {
            return;
        }

        const bed = this.addMode ? {
            name: this.selectedBed,
            capacity: this.bedCapacity,
            description: this.bedDescription,
        } as Bed : this.selectedBed as string

        const newRoomBed: RoomBedForm = {
            bed,
            quantity: this.nBedsInRoom
        }

        Vue.set(this.entity, "roomBeds", [
            ...this.roomBeds,
            newRoomBed
        ]);

        // Reset default form
        this.resetState();
    }

    resetState(): void {
        this.addMode = false;
        this.nBedsInRoom = 0;
        this.bedCapacity = 0;
        this.bedDescription = '';
        this.selectedBed = null;
    }

    get bedsAsync(): boolean {
        return bedsModule.fetchAsync;
    }

    get bedOptions(): Array<SelectFieldOption> {
        if (this.bedsAsync) {
            return [];
        } else {
            return cloneDeep(bedsModule.beds).map((bed: Bed): SelectFieldOption => {
                return {
                    label: bed.name,
                    value: bed.id,
                    topDash: false,
                };
            });
        }
    }

    beforeMount(): void {
        bedsModule.fetchAllBeds();
    }
}
