import { getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { store } from "@/store";
import { DateRange } from "@/components/DateRangePickerWrapper/DateRangePickerWrapper.vue";
import moment, { Moment } from "moment";
import { ReservationType } from "@/types/reservation";

export interface PassengersNumbers {
    childrenUnder6: number,
    children: number,
    adults: number,
}

@Module({ name: "HomeFiltersModule", store: store, dynamic: true })
export default class HomeFiltersModule extends VuexModule {
    dateRange: DateRange = {
        start: moment().add(1, "days").toDate(),
        end: moment().add(2, "days").toDate()
    };

    dateRangeUnconfirmed: DateRange = {
        start: moment().add(1, "days").toDate(),
        end: moment().add(2, "days").toDate()
    };

    roomsNumber = 1;
    location = "";
    type: ReservationType = ReservationType.INDIVIDUAL;
    passengersData: PassengersNumbers = {
        childrenUnder6: 0,
        children: 0,
        adults: 1,
    };

    searchText = "";

    @Mutation
    setLocation(location: string): void {
        this.location = location;
    }

    @Mutation
    setDateRangeUnconfirmed(dateRange: DateRange): void {
        this.dateRangeUnconfirmed = dateRange;
    }

    @Mutation
    setDateRange(dateRange: DateRange): void {
        this.dateRange = dateRange;
    }

    @Mutation
    setRoomsNumber(roomsNo: number): void {
        this.roomsNumber = roomsNo;
    }

    @Mutation
    setType(type: ReservationType): void {
        this.type = type;
    }

    @Mutation
    updatePassengersData(passengersData: Partial<PassengersNumbers>): void {
        this.passengersData = Object.assign(this.passengersData, passengersData);
    }

    @Mutation
    setPassengersData(passengersData: PassengersNumbers): void {
        this.passengersData = passengersData;
    }

    @Mutation
    setSearchText(text: string): void {
        this.searchText = text;
    }

    get invalid(): boolean {
        const pData = homeFiltersModule.passengersData;
        const passengersNum = pData.childrenUnder6 + pData.adults + pData.children;

        return passengersNum < homeFiltersModule.roomsNumber;
    }

    get numOfPassengers() {
        const { adults, childrenUnder6, children } = this.passengersData;
        return adults + childrenUnder6 + children;
    }

    get startDate(): Moment {
        return moment(this.dateRange.start);
    }

    get endDate(): Moment {
        return moment(this.dateRange.end);
    }

    get dateRangeText(): string {
        return `${moment(this.dateRange.start).format("DD.MM.YYYY")} - ${moment(this.dateRange.end).format("DD.MM.YYYY")}`;
    }
}

export const homeFiltersModule = getModule(HomeFiltersModule);
