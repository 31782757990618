import { render, staticRenderFns } from "./SingleHotelLocation.vue?vue&type=template&id=7070c560&scoped=true&"
import script from "./SingleHotelLocation.vue?vue&type=script&lang=ts&"
export * from "./SingleHotelLocation.vue?vue&type=script&lang=ts&"
import style0 from "./SingleHotelLocation.vue?vue&type=style&index=0&id=7070c560&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7070c560",
  null
  
)

export default component.exports