
import HotelRoomCard from "@/views/SingleHotel/components/HotelRoomCard.vue";
import { RouteName } from "@/router";
import Component from "vue-class-component";
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import { Hotel } from "@/types/hotel";
import { roomsModule } from "@/store/rooms/rooms";
import { Room, RoomServiceType } from "@/types/room";
import Spinner from "@/components/Ui/Spinner/Spinner.vue";
import Button, { ButtonType } from "@/components/Ui/Button/Button.vue";
import { reservationFormModule } from "@/store/reservation_form/reservation_form";
import { homeFiltersModule } from "@/store/home_filters/home_filters";
import CustomModal from "@/components/Ui/CustomModal/CustomModal.vue";
import SingleHotelRoom from "@/views/SingleHotelRoom/SingleHotelRoom.vue";
import { RoomVariant } from "@/types/roomVariant";
import { AvailabilityGroups, getAvailabilityGroupsForRoomAvailabilities } from "@/utils/price_utils";

enum CapacityStateClass {
    GREEN = 'green',
    RED = 'red',
}

interface RoomWithMinimumVariantPrice extends Room {
    minimumVariantPrice?: number
}

@Component({
    components: { SingleHotelRoom, CustomModal, Button, Spinner, HotelRoomCard },
})
export default class SingleHotelRooms extends Vue {
    @Prop() readonly hotel!: Hotel;
    ButtonType = ButtonType;

    openedModalRoomId: string | null = null;

    get roomsAsync(): boolean {
        return roomsModule.fetchAsync;
    }

    get rooms(): Array<RoomWithMinimumVariantPrice> {
        const rooms: Array<RoomWithMinimumVariantPrice> = this.hotel.rooms.map(r => {
            let min = Number.POSITIVE_INFINITY;
            r.roomVariants.forEach(variant => {
                variant.variantNightAveragePrice = this.variantNightAveragePrice(variant, r);

                if (variant.variantNightAveragePrice < min) {
                    min = variant.variantNightAveragePrice;
                }
            })

            r.roomVariants.sort((v1, v2) => {
                return (v1.variantNightAveragePrice ?? 0) - (v2.variantNightAveragePrice ?? 0);
            })

            return { ...r, minimumVariantPrice: min };
        }).sort((r1, r2) => r1.minimumVariantPrice - r2.minimumVariantPrice);

        return rooms;
    }

    get bookRoomsEnabled(): boolean {
        return reservationFormModule.bookRoomsButtonEnabled;
    }

    get maxPassengers(): number {
        return homeFiltersModule.numOfPassengers;
    }

    get maxRooms(): number {
        return homeFiltersModule.roomsNumber;
    }

    get numRoomsSelected(): number {
        return reservationFormModule.numRoomsSelected;
    }

    get assignedPassengersCount(): number {
        return reservationFormModule.assignedPassengersCount
    }

    get roomsStateClass(): CapacityStateClass {
        if (this.numRoomsSelected === this.maxRooms) {
            return CapacityStateClass.GREEN;
        } else {
            return CapacityStateClass.RED;
        }
    }

    get passengersStateClass(): CapacityStateClass {
        if (this.assignedPassengersCount === this.maxPassengers) {
            return CapacityStateClass.GREEN;
        } else {
            return CapacityStateClass.RED;
        }
    }

    get variantNightAveragePrice(): (roomVariant: RoomVariant, room: Room) => number {
        return (roomVariant, room) => {
            return this.availabilityGroups(room).calculateVariantNightAveragePrice(roomVariant, room.availabilities);
        }
    }

    get availabilityGroups(): (room: Room) => AvailabilityGroups {
        return room => {
            return getAvailabilityGroupsForRoomAvailabilities(room.availabilities);
        }

    }

    onBookRooms(): void {
        // const selectedRooms = reservationFormModule.selectedRooms;
        //
        // if (selectedRooms !== null) {
        //     for (const [roomId, variants] of Object.values(selectedRooms)) {
        //         if (variants) {
        //             for (const [variantId, variant] of Object.values(variants)) {
        //
        //             }
        //         }
        //     }
        // }

        this.$router.push({
            name: RouteName.ManageReservation,
        });
    }

    openSingleRoomModal(roomId: string): void {
        this.openedModalRoomId = roomId;
        // this.$router.push({
        //     name: RouteName.SingleHotelRoom,
        //     params: {
        //         hotelId: this.hotel.id,
        //         roomName,
        //     },
        // });
    }
}
