import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { store } from "@/store";
import { Currency } from "@/types/pricing";

@Module({ name: "UiModule", store: store, dynamic: true })
export default class UiModule extends VuexModule {
    headerShown = false;
    previousRoute = '/';
    selectedCurrency = Currency.USD;

    // Rems
    calendarRibbonHeight = 2;
    calendarDayContentBaseHeight = 6

    @Mutation
    setHeaderShown(shown: boolean): void {
        this.headerShown = shown;
    }

    @Mutation
    setPreviousRoute(route: string): void {
        this.previousRoute = route;
    }

    @Action
    async fetchHeaderShown(): Promise<void> {
        const res = await new Promise<boolean>(resolve => {
            setTimeout(() => {
                resolve(true);
            }, 1000);
        });

        this.setHeaderShown(res);
    }
}

export const uiModule = getModule(UiModule);
