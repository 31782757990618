
import Vue from 'vue';
import Button, { ButtonType } from '@/components/Ui/Button/Button.vue';
import Component from 'vue-class-component';
import { Prop } from "vue-property-decorator";
import { Hotel } from "@/types/hotel";
import { loginModule } from "@/store/login/login";
import { RouteName } from "@/router";
import Breadcrumbs, { Breadcrumb } from "@/components/Ui/Breadcrumbs/Breadcrumbs.vue";
import { reservationFormModule } from "@/store/reservation_form/reservation_form";
import { homeFiltersModule } from "@/store/home_filters/home_filters";

@Component({
    components: { Breadcrumbs, Button },
})
export default class SingleHotelHeader extends Vue {
    @Prop() readonly hotel!: Hotel;

    ButtonType = ButtonType
    dateRangeText = homeFiltersModule.dateRangeText;
    numOfPassengers = homeFiltersModule.numOfPassengers

    get breadcrumbs(): Array<Breadcrumb> {
        return reservationFormModule.breadcrumbs;
    }

    get displayCrumbs(): boolean {
        return reservationFormModule.reservation !== null
    }

    get isAdmin(): boolean {
        return loginModule.isSuperAdmin;
    }

    get numOfPassengersText(): string {
        return `${this.numOfPassengers + (this.numOfPassengers === 1 ? ` ${this.$tc('passenger')}` : ` ${this.$tc('passengers', 1)}`)}`;
    }

    editHotel(): void {
        this.$router.push({
            name: RouteName.EditHotel,
            params: {
                hotelId: this.hotel.id,
            },
        });
    }
}
