
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import Component from "vue-class-component";
import moment from "moment";
import Cleave from "cleave.js";
import { CleaveOptions } from "cleave.js/options";

export enum InputFieldDisplayType {
    GREEN_BOLD_UNDERLINE = "green-bold-underline",
    NORMAL = "normal",
}

export enum InputFieldType {
    DATE = "date",
    NUMBER = "number",
    TEXT = "text",
}

@Component({})
export default class InputField extends Vue {
    focus = false;

    @Prop({ default: InputFieldDisplayType.NORMAL })
    readonly displayType!: InputFieldDisplayType;
    @Prop() readonly name?: string;
    @Prop() readonly label?: string;
    @Prop() readonly icon?: string;
    @Prop() readonly value!: string;
    @Prop() readonly placeholder?: string;
    @Prop() readonly type?: InputFieldType;
    @Prop() readonly disabled!: boolean;
    @Prop() readonly autocomplete?: string;
    @Prop({ default: false }) readonly hideDatePickerIcon!: boolean;
    @Prop({ default: null }) readonly min?: number | null;
    @Prop({ default: null }) readonly max?: number | null;
    @Prop({ default: false }) readonly normalCursor?: boolean;
    @Prop({ default: false }) readonly pointerCursor?: boolean;
    @Prop({ default: "YYYY-MM-DD" }) readonly dateFormat?: string;
    @Prop({ default: false }) readonly nullable?: boolean;
    @Prop() readonly cleaveOptions?: CleaveOptions;

    focusin(): void {
        this.focus = true;
    }

    focusout(): void {
        this.focus = false;
    }

    onInput(event: Event): void {
        switch (this.type) {
            case InputFieldType.NUMBER: {
                let isInvalid = false;

                const val = (event.target as HTMLInputElement).value

                let num = +val;

                if (!this.nullable && val) {

                    if (this.min != null) {
                        if (num < this.min) {
                            isInvalid = true;
                        }
                    }

                    if (this.max != null) {
                        if (num > this.max) {
                            isInvalid = true;
                        }
                    }

                    if (isInvalid) {
                        event.preventDefault();
                        event.stopPropagation();

                        (event.target as HTMLInputElement).value = this.value;

                        return;
                    }
                }

                this.$emit("input", val ? num : null);
            }
                break;
            case InputFieldType.DATE:
            case InputFieldType.TEXT:
                this.$emit("input", (event.target as HTMLInputElement).value);
                break;
            default:
                this.$emit("input", (event.target as HTMLInputElement).value);
        }
    }

    get actualValue(): string {
        if (this.type === InputFieldType.DATE) {
            return moment(this.value).format(this.dateFormat);
        }

        return this.value;
    }

    mounted(): void {
        if (this.cleaveOptions) {

            const inputElement = this.$el.querySelector('input');

            if (inputElement) {
                new Cleave(inputElement, this.cleaveOptions);
                inputElement.oninput = this.onInput;
            }
        }
    }
}
