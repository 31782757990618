
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import { AddFormItem_Title } from "@/types/addForm/addFormItem";
import Component from "vue-class-component";

@Component
export default class AddContentTitle extends Vue {
    @Prop() readonly item!: AddFormItem_Title;
}
