

import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import Vue from "vue";

export enum CheckboxType {
    RED = "red",
    GREEN = "green",
}

@Component({})
export default class Checkbox extends Vue {
    focus = false;

    @Prop({ default: CheckboxType.RED }) readonly type!: CheckboxType;
    @Prop() readonly label?: string;
    @Prop() readonly value!: boolean;
    @Prop({ default: true }) readonly padded!: boolean;
}
