export enum SettingsKeyType {
    int = "int",
    text = "text",
    float = "float",
    string = "string"
}

export interface SettingsKey {
    id?: string,
    key: string,
    value: string | number,
    type: SettingsKeyType
}