export enum PaymentType {
    TRANSFER = "TRANSFER",
    VISA = "VISA",
    HOTEL = "HOTEL",
    RESERVATION = "RESERVATION",
}


export interface PaymentHistoryItem {
    id: string,
    agencyName: string | null,
    outgoing: boolean,
    amount: number,
    type: PaymentType,
}
