import {
    AddFormItem,
    AddFormItem_Address,
    AddFormItem_Amenities, AddFormItem_Datetime,
    AddFormItem_Gallery,
    AddFormItem_GoogleMap, AddFormItem_HotelNameAutocompleteInput, AddFormItem_HotelSelect, AddFormItem_Hours,
    AddFormItem_Input, AddFormItem_RoomBeds,
    AddFormItem_Select,
    AddFormItem_TextArea,
    AddFormItem_Title, AddFormItemTypes,
} from "@/types/addForm/addFormItem";

export const ADD_FORM_PAGE_TYPE = "ADD_FORM_PAGE";

export class AddFormPage {
    type = ADD_FORM_PAGE_TYPE;
    pageNumber: number;
    items: AddFormItem[] = [];

    constructor(pageNumber: number, items: AddFormItem[]) {
        this.pageNumber = pageNumber;
        this.items = items;
    }

    static parse(rawData: Record<string, unknown>): AddFormPage {
        let pageNumber = -1;

        if (typeof rawData["pageNumber"] === 'number') {
            pageNumber = rawData["pageNumber"] as number;
        }

        const items: AddFormItem[] = [];

        if (rawData["items"]) {
            if (Array.isArray(rawData["items"])) {
                rawData["items"].map((item) => {
                    switch (item["type"]) {
                        case AddFormItemTypes.Title:
                            items.push(AddFormItem_Title.parse(item));
                            break;
                        case AddFormItemTypes.Input:
                            items.push(AddFormItem_Input.parse(item));
                            break;
                        case AddFormItemTypes.TextArea:
                            items.push(AddFormItem_TextArea.parse(item));
                            break;
                        case AddFormItemTypes.Select:
                            items.push(AddFormItem_Select.parse(item));
                            break;
                        case AddFormItemTypes.HotelSelect:
                            items.push(AddFormItem_HotelSelect.parse(item));
                            break;
                        case AddFormItemTypes.Amenities:
                            items.push(new AddFormItem_Amenities());
                            break;
                        case AddFormItemTypes.GoogleMap:
                            items.push(new AddFormItem_GoogleMap());
                            break;
                        case AddFormItemTypes.Gallery:
                            items.push(new AddFormItem_Gallery());
                            break;
                        case AddFormItemTypes.Address:
                            items.push(new AddFormItem_Address());
                            break;
                        case AddFormItemTypes.RoomBeds:
                            items.push(new AddFormItem_RoomBeds());
                            break;
                        case AddFormItemTypes.HotelNameAutocompleteInput:
                            items.push(AddFormItem_HotelNameAutocompleteInput.parse(item));
                            break;
                        case AddFormItemTypes.Hours:
                            items.push(AddFormItem_Hours.parse(item));
                            break;
                        case AddFormItemTypes.Datetime:
                            items.push(AddFormItem_Datetime.parse(item));
                            break;
                    }
                });
            }
        }

        return new AddFormPage(pageNumber, items);
    }
}
