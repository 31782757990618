import { Address } from "@/types/address";
import Maps = google.maps;
import Marker = google.maps.Marker;
import { CustomMapMarkerData } from "@/components/CustomGoogleMap/CustomGoogleMap.vue";

export class MapsWrapper {

    static createMap(htmlElement: HTMLElement, options: Maps.MapOptions): Maps.Map {
        return new Maps.Map(htmlElement, options);
    }

    static setMarkers(map: Maps.Map, markerData: Array<CustomMapMarkerData>, markersContainer: Array<Marker>): void {
        markerData.forEach(marker => {
            if (marker.address.lng && marker.address.lat) {
                markersContainer.push(new Maps.Marker({
                    map: map,
                    label: {
                        text: marker.label ?? "",
                        color: "#000",
                        fontWeight: "bold",
                        className: "custom-map-label"
                    },
                    icon: {
                        path: 'M -1,-1 1,-1 1,1 -1,1 z',
                        scale: 1,
                        strokeColor: "transparent",
                        fillColor: "transparent",
                        fillOpacity: 1,
                    },
                    position: {
                        lng: +marker.address.lng,
                        lat: +marker.address.lat,
                    },
                }));
            }
        });
    }
}

// Export types here, cause i cant import them in single file components for some reason... TODO
export { Maps };
