
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import {
    ManualReservationAdditionalContent,
    ReservationAdditionalContentErrors,
} from "@/types/manualReservation";
import InputLabel from "@/components/Ui/InputLabel/InputLabel.vue";
import InputField, { InputFieldType } from "@/components/Ui/InputField/InputField.vue";
import ExpandableContainer from "@/components/ExpandableContainer/ExpandableContainer.vue";
import SelectField from "@/components/Ui/SelectField/SelectField.vue";
import Button, { ButtonType } from "@/components/Ui/Button/Button.vue";
import { isEmpty } from "lodash";

@Component({
    components: { Button, SelectField, ExpandableContainer, InputField, InputLabel },
})
export default class MRAdditionalContentForm extends Vue {
    @Prop({
        default: () => {
            return {};
        },
    }) additionalContentForm!: ManualReservationAdditionalContent;
    @Prop({ default: false }) editMode!: boolean;
    @Prop({
        default: () => {
            return {};
        },
    }) errors!: ReservationAdditionalContentErrors;

    InputType = InputFieldType;
    ButtonType = ButtonType;

    formExpanded = false;

    get additionalContentTypeOptions() {
        return [{
            value: "transfer",
            label: "Transfer",
        }, {
            value: "other",
            label: "Ostalo",
        }];
    }

    get hasError(): boolean {
        return !isEmpty(this.errors);
    }

    onClickSave(): void {
        this.formExpanded = false;
    }
}
