
import Vue from "vue";
import Component from "vue-class-component";
import Container from "@/components/Ui/Container/Container.vue";
import { Prop } from "vue-property-decorator";
import ReceiptRoom from "@/views/SingleReservation/components/ReceiptRoom.vue";
import Decimal from "decimal.js";
import { settingsModule } from "@/store/settings/settings";
import {
    ManualReservation,
    ManualReservationAdditionalContent,
    ManualReservationRoom, ManualReservationUtils
} from "@/types/manualReservation";

@Component({
    components: { ReceiptRoom, Container },
})
export default class MRReceipt extends Vue {
    @Prop() readonly reservation?: ManualReservation;
    @Prop({ default: false }) readonly isNewReservation!: boolean;

    get nightsCount(): number {
        if (!this.reservation) {
            return 0;
        }
        return ManualReservationUtils.getNightsCount(this.reservation);
    }

    get additionalContent(): Array<ManualReservationAdditionalContent> {
        return this.reservation?.additionalContents ?? [];
    }

    get passengersWithoutVisa(): number {
        return [...this.reservation?.passengers ?? []].reduce<number>((acc, passenger) => {
            if (!passenger.hasVisa) {
                acc += 1;
            }

            return acc;
        }, 0);
    }

    get singleVisaPrice(): number {
        return settingsModule.visaPrice;
    }

    get totalVisaPrice(): number {
        return this.passengersWithoutVisa * this.singleVisaPrice;
    }

    get rooms(): Array<ManualReservationRoom> {
        if (this.isNewReservation) {
            // TODO
        } else {
            if (this.reservation) {
                return this.reservation.rooms;
            }
        }

        return [];
    }

    get totalAccomodationPrice(): number {
        if (this.isNewReservation) {
            // TODO
            return 0;
            // return reservationFormModule.totalAccommodationPrice;
        } else {

            if (!this.reservation) {
                return 0;
            }

            return ManualReservationUtils.getTotalAccommodationPrice(this.reservation) * this.nightsCount;
        }
    }

    get allAdditionalContentItemsPrice(): number {
        if (!this.reservation) {
            return 0;
        }
        return ManualReservationUtils.getTotalAdditionalContentsPrice(this.reservation);
    }

    get totalPrice(): number {
        return this.allAdditionalContentItemsPrice + this.totalAccomodationPrice + this.totalVisaPrice;
    }
}
