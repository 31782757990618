
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

@Component({})
export default class TransitionExpand extends Vue {
    @Prop({ default: 300 }) readonly duration!: number;
    @Prop({ default: false }) readonly isOpen!: boolean;

    throttledOpen = this.isOpen || false;

    $refs!: {
        childContainer: HTMLDivElement,
    };

    childHeight = 0;

    @Watch("isOpen")
    onOpenChanged(): void {
        if (!this.isOpen) {
            // throttle killing the child to give time for the animation to close
            setTimeout(() => {
                this.throttledOpen = this.isOpen;
            }, this.duration);
        } else {
            this.throttledOpen = this.isOpen;
        }
    }

    childUpdated(): void {
        const { height } = this.$refs.childContainer.getBoundingClientRect();
        this.childHeight = height;
    }

    mounted(): void {
        const targetNode = this.$el.querySelector(".child-container");

        if (targetNode === null) return;

        const config = { attributes: true, childList: true, subtree: true };

        // Callback function to execute when mutations are observed
        const callback = (mutations: MutationRecord[], observer: MutationObserver) => {
            this.childUpdated();
        };

        // Create an observer instance linked to the callback function
        const observer = new MutationObserver(callback);

        // Start observing the target node for configured mutations
        observer.observe(targetNode, config);
    }

    updated(): void {
        this.childUpdated();
    }

}
