

import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { Reservation, ReservationUtils } from "@/types/reservation";
import PassengerCard from "@/views/SingleReservation/components/PassengerCard.vue";
import PassengerList from "@/views/SingleReservation/components/PassengerList.vue";
import HotelCard from "@/views/SingleReservation/components/HotelCard.vue";
import Receipt from "@/views/SingleReservation/components/Receipt.vue";
import Button, { ButtonType } from "@/components/Ui/Button/Button.vue";
import Checkbox from "@/components/Ui/Checkbox/Checkbox.vue";
import { reservationsModule } from "@/store/reservations/reservations";
import { AvailabilityRoom } from "@/types/availabilityAdditionals";
import ReservationAdditionalContentCards
    from "@/views/SingleReservation/components/ReservationAdditionalContentCards.vue";
import DeleteReservation from "@/views/Reservations/DeleteReservation.vue";
import Spinner from "@/components/Ui/Spinner/Spinner.vue";
import { endpointNoApi } from "@/api/apiCall";
import { loginModule } from "@/store/login/login";
import CustomModal from "@/components/Ui/CustomModal/CustomModal.vue";
import InputLabel from "@/components/Ui/InputLabel/InputLabel.vue";
import InputField, { InputFieldType } from "@/components/Ui/InputField/InputField.vue";
import {
    addReservationStatus,
    apiGenerateReservationInvoice,
    apiReadReservationInvoice,
} from "@/api/reservations/reservations";
import { ReservationAdditionalContent } from "@/types/additionalContent";
import { MediaObject, UploadedFile } from "@/types/mediaObject";
import UploaderModal from "@/components/UploaderModal/UploaderModal.vue";
import PlaceholderableImage from "@/components/Ui/PlaceholderableImage/PlaceholderableImage.vue";
import { mediaModule } from "@/store/media_objects/media_objects";
import { EntityNameEnum } from "@/api/media_objects/media_objects";
import { ReservationStatusEnum } from "@/types/reservationStatus";

@Component({
    components: {
        InputField,
        InputLabel,
        CustomModal,
        Spinner,
        DeleteReservation,
        ReservationAdditionalContentCards,
        Button,
        Receipt,
        HotelCard,
        PassengerList,
        PassengerCard,
        Checkbox,
        UploaderModal,
        PlaceholderableImage,
    },
})
export default class SingleReservation extends Vue {
    @Prop() readonly listReservation!: Reservation | null;

    InputFieldType = InputFieldType;
    ButtonType = ButtonType;

    deleteConfirmed = false;
    deleteVisible = false;
    invoiceAsync = false;

    sendEmailModalOpened = false;
    reservationPaidModalOpened = false;
    reservationPaidAgentModalOpened = false;
    confirmationNumber = "";
    sendEmailToTransferModalOpened = false;
    isHotelPaidModalOpened = false;
    isReservationVisaSentModalOpened = false;
    isReservationVisaPaidModalOpened = false;
    isReservationVisaApprovedModalOpened = false;
    deleteReservationModalOpened = false;

    isReservationTransferSentModalOpened = false;
    isReservationTransferPaidModalOpened = false;
    isReservationTransferApprovedModalOpened = false;

    selectedEmailAdditionalContent: Record<string, boolean> = {};
    receiptAsync = false;
    hotelPaidPrice = 0;
    visaPaidPrice = 0;
    transferPaidPrice = 0;

    agencyPaidAt: string | null = null;
    visaPaidAt: string | null = null;

    hotelReceiptModalOpened = false;
    receipts: Array<UploadedFile> = [];

    async saveReceipt(): Promise<void> {
        if (this.receipts.length && this.reservation) {
            this.receiptAsync = true;

            const r = await mediaModule.uploadMediaObject({
                file: this.receipts[0],
                entityData: {
                    entityName: EntityNameEnum.Reservation,
                    entityId: this.reservation.id,
                },
            });

            if (this.reservation) {
                this.reservation.hotelReceipt = r;
            }

            this.receipts = [];
            this.receiptAsync = false;
        }
    }

    mounted(): void {
        if (this.listReservation && (reservationsModule.selectedReservation === null || reservationsModule.selectedReservation?.id !== this.listReservation.id)) {
            reservationsModule.fetchReservationByIdAction(this.listReservation.id);
        }
    }

    onRemoveReceipt(file: UploadedFile): void {
        this.receipts = this.receipts.filter(i => i.url !== file.url);
    }

    get isSuperAdmin(): boolean {
        return loginModule.isSuperAdmin;
    }

    get isAgent(): boolean {
        return loginModule.isAgent;
    }

    get sendableContent(): ReservationAdditionalContent[] {
        return this.reservation?.reservationAdditionalContents.filter(ac => ac.additionalContent.email) ?? [];
    }

    get sendEmailToHotelAsync(): boolean {
        return reservationsModule.sendHotelEmailAsync;
    }

    get sendEmailToTransferAsync(): boolean {
        return reservationsModule.sendTransferEmailAsync;
    }

    get reservationConfirmAsync(): boolean {
        return reservationsModule.reservationConfirmAsync;
    }

    get reservationConfirmPaidAsync(): boolean {
        return reservationsModule.confirmPaidAsync;
    }

    get reservationIsHotelPaidAsync(): boolean {
        return reservationsModule.isHotelPaidAsync;
    }

    get reservationIsVisaSentAsync(): boolean {
        return reservationsModule.isVisaSentAsync;
    }

    get reservationisVisaPaidAsync(): boolean {
        return reservationsModule.isVisaPaidAsync;
    }

    get reservationIsVisaApprovedAsync(): boolean {
        return reservationsModule.isVisaApprovedAsync;
    }

    get reservationIsTransferSentAsync(): boolean {
        return reservationsModule.isTransferSentAsync;
    }

    get reservationisTransferPaidAsync(): boolean {
        return reservationsModule.isTransferPaidAsync;
    }

    get reservationIsTransferApprovedAsync(): boolean {
        return reservationsModule.isTransferApprovedAsync;
    }

    get isApproved(): boolean {
        return ReservationUtils.hasStatus(this.reservation, ReservationStatusEnum.ADMIN_APPROVED);
    }

    get availabilityRooms(): Array<AvailabilityRoom> {
        const res = this.reservation;

        if (res === null) return [];

        return [...res.availabilities].map(a => a.room);
    }

    get canDeleteReservation(): boolean {
        return this.isSuperAdmin;
    }

    get reservation(): Reservation | null {
        if (reservationsModule.selectedReservation !== null) {
            return reservationsModule.selectedReservation;
        } else {
            return null;
        }
    }

    get isReceiptGenerated() {
        return this.reservation?.invoiceGeneratedAt !== null;
    }

    get isEmailToHotelSent() {
        return this.reservation?.isEmailToHotelSent;
    }

    get sendEmailToHotelDisabled(): boolean {
        if (!this.canSendEmails) {
            return true;
        }

        if (this.isEmailToHotelSent) {
            return true;
        }

        return !this.isReceiptGenerated;
    }

    get sendEmailToTransferDisabled(): boolean {
        if (!this.canSendEmails) {
            return true;
        }

        if (this.reservation?.isEmailToTransferSent) {
            return true;
        }

        return !this.isReceiptGenerated;
    }

    get generateReceiptHidden(): boolean {
        if (this.isAgent && !ReservationUtils.hasStatus(this.reservation, ReservationStatusEnum.ADMIN_APPROVED)) {
            return true;
        }

        return false;
    }

    get generateReceiptDisabled(): boolean {
        return false;
    }

    get hotelPaidDisabled(): boolean {
        return ReservationUtils.hasStatus(this.reservation, ReservationStatusEnum.ADMIN_PAID_HOTEL) || !ReservationUtils.hasStatus(this.reservation, ReservationStatusEnum.ADMIN_APPROVED);
    }

    get confirmReservationDisabled(): boolean {
        return Boolean(this.reservation?.confirmationNumber);
    }

    get canSendEmails(): boolean {
        return this.isSuperAdmin && this.isReceiptGenerated;
    }

    get showPrintVoucherButton(): boolean {
        return ReservationUtils.hasStatus(this.reservation, ReservationStatusEnum.ADMIN_CONFIRMED_PAID);
    }

    get invoiceFileExists(): boolean {
        return Boolean(this.reservation?.invoiceGeneratedAt);
    }

    get reservationHasTransfer(): boolean {
        return this.reservation?.reservationAdditionalContents.find(rac => rac.additionalContent.category.nameBs === "Transferi") !== undefined;
    }

    get hotelReceipt(): UploadedFile | MediaObject {
        return this.receipts[0] ?? this.reservation?.hotelReceipt;
    }

    get deleteAsync(): boolean {
        return reservationsModule.deleteAsync;
    }

    get isTransferPaid(): boolean {
        return ReservationUtils.hasStatus(this.reservation, ReservationStatusEnum.ADMIN_PAID_TRANSFER);
    }

    get isTransferSent(): boolean {
        return ReservationUtils.hasStatus(this.reservation, ReservationStatusEnum.ADMIN_SENT_EMAIL_TO_TRANSFER);
    }

    get isTransferConfirmed(): boolean {
        return ReservationUtils.hasStatus(this.reservation, ReservationStatusEnum.ADMIN_CONFIRMED_TRANSFER);
    }

    get isVisaPaid(): boolean {
        return ReservationUtils.hasStatus(this.reservation, ReservationStatusEnum.ADMIN_PAID_VISA);
    }

    get isVisaSent(): boolean {
        return ReservationUtils.hasStatus(this.reservation, ReservationStatusEnum.ADMIN_SENT_VISA);
    }

    get isVisaConfirmed(): boolean {
        return ReservationUtils.hasStatus(this.reservation, ReservationStatusEnum.ADMIN_CONFIRMED_VISA);
    }

    @Watch("sendEmailModalOpened")
    onChangeSendEmailModalOpened(newValue: boolean): void {
        if (newValue) {
            this.sendableContent.forEach(item => {
                Vue.set(this.selectedEmailAdditionalContent, item.additionalContent.id, true);
            });
        } else {
            this.selectedEmailAdditionalContent = {};
        }
    }

    async generateReservationInvoice(): Promise<void> {
        if (this.reservation !== null) {

            const reservationId = this.reservation.id;

            this.invoiceAsync = true;

            const r = await addReservationStatus(reservationId, ReservationStatusEnum.ADMIN_APPROVED);

            apiGenerateReservationInvoice(reservationId)
                .then(() => new Promise(resolve => {
                    setTimeout(() => {
                        resolve(reservationsModule.fetchReservationByIdAction(reservationId));
                    }, 3000);       // TODO, this is a temporary hack until notification works properly
                })).finally(() => this.invoiceAsync = false);
        }
    }

    printReceipt(): void {
        if (this.reservation) {
            const reservationId = this.reservation.id;

            this.invoiceAsync = true;

            apiReadReservationInvoice(reservationId).then(invoiceHtml => {
                if (invoiceHtml) {
                    const win = window.open("", "_blank");
                    win?.document.write(invoiceHtml);
                    win?.document.close();
                }
            }).catch(() => reservationsModule.fetchReservationByIdAction(reservationId))    // refresh on fail
                .finally(() => {
                    this.invoiceAsync = false;
                });

        }
    }

    printVoucher(): void {
        if (this.reservation !== null) {
            window.open(`${endpointNoApi}/public/reservations/${this.reservation.id}/generate_voucher_pdf`, "_blank");
        }
    }

    get reservationPaidButtonDisabled(): boolean {
        if (this.isSuperAdmin) {
            return ReservationUtils.hasStatus(this.reservation, ReservationStatusEnum.ADMIN_CONFIRMED_PAID);
        }

        if (this.isAgent) {
            return ReservationUtils.hasStatus(this.reservation, ReservationStatusEnum.AGENCY_MARKED_PAID)
                || !ReservationUtils.hasStatus(this.reservation, ReservationStatusEnum.ADMIN_APPROVED);
        }

        return true;
    }

    confirmReservationPaid(): void {
        if (this.reservation) {
            if (this.isSuperAdmin) {
                reservationsModule.confirmReservationPaid({
                    reservationId: this.reservation.id,
                    confirmationNumber: this.confirmationNumber,
                }).then(success => {
                    if (success) {
                        this.reservationPaidModalOpened = false;
                        this.confirmationNumber = "";
                    }
                });
            } else if (this.isAgent && this.agencyPaidAt) {
                // confirm paid
                reservationsModule.confirmReservationPaidAgent({
                    reservationId: this.reservation.id,
                    agencyPaidAt: this.agencyPaidAt,
                }).then(success => {
                    if (success) {
                        this.reservationPaidAgentModalOpened = false;
                        this.agencyPaidAt = null;
                    }
                });
            }

        }
    }

    async confirmTransferPaid(): Promise<void> {
        if (this.reservation && this.transferPaidPrice) {
            await reservationsModule.confirmTransferIsPaid({
                reservationId: this.reservation.id,
                price: this.transferPaidPrice,
            });

            this.isReservationTransferPaidModalOpened = false;
        }
    }

    async confirmTransferSent(): Promise<void> {
        if (this.reservation) {
            await reservationsModule.confirmTransferIsSent({ reservationId: this.reservation.id });
            this.isReservationTransferSentModalOpened = false;
        }
    }

    async confirmTransferApproved(): Promise<void> {
        if (this.reservation) {
            await reservationsModule.confirmTransferIsApproved({ reservationId: this.reservation.id });

            this.isReservationTransferApprovedModalOpened = false;
        }
    }

    async confirmVisaPaid(): Promise<void> {
        if (this.reservation && this.visaPaidPrice && this.visaPaidAt && this.visaPaidAt.length > 0) {
            await reservationsModule.confirmVisaIsPaid({
                reservationId: this.reservation.id,
                price: this.visaPaidPrice,
                paidAt: this.visaPaidAt,
            });

            this.isReservationVisaPaidModalOpened = false;
        }
    }

    async confirmVisaSent(): Promise<void> {
        if (this.reservation) {
            await reservationsModule.confirmVisaIsSent({ reservationId: this.reservation.id });
            this.isReservationVisaSentModalOpened = false;
        }
    }

    async confirmVisaApproved(): Promise<void> {
        if (this.reservation) {
            await reservationsModule.confirmVisaIsApproved({ reservationId: this.reservation.id });

            this.isReservationVisaApprovedModalOpened = false;
        }
    }

    async confirmHotelPaid(): Promise<void> {
        if (this.hotelPaidPrice > 0 && this.reservation) {
            await reservationsModule.confirmHotelIsPaid({
                reservationId: this.reservation.id,
                amount: this.hotelPaidPrice,
            });

            this.isHotelPaidModalOpened = false;
        }
    }

    onDeleteReservation(): void {
        this.deleteVisible = true;
    }

    async sendEmailToHotel(): Promise<void> {
        if (this.reservation) {
            const r = await reservationsModule.sendReservationHotelEmail({
                reservationId: this.reservation.id,
                additionalContent: Object.entries(this.selectedEmailAdditionalContent).filter(([id, selected]) => selected).map(([id, selected]) => id),
            });

            if (r) {
                this.reservation.isEmailToHotelSent = true;

                reservationsModule.updateListReservationById({
                    reservationId: this.reservation.id,
                    data: {
                        isEmailToHotelSent: true,
                    },
                });
            }

            this.sendEmailModalOpened = false;
        }
    }

    async sendEmailToTransfer(): Promise<void> {
        if (this.reservation) {
            const r = await reservationsModule.sendReservationTransferEmail({
                reservationId: this.reservation.id,
            });

            if (r) {
                this.reservation.isEmailToTransferSent = true;

                reservationsModule.updateListReservationById({
                    reservationId: this.reservation.id,
                    data: {
                        isEmailToTransferSent: true,
                    },
                });
            }

            this.sendEmailToTransferModalOpened = false;
        }
    }

    deleteReservation(): void {
        if (!this.deleteConfirmed) {
            return;
        }

        if (reservationsModule.selectedReservation) {
            reservationsModule.deleteReservationAction(reservationsModule.selectedReservation.id).then(() => this.deleteVisible = false);
            this.$emit("closeParentModal");
        }
    }

    get showTransferFields(): boolean {
        return this.isSuperAdmin && (this.reservation?.reservationAdditionalContents ?? []).some(c => c.additionalContent.category.nameBs.toLowerCase() === "transferi");
    }

    get showVisaFields(): boolean {
        return this.isSuperAdmin && (this.reservation?.passengers ?? []).some((passanger) => passanger.hasVisa === false);
    }
}
