import {User} from "@/types/user";
import {Passenger} from "@/types/Passenger";
import {RoomAvailability} from "@/types/availability";
import {ReservationAdditionalContent,} from "@/types/additionalContent";
import {merge} from "lodash";
import {RoomServiceType} from "@/types/room";
import {RoomVariant} from "@/types/roomVariant";
import {ReservationFormSelectedRoomVariant} from "@/store/reservation_form/reservation_form";
import {getAvailabilityGroupsForRoomAvailabilities} from "@/utils/price_utils";
import Decimal from "decimal.js";
import {MediaObject} from "@/types/mediaObject";
import {ReservationStatus, ReservationStatusEnum} from "@/types/reservationStatus";

export enum ReservationType {
    INDIVIDUAL = "I",
    GROUP = "G",
}


export interface ListReservation {
    id: string,
    createdAt: string,
    fromDate: string | null,
    toDate: string | null,
    type: ReservationType,
    agent: User,
    validThrough: string,
    numberOfPassengers: number,
    isPaid: boolean,
    content: string | null,
    statuses: Array<ReservationStatus>,
    confirmationNumber: string
    totalAccommodationPrice: number,
    totalReservationPrice: number,
    additionalContentPrice: number,
    isEmailToHotelSent: boolean,
    isEmailToTransferSent: boolean,
    didHotelRespondToEmail: boolean,
    isVisaSent: boolean,
    isVisaApproved: boolean,
    hotelPaidPrice: number | null,
    hotelName: string | null,
    hotelReceipt: MediaObject | null,
    invoiceNumber: string | null,
    passengers: Array<Passenger>,

    agencyPaidAt: string | null,
    visaPaidAt: string | null,
    visaPaidPrice: number,
    transferPaidPrice: number,
}

export interface ReservationRoomVariant {
    id: string,
    quantity: number,
    serviceType: RoomServiceType,
    passengers: number,
    variant: RoomVariant
}

export interface Reservation {
    additionalContentPrice: number,
    id: string,
    createdAt: string,
    fromDate: string | null,
    toDate: string | null,
    type: ReservationType,
    agent: User,
    reservationRoomVariants: Array<ReservationRoomVariant>,
    passengers: Array<Passenger>,
    validThrough: string,
    numberOfPassengersInGroup: number,
    isPaid: boolean,
    content: string | null,
    availabilities: Array<RoomAvailability>
    statuses: Array<ReservationStatus>,
    reservationAdditionalContents: Array<ReservationAdditionalContent>,
    confirmationNumber: string,
    earlyBookingDiscount: number | null,
    invoiceGeneratedAt: string | null,
    invoice: string | null,
    isEmailToHotelSent: boolean,
    isEmailToTransferSent: boolean,
    specialRequest: string | null,
    didHotelRespondToEmail: boolean,
    isVisaSent: boolean,
    isVisaApproved: boolean,
    hotelPaidPrice: number | null,
    hotelReceipt: MediaObject | null,
}

export type ReservationClassRoom = { room: { variants: RoomVariant[], availabilities: RoomAvailability[], name: string, id: string, }, variants: Record<string, ReservationFormSelectedRoomVariant> };

export class ReservationClass implements Reservation {
    id: string;
    createdAt: string;
    fromDate: string | null;
    toDate: string | null;
    type: ReservationType;
    agent: User;
    reservationRoomVariants: Array<ReservationRoomVariant>;
    passengers: Array<Passenger>;
    validThrough: string;
    numberOfPassengersInGroup: number;
    isPaid: boolean;
    content: string | null;
    availabilities: Array<RoomAvailability>;
    statuses: Array<ReservationStatus>;
    reservationAdditionalContents: Array<ReservationAdditionalContent>;
    confirmationNumber: string;
    additionalContentPrice: number;
    earlyBookingDiscount: number | null;
    invoiceGeneratedAt: string | null;
    invoice: string | null;
    isEmailToHotelSent: boolean;
    isEmailToTransferSent: boolean;
    specialRequest: string | null;
    didHotelRespondToEmail: boolean;
    isVisaSent: boolean;
    isVisaApproved: boolean;
    hotelPaidPrice: number | null;
    hotelReceipt: MediaObject | null;

    constructor(reservationInterface: Reservation) {
        this.id = reservationInterface.id;
        this.createdAt = reservationInterface.createdAt;
        this.fromDate = reservationInterface.fromDate;
        this.toDate = reservationInterface.toDate;
        this.type = reservationInterface.type;
        this.agent = reservationInterface.agent;
        this.reservationRoomVariants = reservationInterface.reservationRoomVariants;
        this.passengers = reservationInterface.passengers;
        this.validThrough = reservationInterface.validThrough;
        this.numberOfPassengersInGroup = reservationInterface.numberOfPassengersInGroup;
        this.isPaid = reservationInterface.isPaid;
        this.content = reservationInterface.content;
        this.availabilities = reservationInterface.availabilities;
        this.statuses = reservationInterface.statuses;
        this.reservationAdditionalContents = reservationInterface.reservationAdditionalContents;
        this.confirmationNumber = reservationInterface.confirmationNumber;
        this.additionalContentPrice = reservationInterface.additionalContentPrice;
        this.earlyBookingDiscount = reservationInterface.earlyBookingDiscount;
        this.invoiceGeneratedAt = reservationInterface.invoiceGeneratedAt;
        this.invoice = reservationInterface.invoice;
        this.isEmailToHotelSent = reservationInterface.isEmailToHotelSent;
        this.isEmailToTransferSent = reservationInterface.isEmailToTransferSent;
        this.specialRequest = reservationInterface.specialRequest;
        this.didHotelRespondToEmail = reservationInterface.didHotelRespondToEmail;
        this.isVisaSent = reservationInterface.isVisaSent;
        this.isVisaApproved = reservationInterface.isVisaApproved;
        this.hotelPaidPrice = reservationInterface.hotelPaidPrice;
        this.hotelReceipt = reservationInterface.hotelReceipt;
    }

    get totalAccommodationPrice(): number {
        let total = this.rooms.reduce<number>((previousValue, currentValue) => {
            const availabilityGroups = getAvailabilityGroupsForRoomAvailabilities(currentValue.room.availabilities);

            const currentArrTotal = availabilityGroups.calculatePriceForVariantsArray(Object.values(currentValue.variants), this.agent.agency?.agencyMarkup ?? 0);

            previousValue += currentArrTotal;

            return previousValue;
        }, 0);

        if (this.earlyBookingDiscount !== null) {
            total = new Decimal(total).mul(1 - new Decimal(this.earlyBookingDiscount).div(100).toNumber()).toNumber();
        }

        return total;
    }

    get rooms(): Array<ReservationClassRoom> {

        const availabilityGroups: { [roomId: string]: RoomAvailability[] } = {};

        this.availabilities.forEach(availability => {
            if (!availabilityGroups[availability.room.id]) {
                availabilityGroups[availability.room.id] = [availability];
            } else {
                availabilityGroups[availability.room.id].push(availability);
            }
        });

        const rooms: { [roomId: string]: { variants: RoomVariant[], availabilities: RoomAvailability[], name: string, id: string } } = {};

        this.availabilities.forEach(availability => {
            if (!rooms[availability.room.id]) {
                rooms[availability.room.id] = {
                    id: availability.room.id,
                    name: availability.room.name,
                    variants: availability.room.roomVariants,
                    availabilities: availabilityGroups[availability.room.id],
                };
            }
        });

        const reservationFormRoomVariants: { [roomId: string]: ReservationFormSelectedRoomVariant[] } = {};

        this.reservationRoomVariants.forEach(roomVariant => {
            const roomWithThisVariant = Object.values(rooms).find(room => room.variants.findIndex(variant => variant.id === roomVariant.variant.id) !== -1);
            if (roomWithThisVariant) {
                if (reservationFormRoomVariants[roomWithThisVariant.id]) {
                    reservationFormRoomVariants[roomWithThisVariant.id].push({
                        q: roomVariant.quantity,
                        serviceType: roomVariant.serviceType,
                        rawVariant: roomVariant.variant,
                        passengers: roomVariant.passengers,
                    });
                } else {
                    reservationFormRoomVariants[roomWithThisVariant.id] = [{
                        q: roomVariant.quantity,
                        serviceType: roomVariant.serviceType,
                        rawVariant: roomVariant.variant,
                        passengers: roomVariant.passengers,
                    }];
                }
            }
        });

        return Object.values(rooms).map(room => {
            const variants: Record<string, ReservationFormSelectedRoomVariant> = {};

            reservationFormRoomVariants[room.id].forEach(variant => {
                if (variant.rawVariant?.id) {
                    variants[variant.rawVariant.id] = variant;
                }
            });

            return {
                room,
                variants,
            };
        });
    }

}

export interface IReservationForm {
    type?: ReservationType;
    agent?: User;
    passengers?: Array<Passenger>;
    reservationAdditionalContents?: Array<ReservationAdditionalContent>;
}

export class ReservationForm implements IReservationForm {
    type?: ReservationType;
    agent?: User;
    passengers?: Array<Passenger>;
    reservationAdditionalContents?: Array<ReservationAdditionalContent>;
    specialRequest?: string;

    constructor(props: IReservationForm) {
        merge(this, props);
    }

    static createFormWithReservation(reservation: Reservation): ReservationForm {
        const changed: Record<string, unknown> = reservation as unknown as Record<string, unknown>;

        changed.reservationAdditionalContents = reservation.reservationAdditionalContents.map(content => ({
            additionalContent: content.additionalContent,
            passengerCount: content.passengerCount,
        }));

        return new ReservationForm(changed);
    }

    get validate(): boolean {
        return true;
    }
}

export class ReservationUtils {
    public static hasStatus(reservation: Pick<ListReservation, "statuses"> | null, status: ReservationStatusEnum): boolean {
        if (!reservation) {
            return false;
        }

        return reservation.statuses.findIndex(el => el.status === status) !== -1;
    }
}
