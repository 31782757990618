
import Vue from "vue";
import {
    AddFormItem, AddFormItem_Gallery,
    AddFormItem_Input, AddFormItem_Select,
    AddFormItem_TextArea,
    AddFormItem_Title,
    AddFormItemTypes,
} from "@/types/addForm/addFormItem";
import { Emit, Prop } from "vue-property-decorator";
import Component from "vue-class-component";

@Component
export default class FormEditorPageItem extends Vue {
    @Prop({ default: null }) readonly item!: AddFormItem | null;
    @Prop({ default: false }) readonly isSelected!: boolean;

    AddFormItemTypes = AddFormItemTypes;

    @Emit()
    removeItem(): void {/**/
    }

    @Emit()
    selectItem(): void {/**/
    }

    get getItemType(): string | null {
        if (this.item instanceof AddFormItem_Input) {
            return "Input";
        } else if (this.item instanceof AddFormItem_TextArea) {
            return "TextArea";
        } else if (this.item instanceof AddFormItem_Title) {
            return "Naslov";
        } else if (this.item instanceof AddFormItem_Select) {
            return "Selection";
        } else if (this.item instanceof AddFormItem_Gallery) {
            return "Galerija";
        } else {
            return null;
        }
    }
}
