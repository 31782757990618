

import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { MediaObject } from "@/types/mediaObject";
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import Button from "@/components/Ui/Button/Button.vue";

interface CarouselHtmlElement extends HTMLElement {
    prev: () => void                // prev slide
    next: () => void                // next slide
    goTo: (idx: number) => void     // go to slide index
    play: () => void                // resume autoplay
    pause: () => void                // stop autoplay
}

@Component({
    components: {
        Button,
        VueSlickCarousel
    }
})
export default class ImageSlider extends Vue {
    @Prop({ default: () => [] }) readonly images!: Array<MediaObject>
    @Prop({ default: true }) readonly displayArrows!: boolean
    @Prop({ default: false }) readonly displayDots!: boolean
    @Prop({ default: false }) readonly autoplay!: boolean

    activeIdx = 0;

    goToSlide(idx: number): void {

        const carouselRef = this.$refs.carousel as CarouselHtmlElement;

        if (carouselRef) {
            carouselRef.goTo(idx)
        }
    }

    next(): void {
        this.activeIdx++;
    }

    previous(): void {
        this.activeIdx--;
    }

    afterChange(idx: number): void {
        this.activeIdx = idx;
    }
}
