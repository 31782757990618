import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { store } from "@/store";
import { Agency, AgencyForm, ListAgency } from "@/types/agency";
import {
    addAgency,
    apiFetchAllAgencies,
    deleteAgency,
    editAgency,
    fetchAgenciesPage,
    fetchAgencyById,
} from "@/api/agencies/agencies";
import { MediaObject, UploadedFile } from "@/types/mediaObject";
import { EntityNameEnum, MediaUploadEntityData, uploadMediaObject } from "@/api/media_objects/media_objects";
import { cloneDeep } from "lodash";
import { loginModule } from "@/store/login/login";

@Module({ name: "AgenciesModule", store: store, dynamic: true, namespaced: true })
export default class AgenciesModule extends VuexModule {
    fetchAsync = false;
    agencies: Array<ListAgency> = [];
    hasMore = true;
    currentPage = 0;
    totalPages = 1;
    perPage = 10;
    selectedAgency: Agency | null = null;
    addEditAsync = false;

    search = "";

    @Mutation
    setFetchAsync(async: boolean): void {
        this.fetchAsync = async;
    }

    @Mutation
    setCurrentPage(page: number): void {
        this.currentPage = page;
    }

    @Mutation
    setHasMore(hasMore: boolean): void {
        this.hasMore = hasMore;
    }

    @Mutation
    setPerPage(perPage: number): void {
        this.perPage = perPage;
    }

    @Mutation
    setTotalPages(totalPages: number): void {
        this.totalPages = totalPages;
    }

    @Mutation
    setSearchText(search: string): void {
        this.search = search;
    }

    // @Mutation
    // appendAgencies(agencies: Array<ListAgency>): void {
    //     this.agencies = this.agencies.concat(agencies);
    // }

    @Mutation
    removeAgency(agencyId: string): void {
        this.agencies = cloneDeep(this.agencies).filter((a) => a.id !== agencyId);
    }


    @Mutation
    setAgencies(agencies: Array<ListAgency>): void {
        this.agencies = agencies;
    }

    @Mutation
    selectAgency(agency: Agency | null): void {
        this.selectedAgency = agency;
    }

    @Mutation
    setAddEditAsync(async: boolean): void {
        this.addEditAsync = async;
    }

    @Mutation
    updateAgencyImage(image: MediaObject): void {

        const clone = cloneDeep(this.selectedAgency);

        if (clone) {
            clone.image = image;
            this.selectedAgency = clone;
        }
    }

    @Action
    async fetchAgenciesFirstPage(): Promise<void> {
        return this.fetchAgenciesPageAction(1);
    }

    @Action
    async fetchAgenciesPageAction(page: number): Promise<void> {
        this.setFetchAsync(true);

        try {
            const r = await fetchAgenciesPage(page, this.perPage, this.search);

            if (r !== null) {
                this.setAgencies(r.data);
                this.setTotalPages(Math.ceil(r.totalItems / this.perPage));
                this.setCurrentPage(page);
                this.setHasMore(r.hasMore);
            }
        } catch (e) {
            console.error(e);
        } finally {
            this.setFetchAsync(false);
        }
    }

    @Action
    async fetchAllAgencies(): Promise<Array<ListAgency> | null> {
        this.setFetchAsync(true);

        try {
            const r = await apiFetchAllAgencies();

            if (r !== null) {
                this.setAgencies(r);
            }

            return r;
        } catch (e) {
            console.error(e);
        } finally {
            this.setFetchAsync(false);
        }

        return null;
    }

    @Action
    async addAgencyAction(data: AgencyForm): Promise<void> {
        this.setAddEditAsync(true);

        try {
            const newAgency = await addAgency(data);

            if (newAgency !== null) {
                // this.appendAgencies([newAgency])
            }
        } catch (e) {
            console.error(e);
        } finally {
            this.setAddEditAsync(false);
        }
    }

    @Action
    async editAgencyAction({ agencyId, data }: { agencyId: string, data: AgencyForm }): Promise<void> {

        this.setAddEditAsync(true);

        try {
            const updatedAgency = await editAgency(agencyId, data);

            if (updatedAgency !== null) {
                if (updatedAgency.id === loginModule.user?.agency?.id) {
                    loginModule.updateUserAgency(updatedAgency);
                }

                this.selectAgency(updatedAgency);
            }
        } catch (e) {
            console.error(e);
        } finally {
            this.setAddEditAsync(false);
        }
    }

    @Action
    async fetchAgencyByIdAction(agencyId: string): Promise<void> {
        this.setFetchAsync(true);

        try {
            const agency = await fetchAgencyById(agencyId);

            if (agency !== null) {
                this.selectAgency(agency);
            }
        } catch (e) {
            console.error(e);
        } finally {
            this.setFetchAsync(false);
        }
    }

    @Action
    async deleteAgency(agencyId: string): Promise<void> {
        this.setAddEditAsync(true);

        try {
            const r = await deleteAgency(agencyId);

            if (r !== null) {
                this.removeAgency(agencyId);
            }
        } catch (e) {
            console.error(e);
        } finally {
            this.setAddEditAsync(false);
        }
    }

    @Action
    async changeAgencyImage({ agency, image }: { agency: Agency, image: UploadedFile }): Promise<void> {
        this.setAddEditAsync(true);

        if (!image) return;

        const entityData: MediaUploadEntityData = {
            entityId: agency.id,
            entityName: EntityNameEnum.Agency,
        };

        try {
            const mediaObj = await uploadMediaObject(image, (progress) => {
                image.progress = progress;
            }, entityData);

            if (mediaObj) {
                this.updateAgencyImage(mediaObj);
            }

        } finally {
            this.setAddEditAsync(false);
        }

    }
}

export const agenciesModule = getModule(AgenciesModule);
